import React from 'react';

const EmptyCard = ({ img, message, children }) => {
  return (
    <div className="client__project-container">
      <div className="client__card-dashed ">
        <div className="client__image-container">
          <div className="circle">&nbsp;</div>
          <img src={img} />
        </div>
        <span>{message}</span>
        {children}
      </div>
    </div>
  );
};

export default EmptyCard;
