import React from 'react';

const Stepper = ({ text, isActive, isDone }) => {
  const stepperClassName = isActive ? 'stepper-text--active' : 'stepper-text';
  const indicatorClassName = isDone
    ? 'stepper__indicator stepper__indicator--done'
    : 'stepper__indicator';

  return (
    <div className="stepper">
      <div className={indicatorClassName}>
        {isDone && <span className="material-icons stepper__icon">done</span>}
      </div>
      <div className={stepperClassName}>{text}</div>
    </div>
  );
};
export default Stepper;
