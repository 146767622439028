import React from 'react';
import Paper from '@material-ui/core/Paper';
import { InputBase } from '@material-ui/core';
import { useField } from 'formik';

export default function CustomizedInputBase({ ...props }) {
  const [field, meta] = useField(props)

  let setValueColor = field.value.replace('#', '').toUpperCase()

  return (
    <>
      <Paper elevation={1} className="input_color_container">
        <div className="input_color_prefix">#</div>
        <InputBase
          className='input_color_base'
          value={setValueColor}
        />
        <input
          type='color'
          className="input_color_formatter"
          {...field}
        />
      </Paper>
      {meta.touched && meta.error && <span className="error-message">{meta.error}</span>}

    </>
  );
}