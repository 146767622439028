import { Modal, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Field, Form, Formik, isNaN } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Alert from '../../../../components/common/Alert';
import Button from '../../../../components/common/Button';
import { Input } from '../../../../components/forms';
import InputImage from '../../../../components/forms/Input/inputImage';
import InputWrapper from '../../../../components/forms/Input/inputWrapper';
import { TODAY } from '../../../../utils/constants';
import { ALLOWED_CHAR } from '../../../ProjectsContainer/schemas/landing';
import { AnnouncementContext } from '../AnnouncementContext';
import AnnouncementApi from '../../announcement-api';
import { receptorSchema } from '../../schemas';
import { ACTION } from '../../../../reducer/announcementReducer';
import AnnouncementPreview from '../Preview/AnnouncementPreview';
import { useHistory } from 'react-router-dom';
import ModalContent from '../../../../components/common/Modal/ModalContent';
import { getTomorrowDate } from '../../../../utils/dates';

const ORGANIZATION_ALLOWED_CHAR = 50

const ReceptorForm = ({ onNext }) => {
  const [state, dispatch] = useContext(AnnouncementContext);
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [messageAlert, setMessageAlert] = useState({ type: '', message: '' });
  const [organizations, setOrganizations] = useState([]);
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [organization, setOrganization] = useState(state.organization)

  const initialValues = {
    name: state.announcement.name,
    organization: state.organization,
    logo: state.organization.logo,
    role: 'mentor',
    startDate: state.announcement.startDate,
    endDate: state.announcement.endDate,
    urlMentee: state.announcement.urlMentee,
    urlMentor: state.announcement.urlMentor,
  };


  const updateState = (values) => {

    dispatch({
      type: ACTION.UPDATE_ORGANIZATION,
      payload: { ...values.organization, logo: values.logo },
    });

    dispatch({
      type: ACTION.UPDATE_ANNOUNCEMENT,
      payload: {
        name: values.name,
        startDate: values.startDate,
        endDate: values.endDate,
        urlMentee: values.urlMentee,
        urlMentor: values.urlMentor,
      },
    });
  };

  const onSubmit = (values) => {
    updateState(values);
    onNext();
  };

  const onClickPreview = (values) => {
    updateState(values);
    setOpen(true);
  };

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { organizations },
        } = await AnnouncementApi.fetchOrganizations();
        setOrganizations(organizations);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const onChange = (option, setFieldValue) => {
    const org = option?.id ? option : { name: option, id: null, logo: "" }
    org.logo ? setFieldValue('logo', org.logo) : setFieldValue('logo', '')
    setFieldValue('organization', org)
    setOrganization(org)
  };

  const handlePrev = () => {
    history.goBack();
  };

  return (
    <>
      {loading ? null : (<Formik
        initialValues={initialValues}
        validationSchema={receptorSchema}
        onSubmit={onSubmit}

      >
        {({ setFieldValue, ...props }) => {
          return (
            <>
              <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <ModalContent
                  onClick={() => setIsModalOpen(false)}
                  title="Estás saliendo sin guardar los cambios"
                >
                  <span className="title">
                    ¿Estás seguro que deseas volver?
                  </span>
                  <div className="modal__action-container">
                    <Button
                      type="button"
                      secondary
                      onClick={() => setIsModalOpen(false)}
                    >
                      No
                    </Button>
                    <Button type="button" onClick={handlePrev}>
                      Si
                    </Button>
                  </div>
                </ModalContent>
              </Modal>
              <AnnouncementPreview open={open} onClose={() => setOpen(false)} />
              <Form>
                <div className="wizard__preview-container">
                  <Button
                    type="button"
                    secondary
                    className="button button--flat"
                    onClick={() => onClickPreview(props.values)}
                  >
                    Previsualizar
                  </Button>
                </div>
                <div className="wizard__form">
                  <div className="wizard__grid-section organization-info">
                    <span className="subtitle">
                      Selecciona la fecha de activación del landing de registro
                      y a quien va dirigido.
                    </span>
                    <div className="wizard__grid-section">
                      <InputWrapper label="Nombre de la convocatoria" id="name">
                        <Input name="name" label="Nombre convocatoria" id="name" {...props} />
                        <span className="char-counter">
                          {props.values.name?.length}/{ALLOWED_CHAR}
                        </span>
                      </InputWrapper>

                      <InputWrapper
                        label="Nombre de la organización"
                        id="organizationName"
                      >
                        <>
                          <Autocomplete
                            id="organizationName"
                            freeSolo
                            options={organizations}
                            getOptionLabel={(option) => {
                              return option.name;
                            }}
                            value={props.organization}
                            renderInput={(params) => (
                              <TextField {...params} {...props} placeholder='Nombre de la organización' name="organization.name" />
                            )}
                            onInputChange={(_, option) => onChange(option, setFieldValue, props.setErrors)}
                            onChange={(_, option) => onChange(option, setFieldValue, props.setErrors)}
                            onBlur={(e, option) => {
                              props.setFieldTouched('organization', true);
                            }}
                          />
                          <span className="char-counter">
                            {props.values.organization?.name?.length || 0}/{50}
                          </span>
                          <span className="error-message">{props.touched?.organization && props.errors.organization ? props.errors.organization.name : ''}</span>
                        </>
                      </InputWrapper>

                      <InputWrapper
                        variant="big"
                        label="Logo de la organización"
                        description="Archivos 176x70px  PNG, SVG  - Tamaño máximo 5MB."
                        id="logo"
                      >
                        <InputImage
                          name="logo"
                          id="logo"
                          allowedExts={['image/png', 'image/svg+xml']}
                          {...props}
                        />
                      </InputWrapper>

                      <InputWrapper label="Selecciona un rol">
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'start',
                            gap: 16,
                            marginTop: 16,
                          }}
                        >
                          <label className="label__radio" htmlFor="rol-mentor">
                            <Field
                              type="radio"
                              id="rol-mentor"
                              name="role"
                              value="mentor"
                              onClick={() => {
                                setFieldValue('urlMentee', '');
                                setFieldValue('urlMentor', 'registro-mentor')
                              }}
                            />
                            Mentor
                          </label>
                          <label className="label__radio" htmlFor="rol-mentee">
                            <Field
                              type="radio"
                              id="rol-mentee"
                              name="role"
                              value="mentee"
                              onClick={() => {
                                setFieldValue('urlMentor', '');
                                setFieldValue('urlMentee', 'registro-mentee')
                              }}
                            />
                            Mentee
                          </label>
                          <label className="label__radio" htmlFor="rol-both">
                            <Field
                              type="radio"
                              id="rol-both"
                              name="role"
                              value="both"
                              onClick={() => {
                                setFieldValue('urlMentee', 'registro-mentee');
                                setFieldValue('urlMentor', 'registro-mentor')
                              }}
                            />
                            Ambos
                          </label>
                        </div>
                      </InputWrapper>
                    </div>
                  </div>
                  <div className="wizard__grid-section organization-info">
                    <span className="wizard__title-section">
                      Fecha de activación
                    </span>
                    <InputWrapper label="Fecha de inicio" id="startDate">
                      <Input
                        InputProps={{
                          inputProps: { min: getTomorrowDate()},
                        }}
                        label=""
                        id="startDate"
                        type="date"
                        name="startDate"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </InputWrapper>
                    <InputWrapper label="Fecha de término" id="endDate">
                      <Input
                        InputProps={{
                          inputProps: {
                            min: props.values?.startDate || TODAY,
                          },
                        }}
                        label=""
                        id="endDate"
                        type="date"
                        name="endDate"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </InputWrapper>
                    {props.values.role === 'mentor' ||
                      props.values.role === 'both' ? (
                      <InputWrapper label="URL Mentor" id="urlMentor">
                        <Input
                          name="urlMentor"
                          label={'registroMentor'}
                          id="urlMentor"
                        />
                      </InputWrapper>
                    ) : null}
                    {props.values.role === 'mentee' ||
                      props.values.role === 'both' ? (
                      <InputWrapper label="URL Mentee" id="urlMentee">
                        <Input
                          name="urlMentee"
                          label={'registroMentee'}
                        />
                      </InputWrapper>
                    ) : null}
                  </div>
                  <div className="wizard__action">
                    <Button secondary onClick={() => setIsModalOpen(true)}>
                      Volver
                    </Button>
                    <Button type="submit" loading={false}>
                      Siguiente
                    </Button>
                  </div>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>)}
    </>
  );
};

export default ReceptorForm;
