import React from 'react';
import PropTypes from 'prop-types';

const Title = ({ title }) => {
  return (
    <div className="title">
      <h1>{title}</h1>
    </div>
  );
};

/**  */

/**
 * Description
 * @text {string} 
 * @variant {string} "subtitle--strong" || "subtitle--big"
 * @returns jsxElement
 */
export const StrongSubtitle = ({ text, variant = "subtitle--strong" }) => {
  return <span className={`subtitle ${variant}`}>{text}</span>;
};
//please used in pages with a description example client table
export const Subtitle = ({ text }) => {
  return <span className="subtitle">{text}</span>;
};

Title.propTypes = {
  title: PropTypes.string,
};

export default Title;
