import React, { useState } from 'react';
import { Modal } from '@material-ui/core';

import { useHistory } from 'react-router-dom';

import request from '../../../../utils/request';
import Alert from '../../../../components/common/Alert';
import Button from '../../../../components/common/Button';
import ModalContent from '../../../../components/common/Modal/ModalContent';

const InputEditionSummary = ({ workshopData, onPrev, id }) => {
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [messageAlert, setMessageAlert] = useState(null);
  const [isWorkshopCreated, setWorkshopCreated] = useState(false);

  const handleCreateClick = () => {
    if (!isWorkshopCreated) {
      setModalOpen(true);
    }
  };

  const handleConfirm = () => {
    setModalOpen(false);
    updateWorkshop();
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const updateWorkshop = () => {
    const {
      title,
      description,
      assistants,
      startDate,
      status,
      image,
      recordingLink,
      workshopLink,
      hour,
      receptor,
      estimatedTime,
      pillar,
      file,
    } = workshopData;
    const startDataInfo = startDate + ' ' + hour;

    request({
      url: `/workshop/update/${id}`,
      method: 'PUT',
      private: true,
      data: {
        title,
        description,
        assistants,
        startDate: startDataInfo,
        status,
        image,
        recordingLink,
        workshopLink,
        receptor,
        estimatedTime,
        pillar: pillar.id,
        file,
      },
    })
      .then((response) => {
        setMessageAlert({
          type: 'success',
          message: 'Taller actualizado exitosamente',
        });
        setWorkshopCreated(true);
        setTimeout(() => {
          history.push('/talleres');
        }, 5000);
      })
      .catch((error) => {
        console.error('Error al actualizar el taller:', error);
        setMessageAlert({
          type: 'error',
          message: 'Error al actualizar el taller',
        });
      });
  };

  return (
    <>
      <div className="wizard__form wizard__form--full">
        {messageAlert && (
          <Alert type={messageAlert.type}>{messageAlert.message}</Alert>
        )}

        <div className="wizard__grid-section">
          <h2>Resumen</h2>
          <div
            className="container-summary"
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto auto',
              gridTemplateRows: 'repeat(8,1fr)',
              columnGap: '20px',
            }}
          >
            <div style={{ gridColumn: '1' }}>
              <h3>Título</h3>
              <p>{workshopData.title ? workshopData.title : '-'}</p>
            </div>
            <div style={{ gridColumn: '1' }}>
              <h3>Descripción</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: workshopData.description
                    ? workshopData.description
                    : '-',
                }}
              ></div>
            </div>
            <div style={{ gridColumn: '1' }}>
              <h3>Fecha y horario</h3>
              <div className="summaryInfo">
                <span
                  style={{ color: '#0BB5E7' }}
                  className="material-symbols-outlined"
                >
                  calendar_today
                </span>
                <p>{workshopData.startDate ? workshopData.startDate : '-'}</p>
              </div>
              <div className="summaryInfo">
                <span
                  style={{ color: '#0BB5E7' }}
                  className="material-symbols-outlined"
                >
                  timer
                </span>
                <p>{workshopData.hour ? workshopData.hour : '-'}</p>
              </div>
            </div>
            <div style={{ gridColumn: '1' }}>
              <h3 style={{ whiteSpace: 'nowrap' }}>
                Tiempo estimado de duración
              </h3>
              <div className="summaryInfo">
                <span
                  style={{ color: '#0BB5E7' }}
                  className="material-symbols-outlined"
                >
                  timer
                </span>
                <p>
                  {workshopData.estimatedTime
                    ? workshopData.estimatedTime
                    : '-'}
                </p>
              </div>
            </div>
            <div style={{ gridColumn: '1' }}>
              <h3>Total cupos disponibles</h3>
              <div className="summaryInfo">
                <span
                  style={{ color: '#0BB5E7' }}
                  className="material-symbols-outlined"
                >
                  groups
                </span>
                -
                <p>{workshopData.assistants ? workshopData.assistants : '-'}</p>
              </div>
            </div>
            <div
              style={{
                gridColumn: '2',
                gridRow: '1',
              }}
            >
              <div>
                <h3 style={{ whiteSpace: 'nowrap' }}>
                  Material para las siguientes categorías
                </h3>

                <div
                  style={{
                    borderRadius: '15px',
                    backgroundColor: '#f2f1f2',
                    width: 'fit-content',
                    padding: '7px 0 10px',
                    display: 'flex',
                  }}
                  key={workshopData.pillar.id}
                >
                  <span
                    style={{
                      color: '#0BB5E7',
                      paddingLeft: '5px',
                      paddingRight: '5px',
                    }}
                    className="material-symbols-outlined"
                  >
                    circle
                  </span>

                  {workshopData.pillar.name}
                </div>
              </div>
            </div>
            <div
              style={{
                gridColumn: '2',
                gridRow: '2',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <h3>Links del taller</h3>
              <span>Link de la sala del taller:</span>
              <span style={{ color: '#0BB5E7' }}>
                {workshopData.recordingLink ? workshopData.recordingLink : '-'}
              </span>
              <span>Link de acceso al taller:</span>
              <span style={{ color: '#0BB5E7' }}>
                {workshopData.workshopLink ? workshopData.workshopLink : '-'}
              </span>
            </div>

            <Modal
              open={modalOpen}
              onClose={handleCancel}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  background: '#fff',
                  padding: '20px',
                  borderRadius: '8px',
                  textAlign: 'center',
                }}
              >
                <ModalContent title={'Nuevo taller'} onClick={handleCancel}>
                  <button
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                      fontSize: '24px',
                    }}
                    onClick={handleCancel}
                  />
                  <p>¿Estás seguro que deseas agregar un nuevo taller?</p>
                  <div
                    style={{
                      marginTop: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      gap: '15px',
                    }}
                  >
                    <Button onClick={handleConfirm}>Confirmar</Button>
                    <Button secondary onClick={handleCancel}>
                      Cancelar
                    </Button>
                  </div>
                </ModalContent>
              </div>
            </Modal>
          </div>
        </div>
        <div
          style={{ paddingTop: '30px', maxWidth: '500px', margin: 'auto' }}
          className="wizard__action"
        >
          <Button secondary onClick={onPrev}>
            Volver
          </Button>
          <Button
            type="button"
            onClick={handleCreateClick}
            disabled={isWorkshopCreated}
          >
            Editar taller
          </Button>
        </div>
      </div>
    </>
  );
};

export default InputEditionSummary;
