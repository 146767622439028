import React, { createContext, useReducer } from 'react';
import userReducer, { initialState } from '../../../reducer/userReducer';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  return (
    <UserContext.Provider value={useReducer(userReducer, initialState)}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext };
export default UserProvider;
