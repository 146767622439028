const initialState = {
  client: {
    id: '',
    name: '',
    status: '',
  },
  status: false,
  landing: {
    title: '',
    startDate: '',
    endDate: '',
    urlMentee: 'registro-mentee',
    urlMentor: 'registro-mentor',
    logo: '',
    desktopBanner: '',
    mobileBanner: '',
    about: '',
    items: [
      { icon: '', text: '' },
      { icon: '', text: '' },
      { icon: '', text: '' },
    ],
  },
  login: {
    loginPrimaryColor: '#003965',
    loginSecondaryColor: '#E42313',
    description: `<h4>Estamos creando la red Mipyme más grande de Chile, en la que encontrarás:</h4>`,
  },
  platform: {
    platformBanner: '',
    platformPrimaryColor: '#003965',
    platformSecondaryColor: '#E42313',
    welcomeMessage: `<span>
    Nos encanta que seas parte de ChileConverge, la red más grande de las
    Mipyme nacionales.
    <br />
    Como sabes tenemos distintos servicios para que nunca más te sientas solo.
    <br />
    <strong> Ahora, cuéntanos ¿qué necesitas hoy?</strong>
  </span>`,
  },
};

const ACTION = {
  UPDATE_CLIENT: 'UPDATE_CLIENT',
  UPDATE_ANNOUNCEMENT_STATUS: 'UPDATE_ANNOUNCEMENT_STATUS',
  UPDATE_LOGIN: 'UPDATE_LOGIN',
  UPDATE_LANDING: 'UPDATE_LANDING',
  UPDATE_PLATFORM: 'UPDATE_PLATFORM',
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.UPDATE_CLIENT:
      return { ...state, client: action.payload.client };
      break;

    case ACTION.UPDATE_ANNOUNCEMENT_STATUS:
      return {
        ...state,
        status: action.payload.status,
      };
      break;

    case ACTION.UPDATE_LOGIN:
      return {
        ...state,
        login: action.payload.login,
      };

    case ACTION.UPDATE_LANDING:
      return {
        ...state,
        landing: action.payload.landing,
      };
      break;

    case ACTION.UPDATE_PLATFORM:
      return {
        ...state,
        platform: action.payload.platform,
      };
      break;

    default:
      return state;
      break;
  }
};
export { initialState, ACTION };
export default projectReducer;
