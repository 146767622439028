import React from 'react';
import { Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Button from '../Button';

export const SectionCard = ({ title, caracteristics, img, link }) => {
  const history = useHistory();
  return (
    <Paper className="client__card">
      <div className="client__image-container client__image-container--start">
        <img src={img} />
      </div>
      <div>
        <span className="title">{title}</span>
        <ul>
          {caracteristics.map((caracteristic) => (
            <li>{caracteristic}</li>
          ))}
        </ul>
      </div>
      <Button type="button" onClick={() => history.push(link)}>
        Ver más
      </Button>
    </Paper>
  );
};
