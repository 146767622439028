export const getTomorrowDate = () => {
  const tomorrow = new Date(Date.now() + 3600 * 1000 * 24);
  return tomorrow.toISOString().split('T')[0];
};

export const AVAILABLE_HOURS = [
  { id: 8, value: '08:00' },
  { id: 9, value: '09:00' },
  { id: 10, value: '10:00' },
  { id: 11, value: '11:00' },
  { id: 12, value: '12:00' },
  { id: 13, value: '13:00' },
  { id: 14, value: '14:00' },
  { id: 15, value: '15:00' },
  { id: 16, value: '16:00' },
  { id: 17, value: '17:00' },
  { id: 18, value: '18:00' },
  { id: 19, value: '19:00' },
  { id: 20, value: '20:00' },
  { id: 21, value: '21:00' },
];
