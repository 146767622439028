import { useField } from 'formik';
import React from 'react';
import {
  getImagefromBase64,
  handleFileRead,
  isValidImageSize,
  isValidImagetype,
} from '../../../utils/images';

const InputImage = (props) => {
  const [field, meta, helpers] = useField(props.name);

  const renderImage = () =>
    field.value.name
      ? getImagefromBase64(field.value)
      : field.value?.includes('http')
      ? field.value
      : null;

  const isThereImage = field.value?.name
    ? true
    : field.value?.includes('http')
    ? true
    : false;

  const onChange = async (e) => {
    if (isValidImageSize(e.target.files[0])) {
      if (isValidImagetype(props.allowedExts, e.target.files[0])) {
        const base64 = await handleFileRead(e.target.files);
        helpers.setValue(base64);
      } else {
        helpers.setError(
          `Asegurate que la imagen sea de formato ${props.allowedExts.join(
            ', '
          )}`
        );
      }
    } else {
      helpers.setError('Asegurate que la imagen sea menor a 5MB');
    }
  };
  const icon = props.iconName || 'file_upload';
  const onClick = () => {
    helpers.setValue('');
  };
  return (
    <div
      className="input-image__container"
      onClick={() => helpers.setTouched(true)}
    >
      <label
        className={`${
          props.disabled ? 'input-image input-image--disabled' : 'input-image'
        }`}
        htmlFor={props.id}
      >
        <span className="material-symbols-outlined input-image__icon">
          {icon}
        </span>
        <span className="input-image__text">Cargar archivo</span>
      </label>
      <input
        type="file"
        name={props.name}
        className="input-image__field"
        id={props.id}
        onChange={(e) => onChange(e)}
        accept="image/png, image/svg+xml"
        disabled={props.disabled}
      ></input>

      {isThereImage && (
        <div className="input-image__file-container">
          <div className="image-container">
            <img src={renderImage()} alt="imagen" />
            {!props.disabled && (
              <div className="image-eraser" onClick={onClick}>
                <span className="material-icons icon">close</span>
              </div>
            )}
          </div>
        </div>
      )}

      {meta.error && meta.touched && (
        <span className="error-message">{meta.error}</span>
      )}
    </div>
  );
};

export default InputImage;
