import React, { useEffect, useMemo, useState } from 'react';
import BreadCrumb from '../../../components/Breadcrumbs';
import EnhancedTable from '../../../components/common/Table';
import useDebounce from '../../../hooks/useDebounce';
import UsersAPI from '../../UserContainer/user-api';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import StatusIndicator from '../../../components/common/StatusIndicator';
import { USER_STATUS_CLASSNAMES } from '../../ClientContainer/constants';
import UsersModal from './userModal';
import Alert from '../../../components/common/Alert';

const LIMIT_PER_PAGE = 25;
const AddAnnouncementUser = () => {
  const history = useHistory();
  const {
    state: { announcementId, clientId, role },
  } = useLocation();
  const links = [
    { url: '/convocatorias', text: 'Convocatorias' },
    { url: '/convocatorias/detalle', text: 'Detalle convocatoria' },
    { url: '', text: 'Nuevo participante' },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [activePage, setActivePage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [users, setUsers] = useState([]);
  const [usersChecked, setUsersChecked] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });

  const debouncedTerm = useDebounce(filter, 1000);

  useEffect(() => {
    if (!debouncedTerm) {
      (async () => {
        try {
          setIsLoading(true);

          const { data } = await UsersAPI.getUsers(
            [role],
            activePage,
            LIMIT_PER_PAGE
          );
          setUsers(data.users);
          setTotalPages(data.totalPages);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [activePage, debouncedTerm]);

  useEffect(() => {
    if (debouncedTerm !== '') {
      (async () => {
        try {
          setIsLoading(true);
          const { data } = await UsersAPI.searchUsers(role, debouncedTerm);
          setUsers(data.users);

          setTotalPages(1);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [debouncedTerm]);

  useEffect(() => {
    if (alertMessage.message !== '') {
      setTimeout(() => {
        history.goBack();
      }, 2000);
    }
  }, [alertMessage]);

  const onClickCheck = (id) => {
    const checkedList = usersChecked.some((us) => us === id)
      ? usersChecked.filter((us) => us !== id)
      : [id, ...usersChecked];

    setUsersChecked(checkedList);
  };

  const onChangePage = (event, newPage) => {
    setActivePage(newPage);
  };

  const renderUserStatus = (status) => {
    switch (status) {
      case 'active':
        return (
          <StatusIndicator
            statusClassName={USER_STATUS_CLASSNAMES[status]}
            text="Activo"
          />
        );
      case 'inactive':
        return (
          <StatusIndicator
            statusClassName={USER_STATUS_CLASSNAMES[status]}
            text="Inactivo"
          />
        );
      case 'deactivated':
        return (
          <StatusIndicator
            statusClassName={USER_STATUS_CLASSNAMES[status]}
            text="Desactivado"
          />
        );
      case 'rejected':
        return (
          <StatusIndicator
            statusClassName={USER_STATUS_CLASSNAMES[status]}
            text="rechazado"
          />
        );
      default:
        return (
          <StatusIndicator
            statusClassName={USER_STATUS_CLASSNAMES[status]}
            text="Sin especificar"
          />
        );
    }
  };
  const columns = useMemo(
    () => [
      {
        id: 'select',
        Header: ({ table }) => (
          <input type="checkbox" onChange={() => {}}></input>
        ),
        Cell: ({ row }) => {
          return (
            <input
              type="checkbox"
              checked={usersChecked.some((us) => us.id === row.original.id)}
              onChange={(e) => onClickCheck(row.original)}
            ></input>
          );
        },
      },
      { Header: 'Nombre', accessor: 'name' },
      { Header: 'Email', accessor: 'email' },
      { Header: 'Pilar', accessor: 'pillar.name' },
      {
        Header: 'Referer',
        accessor: 'referer',
        Cell: ({ value }) => (value ? value : '-'),
      },
      {
        Header: 'Estado',
        accessor: 'status',
        Cell: ({ value }) => {
          return renderUserStatus(value);
        },
      },
    ],
    [usersChecked]
  );
  return (
    <>
      {alertMessage.type !== '' && (
        <Alert type={alertMessage.type}>{alertMessage.message}</Alert>
      )}
      <div className="content-panel user-container">
        <BreadCrumb links={links} />
        <h2>Nuevo participante - {role || 'Mentee'}</h2>
        <EnhancedTable
          data={users}
          columns={columns}
          isLoadingData={isLoading}
          tableName={`Participantes ${usersChecked.length || 0}`}
          buttonLabel="Agregar a convocatoria"
          onClickButton={() => setIsModalOpen(true)}
          onChangePage={onChangePage}
          currentPage={activePage}
          totalPages={totalPages}
          onSearch={(e) => setFilter(e.target.value)}
        />
        <UsersModal
          setAlertMessage={setAlertMessage}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          users={usersChecked}
          setUsersChecked={setUsersChecked}
          announcementId={announcementId}
          role={role}
        />
      </div>
    </>
  );
};
export default AddAnnouncementUser;
