import request from '../../utils/request';
function fetchActivePillars () {
  return request({
    url: 'pillars?active=true&owned=true',
    method: 'GET',
    private: true,
   
  });
}
function getPillars() {
  return request({
    url: 'pillars',
    method: 'GET',
    private: true,
  });
}
function getPillar(id) {
  return request({
    url: `pillar/get/${id}`,
    method: 'GET',
    private: true,
  });
}
function addPillar(payload) {
  return request({
    url: 'pillars',
    method: 'POST',
    data: payload,
    private: true,
  });
}
function updatePillar(payload) {
  return request({
    url: `pillars/${payload.id}`,
    method: 'PATCH',
    private: true,
    data: payload,
  });
}

const PillarAPI = {
  fetchActivePillars,
  getPillars,
  addPillar,
  updatePillar,
  getPillar,
};

export default PillarAPI;
