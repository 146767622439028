import React, { useState } from 'react';

const ReceptorInput = ({ name }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleBlur = () => {};

  return (
    <div>
      <label>
        <p className="title">Usuario receptor del taller</p>
        <select
          name={name}
          className="input"
          value={selectedOption}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <option value="">Seleccione una opción</option>
          <option value="mentor">Mentor</option>
          <option value="mentee">Mentee</option>
          <option value="both">Ambos</option>
        </select>
      </label>
    </div>
  );
};

export default ReceptorInput;
