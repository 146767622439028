import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import { Form, Formik } from 'formik';

import * as Yup from 'yup';
import { Input } from '../../../../components/forms';
import InputWrapper from '../../../../components/forms/Input/inputWrapper';
import Button from '../../../../components/common/Button';

const DateWorkShop = ({ onPrev, onNext, workShopData, setWorkShopData }) => {
  const validationSchema = Yup.object().shape({
    assistants: Yup.number()
      .typeError('Ingrese el número de cupos disponibles')
      .required('Ingrese el número de cupos disponibles'),
    startDate: Yup.date().required('Seleccione una fecha'),
    hour: Yup.string().required('Seleccione una hora'),
    estimatedTime: Yup.string().required(
      'Ingrese el tiempo estimado de duración'
    ),
  });

  const onSubmit = (values) => {
    setWorkShopData(values);
    onNext();
  };

  const today = new Date().toISOString().split('T')[0];

  return (
    <div className="wizard__form wizard__form--full">
      <Formik
        onSubmit={onSubmit}
        initialValues={workShopData}
        validationSchema={validationSchema}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <>
              <Form>
                <div className="wizard__grid-section">
                  <h3>Horarios y cupos</h3>

                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'auto auto',
                      gap: '20px',
                    }}
                  >
                    <InputWrapper
                      variant="full"
                      id="assistants"
                      name="assistants"
                      label="Total de Cupos Disponibles:"
                    >
                      <Input
                        id="assistants"
                        name="assistants"
                        type="number"
                        max={1000}
                        min={1}
                        value={values.assistants}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputWrapper>

                    <InputWrapper variant="full" id="startDate" label="Fecha:">
                      <Input id="startDate" name="startDate" type="date" />
                    </InputWrapper>

                    <InputWrapper id="hour" variant="full" label="Hora:">
                      <Input id="hour" name="hour" type="time" />
                    </InputWrapper>

                    <InputWrapper
                      variant="full"
                      id="estimatedTime"
                      label="Tiempo Estimado de Duración:"
                    >
                      <Input name="estimatedTime" type="datetime" />
                    </InputWrapper>
                  </div>
                  <div className="wizard__action">
                    <Button secondary onClick={onPrev}>
                      Volver
                    </Button>
                    <Button type="submit">Siguiente</Button>
                  </div>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default DateWorkShop;
