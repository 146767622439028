import React, { createContext, useReducer } from 'react';

import projectReducer, { initialState } from '../../reducer/projectReducer';
const ProjectContext = createContext();
const ProjectProvider = ({ children }) => {
  return (
    <ProjectContext.Provider value={useReducer(projectReducer, initialState)}>
      {children}
    </ProjectContext.Provider>
  );
};
export { ProjectContext };
export default ProjectProvider;
