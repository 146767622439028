import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { date } from 'yup';
import Alert from '../../../components/common/Alert';
import Button from '../../../components/common/Button';
import LinkComponent from '../../../components/common/Link';
import { formatDate } from '../../../utils/format';
import Wizard from '../../ClientContainer/ClientCreationContainer/wizard';
import UsersAPI from '../../UserContainer/user-api';
import SessionForm from './sessionForm';
import SessionSumary from './sessionSummary';
import ConfirmationModal from '../../ClientContainer/statusModal';

const SessionCreationContainer = () => {
  const { state } = useLocation();

  const [meetingList, setMeetingList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bussyDates, setBussyDates] = useState([]);
  const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState('');
  const [session, setSession] = useState({
    mentorId: '',
    menteeName: state.menteeName,
    menteeId: state.menteeId,
    title: '',
    description: '',
    startDay: '',
    startTime: '',
    timesToRepeat: 0,
    daysToRepeat: 0,
    client: state.clientId,
  });

  // generates array with future datetimes
  // with format YYYY-MM-DD for input reading
  const generateMeetingList = ({
    repeatingTimes,
    daysOfDifference,
    startDay,
    startTime,
  }) => {
    const meetings = Array.from({ length: repeatingTimes }, (_, index) => {
      const dateMeeting = formatDate(
        moment(startDay).add(+daysOfDifference * index, 'days'),
        'YYYY-MM-DD'
      );
      return { date: dateMeeting, time: startTime };
    });

    return meetings;
  };

  //allows to show a list of meetings
  useEffect(() => {
    (async () => {
      if (session.mentorId) {
        //getting if mentor is busy
        const { data } = await UsersAPI.getBusySchedule(
          session.mentorId,
          session.menteeId
        );
        setBussyDates(data);

        const meets = generateMeetingList({
          repeatingTimes: session.timesToRepeat,
          daysOfDifference: session.daysToRepeat,
          startDay: session.startDay,
          startTime: session.startTime,
        });

        //validates session dates proposed
        //and add message in case users are not available
        const confirmedMeets = meets.map((meet) => {
          const time = meet.time > 9 ? `${meet.time}:00` : `0${meet.time}:00`;
          const bussy = data.find((d) =>
            d.date.includes(`${meet.date} ${time}`)
          );

          if (bussy) {
            return { ...meet, msg: bussy.message };
          } else {
            return meet;
          }
        });

        setMeetingList(confirmedMeets);
      }
    })();
  }, [session]);

  const onClick = () => {
    if (!meetingList.length) {
      setAlertMessage({
        type: 'error',
        message: 'Debes rellenar el formulario con los datos de la reunión',
      });
      setOpenModal(false);
      return;
    }
    setOpenModal(true);
  };

  const onSubmit = async (notify = false) => {
    try {
      setOpenModal(false);
      setIsSubmitting(true);
      const calendar = meetingList.map((meet) => {
        const time = meet.time > 9 ? `${meet.time}:00` : `0${meet.time}:00`;
        return `${meet.date} ${time}`;
      });
      await UsersAPI.createMassiveSessions({
        calendar,
        idMentee: session.menteeId,
        idMentor: session.mentorId,
        title: session.title,
        description: session.description,
        notify,
        clientId: session.client,
        announcementId: state.announcementId,
      });

      setAlertMessage({
        type: 'success',
        message: 'Reunión creada exitosamente',
      });
    } catch (error) {
      setAlertMessage({
        type: 'error',
        message: error.data.message,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="client-container">
        <div className="client__back-container">
          <LinkComponent
            title="Volver"
            url="/clientes"
            icon="arrow_back"
          ></LinkComponent>
        </div>
        <Wizard title="Crear nueva reunión">
          <div className="session">
            {alertMessage.message && (
              <Alert type={alertMessage.type}>{alertMessage.message}</Alert>
            )}
            <span className="title">Participantes</span>
            <div className="session__container">
              <SessionForm session={session} setSession={setSession} />
              <SessionSumary
                session={session}
                meetingList={meetingList}
                setMeetingList={setMeetingList}
                bussyDates={bussyDates}
              />
            </div>
            <div className="button-container">
              <Button secondary>Cancelar</Button>
              <Button loading={isSubmitting} onClick={onClick}>
                Confirmar reuniones
              </Button>
            </div>
          </div>
        </Wizard>
      </div>
      <ConfirmationModal
        open={openModal}
        header="Notificación"
        title="Desea Notificar a los participantes"
        onClose={() => setOpenModal(false)}
        handleDenied={() => onSubmit(false)}
        handleAccept={() => onSubmit(true)}
      />
    </>
  );
};

export default SessionCreationContainer;
