import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Input } from '../../../components/forms';
import { FormControlLabel, Grid, IconButton, Switch } from '@material-ui/core';
import {
  AccountBalance,
  AddToHomeScreen,
  Assessment,
  BubbleChart,
  BusinessCenter,
  Close,
  Create,
  Devices,
  Edit,
  Group,
  Info,
  LocalMall,
  QuestionAnswer,
  ShowChart,
  Storefront,
  TrendingUp,
} from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import PillarAPI from '../pillar-api';
import Button from '../../../components/common/Button';
import Alert from '../../../components/common/Alert';
import { formatDate } from '../../../utils/format';
import { Skeleton } from '@material-ui/lab';

const PillarEditContainer = () => {
  let timer;
  const history = useHistory();
  const location = useLocation();
  const [pillar, setPillar] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const [initialValues, setInitialValues] = useState({
    name: '',
    description: '',
    active: false,
    iconName: '',
  });
  const [iconName, setIconName] = useState(null);
  const [currentIcon, setCurrentIcon] = useState(null);
  const [message, setMessage] = useState(null);

  const handleClick = (name) => {
    if (currentIcon !== name) {
      setCurrentIcon(name);
      setIconName(name);
    } else {
      setCurrentIcon('');
      setIconName('');
    }
  };

  useEffect(() => {
    // eslint-disable-next-line
    timer = setTimeout(function () {
      setShowAlert(false);
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  });

  useEffect(() => {
    if (location.state && location.state.pillarId) {
      PillarAPI.getPillar(location.state.pillarId).then((response) => {
        setPillar(response.data);
        setInitialValues({
          name: response.data.name,
          description: response.data.description,
          active: response.data.active,
          iconName: response.data.iconName,
        });
        setIconName(response.data.iconName)
      });
    }
  }, [location.state]);

  useEffect(() => {
    if (pillar.name) {
      setIsLoading(false);
    }
  }, [pillar]);

  const handleSubmit = (values) => {
    if (iconName === null || iconName === '') {
      setMessage('Icono requerido');
    } else {
      setMessage('');
      values.iconName = iconName;
      PillarAPI.updatePillar({ ...values, id: pillar.id })
        .then((response) => {
          history.push({
            pathname: '/pilares',
            state: {
              message: response.data.message,
              type: 'success',
            },
          });
        })
        .catch((e) => {
          setShowAlert(true);
          setMessageAlert(e.data.message);
        });
    }
  };

  return !isLoading ? (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        name: Yup.string().required('Nombre requerido'),
        description: Yup.string().required('Descripción requerida'),
      })}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue }) => (
        <Form>
          <Grid container className="user-container__creation">
            <Grid item xs={12}>
              {showAlert && <Alert type="error">{messageAlert}</Alert>}
            </Grid>
            <Grid
              item
              xs={4}
              className="title"
              style={{ backgroundColor: 'white' }}
            >
              <IconButton
                aria-label="delete"
                className="delete-button none-mentor"
                onClick={() => history.push('/pilares')}
              >
                <Close />
              </IconButton>
              <Edit />
              <span>Editar pilar</span>
            </Grid>
            <Grid className="content" container>
              <Grid item xs={4} style={{ backgroundColor: 'white' }}>
                <Grid
                  container
                  direction="column"
                  className="personal-info"
                  style={{ backgroundColor: 'white' }}
                >
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}>
                    <span>
                      <strong>Nombre</strong>
                    </span>
                    <Input name="name" placeholder="Nombre" label="" />
                  </Grid>
                  <Grid item xs={12}>
                    <span>
                      <strong>Descripción</strong>
                    </span>
                    <Input
                      name="description"
                      placeholder="Description"
                      label=""
                    />
                  </Grid>
                  <Grid container alignItems="flex-end">
                    <Grid item xs={12}>
                      <span className="current-icon">
                        <strong>Icono actual:</strong>
                      </span>
                      {pillar.iconName === 'Info' ? (
                        <Info />
                      ) : pillar.iconName === 'TrendingUp' ? (
                        <TrendingUp />
                      ) : pillar.iconName === 'AccountBalance' ? (
                        <AccountBalance />
                      ) : pillar.iconName === 'BusinessCenter' ? (
                        <BusinessCenter />
                      ) : pillar.iconName === 'Assesment' ? (
                        <Assessment />
                      ) : pillar.iconName === 'Devices' ? (
                        <Devices />
                      ) : pillar.iconName === 'AddToHomeScreen' ? (
                        <AddToHomeScreen />
                      ) : pillar.iconName === 'Group' ? (
                        <Group />
                      ) : pillar.iconName === 'LocalMall' ? (
                        <LocalMall />
                      ) : pillar.iconName === 'QuestionAnswer' ? (
                        <QuestionAnswer />
                      ) : pillar.iconName === 'ShowChart' ? (
                        <ShowChart />
                      ) : pillar.iconName === 'Storefront' ? (
                        <Storefront />
                      ) : pillar.iconName === 'BubbleChart' ? (
                        <BubbleChart />
                      ) : pillar.iconName === 'Create' ? (
                        <Create />
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      style={{ marginTop: '10px' }}
                    >
                      <Grid item xs={2}>
                        <TrendingUp
                          className={
                            currentIcon === 'TrendingUp' ? 'selected' : 'icon-grid'
                          }
                          onClick={() => {
                            handleClick('TrendingUp');
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <AccountBalance
                          className={
                            currentIcon === 'AccountBalance' ? 'selected' : 'icon-grid'
                          }
                          onClick={() => {
                            handleClick('AccountBalance');
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <BusinessCenter
                          className={
                            currentIcon === 'BusinessCenter' ? 'selected' : 'icon-grid'
                          }
                          onClick={() => {
                            handleClick('BusinessCenter');
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Assessment
                          className={
                            currentIcon === 'Assesment' ? 'selected' : 'icon-grid'
                          }
                          onClick={() => {
                            handleClick('Assesment');
                          }}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <Devices
                          className={
                            currentIcon === 'Devices' ? 'selected' : 'icon-grid'
                          }
                          onClick={() => {
                            handleClick('Devices');
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <AddToHomeScreen
                          className={
                            currentIcon === 'AddToHomeScreen' ? 'selected' : 'icon-grid'
                          }
                          onClick={() => {
                            handleClick('AddToHomeScreen');
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Group
                          className={currentIcon === 'Group' ? 'selected' : 'icon-grid'}
                          onClick={() => {
                            handleClick('Group');
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <LocalMall
                          className={
                            currentIcon === 'LocalMall' ? 'selected' : 'icon-grid'
                          }
                          onClick={() => {
                            handleClick('LocalMall');
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <QuestionAnswer
                          className={
                            currentIcon === 'QuestionAnswer' ? 'selected' : 'icon-grid'
                          }
                          onClick={() => {
                            handleClick('QuestionAnswer');
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <ShowChart
                          className={
                            currentIcon === 'ShowChart' ? 'selected' : 'icon-grid'
                          }
                          onClick={() => {
                            handleClick('ShowChart');
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Storefront
                          className={
                            currentIcon === 'Storefront' ? 'selected' : 'icon-grid'
                          }
                          onClick={() => {
                            handleClick('Storefront');
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <BubbleChart
                          className={
                            currentIcon === 'BubbleChart' ? 'selected' : 'icon-grid'
                          }
                          onClick={() => {
                            handleClick('BubbleChart');
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Create
                          className={currentIcon === 'Create' ? 'selected' : 'icon-grid'}
                          onClick={() => {
                            handleClick('Create');
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Info
                          className={currentIcon === 'Info' ? 'selected' : 'icon-grid'}
                          onClick={() => {
                            handleClick('Info');
                          }}
                        />
                      </Grid>
                      <Grid container>
                        <div className="mb-3">
                          {!!message && (
                            <h4 className="error-message2">{message}</h4>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      className="switch-label"
                      control={
                        <Switch
                          defaultChecked={initialValues.active}
                          onChange={(e) => {
                            setFieldValue('active', e.target.checked);
                          }}
                          name="active"
                        />
                      }
                      label="Activar Pilar"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <p>
                      <strong>Fecha de creación:</strong> <br />
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        <span>{formatDate(pillar.createdAt)}</span>
                      )}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <p>
                      <strong>Fecha de modificación:</strong> <br />
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        <span>{formatDate(pillar.updatedAt)}</span>
                      )}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={4}
                  style={{ backgroundColor: 'white' }}
                  className="submit"
                >
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid item xs={8}>
                      <Button type="submit" widthFull>
                        Aceptar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  ) : (
    <></>
  );
};

export default PillarEditContainer;
