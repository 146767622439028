import { Modal } from '@material-ui/core';
import React from 'react';
import Button from '../../../../components/common/Button';
import ContentPreview from './contentPreview';
import MenuPreview from './menuPreview';
import NavbarPreview from './navbarPreview';

const PlatformPreview = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} className="modal">
      <div className="modal__container modal--full preview__landing">
        {/* HEADER */}
        <div className="modal__title modal__title--secondary">
          <span className="title">Previsualización del cliente</span>
          <Button onClick={onClose}>
            <span className="material-symbols-outlined">arrow_back</span>
            <span>Volver</span>
          </Button>
        </div>
        <NavbarPreview />
        <div className="platform__content-container">
          <MenuPreview />
          <ContentPreview />
        </div>
      </div>
    </Modal>
  );
};
export default PlatformPreview;
