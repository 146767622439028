import React, { useState, useEffect, useReducer, useContext } from 'react';
import Button from '../../../../components/common/Button';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import Input from '../../../../components/forms/Input';
import Select from '../../../../components/forms/Select';
import Checkbox from '../../../../components/forms/Checkbox';
import Card from '../../../../components/forms/Card';
import Alert from '../../../../components/common/Alert';
import Skeleton from 'react-placeholder';
import SettingsAPI from '../../../SettingContainer/setting-api';
import { useLocation } from 'react-router-dom';
import UserAutoSave from '../UserAutoSave';
import { UserContext } from '../userContext';
import UsersAPI from '../../user-api';

const CustomFields = ({ setShowAlert, setMessageAlert }) => {
  const location = useLocation();
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState({});
  const [validation, setValidation] = useState({});
  const [messageResponse, setMessageResponse] = useState({
    mesage: '',
    type: '',
    show: false,
  });
  const [state, dispatch] = useContext(UserContext);
  const { user } = state;

  const handleSubmit = (values) => {
    setIsSubmit(true);
    setMessageResponse({
      message: 'Actualizado',
      type: 'success',
      show: true,
    });
    setIsSubmit(false);
    setIsSubmit(false);

    let fieldsForUpdating = {};
    //set values according questions from api to be sent
    fields.map((field) => {
      return (fieldsForUpdating[field.name] = values[field.name]);
    });

    UsersAPI.updateExtraData(user.id, fieldsForUpdating)
      .then(({ data }) => {
        setMessageResponse({
          message: data.message,
          type: 'success',
          show: true,
        });
        setIsSubmit(false);
      })
      .catch((error) => {
        setIsSubmit(false);
        setShowAlert(true);
        setMessageAlert(error.data.message);
      });
  };

  const fetchFields = () => {
    SettingsAPI.getFields(user.role.type)
      .then(({ data }) => {
        setFields(data);
        let validation = {};
        let emptyData = {};
        data.map((field) => {
          if (
            field.type === 'text' ||
            field.type === 'textarea' ||
            field.type === 'select' ||
            field.type === 'cards'
          ) {
            emptyData[field.name] = '';
            if (field.isRequired) {
              validation[field.name] = Yup.string().required(
                'Ingrese ' + field.placeholder
              );
            }
          }
          if (field.type === 'number') {
            emptyData[field.name] = '';
            if (field.isRequired) {
              validation[field.name] = Yup.number().required(
                'Ingrese ' + field.placeholder
              );
            }
          }
          if (field.type === 'date') {
            if (field.isRequired) {
              validation[field.name] = Yup.date().required(
                'Ingrese ' + field.placeholder
              );
            }
          }
          if (field.type === 'checkbox') {
            emptyData[field.name] = false;
          }
          return true;
        });
        setValidation(validation);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchFields();
  }, []);

  return (
    <div className="edit-information-user">
      <h3>Edita información adicional del usuario</h3>
      {loading ? (
        <Skeleton showLoadingAnimation type="text" rows={15} className="mt-4" />
      ) : (
        <Formik
          // enableReinitialize
          initialValues={user}
          validationSchema={Yup.object(validation)}
          onSubmit={handleSubmit}
          validateOnChange
        >
          <Form className="mt-3">
            <UserAutoSave context="extraData" />
            <Grid container justify="center">
              {/** Datos adicionales **/}
              <Grid
                item
                xs={10}
                style={{
                  backgroundColor: 'white',
                }}
              >
                {messageResponse.show && (
                  <Alert type={messageResponse.type}>
                    {messageResponse.message}
                  </Alert>
                )}
                {fields.length &&
                  fields.map((field) => {
                    return field.type === 'text' || field.type === 'number' ? (
                      <div key={field.id}>
                        <Input
                          name={field.name}
                          label={field.placeholder}
                          type={field.type}
                          isRequired={field.isRequired}
                        />
                      </div>
                    ) : field.type === 'textarea' ? (
                      <div key={field.id}>
                        <Input
                          name={field.name}
                          label={field.label}
                          placeholder={field.placeholder}
                          type={field.type}
                          max={500}
                          showCounter
                          isRequired={field.isRequired}
                        />
                      </div>
                    ) : field.type === 'cards' ? (
                      <div key={field.id}>
                        <Card
                          name={field.name}
                          label={field.placeholder}
                          type={field.type}
                          options={field.options}
                          isRequired={field.isRequired}
                        />
                      </div>
                    ) : field.type === 'select' ? (
                      <div key={field.id}>
                        <Select
                          name={field.name}
                          label={field.label}
                          type={field.type}
                          options={field.options}
                          advancedOptions={[]}
                          placeholder={field.placeholder}
                          isRequired={field.isRequired}
                        />
                      </div>
                    ) : field.type === 'date' ? (
                      <div key={field.id}>
                        <Input
                          name={field.name}
                          label={field.placeholder}
                          type={field.type}
                          InputLabelProps={{ shrink: true }}
                          isRequired={field.isRequired}
                        />
                      </div>
                    ) : field.type === 'checkbox' ? (
                      <div key={field.id} className="checkbox">
                        <Checkbox
                          name={field.name}
                          label={field.placeholder}
                          type={field.type}
                        />
                      </div>
                    ) : null;
                  })}
              </Grid>
              <Grid
                item
                xs={12}
                style={{ backgroundColor: 'white' }}
                className="submit"
              >
                <Grid container justify="center">
                  <Grid item xs={4}>
                    <Button type="submit" loading={isSubmit} widthFull>
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      )}
    </div>
  );
};

export default CustomFields;
