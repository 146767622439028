import React, { useEffect, useState, useContext } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Input } from '../../../components/forms';
import { Grid } from '@material-ui/core';
import Category from '../../../components/common/Category';
import { Edit } from '@material-ui/icons';
import Button from '../../../components/common/Button';
import { useHistory, useLocation } from 'react-router-dom';
import UsersAPI from '../user-api';
import PillarAPI from '../../PillarContainer/pillar-api';
import { Skeleton } from '@material-ui/lab';
import { AppContext } from '../../../app/AppContext';
import Alert from '../../../components/common/Alert';

const UserCreationContainer = () => {
  const location = useLocation();
  const history = useHistory();
  const { roles: rolesContext } = useContext(AppContext);
  const [pillars, setPillars] = useState([]);
  const [selectedPillar, setSelectedPillar] = useState();
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('mentee');
  const [isLoading, setIsLoading] = useState(true);
  const [path, setPath] = useState('usuarios');
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');

  useEffect(() => {
    location.pathname.split('/')[1] === 'mentores' && setPath('mentores');
  }, [location.pathname]);

  useEffect(() => {
    PillarAPI.getPillars().then((response) => {
      setPillars(response.data);
    });
    setRoles(rolesContext.roles);
    setIsLoading(false);
  }, [rolesContext.roles]);

  useEffect(() => {
    if (selectedRole !== 'mentor') {
      setSelectedPillar(null);
    }
  }, [selectedRole]);

  const addPillar = (pillar) => {
    setSelectedPillar(pillar);
  };
  const addRole = (role) => {
    setSelectedRole(role);
  };

  const handleSubmit = (values) => {
    var rolSelected = roles.find((rol) => {
      return rol.type === selectedRole;
    });

    UsersAPI.createUser({
      ...values,
      role: rolSelected.id,
      pillar: selectedPillar,
    })
      .then((response) => {
        history.push({
          pathname: `/${path}`,
          state: {
            message: response.data.message,
            type: 'success',
          },
        });
      })
      .catch((e) => {
        setMessageAlert(e.data.message);
        setShowAlert(true);
      });
  };

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        password: '',
      }}
      validationSchema={Yup.object({
        name: Yup.string().required('Nombre requerido.'),
        email: Yup.string()
          .email('Formato de email incorrecto.')
          .required('Email requerido.'),
        password: Yup.string().required('Contraseña requerida.'),
      })}
      onSubmit={handleSubmit}
    >
      <Form>
        <Grid container className="user-container__creation">
          <Grid item xs={8}>
            {showAlert && <Alert type="error">{messageAlert}</Alert>}
          </Grid>
          <Grid
            item
            xs={8}
            className="title"
            style={{ backgroundColor: 'white' }}
          >
            <Edit />
            <span>
              Crear {path === 'mentores' ? 'mentor' : 'usuario'} nuevo
            </span>
          </Grid>
          <Grid container className="content">
            <Grid
              item
              xs={4}
              style={{
                backgroundColor: 'white',
                border: '1px solid rgba(0, 0, 0, 0.1)',
              }}
            >
              <Grid
                container
                direction="column"
                className="personal-info"
                style={{ backgroundColor: 'white' }}
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    justify="flex-start"
                    alignItems="center"
                    direction="column"
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <span>
                        <strong>Seleccione un rol</strong>
                      </span>
                    </Grid>
                    {!isLoading ? (
                      roles &&
                      roles.map((rol) => {
                        return (
                          <Grid
                            item
                            xs={4}
                            className="personal-info__role"
                            key={rol.id}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <div
                              className={
                                selectedRole && selectedRole === rol.type
                                  ? 'pilares__category-item active'
                                  : 'pilares__category-item'
                              }
                              onClick={() => {
                                addRole(rol.type);
                              }}
                            >
                              <Category
                                title={rol.name}
                                description={rol.description}
                              />
                            </div>
                          </Grid>
                        );
                      })
                    ) : (
                      <>
                        <Skeleton>
                          <Grid item xs={4}>
                            {' '}
                            <div className={'pilares__category-item'}>
                              <Category
                                title={'rol.name'}
                                description={'rol.description'}
                              />
                            </div>
                          </Grid>
                        </Skeleton>
                        <Skeleton>
                          <Grid item xs={4}>
                            {' '}
                            <div className={'pilares__category-item'}>
                              <Category
                                title={'rol.name'}
                                description={'rol.description'}
                              />
                            </div>
                          </Grid>
                        </Skeleton>
                        <Skeleton>
                          <Grid item xs={4}>
                            {' '}
                            <div className={'pilares__category-item'}>
                              <Category
                                title={'rol.name'}
                                description={'rol.description'}
                              />
                            </div>
                          </Grid>
                        </Skeleton>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              style={{ backgroundColor: 'white', paddingBottom: '20px' }}
              item
              xs={4}
              className="role-info horarios"
            >
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <span>
                    <strong>Datos personales</strong>
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <Input name="name" placeholder=" " label="Nombre" />
                </Grid>
                <Grid item xs={12}>
                  <Input name="email" label="Email" />
                </Grid>
                <Grid item xs={12}>
                  <Input name="password" label="Contraseña " type="password" />
                  <small style={{ fontStyle: 'italic', color: 'grey' }}>
                    Las políticas de seguridad para crear una contraseña son:
                    <br />- De 6 a 8 caracteres. <br />- Al menos 2 letras.{' '}
                    <br />- Al menos 2 números.
                  </small>
                </Grid>
                {selectedRole === 'mentor' && (
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <h3>Designar pilar</h3>
                    </Grid>
                    {pillars.map((pillar) => {
                      return (
                        <div
                          key={pillar.id}
                          className={
                            selectedPillar && selectedPillar === pillar.id
                              ? 'pilares__category-item active'
                              : 'pilares__category-item'
                          }
                          onClick={() => {
                            addPillar(pillar.id);
                          }}
                        >
                          <Category
                            title={pillar.name}
                            description={pillar.description}
                          />
                        </div>
                      );
                    })}
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={8}
                style={{ backgroundColor: 'white' }}
                className="submit"
              >
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item xs={4}>
                    <Button
                      onClick={() => history.push(`/${path}`)}
                      secondary
                      widthFull
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button type="submit" widthFull>
                      Aceptar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

export default UserCreationContainer;
