import React from 'react';

const BoxParticipants = ({ name, handleClick }) => {
  const firstLetter = name && name.substr(0, 1);
  return (
    <div className="box-participants">
      <div
        className="box-participants__avatar"
        onClick={() => {
          handleClick();
        }}
      >
        {firstLetter}
      </div>
      <span
        onClick={() => {
          handleClick();
        }}
      >
        {name}
      </span>
    </div>
  );
};

export default BoxParticipants;
