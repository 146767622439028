import React, { useEffect, useState, useRef } from 'react';
import { Grid } from '@material-ui/core';
import Button from '../../../../components/common/Button';
import Select from '../../../../components/forms/SelectIdName';
import SessionAPI from '../../session-api';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Skeleton from 'react-placeholder';
import SchedulesAvailables from '../SchedulesAvailables';

const AssignMentor = ({ type, onSubmit, currentMentor, loading }) => {
  const isMountedRef = useRef(null);
  const [mentors, setMentors] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [idMentor, setIdMentor] = useState(0);
  const [idSchedule, setIdSchedule] = useState(0);

  useEffect(() => {
    isMountedRef.current = true;
    if (type === 'mentor') {
      SessionAPI.getMentors()
        .then((response) => {
          if (isMountedRef.current) {
            let tmpMentors = [];
            if (currentMentor) {
              tmpMentors = response.data.filter((mentor) => {
                return mentor.id !== currentMentor;
              });
            } else {
              tmpMentors = response.data;
            }
            setMentors(tmpMentors);
          }
        })
        .catch((e) => {
          console.error('Error: >> ', e);
        });
    }
    return () => (isMountedRef.current = false);
  }, [type, currentMentor]);

  const handleChangeMentor = (target) => {
    const data = mentors.find((mentor) => {
      return mentor.id === target;
    });

    setIdMentor(data.id);
    setSchedules(data.schedules);
  };

  const handleClickSchedule = (id) => {
    return setIdSchedule(id);
  };

  const handleAssignMentor = () => {
    const payload = {
      idMentor: idMentor,
      idSchedule: idSchedule,
    };

    onSubmit(payload);
  };

  return mentors.length > 0 ? (
    <Formik
      initialValues={{ mentor: '' }}
      validationSchema={Yup.object({
        mentor: Yup.string().required('Seleccione Mentor'),
      })}
      onSubmit={handleAssignMentor}
    >
      <Form>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} md={8}>
                <p>Selecciona un mentor para configurar la reunión</p>
                <Select
                  label="Selecciona"
                  name="mentor"
                  options={mentors}
                  advancedOptions={[]}
                  placeholder="Selecciona"
                  isRequired={true}
                  onChangeProps={handleChangeMentor}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="assign-date">
                  <SchedulesAvailables
                    data={schedules}
                    onClickSchedule={handleClickSchedule}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  disabled={idSchedule === 0}
                  type="submit"
                  widthFull
                  loading={loading}
                >
                  Asignar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  ) : (
    <Skeleton variant="text" rows={10} />
  );
};

export default AssignMentor;
