import { MenuItem, TextField } from '@material-ui/core';
import { CalendarTodayOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { TODAY } from '../../../utils/constants';
import { AVAILABLE_HOURS } from '../../../utils/dates';

const SessionSummary = ({
  session,
  meetingList,
  setMeetingList,
  bussyDates,
}) => {
  const [dateAlert, setDateAlert] = useState([]);
  const [meetingClassName, setMeetingClassName] = useState(
    'meeting-summary__container meeting-summary__container--empty'
  );
  useEffect(() => {
    setMeetingClassName(
      session.startDay
        ? 'meeting-summary__container'
        : 'meeting-summary__container meeting-summary__container--empty'
    );
  }, [session]);

  const onChangeDate = (newValue, dateIndex) => {
    const newList = meetingList.map((meet, index) => {
      if (index === dateIndex) {
        const isBussy = bussyDates.find((d) =>
          d.date.includes(`${newValue} ${meet.time}`)
        );

        return isBussy
          ? { date: newValue, time: meet.time, msg: isBussy.message }
          : { date: newValue, time: meet.time };
      } else {
        return meet;
      }
    });

    setMeetingList(newList);
  };

  const onChangeTime = (newValue, timeIndex) => {
    const time = newValue > 9 ? `${newValue}:00` : `0${newValue}:00`;

    const newList = meetingList.map((meet, index) => {
      if (index === timeIndex) {
        const isBussy = bussyDates.find((d) =>
          d.date.includes(`${meet.date} ${time}`)
        );

        return isBussy
          ? { date: meet.date, time: newValue, msg: isBussy.message }
          : { date: meet.date, time: newValue };
      } else {
        return meet;
      }
    });
    setMeetingList(newList);
  };

  return (
    <div
      className="meeting-summary"
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <div className={meetingClassName}>
        <h4 className="subtitle">Fecha y hora programada</h4>
        {meetingList.length ? (
          meetingList.map((meet, index) => (
            <div
              key={`meeting-${index}`}
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 1fr 1fr',
                gap: 16,
              }}
            >
              <span>Sesión {index + 1}</span>
              <TextField
                InputProps={{
                  inputProps: { min: TODAY },
                }}
                className="text-input"
                name={`date-${index}`}
                type="date"
                value={meet.date}
                onChange={({ target }) => onChangeDate(target.value, index)}
              />

              <TextField
                className="text-input"
                select
                name={`startTime-${index + 1}`}
                value={meet.time}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  },
                }}
                onChange={({ target }) => onChangeTime(target.value, index)}
              >
                {AVAILABLE_HOURS.map((option) => {
                  return (
                    <MenuItem key={option.value} value={option.id}>
                      {option.value}
                    </MenuItem>
                  );
                })}
              </TextField>

              <span className="error-message" style={{ gridColumn: '1/3' }}>
                {meet.msg ? meet.msg : null}
              </span>
            </div>
          ))
        ) : (
          <EmptySummary />
        )}
      </div>
    </div>
  );
};

const EmptySummary = () => {
  return (
    <div className="empty-box">
      <CalendarTodayOutlined style={{ fontSize: 120 }} color="disabled" />
    </div>
  );
};

export default SessionSummary;
