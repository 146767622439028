import React from 'react';
import logos from './../../../../assets/images/logos.png';
import mobileLogos from './../../../../assets/images/mobile.png';

const AllysPreview = () => {
  return (
    <div className="landing__allys-container">
      <div className="container">
        <h1>Ya son parte de Chileconverge</h1>
        <hr className="divider-title" />
      </div>
      <img src={logos} alt="" className="filter-image landing__allys-image" />
      <img
        src={mobileLogos}
        alt=""
        className="filter-image landing__allys-image--mobile"
      />
    </div>
  );
};

export default AllysPreview;
