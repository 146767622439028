import React, { useEffect, useMemo, useState } from 'react';
import EmptyCard from '../../../components/common/Cards/emptyCard';
import empty from '../../../assets/images/empty.png';
import ProjectApi from '../project-api';
import StatusIndicator from '../../../components/common/StatusIndicator';
import { STATUS_CLASSNAMES } from '../../ClientContainer/constants';
import { ROLES } from '../../../utils/constants';
import Alert from '../../../components/common/Alert';
import Menu, { MenuContainer } from '../../../components/common/Menu';
import { useHistory } from 'react-router-dom';
import UsersAPI from '../../UserContainer/user-api';
import TableWrapper from '../../../components/common/Table/tableWrapper';
import Button from '../../../components/common/Button';
import ConfirmationModal from '../../ClientContainer/statusModal';

const ACTIONS = {
  INACTIVE: 'inactive',
  DELETED: 'deleted',
  ACTIVATE: 'activate',
};

const MenteesTable = ({ project }) => {
  const history = useHistory();
  const [mentees, setMentees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [clientId, setClientId] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState({ open: false, id: '' });
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    message: '',
    title: '',
  });
  const [usersSelected, setUsersSelected] = useState([]);
  const [menteeSelected, setMenteeSelected] = useState();
  //const [areAllChecked, setAllChecked] = useState(false);

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          const {
            data: { mentees, clientId, ...rest },
          } = await ProjectApi.fetchUserByProject(
            project.announcementId,
            ROLES.Mentee
          );
          setClientId(clientId);
          setMentees(mentees);
        } catch (error) {
          setError(error.data.message);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [isLoading]);

  const onClick = (id) => {
    setIsMenuOpen((prev) => {
      return { id: id, open: !prev.open };
    });
  };

  const handleActivation = async ({ menteeId, status }) => {
    try {
      await UsersAPI.updateUser(menteeId, {
        status,
      });
    } catch (error) {
      setError(error.data.message);
    } finally {
      setIsModalOpen({ open: false, message: '', title: '' });
      setIsLoading(true);
    }
  };

  const onAccept = (action, menteeId) => {
    setIsMenuOpen({ open: false, id: '' });
    switch (action) {
      case ACTIONS.INACTIVE:
        handleActivation({ menteeId, status: 'deactivated' });
        break;
      case ACTIONS.ACTIVATE:
        handleActivation({ menteeId, status: 'active' });
        break;
      default:
        break;
    }
  };

  const handleChangeOfStatus = (action, menteeId) => {
    switch (action) {
      case ACTIONS.INACTIVE:
        setMenteeSelected({ mentee: menteeId, action: ACTIONS.INACTIVE });
        setIsModalOpen({
          open: true,
          message:
            'El usuario se inactivará, no podrá acceder a la plataforma o asistir a reuniones. ¿Estás seguro de inactivarlo?',
          title: 'Inactivación del usuario',
        });
        break;
      case ACTIONS.ACTIVATE:
        setMenteeSelected({ mentee: menteeId, action: ACTIONS.ACTIVATE });
        setIsModalOpen({
          open: true,
          message: '¿Estás seguro de activar al mentee?',
          title: 'Activar mentee',
        });
        break;
      default:
        break;
    }
  };

  const pdfDownload = async (id) => {
    try {
      const { data } = await UsersAPI.fetchPdfUser(id);
      window.open(data.link, '_blank');
    } catch (error) {
      console.error('Error fetching PDF data:', error.message);
      setError('Ocurrio un error al descargar el PDF');
    }
  };

  const renderSignupState = (step) => {
    if (step === 0) {
      return 'Registro completo';
    } else if (step === 1) {
      return 'Registro Inicial';
    } else {
      return 'Registro Incompleto';
    }
  };

  // const onClickCheck = (userId) => {
  //   if (usersSelected.some((u) => u === userId)) {
  //     setUsersSelected((prev) => prev.filter((id) => id !== userId));
  //   } else {
  //     const users = [...usersSelected, userId];
  //     setUsersSelected(users);
  //   }
  // };

  const onClickApplication = async (isAccepted, users = []) => {
    try {
      const data = await ProjectApi.updateUserApplication(
        project.announcementId,
        { isAccepted, users }
      );
      setUsersSelected([]);
      setIsLoading(true);
    } catch (error) {
      console.log(error);
    }
  };
  const columns = useMemo(
    () => [
      // this would be used in the future
      //{
      //   id: 'select',
      //   Header: ({ table }) => (
      //     <input
      //       type="checkbox"
      //       checked={areAllChecked}
      //       onChange={()=> onClickCheckAll(!areAllChecked)}
      //     ></input>
      //   ),
      //   Cell: ({ row }) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         checked={usersSelected.some((us) => us === row.original.id)}
      //         onChange={(e) => onClickCheck(row.original.id)}
      //       ></input>
      //     );
      //   },
      // },
      { Header: 'Nombre', accessor: 'name' },
      { Header: 'E-mail', accessor: 'email' },
      { Header: 'Celular', accessor: 'celphone' },
      {
        Header: 'Estado',
        accessor: 'status',
        Cell: ({ value }) => (
          <StatusIndicator
            text={value}
            statusClassName={
              value === 'Activo'
                ? STATUS_CLASSNAMES.ACTIVE
                : STATUS_CLASSNAMES.INACTIVE
            }
          />
        ),
      },
      {
        Header: 'Estado Registro',
        Cell: ({ row: { original } }) => renderSignupState(original.registro),
      },
      {
        Header: 'Estado postulacion',
        accessor: 'isAccepted',
      },
      { Header: 'Pilar', accessor: 'pillar' },
      {
        Header: 'Acción',
        accessor: 'id',
        Cell: ({ value, row: { original } }) => {
          return (
            <MenuContainer onClick={() => onClick(value)}>
              {isMenuOpen.id === value && (
                <Menu
                  open={isMenuOpen.open}
                  items={[
                    {
                      text: 'Agendar reunión',
                      action: () =>
                        history.push('/clientes/reunion/nueva', {
                          menteeId: value,
                          menteeName: original.name,
                          ref: original.referer,
                          announcementId: project.announcementId,
                          clientId,
                        }),
                    },
                    {
                      text: 'Editar',
                      action: () =>
                        history.push('/usuarios/editar', {
                          idUser: value,
                          roleType: ROLES.Mentee,
                          ref: original.referer,
                          announcementId: project.announcementId,
                          clientId,
                        }),
                    },
                    {
                      text: 'Aceptar',
                      action: () => {
                        onClickApplication(true, [original.id]);
                      },
                      status:
                        original.isAccepted === 'Aceptado'
                          ? 'disabled'
                          : 'featured',
                    },
                    {
                      text: 'Rechazar',
                      action: () => onClickApplication(false, [original.id]),
                      status:
                        original.isAccepted === 'Rechazado'
                          ? 'disabled'
                          : 'warning',
                    },
                    {
                      text:
                        original.status !== 'Activo' ? 'Activar' : 'Desactivar',
                      action: () =>
                        original.status === 'Activo'
                          ? handleChangeOfStatus(ACTIONS.INACTIVE, value)
                          : handleChangeOfStatus(ACTIONS.ACTIVATE, value),
                    },
                    {
                      text: 'Descargar PDF',
                      action: () => pdfDownload(original.id),
                    },
                  ]}
                />
              )}
            </MenuContainer>
          );
        },
      },
    ],
    [isMenuOpen, usersSelected]
  );

  return (
    <div>
      {error && <Alert type="error">{error}</Alert>}
      {isLoading || mentees.length ? (
        <TableWrapper
          data={mentees}
          columns={columns}
          isLoadingData={isLoading}
          tableName="Mentees"
        >
          {/* <Button
            className="actions-table__button button"
            type={'button'}
            onClick={() => onClickApplication(true, usersSelected)}
          >
            Aceptar
          </Button>*/}
          <Button
            className="actions-table__button button"
            type="button"
            onClick={() => {
              history.push('/convocatorias/agregar', {
                clientId,
                role: 'mentee',
                announcementId: project.announcementId,
              });
            }}
          >
            Nuevo Participante
          </Button>
        </TableWrapper>
      ) : (
        <div className="empty-tab">
          <EmptyCard
            message="Actualmente no existen mentees registrados"
            img={empty}
          >
            <Button
              className="actions-table__button button"
              type="button"
              onClick={() => {
                history.push('/convocatorias/agregar', {
                  clientId,
                  role: 'mentee',
                  announcementId: project.announcementId,
                });
              }}
            >
              <span className="material-symbols-outlined">add</span>
              {'Crear nuevo usuario'}
            </Button>
          </EmptyCard>
        </div>
      )}
      <ConfirmationModal
        title={isModalOpen.message}
        open={isModalOpen.open}
        header={isModalOpen.title}
        handleDenied={() =>
          setIsModalOpen({ open: false, message: '', title: '' })
        }
        handleAccept={() => {
          onAccept(menteeSelected.action, menteeSelected.mentee);
        }}
        onClose={() => setIsModalOpen({ open: false, message: '', title: '' })}
      />
    </div>
  );
};

export default MenteesTable;
