import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Input } from '../../../components/forms';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';

import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../../components/common/Button';
import Alert from '../../../components/common/Alert';
import { useParams } from 'react-router-dom';
import ControlPointSharpIcon from '@material-ui/icons/ControlPointSharp';
import InputWrapper from '../../../components/forms/Input/inputWrapper';
import Select from '../../../components/forms/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import InstitutionInput from '../../../components/forms/Input/institutionInput';
import Dropdown from '../../WorkShopContainer/WorkShopCreate/CreationInputs/dropDownInput';

const CourseEditContainer = ({
  onNext,
  course,
  setCourse,
  pillars,
  institutions,
  institutionLogo,
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPillar, setSelectedPillar] = useState();
  const [showInstitutionInput, setShowInstitutionInput] = useState(false);

  const toggleInstitutionInput = () => setShowInstitutionInput((prev) => !prev);
  const handleSubmit = (values) => {
    setIsLoading(true);

    setCourse(values);
    onNext();
  };
  const onInstitutionSubmit = (institutionData) => {
    setCourse((prevCourse) => ({
      ...prevCourse,
      institution: institutionData.name,
      logo: institutionData.logo,
    }));
    setShowInstitutionInput(false);
  };
  const onClickPillar = (option, setFieldValue) => {
    setFieldValue('pillar', option.id);
    setSelectedPillar(option);
  };

  const Cancel = () => {
    history.push('/cursos');
  };
  useEffect(() => {
    if (course && course.pillar) {
      setSelectedPillar(course.pillar);
    }
  }, [course]);

  return (
    <div className="wizard__form wizard__form--full">
      <Formik
        initialValues={{
          ...course,
          pillar: course.pillar ? course.pillar.id : '',
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Nombre requerido'),
          institution: Yup.string().required('Institución requerida'),
          estimatedTime: Yup.string().required('Tiempo requerido'),
          hash: Yup.string().required('Hash requerido'),
          pillar: Yup.number().required('Pilar Requerido').min(1),
          active: Yup.boolean(),
          hasVideo: Yup.boolean(),
          hasReading: Yup.boolean(),
          hasDownload: Yup.boolean(),
          description: Yup.string(),
        })}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue, setFieldTouched }) => (
          <Form>
            <div className="wizard__grid-section">
              <div style={{ backgroundColor: 'white' }}>
                <div
                  container
                  direction="column"
                  className="personal-info"
                  style={{ backgroundColor: 'withe' }}
                >
                  <InputWrapper style={{ marginTop: '12px' }}>
                    <span>
                      <strong>Nombre</strong>
                    </span>
                    <Input name="name" placeholder="Nombre" label="" />
                  </InputWrapper>

                  <InputWrapper item xs={12}>
                    <span>
                      <strong>Institución</strong>
                    </span>
                    {showInstitutionInput ? (
                      <>
                        <InstitutionInput
                          onClose={toggleInstitutionInput}
                          onInstitutionSubmit={onInstitutionSubmit}
                          institutionLogo={institutionLogo}
                          {...values}
                        />
                        <div
                          onClick={toggleInstitutionInput}
                          style={{
                            margin: '10px 0',
                            color: '#0BB5E7',
                            display: 'flex',
                            cursor: 'pointer',
                          }}
                        >
                          <ControlPointSharpIcon /> Seleccionar institución
                          existente
                        </div>
                      </>
                    ) : (
                      <>
                        <Select
                          label="Selecciona Institución"
                          name="institution"
                          options={[]}
                          advancedOptions={institutions}
                          value={values.institution}
                          onChange={(e) =>
                            setFieldValue('institution', e.target.value)
                          }
                        />
                        <div
                          onClick={toggleInstitutionInput}
                          style={{
                            margin: '10px 0',
                            color: '#0BB5E7',
                            display: 'flex',
                            cursor: 'pointer',
                          }}
                        >
                          <ControlPointSharpIcon /> Agregar nueva institución
                        </div>
                      </>
                    )}
                  </InputWrapper>

                  <InputWrapper name="description" label="Descripcion">
                    <Input
                      placeholder="Descripcion"
                      name="description"
                      type="textarea"
                      max={500}
                      showCounter
                    />
                  </InputWrapper>

                  <div>
                    <span>
                      <strong>Tipo de contenido</strong>
                    </span>
                    <div>
                      <FormControlLabel
                        name="hasVideo"
                        id="hasVideo"
                        control={
                          <Checkbox
                            id="hasVideo"
                            name="hasVideo"
                            style={{ color: '#0BB5E7' }}
                            onChange={(e) =>
                              setFieldValue('hasVideo', e.target.checked)
                            }
                            color="primary"
                            checked={values?.hasVideo}
                          />
                        }
                        label="Videos"
                      />
                      <FormControlLabel
                        name="hasReading"
                        id="hasReading"
                        control={
                          <Checkbox
                            id="hasReading"
                            name="hasReading"
                            style={{ color: '#0BB5E7' }}
                            onChange={(e) =>
                              setFieldValue('hasReading', e.target.checked)
                            }
                            color="primary"
                            checked={values?.hasReading}
                          />
                        }
                        label="Material de lectura"
                      />
                      <FormControlLabel
                        id="hasDownload"
                        name="hasDownload"
                        control={
                          <Checkbox
                            id="hasDownload"
                            name="hasDownload"
                            style={{ color: '#0BB5E7' }}
                            onChange={(e) =>
                              setFieldValue('hasDownload', e.target.checked)
                            }
                            color="primary"
                            checked={values?.hasDownload}
                          />
                        }
                        label="Material descargable"
                      />
                    </div>
                  </div>

                  <InputWrapper>
                    <span>
                      <strong>Hash de Curso</strong>
                    </span>
                    <Input name="hash" placeholder="Hash" label="" />
                  </InputWrapper>
                  <InputWrapper>
                    <span>
                      <strong>Tiempo Estimado</strong>
                    </span>
                    <Input
                      name="estimatedTime"
                      placeholder="Tiempo estimado"
                      label=""
                    />
                  </InputWrapper>
                  <InputWrapper id="pillar" name="pillar">
                    <span>
                      <strong>Pilar</strong>
                    </span>
                    <Dropdown
                      id="pillar"
                      label="Selecciona Pilar"
                      name="pillar"
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      options={pillars.map((pillar) => ({
                        name: pillar.name,
                        id: pillar.id,
                      }))}
                      selectedOption={selectedPillar}
                      onClick={(option) => onClickPillar(option, setFieldValue)}
                    />
                    {touched.pillar && errors.pillar ? (
                      <span className="error-message">{errors.pillar}</span>
                    ) : null}
                  </InputWrapper>
                </div>
              </div>

              <div className="wizard__action">
                <Button variant="outlined" secondary widthFull onClick={Cancel}>
                  Cancelar
                </Button>

                <Button type="submit" widthFull>
                  Aceptar
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CourseEditContainer;
