import { Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ANNOUNCEMENT_STATUS_CLASSNAMES } from '../../ClientContainer/constants';
import { Skeleton } from '@material-ui/lab';
import Avatar from '../../../components/common/avatars';
import StatusIndicator from '../../../components/common/StatusIndicator';
import { StrongSubtitle } from '../../../components/common/Title';
import { utils, writeFileXLSX } from 'xlsx';
import CoursesAPI from '../../CoursesContainer/course-api';
import Alert from '../../../components/common/Alert';
import { useHistory } from 'react-router-dom';

// ESTE COMPONENTE SE USA EN CONVOCATORIAS ABIERTAS
const OrganizationCard = ({ isLoading, announcement }) => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const statusTextTransform = (status) => {
    switch (status) {
      case 'waiting':
        return 'En espera';

      case 'user-register':
        return 'Registro de usuarios';

      case 'active':
        return 'Activo';

      case 'finished':
        return 'Finalizado';
    }
  };

  const roleAnnouncementText = () => {
    if (announcement.urlMentee && announcement.urlMentor) {
      return 'Mentor y Mentee';
    } else if (announcement.urlMentee) {
      return 'Mentee';
    } else {
      return 'Mentor';
    }
  };
  const downloadReport = async (announcementId) => {
    try {
      const {
        data: { objects },
      } = await CoursesAPI.getAnnouncementReport(announcementId);

      const wb = utils.book_new();
      objects.forEach((data) => {
        const ws = utils.json_to_sheet(data.info);
        utils.book_append_sheet(wb, ws, data.name);
      });

      writeFileXLSX(wb, `${objects[0]?.name}-.xlsx`, {
        compression: true,
        cellDates: true,
      });

      setSuccessMessage('¡El reporte de cursos se ha descargado exitosamente!');
    } catch (error) {
      setError(
        'Ocurrió un error al descargar el informe. Por favor, inténtalo de nuevo.'
      );
    }
  };
  useEffect(() => {
    if (successMessage) {
      const timeout = setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [successMessage]);
  return (
    <>
      {successMessage && (
        <div className="success-message">
          <Alert type="success">{successMessage}</Alert>
        </div>
      )}

      {error && (
        <div className="error-message">
          <Alert type="error">{error}</Alert>
        </div>
      )}
      <Paper>
        <div className="client-details__info-container">
          <div className="flex-row">
            <div className="flex-row">
              {isLoading ? (
                <Skeleton variant="circle" width={60} height={60} />
              ) : (
                <Avatar image={announcement.organization.logo} />
              )}
              <div>
                {isLoading ? (
                  <Skeleton variant="text" width={250} />
                ) : (
                  <h2>{announcement.organization.name.toUpperCase()}</h2>
                )}
                {isLoading ? (
                  <Skeleton variant="text" width={200} />
                ) : (
                  <StatusIndicator
                    text={statusTextTransform(announcement.status)}
                    statusClassName={
                      ANNOUNCEMENT_STATUS_CLASSNAMES[
                        announcement.status.toUpperCase().replaceAll('-', '_')
                      ]
                    }
                  />
                )}
              </div>
            </div>
            <div className="flex-row">
              {isLoading ? (
                <Skeleton variant="text" width={250} />
              ) : (
                <div className="client-details__flex-columns link">
                  <span
                    className="material-symbols-outlined"
                    onClick={() => {
                      history.push('/convocatorias/editar', {
                        announcementId: announcement.id,
                        orgId: announcement.organization?.id,
                      });
                    }}
                  >
                    edit
                  </span>
                  <span>Editar</span>
                </div>
              )}
            </div>
          </div>
          <div className="client-details__grid-row">
            {/* <div className="client-details__client-data">
            <StrongSubtitle text="convocatoria dirigida" />
            {isLoading ? (
              <Skeleton variant="text" />
            ) : (
              <span>{client.contactName}</span>
            )}
          </div>
          <div className="client-details__client-data">
            <StrongSubtitle text="E-mail" />
            {isLoading ? (
              <Skeleton variant="text" />
            ) : (
              <span>{client.email}</span>
            )}
          </div>
         */}
            <div className="client-details__client-data">
              <StrongSubtitle text="Dirigido a" />
              {isLoading ? (
                <Skeleton variant="text" />
              ) : (
                <span>{roleAnnouncementText()}</span>
              )}
            </div>
            <div className="client-details__client-data">
              <StrongSubtitle text="Fecha de creación" />
              {isLoading ? (
                <Skeleton variant="text" />
              ) : (
                <span>{announcement.createdAt}</span>
              )}
            </div>
          </div>
          <div className="flex-row"></div>
        </div>
      </Paper>
    </>
  );
};
export default OrganizationCard;
