import request from '../../utils/request';

function getSettings() {
  return request({
    url: 'client/info',
    method: 'GET',
    private: true,
  });
}

function updateSettings(data) {
  return request({
    url: 'client/update',
    method: 'PATCH',
    data,
    private: true,
  });
}

function getFields(roleType) {
  return request({
    url: `client/fields?typeForm=${roleType}`,
    method: 'GET',
    private: true,
  });
}

const SettingsAPI = {
  getSettings,
  updateSettings,
  getFields,
};

export default SettingsAPI;
