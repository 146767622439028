import React, { useContext, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import Category from '../../../../components/common/Category';
import { UserContext } from '../userContext';

//allows to select or remove pillars that user is interested
const PillarsSection = ({ alertPillar, onClick }) => {
  const [state, dispatch] = useContext(UserContext);
  const { user, pillars } = state;
  const PillarsMentorSelection = () => {
    return pillars.map((pillar) => {
      const p = user.pillar?.id === pillar?.id;
      return (
        <div
          key={pillar.id}
          className={
            p ? 'pilares__category-item active' : 'pilares__category-item'
          }
          onClick={() => onClick(pillar)}
        >
          <Category title={pillar.name} description={pillar.description} />
        </div>
      );
    });
  };
  const PillarsMenteeSection = () => {
    return pillars.map((pillar) => {
      const p = user.pillars.some((p) => p.id === pillar.id);
      return (
        <div
          key={pillar.id}
          className={
            p ? 'pilares__category-item active' : 'pilares__category-item'
          }
          onClick={() => onClick(pillar)}
        >
          <Category title={pillar.name} description={pillar.description} />
        </div>
      );
    });
  };
  return (
    <Grid item className="role-info pillars">
      <h3>
        {user.role === 'mentor' ? 'Designar pilar' : 'Pilares de mentorias'}
      </h3>
      {user.preRegistry && !user.active && (
        <div className="pilares__preRegistry">
          <p>Pilares escogidos en la postulación</p>
          {pillars.map((pillar) => {
            let prePillars = user.prePillars ? user.prePillars.split(',') : [];
            return prePillars.map((pp) => {
              const idPrePillar = parseInt(pp);
              return (
                idPrePillar === pillar.id && (
                  <div key={pillar.id} className={'pilares__category-pre'}>
                    {pillar.name}
                  </div>
                )
              );
            });
          })}
        </div>
      )}
      {user.role.type == 'mentee' ? (
        <PillarsMenteeSection />
      ) : (
        <PillarsMentorSelection />
      )}
      {alertPillar && (
        <div className={`alert error mt-4`} role="alert">
          <BlockIcon />
          <p>Debe seleccionar una categoría</p>
        </div>
      )}
    </Grid>
  );
};
export default PillarsSection;
