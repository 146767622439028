import React from "react"
import Title, { Subtitle } from "."
const HeadingSection = ({ title, subtitle }) => {

    return (<div>
        <Title title={title} />
        {subtitle && <Subtitle text={subtitle} />}
    </div>)

}

export default HeadingSection