import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card } from '@material-ui/core';

import ReportsAPI from '../reports-api';
import { PowerBIEmbed } from 'powerbi-client-react';
import { read, utils, writeFile, writeFileXLSX } from 'xlsx';
import { TODAY } from '../../../utils/constants';

// images
import mentees from '../../../assets/images/reports/mentees.png';
import logs from '../../../assets/images/reports/logs.png';
import mentors from '../../../assets/images/reports/mentors.png';
import meetings from '../../../assets/images/reports/meetings.png';
import poll from '../../../assets/images/reports/poll.png';
import power from '../../../assets/images/reports/powerbi.png';

import { GetApp } from '@material-ui/icons';
import ReportModal, { ErrorModal } from './reportModal';
import LoadingModal from './reportModal';

const FILES = [
  { name: 'Registro Mentees', img: mentees, fn: () => ReportsAPI.getMentees() },
  {
    name: ' Listado Mentores',
    img: mentors,
    fn: () => ReportsAPI.getMentors(),
  },
  { name: 'Inicios de Sesión', img: logs, fn: () => ReportsAPI.getLogins() },
  { name: 'Reuniones', img: meetings, fn: () => ReportsAPI.getSessions() },
  {
    name: 'Últimas reuniones del emprendedor',
    img: meetings,
    fn: () => ReportsAPI.getLastSessions(),
  },
  { name: 'Evaluaciones', img: poll, fn: () => ReportsAPI.getSessionPolls() },
];

const ReportsList = () => {
  const [files, setFiles] = useState(FILES);
  const [powerbiToken, setPowerbiToken] = useState(null);
  const [embedUrl, setEmbedUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const exportFileWithPages = async (file) => {
    try {
      setIsLoading(true);
      const {
        data: { objects },
      } = await file.fn();

      var wb = utils.book_new();
      objects.map((data) => {
        const ws = utils.json_to_sheet(data.info);
        utils.book_append_sheet(wb, ws, data.name);
      });
      writeFileXLSX(wb, `${file.name}-${TODAY}.xlsx`, {
        compression: true,
        cellDates: true,
      });
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="content-panel report-container">
        {files.map((file, index) => (
          <Card
            className="report__card"
            key={`file-${index}`}
          >
            <img src={file.img} alt="texto" />
            <div className="text-container">
              <span className="title">{file.name}</span>
              <span
                className={`subtitle ${isLoading && 'subtitle--loading'}`}
                onClick={() => exportFileWithPages(file)}
              >
                <GetApp />
                Descargar Reporte
              </span>
            </div>
          </Card>
        ))}
        <Card className="report__card">
          <img src={power} alt="texto" />
          <div className="text-container">
            <span className="title">POWER BI</span>
            <span
              className={`subtitle ${isLoading && 'subtitle--loading'}`}
              onClick={() => {
                ReportsAPI.powerbi().then((res) => {
                  const dataPowerBi = res.data;
                  setPowerbiToken(dataPowerBi.access_token);
                  setEmbedUrl(dataPowerBi.embedUrl);
                });
              }}
            >
              <GetApp />
              Ver Reporte
            </span>
          </div>
        </Card>
      </div>
      {/* POWERBI view */}
      {powerbiToken && (
        <div style={{ height: '100vh', padding: 24 }}>
          <PowerBIEmbed
            embedConfig={{
              type: 'report', // Supported types: report, dashboard, tile, visual and qna
              embedUrl: embedUrl,
              accessToken: powerbiToken, // Keep as empty string, null or undefined
            }}
            cssClassName="report-style-class"
          />
        </div>
      )}
      {/* LOADING MODAL */}
      <LoadingModal open={isLoading} />
      {/* ERROR MODAL */}
      <ErrorModal open={isError} onClose={() => setIsError(false)} />
    </>
  );
};

export default ReportsList;
