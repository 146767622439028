import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const LinkComponent = ({ title, url, icon, className, state = {} }) => {
  const history = useHistory();
  const onClick = () => {
    history.push(url, state);
  };
  return (
    <>
      {url ? (
        <div className={className || 'link'} onClick={() => onClick()}>
          {icon && (
            <span className="material-symbols-outlined icon-link">{icon}</span>
          )}
          {title}
        </div>
      ) : (
        <span className="link">{title}</span>
      )}
    </>
  );
};

Link.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.string,
  state: PropTypes.object,
};

export default LinkComponent;
