import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { Input } from '../../components/forms';
import * as Yup from 'yup';
import converge from '../../assets/images/converge-login.png';
import { useHistory } from 'react-router-dom';
import Button from '../../components/common/Button';
import Alert from '../../components/common/Alert';
import AuthAPI from './login-api';
import ReCAPTCHA from 'react-google-recaptcha';
import { parseJwt } from '../../utils/session';
import { AppContext } from '../../app/AppContext';

const LoginContainer = () => {
  const { user, roles, settings } = useContext(AppContext);
  const [error, setError] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState({
    error: false,
    value: '',
  });
  const history = useHistory();
  const recaptchaRef = React.useRef();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleFormSubmit = async ({ email, password }) => {
    if (!recaptchaValue.value) {
      setRecaptchaValue({ error: true });
    } else {
      setDisabled(true);
      setLoading(true);
      const gRecaptchaResponse = recaptchaValue.value;
      AuthAPI.login({ email, password, gRecaptchaResponse })
        .then((response) => {
          const { token } = response.data;
          const newUser = parseJwt(token);
          user.actions.setUser(newUser);
          window.sessionStorage.setItem('converge.token.private', token);

          AuthAPI.getRoles().then((res) => {
            window.sessionStorage.setItem(
              'converge.roles',
              JSON.stringify(res.data.roles)
            );
            roles.actions.setRoles(res.data.roles);
          });

          AuthAPI.getSettings().then((res) => {
            window.sessionStorage.setItem(
              'converge.settings',
              JSON.stringify(res.data)
            );
            settings.actions.setSettings(res.data);
          });

          history.push('/usuarios');
        })
        .catch(({ data }) => {
          setError(data.message);
        })
        .finally(() => {
          setDisabled(false);
          setLoading(false);
        });
    }
  };
  const onChangeRecaptcha = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    setRecaptchaValue({ value: recaptchaValue });
  };
  return (
    <div className="login-container">
      <Grid container className="login-container__content">
        {/* credential secction */}
        <Grid item xs={12} sm={6} md={4} className="card-login">
          <div
            style={{ backgroundImage: `url(${converge})` }}
            className="card-login__image"
          ></div>
          {error && <Alert type="error">{error}</Alert>}
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email('Formato de email incorrecto')
                .required('Ingrese su email'),
              password: Yup.string().required('Ingrese la contraseña'),
            })}
            onSubmit={handleFormSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="mt-8">
                <span>
                  <strong>Ingreso administrador</strong>
                </span>
                <Input name="email" type="text" label="ingresa tu email" />
                <Input
                  name="password"
                  label="ingrese contraseña"
                  type="password"
                />
                <ReCAPTCHA
                  ref={recaptchaRef}
                  onChange={onChangeRecaptcha}
                  sitekey="6LfnuNMjAAAAAK48w1kuEdsaiGGokOszTn-xHQs-"
                />
                {recaptchaValue.error && (
                  <div className="error-message">
                    <p>Seleccione Recaptcha</p>
                  </div>
                )}
                <Button
                  className="button"
                  loading={loading}
                  type="submit"
                  widthFull
                  disabled={disabled}
                >
                  Iniciar sesión
                </Button>
              </Form>
            )}
          </Formik>
        </Grid>
        {/* image */}
        <Grid item xs={12} sm={6} md={8}>
          <div className="login-container__bg-image"></div>
        </Grid>
      </Grid>
      <div className="powered">By Moveapps</div>
    </div>
  );
};

export default LoginContainer;
