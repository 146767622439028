import React from 'react';

const Avatar = ({ image, alt, variant }) => {
  const classModifier = variant ? `avatar avatar--${variant}` : 'avatar'

  return (
    <div className={classModifier}>
      <img src={image} className="avatar__image" />
    </div>
  );
};

export default Avatar;
