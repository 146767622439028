import React from 'react';

const InputWrapper = ({ label = '', description, children, ...props }) => {
  const wrapperClassName = props.variant
    ? `form-wrapper--${props.variant}`
    : 'form-wrapper';
  return (
    <div className={wrapperClassName}>
      <label className="form-wrapper__label" htmlFor={props.id}>
        {label}
      </label>
      {description && (
        <span className="form-wrapper__label--description">{description}</span>
      )}
      {children}
    </div>
  );
};
export default InputWrapper;
