import React from 'react';
import PropTypes from 'prop-types';

const Wizard = ({ title, children, actionElement }) => {
  return (
    <div className="wizard">
      <div className="wizard__title-container wizard-padding">
        <span className="wizard__title">{title}</span>
        {actionElement && actionElement}
      </div>
      {children}
    </div>
  );
};

Wizard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  actionElement: PropTypes.element,
};

export default Wizard;
