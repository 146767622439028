import { FormControlLabel, Switch } from '@material-ui/core';

import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import LinkComponent from '../../../components/common/Link';
import Stepper from '../../../components/common/stepper';
import { ACTION } from '../../../reducer/projectReducer';

import Wizard from '../../ClientContainer/ClientCreationContainer/wizard';

import { ProjectContext } from '../projectContext';
import LandingForm from './landing/landingForm';
import LoginForm from './login/LoginForm';
import PlatformForm from './platform/platformForm';
import ConfirmationModal from '../../ClientContainer/statusModal';

const ProjectWizard = () => {
  const [state, dispatch] = useContext(ProjectContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const {
    state: { client },
  } = location;

  useEffect(() => {
    if (!state.client?.name) {
      dispatch({ type: ACTION.UPDATE_CLIENT, payload: { client } });
    }
    setIsLoading(false);
  }, []);

  const [announcementStatus, setAnnouncementStatus] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const onPrev = () => {
    return activeStep > 0 ? setActiveStep((prev) => prev - 1) : null;
  };

  const onNext = () => {
    return activeStep < steps(client.isCustomized).length
      ? setActiveStep((prev) => prev + 1)
      : null;
  };

  const onToggle = () => {
    setIsOpen(true);
  };

  const onClose = () => setIsOpen(false);
  const onAccept = () => {
    dispatch({
      type: ACTION.UPDATE_ANNOUNCEMENT_STATUS,
      payload: { status: !announcementStatus },
    });
    setAnnouncementStatus((prev) => !prev);
    onClose();
  };

  const steps = (isCustomized) => {
    return isCustomized
      ? [{ title: 'Landing de registro', Componente: LandingForm }]
      : [
          { title: 'Landing de registro', Componente: LandingForm },
          { title: 'Login customizado', Componente: LoginForm },
          { title: 'Plataforma customizada', Componente: PlatformForm },
        ];
  };

  //if client status is non active  toggle should be disabled for announcements
  const isToggleDisabled = state.client.status ? false : true;
  return (
    <>
      {isLoading ? (
        'cargando'
      ) : (
        <div className="client-container">
          <ConfirmationModal
            header={
              !announcementStatus
                ? 'Activar Convocatoria'
                : 'Desactivar Convocatoria'
            }
            title={
              !announcementStatus
                ? '¿Estás seguro/a que deseas activar la convocatoria?'
                : '¿Estás seguro/a que deseas dejar la convocatoria en espera?'
            }
            open={isOpen}
            onClose={onClose}
            handleAccept={onAccept}
            handleDenied={onClose}
          />
          <div className="client__back-container">
            <LinkComponent
              title="Volver"
              url="/clientes/detalles"
              icon="arrow_back"
              state={{ client: client.id }}
            ></LinkComponent>
          </div>
          <Wizard title="Crear convocatoria para cliente">
            <div className="wizard__content">
              <div className="wizard__steps-container wizard-padding">
                <FormControlLabel
                  className={`wizard__toggle-container ${
                    isToggleDisabled
                      ? 'wizard__toggle-container--disabled'
                      : null
                  }`}
                  control={
                    <Switch
                      checked={announcementStatus}
                      onChange={onToggle}
                      name="active"
                      disabled={isToggleDisabled}
                    />
                  }
                  label="Aprobar y activar convocatoria"
                />

                {steps(client.isCustomized).map((step, index) => (
                  <Stepper
                    text={step.title}
                    isActive={index === activeStep}
                    key={`step-${index}`}
                    isDone={index < activeStep}
                  />
                ))}
              </div>
              <div className="wizard__form-container">
                {steps(client.isCustomized).map((step, index) => {
                  const C = step.Componente;
                  return (
                    index === activeStep && (
                      <C
                        key={`showing-step-${index}`}
                        onPrev={onPrev}
                        onNext={onNext}
                      />
                    )
                  );
                })}
              </div>
            </div>
          </Wizard>
        </div>
      )}
    </>
  );
};

export default ProjectWizard;
