import React from 'react';
import PropTypes from 'prop-types';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import InfoIcon from '@material-ui/icons/Info';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const Alert = ({ children, type }) => {
  return (
    <div className={`alert ${type}`} role="alert">
      {type === 'error' && <BlockIcon />}
      {type === 'success' && <CheckCircleOutlineIcon />}
      {type === 'info' && <InfoIcon />}
      {type === 'warning' && <ErrorOutlineIcon />}
      <p>{children}</p>
    </div>
  );
};

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
};

export default Alert;
