import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Input } from '../../../components/forms';
import Button from '../../../components/common/Button';
import Alert from '../../../components/common/Alert';
import { Grid, FormControlLabel, Switch } from '@material-ui/core';
import {
  Assessment,
  BusinessCenter,
  Edit,
  Devices,
  AddToHomeScreen,
  Group,
  LocalMall,
  QuestionAnswer,
  ShowChart,
  BubbleChart,
  Create,
  Storefront,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import PillarAPI from '../pillar-api';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Info from '@material-ui/icons/Info';
import PillarCreationForm from '../../../components/common/Pillars/creationForm';
import Title from '../../../components/common/Title';
import LinkComponent from '../../../components/common/Link';

const PillarCreationContainer = () => {
  let timer;
  const history = useHistory();
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');




  useEffect(() => {
    // eslint-disable-next-line
    timer = setTimeout(function () {
      setShowAlert(false);
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  });

  const handleSubmit = async (values) => {
    try {
      await PillarAPI.addPillar(values);
      history.push({
        pathname: '/pilares',
        state: {
          message: 'Pilar creado exitosamente',
          type: 'success',
        },
      });
    } catch (error) {
      setShowAlert(true);
      setMessageAlert(error.data.message);
    }
  };

  return (
    <div className="user-container content-panel" style={{ background: '#ffff', minHeight: '100vh' }}>

      <LinkComponent
        title="Volver"
        url="/pilares"
        icon="arrow_back"
      />
      <Title title="Crear pilar" />

      <div style={{ justifyContent: 'start', display: 'flex' }}>
        {showAlert && <Alert type="error">{messageAlert}</Alert>}
        <PillarCreationForm
          onCancel={() => history.push('/pilares')}
          onSubmit={handleSubmit}
        />
      </div>


    </div>
  );
};

export default PillarCreationContainer;
