import { FormControlLabel, Stepper, Switch } from '@material-ui/core';
import React from 'react';
import LinkComponent from '../../../components/common/Link';
import Wizard from '../../ClientContainer/ClientCreationContainer/wizard';

import NewsForm from './newsForm';

const NewsCreation = () => {
  return (
    <div className="client-container">
      <div className="client__back-container">
        <LinkComponent
          title="Volver"
          url="/novedades"
          icon="arrow_back"
        ></LinkComponent>
      </div>
      <NewsForm />
    </div>
  );
};

export default NewsCreation;
