//client status
export const STATUS_CLASSNAMES = {
  ACTIVE: 'status__indicator--green',
  INACTIVE: 'status__indicator--gray',
  REJECTED: 'status__indicator--red',
};

export const ANNOUNCEMENT_STATUS_CLASSNAMES = {
  WAITING: 'status__indicator--yellow',
  USER_REGISTER: 'status__indicator--blue',
  ACTIVE: 'status__indicator--green',
  FINISH: 'status__indicator--gray',
};

export const USER_STATUS_CLASSNAMES = {
  active: 'status__indicator--green',
  inactive: 'status__indicator--gray',
  rejected: 'status__indicator--red',
  deactivated: 'status__indicator--yellow',
};
