import LoginContainer from '../content/LoginContainer';
import {
  CoursesEditContainer,
  CoursesTableContainer,
  CoursesCreationContainer,
} from '../content/CoursesContainer';
import {
  PillarEditContainer,
  PillarTableContainer,
  PillarCreationContainer,
} from '../content/PillarContainer';
import { ApplicationTableContainer } from '../content/PillarApplicationContainer';
import {
  MassiveMailsTableContainer,
  MassiveMailCreationContainer,
} from '../content/MasiveMails';
import {
  SessionTableContainer,
  SessionDetailsContainer,
  SessionCreationContainer,
} from '../content/SessionContainer';
import {
  UserTableContainer,
  UserEditContainer,
  UserCreationContainer,
  UserMentorTableContainer,
} from '../content/UserContainer';
import SettingEditContainer from '../content/SettingContainer/SettingEditContainer';

import {
  BenefitTableContainer,
  BenefitEditContainer,
  BenefitCreationContainer,
} from '../content/BenefitsContainer';
import { ReportsList } from '../content/ReportsContainer';
import DashboardContainer from '../content/DashboardContainer';
import {
  ClientContainer,
  ClientCreation,
  ClientEditContainer,
  ClientInfoContainer,
  Container,
} from '../content/ClientContainer';
import { ProjectCreationContainer } from '../content/ProjectsContainer';
import ProjectInfoContainer from '../content/ProjectsContainer/ProjectInfoContainer';
import EditProjectClientContainer from '../content/ProjectsContainer/ProjectEditContainer';
import { NewsCreation, NewsList } from '../content/NewsContainer';
import NewsEdition from '../content/NewsContainer/newsEdition';
import Announcements from '../content/AnnouncementContainer';
import AnnouncementsInfo from '../content/AnnouncementContainer/AnnouncementListContainer';
import AnnouncementCreation from '../content/AnnouncementContainer/AnnouncementCreation';
import AnnouncementInfo from '../content/AnnouncementContainer/AnnouncementInfoContainer';
import AnnouncementEditContainer from '../content/AnnouncementContainer/AnnouncementEditContainer';
import AddAnnouncementUser from '../content/AnnouncementContainer/AnnouncementNewUser';
import WorkShopContainer from '../content/WorkShopContainer';
import WorkShopEdition from '../content/WorkShopContainer/WorkShopEdition';
import WorkShopHistory from '../content/WorkShopContainer/WorkShopHistory';
import WorkShopCreation from '../content/WorkShopContainer/WorkShopCreate';
import { SurveyContainer } from '../content/SurveyContainer';
import SurveyCreate from '../content/SurveyContainer/SurveyCreate';
import SurveyEditContainer from '../content/SurveyContainer/SurveyEditContainer.js';

const routes = [
  {
    path: '/',
    component: LoginContainer,
    isPublic: true,
    restricted: true,
    exact: true,
    title: 'Inicio de sesion',
  },
  {
    path: '/beneficios',
    component: BenefitTableContainer,
    exact: true,
    title: 'Beneficios',
  },
  {
    path: '/beneficios/editar',
    component: BenefitEditContainer,
    exact: true,
    title: 'Editar Beneficio',
  },
  {
    path: '/Beneficios/crear',
    component: BenefitCreationContainer,
    exact: true,
    title: 'Crear beneficio',
  },
  {
    path: '/clientes',
    component: ClientContainer,
    exact: true,
    title: 'Clientes',
  },
  {
    path: '/clientes-y-convocatorias',
    component: Announcements,
    exact: true,
    title: 'Clientes',
  },

  {
    path: '/clientes/crear',
    component: ClientCreation,
    exact: true,
    title: 'Crear cliente',
  },
  {
    path: '/clientes/editar',
    component: ClientEditContainer,
    exact: true,
    title: 'Editar cliente',
  },
  {
    path: '/clientes/detalles',
    component: ClientInfoContainer,
    exact: true,
    title: 'Detalle del cliente',
  },
  {
    path: '/clientes/detalles/proyecto/',
    component: ProjectInfoContainer,
    exact: true,
    title: 'Proyecto',
  },
  {
    path: '/clientes/detalles/proyecto/editar',
    component: EditProjectClientContainer,
    exact: true,
    title: 'Proyecto',
  },
  {
    path: '/clientes/:name/convocatoria',
    component: ProjectCreationContainer,
    exact: true,
    title: 'Crear convocatoria cliente',
  },
  {
    path: '/clientes/reunion/nueva',
    component: SessionCreationContainer,
    exact: true,
    title: 'Nueva reunión',
  },

  //convocatorias sin clientes
  {
    path: '/convocatorias',
    component: AnnouncementsInfo,
    exact: true,
    title: 'Convocatorias',
  },
  {
    path: '/convocatorias/agregar',
    component: AddAnnouncementUser,
    exact: true,
    title: 'Convocatorias',
  },
  {
    path: '/convocatorias/editar',
    component: AnnouncementEditContainer,
    exact: true,
    title: 'Convocatorias',
  },
  {
    path: '/convocatorias/detalle',
    component: AnnouncementInfo,
    exact: true,
    title: 'Convocatorias',
  },
  {
    path: '/convocatorias/crear',
    component: AnnouncementCreation,
    exact: true,
    title: 'Crear convocatoria',
  },
  {
    path: '/cursos',
    component: CoursesTableContainer,
    exact: true,
    title: 'Cursos',
  },
  {
    path: '/cursos/crear',
    component: CoursesCreationContainer,
    exact: true,
    title: 'Crear curso',
  },
  {
    path: `/cursos/editar/:courseId`,
    component: CoursesEditContainer,
    exact: true,
    title: 'Editar curso',
  },
  {
    path: '/novedades',
    component: NewsList,
    exact: true,
    title: 'Novedades',
  },
  {
    path: '/novedades/crear',
    component: NewsCreation,
    exact: true,
    title: 'Novedades',
  },
  {
    path: '/novedades/editar',
    component: NewsEdition,
    exact: true,
    title: 'Novedades',
  },

  {
    path: '/mentores',
    component: UserMentorTableContainer,
    exact: true,
    title: 'Mentores',
  },
  {
    path: '/mentores/editar',
    component: UserEditContainer,
    exact: true,
    title: 'Editar mentor',
  },
  {
    path: '/mentores/crear',
    component: UserCreationContainer,
    exact: true,
    title: 'Crear mentor',
  },
  {
    path: '/solicitudes',
    component: ApplicationTableContainer,
    exact: true,
    title: 'Solicitudes',
  },
  {
    path: '/correos',
    component: MassiveMailsTableContainer,
    exact: true,
    title: 'Correos Masivos',
  },
  {
    path: '/correos/crear',
    component: MassiveMailCreationContainer,
    exact: true,
    title: 'Crear Correo Masivo',
  },
  {
    path: '/pilares',
    component: PillarTableContainer,
    exact: true,
    title: 'Pilares',
  },
  {
    path: '/pilares/crear',
    component: PillarCreationContainer,
    exact: true,
    title: 'Crear pilar',
  },
  {
    path: '/pilares/editar',
    component: PillarEditContainer,
    exact: true,
    title: 'Editar pilar',
  },

  {
    path: '/reuniones',
    component: SessionTableContainer,
    exact: true,
    title: 'Reuniones',
  },
  {
    path: '/reuniones/detalle',
    component: SessionDetailsContainer,
    exact: true,
    title: 'Detalle de la reunión',
  },

  {
    path: '/usuarios',
    component: UserTableContainer,
    exact: true,
    title: 'Usuarios',
  },
  {
    path: '/usuarios/editar',
    component: UserEditContainer,
    exact: true,
    title: 'Editar usuario',
  },
  {
    path: '/usuarios/crear',
    component: UserCreationContainer,
    exact: true,
    title: 'Editar usuario',
  },
  {
    path: '/configuraciones',
    component: SettingEditContainer,
    exact: true,
    title: 'Configuraciones',
  },
  {
    path: '/reportes',
    component: ReportsList,
    exact: true,
    title: 'Reportes',
  },
  {
    path: '/dashboard',
    component: DashboardContainer,
    exact: true,
    title: 'Dashboard',
  },
  {
    path: '/formacion',
    component: WorkShopContainer,
    exact: true,
    title: 'Formación',
  },
  {
    path: '/talleres',
    component: WorkShopHistory,
    exact: true,
    title: 'Historial de talleres',
  },
  {
    path: '/talleres/crear',
    component: WorkShopCreation,
    exact: true,
    title: 'Crear Taller',
  },
  {
    path: `/talleres/editar`,
    component: WorkShopEdition,
    exact: true,
    title: 'Editar Taller',
  },
  {
    path: `/encuestas`,
    component: SurveyContainer,
    exact: true,
    title: 'Encuestas',
  },
  {
    path: `/encuestas/crear`,
    component: SurveyCreate,
    exact: true,
    title: 'Encuestas',
  },
  {
    path: `/encuestas/editar`,
    component: SurveyEditContainer,
    exact: true,
    title: 'Encuestas',
  },

];
export default routes;
