import React, { useEffect, useState } from 'react';
import {
  Modal,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from '@material-ui/core';
import ModalContent from './ModalContent';
import Button from '../Button';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import SurveyAPI from '../../../content/AnnouncementContainer/survey-api';

const SurveyModal = ({
  modalOpen,
  announcementId,
  survey,
  isEditableSource,
  setIsSurveyModalOpen,
  setIsLoading,
}) => {
  const [message, setMessage] = useState('');
  const [repeatCount, setRepeatCount] = useState(1);
  const [repeatDates, setRepeatDates] = useState(Array(repeatCount).fill(''));
  const [surveyOptions, setSurveyOptions] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState('');
  const [responseStatus, setResponseStatus] = useState(null);
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedDate, setSelectedDate] = useState([]);

  const repeatCountChange = (event) => {
    const count = event.target.value;
    setRepeatCount(count);
    setRepeatDates(Array(count).fill(''));
  };

  const closeModal = () => {
    setMessage('');
    setIsSurveyModalOpen(false);
  };

  const AddSurvey = async () => {
    try {
      if (repeatDates.some((date) => date === '')) {
        setMessage('Por favor elige fechas para todas las encuestas.');
        return;
      }
      if (!selectedRole) {
        setMessage('Por favor selecciona un rol.');
        return;
      }

      const requestData = {
        announcementId: announcementId,
        surveySchedule: {
          surveyId: selectedSurvey,
          role: selectedRole,
          dates: repeatDates.filter((date) => date !== ''),
        },
      };

      const response = await SurveyAPI.updateSurvey(
        announcementId,
        requestData
      );

      if (response.status === 200) {
        setResponseStatus(200);
        setMessage('¡Se ha agregado encuesta a convocatoria exitosamente!');
      } else if (response.error) {
        setMessage(
          'Error en encuesta. No fue posible enlazar convocatoria con la encuesta'
        );
      }
    } catch (error) {
      setMessage('Error al agregar la encuesta');
    } finally {
      setIsLoading(true);
    }

    setRepeatCount(1);
    setRepeatDates(Array(1).fill(''));
  };

  const onSave = async () => {
    try {
      setSelectedDate(repeatDates);

      if (!selectedDate) {
        setMessage('Por favor selecciona una fecha.');
        return;
      }
      if (!selectedRole) {
        setMessage('Por favor selecciona un rol.');
        return;
      }

      const requestData = {
        surveyId: survey.id,
        surveySchedule: {
          role: selectedRole,
          dates: repeatDates.filter((date) => date !== ''),
        },
      };

      const response = await SurveyAPI.updateSurvey(survey.id, requestData);

      if (response.status === 200) {
        setResponseStatus(200);
        setMessage('¡Encuesta actualizada exitosamente!');
      } else if (response.error) {
        setMessage('Error al actualizar la encuesta.');
      }
    } catch (error) {
      setMessage('Error al actualizar la encuesta.');
    } finally {
      setIsLoading(true);
    }
  };

  const surveyList = async () => {
    try {
      const { data } = await SurveyAPI.listSurvey();

      if (data?.surveys?.length) {
        const options = data.surveys.map((survey) => ({
          value: survey.id,
          label: survey.name,
        }));
        setSurveyOptions(options);
      } else {
        setSurveyOptions([]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    surveyList();
  }, []);

  return (
    <>
      <Modal open={modalOpen} onClose={closeModal}>
        <ModalContent onClick={closeModal} title="Agregar encuesta">
          <div className="custom_modal">
            <div>
              <div className="reschedule-session">
                {message ? (
                  <>
                    <div className="confirmation-messagge">
                      {responseStatus === 200 ? (
                        <CheckCircleOutlinedIcon className="success-icon" />
                      ) : (
                        <ErrorOutlineOutlinedIcon className="error-icon" />
                      )}
                      <p>{message}</p>
                      <Button secondary onClick={closeModal}>
                        Aceptar
                      </Button>{' '}
                    </div>
                  </>
                ) : (
                  <div className="confirmation-messagge">
                    <p>
                      Ingresa los datos mencionados para enlazar encuesta a
                      convocatoria.
                    </p>
                    <FormControl>
                      <InputLabel id="role-select-label">
                        Seleccione el rol
                      </InputLabel>
                      <Select
                        labelId="role-select-label"
                        value={selectedRole}
                        onChange={(e) => setSelectedRole(e.target.value)}
                      >
                        <MenuItem value="mentee">Mentee</MenuItem>
                        <MenuItem value="mentor">Mentor</MenuItem>
                        <MenuItem value="both">Ambos</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl>
                      <InputLabel id="survey-select-label">
                        Seleccionar encuesta
                      </InputLabel>
                      <Select
                        labelId="survey-select-label"
                        value={selectedSurvey}
                        onChange={(e) => setSelectedSurvey(e.target.value)}
                        disabled={isEditableSource}
                      >
                        {surveyOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <InputLabel id="repeat-count-label">
                        Cantidad de fechas
                      </InputLabel>
                      <Select
                        labelId="repeat-count-label"
                        value={repeatCount}
                        onChange={(e) => repeatCountChange(e)}
                        disabled={isEditableSource}
                      >
                        {[1, 2, 3, 4, 5].map((count) => (
                          <MenuItem key={count} value={count}>
                            {count}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {repeatDates.map((date, index) => (
                      <TextField
                        key={index}
                        label={`Seleccionar fecha ${index + 1}`}
                        type="date"
                        value={date}
                        onChange={(e) => {
                          const currentDate = new Date()
                            .toISOString()
                            .split('T')[0];
                          if (e.target.value >= currentDate) {
                            const newDates = [...repeatDates];
                            newDates[index] = e.target.value;
                            setRepeatDates(newDates);
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="modal__action-container">
            {!message && (
              <>
                <Button secondary onClick={closeModal}>
                  Cancelar
                </Button>
                <Button onClick={isEditableSource ? onSave : AddSurvey}>
                  Agregar
                </Button>
              </>
            )}
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SurveyModal;
