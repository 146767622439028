import { Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '../../../components/common/avatars';
import StatusIndicator from '../../../components/common/StatusIndicator';
import { StrongSubtitle } from '../../../components/common/Title';
import { formatDate, formatPhone } from '../../../utils/format';
import { STATUS_CLASSNAMES } from '../constants';

const ClientInfoCard = ({ isLoading, client }) => {
  const history = useHistory();
  return (
    <Paper>
      <div className="client-details__info-container">
        <div className="flex-row">
          <div className="flex-row">
            {isLoading ? (
              <Skeleton variant="circle" width={60} height={60} />
            ) : (
              <Avatar image={client.logo} />
            )}
            <div>
              {isLoading ? (
                <Skeleton variant="text" width={250} />
              ) : (
                <h2>{client.name.toUpperCase()}</h2>
              )}
              {isLoading ? (
                <Skeleton variant="text" width={200} />
              ) : (
                <StatusIndicator
                  text={client.status ? 'Activo' : 'Inactivo'}
                  statusClassName={
                    client.status
                      ? STATUS_CLASSNAMES.ACTIVE
                      : STATUS_CLASSNAMES.INACTIVE
                  }
                />
              )}
            </div>
          </div>
          <div className="flex-row">
            {/* <div
              className="client-details__flex-columns link"
              onClick={() =>
                history.push(`/clientes/editar`, {
                  client: client.id,
                })
              }
            >
              <span className="material-symbols-outlined">preview</span>
              <span>Visualizar plataforma</span>
            </div> */}
            {isLoading ? (
              <Skeleton variant="text" width={250} />
            ) : (
              <div
                className="client-details__flex-columns link"
                onClick={() =>
                  history.push(`/clientes/editar`, {
                    client: client.id,
                  })
                }
              >
                <span className="material-symbols-outlined">edit</span>
                <span>Editar</span>
              </div>
            )}
          </div>
        </div>
        <div className="client-details__grid-row">
          <div className="client-details__client-data">
            <StrongSubtitle text="Contacto" />
            {isLoading ? (
              <Skeleton variant="text" />
            ) : (
              <span>{client.contactName}</span>
            )}
          </div>
          <div className="client-details__client-data">
            <StrongSubtitle text="E-mail" />
            {isLoading ? (
              <Skeleton variant="text" />
            ) : (
              <span>{client.email}</span>
            )}
          </div>
          <div className="client-details__client-data">
            <StrongSubtitle text="Teléfono" />
            {isLoading ? (
              <Skeleton variant="text" />
            ) : (
              <span>{formatPhone(client.phoneNumber)}</span>
            )}
          </div>
          <div className="client-details__client-data">
            <StrongSubtitle text="Fecha de creación" />
            {isLoading ? (
              <Skeleton variant="text" />
            ) : (
              <span>{formatDate(client.createdAt)}</span>
            )}
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default ClientInfoCard;
