import React, { useState } from "react";
import { AddToHomeScreen, Assessment, BubbleChart, BusinessCenter, Create, Devices, Group, LocalMall, QuestionAnswer, ShowChart, Storefront } from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import { Input } from "../../forms";
import InputWrapper from '../../../components/forms/Input/inputWrapper'
import { FormControlLabel, Switch } from "@material-ui/core";
import * as Yup from 'yup';
import Button from "../Button";


const PillarCreationForm = ({  onCancel, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [currentIcon, setCurrentIcon] = useState('');
  const [pillar, setPillar] = useState({ name: '', description: '', iconName: '', owned: false, active: false })

  const icons = [
    { Icon: Assessment, name: 'Assesment' },
    { Icon: BusinessCenter, name: 'BusinessCenter' },
    { Icon: Devices, name: 'Devices' },
    { Icon: AddToHomeScreen, name: 'AddToHomeScreen' },
    { Icon: Group, name: 'Group' },
    { Icon: LocalMall, name: 'LocalMall' },
    { Icon: QuestionAnswer, name: 'QuestionAnser' },
    { Icon: ShowChart, name: 'ShowChart' },
    { Icon: BubbleChart, name: 'BubbleChart' },
    { Icon: Create, name: 'Create' },
    { Icon: Storefront, name: 'Storefront' },
  ];

  const schema = Yup.object({
    name: Yup.string().max(30, 'El nombre del pilar no puede superar los 30 caracteres').required('Nombre del pilar requerido'),
    description: Yup.string().max(100, 'La descripción del  pilar no puede superar los 100 caracteres').required('Descripción requerida'),
    iconName: Yup.string().required('Icono requerido'),
    owned: Yup.boolean(),
  });

  const handleSubmit = async (values) => {
    setLoading(true)
    onSubmit(values);
    setLoading(false);
  
  };

  return (

    <Formik
      initialValues={pillar}
      validationSchema={schema}
      onSubmit={handleSubmit}
      validateOnChange
    >
      {(props) => {
        return (
          <Form style={{display:'grid', gap:16}}>
            <InputWrapper variant="big" label="Nombre" id="Name">
              <Input name="name" placeholder="Nombre" />
            </InputWrapper>
            <InputWrapper variant="big" label="Descripción" id="description">
              <Input name="description" placeholder="Descripción" />
            </InputWrapper>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(8, auto)',
                gap: 12,
              }}
            >
              {icons.map((icon, index) => {
                const C = icon.Icon;
                return (
                  <C
                    key={index}
                    onClick={() => { setCurrentIcon(icon.name); props.setFieldValue('iconName', icon.name, true) }}
                    className={
                      currentIcon === icon.name ? 'pillar__icon pillar__icon--active' : 'pillar__icon'
                    }
                  />
                );
              })}
            </div>
            <div className="error-message">
              <p>{ props.errors.iconName}</p>
            </div>
            <div className='modal__action-container modal__action-container--2-columns'>
              <FormControlLabel
                control={
                  <Switch
                    checked={props.values.active}
                    name="active"
                    onChange={() => props.setFieldValue('active', !props.values.active)}
                  />
                }
                label="Activar Pilar"
              />
              <label>
                <Field type="checkbox" name="owned" />
                <span>Chileconverge</span>
              </label>
            </div>
            <div className='modal__action-container'>
              <Button secondary onClick={onCancel} loading={loading}>
                Cancelar
              </Button>
              <Button type="submit" loading={loading}>
                Guardar
              </Button>
            </div>
          </Form>)
      }}
    </Formik>

  );
};

export default PillarCreationForm;
