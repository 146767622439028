import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { Input } from '../../../components/forms';
import * as Yup from 'yup';
import InputWrapper from '../../../components/forms/Input/inputWrapper';
import InputImage from '../../../components/forms/Input/inputImage';
import ClientsApi from '../client-api';
import Button from '../../../components/common/Button';
import Alert from '../../../components/common/Alert';
import { useHistory } from 'react-router-dom';
import { TIME_TO_REDIRECT } from '../../../utils/constants';

const ClientForm = ({ isClientActive }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState({ type: '', message: '' });

  const onSubmit = (values) => {
    if(isLoading) return;
    setIsLoading(true);
    (async () => {
      try {
        const clientResponse = await ClientsApi.createClient({
          ...values,
          status: isClientActive,
        });

        setMessageAlert({
          type: 'success',
          message: 'Cliente creado exitosamente',
        });
        setTimeout(() => {
          history.push('/clientes');
        }, TIME_TO_REDIRECT);
      } catch (error) {
        setMessageAlert({ type: 'error', message: error.data.message });
      } finally {
        setIsLoading(false);
      }
    })();
  };

  const schema = Yup.object().shape({
    name: Yup.string()
      .max(50, 'El nombre no debe superar los 50 caracteres')
      .required('Debes ingresar el nombre del cliente'),
    contactName: Yup.string()
      .max(50, 'El nombre no debe superar los 50 caracteres')
      .matches(
        /(\w+\s+[^-])+\S[^-]+/,
        'Debes ingresar nombre y apellido de la contraparte'
      )
      .required('Debes ingresar un nombre de contacto.'),
    phoneNumber: Yup.string()
      .matches(/^[0-9]+$/, 'Debes ingresar un teléfono de contacto válido')
      .min(9, 'Debes ingresar un teléfono de 9 números de largo')
      .max(9, 'Debes ingresar un teléfono de 9 números de largo')
      .required('Debes ingresar un teléfono de contacto válido'),
    email: Yup.string()
      .email('Debes ingresar un correo electrónico válido.')
      .max(100, 'El correo no debe superar los 100 caracteres')
      .required('Debes ingresar un correo electrónico válido.'),
    emailCoord: Yup.string()
      .email('Debes ingresar un correo electrónico válido')
      .max(100, 'El correo no debe superar los 100 caracteres')
      .required(
        'Debes ingresar un correo electrónico válido para coordinación'
      ),
    logo: Yup.string().nullable(),
  });

  return (
    <>
      {messageAlert && (
        <Alert type={messageAlert.type}>{messageAlert.message}</Alert>
      )}
      <Formik
        initialValues={{
          name: '',
          contactName: '',
          email: '',
          phoneNumber: '',
          logo: '',
        }}
        validationSchema={schema}
        validateOnChange
        onSubmit={onSubmit}
      >
        <Form className="wizard__form">
          <InputWrapper label="Nombre cliente" id="name">
            <Input name="name" placeholder="Nombre cliente" id="name" />
          </InputWrapper>
          <InputWrapper label="Nombre de contacto" id="contactName">
            <Input
              name="contactName"
              placeholder="Nombre y apellido"
              id="contactName"
            />
          </InputWrapper>
          <InputWrapper label="E-mail" id="email">
            <Input
              name="email"
              placeholder="E-mail"
              label="usuario@email.com"
              id="email"
            />
          </InputWrapper>
          <InputWrapper
            label="E-mail Coordinación"
            id="emailCoord"
            description="A este e-mail se le será añadido las citas de mentoria cuando sean agendadas"
          >
            <Input
              name="emailCoord"
              placeholder="E-mail"
              label="cliente@chileconverge.cl"
              id="emailCoord"
            />
          </InputWrapper>
          <InputWrapper label="Teléfono de contacto" id="phoneNumber">
            <Input
              name="phoneNumber"
              placeholder="9 8223 4567"
              id="phoneNumber"
            />
          </InputWrapper>
          <InputWrapper
            isBig
            label="Logo cliente"
            description="Archivos 176x70px  PNG, SVG  - Tamaño máximo 5MB."
            id="clientImage"
          >
            <InputImage
              name="logo"
              id="clientImage"
              allowedExts={['image/png', 'image/svg+xml']}
            />
          </InputWrapper>
          <div className="wizard__action">
            <Button
              type="button"
              secondary
              onClick={() => history.push('/clientes')}
            >
              Volver
            </Button>
            <Button type="submit" loading={isLoading} disabled={isLoading}>
              Crear
            </Button>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default ClientForm;
