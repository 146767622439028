import { CircularProgress, Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Button from '../../../components/common/Button';
import ModalContent from '../../../components/common/Modal/ModalContent';
import PillarAPI from '../../PillarContainer/pillar-api';
import ClientsApi from '../client-api';

const PillarsModal = ({ open, onClose, announcement, setMessage, handleOpen }) => {
  const [loading, setLoading] = useState(true)
  const [pillars, setPillars] = useState([]);

  useEffect(() => {
    if (open) {
      (async () => {
        try {

          const { data:  pillars } = await PillarAPI.getPillars()
          const { data: { pillars: announcementPillars } } = await ClientsApi.fetchAnnouncementPillars(announcement)
          const checkedPillars = pillars.map(p => { return announcementPillars.find(ap => ap.id === p.id) ? { ...p, checked: true } : { ...p, checked: false } });
          setPillars(checkedPillars);
        } catch (error) {
          setMessage({ type: 'error', message: 'Error al cargar los pilares.' })
          onClose();
        }
        finally {
          setLoading(false)
        }
      })();
    }
  }, [open]);

  const onClickPillar = (pillar) => {
    const filterPillars = pillars.filter(p => p.id !== pillar.id)
    setPillars([{ ...pillar, checked: !pillar.checked }, ...filterPillars])
  }

  const onSubmit = async () => {
    try {
      const selectedPillars = pillars.filter(p => p.checked).map(p => p.id)

      if (!selectedPillars.length) {
        return onClose()
      }
      await ClientsApi.createAnnouncementPillars(announcement, { pillars: selectedPillars })

      setMessage({ type: 'success', msge: 'Pilares asociados exitosamente' })

    } catch (e) {
      setMessage({ type: 'error', msge: e.data.message })
    }
    finally {
      onClose();
    }
  }

  return (
    <Modal open={open} onClose={onClose} className="modal">
      <ModalContent title='Editar pilares' onClick={onClose}>
        <div className='box'>
          <div className='box__header'>
            <span>Pilares ({pillars.filter(p => p.checked).length})</span>
            <span onClick={handleOpen}>Agregar pilar</span>
          </div>
          <hr />
          <span>Selecciona los pilares correspondiente a la convocatoria</span>
          <div className='box__container'>
            {loading ? <div style={{ display: 'flex', justifyContent: 'center', minHeight: 200, alignItems: 'center' }}>
              <CircularProgress color="inherit" />
            </div> : pillars.map(pillar => ((
              <label>
                <input type='checkbox' name='pilar' value={pillar.id} onClick={() => onClickPillar(pillar)} checked={pillar.checked} />
                <span>{pillar.name}</span>
              </label>
            )))}
          </div>
        </div>
        <div className='modal__action-container'>
          <Button secondary onClick={onClose}>
            Volver
          </Button>
          <Button onClick={onSubmit}>
            Guardar
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default PillarsModal;
