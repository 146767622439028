//turns image into base64
/**
 * turns list of files into an object
 * which data is in base64 and its name
 * @param {[]} files
 * @returns {Object}
 */

const ALLOWED_SIZE = 5;
const MB = 1024;

const handleFileRead = (files) => {
  return new Promise((resolve) => {
    Array.from(files).forEach((archivo) => {
      var reader = new FileReader();
      reader.readAsDataURL(archivo);
      reader.onload = () => {
        resolve({ name: files[0].name, data: reader.result });
      };
    });
  });
};

/**
 * returns if image has a valid type
 * @param {string[]} allowedExt
 * @returns {Boolean}
 */
const isValidImagetype = (allowedExt, file) => {
  return file ? allowedExt.includes(file.type) : false;
};
const isValidImageSize = (file, allowedSize = ALLOWED_SIZE) => {
  return file ? allowedSize * MB * MB > file.size : false;
};

/**
 * obtains image from a base64 saved image
 * @param {string} sourceImage
 * @returns {string}
 */
const getImagefromBase64 = (sourceImage) => {
  try {
    const image = new Image();
    image.src = sourceImage.data;
    return image.src;
  } catch (error) {
    return '';
  }
};
export {
  handleFileRead,
  isValidImagetype,
  isValidImageSize,
  getImagefromBase64,
};
