import request from '../../utils/request';

function getBenefits() {
  return request({
    url: '/benefits',
    method: 'GET',
    private: true,
  });
}
function getBenefit(id) {
  return request({
    url: `/benefit/${id}`,
    method: 'GET',
    private: true,
  });
}
function createBenefit(data) {
  return request({
    url: '/benefit',
    method: 'POST',
    private: true,
    data,
  });
}
function updateBenefit(id, data) {
  return request({
    url: `/benefit/update/${id}`,
    method: 'PATCH',
    data,
    private: true,
  });
}



const BenefitsAPI = {
  getBenefits,
  getBenefit,
  createBenefit,
  updateBenefit,
};

export default BenefitsAPI;
