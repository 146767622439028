import React, { useState, useEffect } from 'react';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';

const Dropdown = ({
  options,
  setFieldValue,
  setFieldTouched,
  name,
  id,
  onClick,
  selectedOption = {},
}) => {
  const [showAvailableOptions, setShowAvailableOptions] = useState(false);

  const toggleAvailableOptions = () => {
    setShowAvailableOptions(!showAvailableOptions);
  };
  const closeOptions = (option) => {
    setFieldValue(name, id, option.id);
    setFieldTouched(name, true);
    onClick(option);
    toggleAvailableOptions();
  };

  return (
    <div>
      <div onClick={toggleAvailableOptions} style={{ cursor: 'pointer' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            border: '2px solid #DEEDF2',
            borderRadius: '4px',
            padding: '8px',
            margin: '4px',
            width: '100%',
          }}
        >
          {selectedOption.name ? (
            selectedOption.name
          ) : (
            <span>Selecciona un pilar</span>
          )}
          <span class="material-symbols-outlined">expand_more</span>
        </div>
      </div>
      <div>
        {showAvailableOptions && (
          <FormControl name="" component="fieldset">
            <FormGroup>
              {options.map((option) => (
                <FormControlLabel
                  key={option.id}
                  control={
                    <Checkbox
                      style={{ color: '#0BB5E7' }}
                      checked={selectedOption.id === option.id}
                      value={option.name}
                      name={option.name}
                      onChange={() => closeOptions(option)}
                    />
                  }
                  label={option.name}
                />
              ))}
            </FormGroup>
          </FormControl>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
