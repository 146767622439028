import React from 'react';
import { useField, ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const Select = ({
  label,
  extra,
  max,
  icon,
  startIcon,
  onClickIcon,
  type,
  options,
  advancedOptions,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <div className="input__container">
      <TextField
        id="standard-select"
        select
        label={label}
        {...field}
        {...props}
        name={props.name}
        value={field.value}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          },
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          );
        })}
        {advancedOptions.map((option) => {
          if (option.id !== undefined) {
            return (
              <MenuItem key={option.value} value={option.id}>
                {option.value}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.value}
              </MenuItem>
            );
          }
        })}
      </TextField>
      {meta.touched && meta.error && (
        <div className="error-message">
          <ErrorMessage
            className="error-message"
            name={props.id || props.name}
          />
        </div>
      )}
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.string.isRequired,
  extra: PropTypes.string,
  max: PropTypes.number,
  type: PropTypes.string,
  icon: PropTypes.node,
  startIcon: PropTypes.node,
  onClickIcon: PropTypes.func,
  options: PropTypes.array,
  advancedOptions: PropTypes.array,
};

export default Select;
