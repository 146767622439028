import React, { useEffect, useMemo, useState } from 'react';
import EnhancedTable from '../../../components/common/Table';
import ActionMenu from '../../../components/ActionMenu';
import { useHistory } from 'react-router-dom';
import { ANNOUNCEMENT_STATUS_CLASSNAMES } from '../../ClientContainer/constants';
import StatusIndicator from '../../../components/common/StatusIndicator';
import { formatDate, formatSlug } from '../../../utils/format';
import CopyElement from '../../../components/common/Copy';
import { SITES } from '../../../utils/constants';
import AnnouncementApi from '../announcement-api';
import { ItemMenu } from '../../../components/common/Menu';
import SurveyModal from '../../../components/common/Modal/SurveyModal';
import CoursesModal from './courseModal';

const AnnouncementTable = ({
  setIsLoading,
  isLoading,
  setAllAnnouncements,
  announcements,
}) => {
  const history = useHistory();
  const [announcementSelected, setAnnouncementSelected] = useState({
    isOpen: false,
    id: '',
  });
  const [isSurveyModalOpen, setIsSurveyModalOpen] = useState(false);
  const [selectedAnnouncementId, setSelectedAnnouncementId] = useState(null);
  const [isCourseModalOpen, setIsCourseModalOpen] = useState(false);
  const [message, setMessage] = useState({ type: '', msge: '' });

  const onChangeStatus = async (id, status) => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      let data = { status };
      const updatedAnnouncements =
        await AnnouncementApi.updateAnnouncementStatus(id, { ...data });

      if (updatedAnnouncements.data.code === 200) {
        const {
          data: { announcements },
        } = await AnnouncementApi.fetchAnnouncements();
        setAllAnnouncements(announcements);
      }
      setMessage({
        type: 'success',
        msge: 'Estado de la convocatoria actualizado exitosamente',
      });

      setIsLoading(false);
    } catch (err) {
      setMessage({
        type: 'error',
        msge: 'Hubo un error al actualizar el estado de la convocatoria',
      });
    }
  };

  const openCourseModal = (id) => {
    setSelectedAnnouncementId(id);
    setIsCourseModalOpen(true);
  };

  const renderCopyAction = ({
    urlMentee,
    urlMentor,
    slug,
    rol,
    organization,
  }) => {
    const url =
      window.location.origin === SITES.PROD.admin
        ? SITES.PROD.platform
        : SITES.DEV.platform;

    const iconArray = [
      { rol: 'mentee', urlMentee },
      { rol: 'mentor', urlMentor },
    ];

    return iconArray.map((iconUrl, key) => {
      if (iconUrl.urlMentee && iconUrl?.urlMentee !== '') {
        return (
          <CopyElement
            key={key}
            tooltipText={`Convocatoria ${iconUrl?.rol}`}
            copyText={`${url}/convocatorias/${formatSlug(
              organization?.slug ?? ''
            )}/${formatSlug(slug)}/${iconUrl.urlMentee}`}
          />
        );
      }
      if (iconUrl.urlMentor && iconUrl?.urlMentor !== '') {
        return (
          <CopyElement
            key={key}
            tooltipText={`Convocatoria ${iconUrl?.rol}`}
            copyText={`${url}/convocatorias/${formatSlug(
              organization?.slug ?? ''
            )}/${formatSlug(slug)}/${iconUrl.urlMentor}`}
          />
        );
      }
    });
  };

  const renderMenuAction = (id, original) => {
    return (
      <React.Fragment key={id}>
        <div className="action-container">
          <span
            className="material-icons icon"
            onClick={() =>
              setAnnouncementSelected((prev) => {
                return { isOpen: !prev.isOpen, id: id };
              })
            }
          >
            more_horiz
          </span>
          {announcementSelected.id === id && (
            <ActionMenu isOpen={announcementSelected.isOpen}>
              <ItemMenu
                action={() =>
                  history.push('/convocatorias/detalle', {
                    announcementId: announcementSelected.id,
                    orgId: original.organizationId.id,
                  })
                }
                text="Ver detalle"
              />
              <ItemMenu
                action={() =>
                  history.push('/convocatorias/editar', {
                    announcementId: announcementSelected.id,
                    orgId: original.organizationId.id,
                  })
                }
                text="Editar"
                status={original.status === 'finish' ? 'disabled' : ''}
              />
              <ItemMenu
                action={() => onChangeStatus(id, 'waiting')}
                status={
                  original.status === 'user-register' ? 'warning' : 'disabled'
                }
                text="Desactivar"
              />
              <ItemMenu
                action={() => onChangeStatus(id, 'finish')}
                status={
                  original.status === 'user-register' ? 'warning' : 'disabled'
                }
                text="Finalizar"
              />
              <ItemMenu
                action={() => onChangeStatus(id, 'deleted')}
                status={original.status === 'waiting' ? 'warning' : 'disabled'}
                text="Eliminar"
              />

              {/* <ItemMenu
                action={() => {
                  setSelectedAnnouncementId(id);
                  setIsSurveyModalOpen(true);
                }}
                status={original.status === 'finish' ? 'disabled' : ''}
                text="Agregar encuesta"
              />
              <ItemMenu
                action={() => openCourseModal(original.id)}
                text="Agregar Curso"
              /> */}
            </ActionMenu>
          )}
        </div>
      </React.Fragment>
    );
  };

  const statusTextTransform = (statusName) => {
    switch (statusName) {
      case 'active':
        return {
          text: 'Activo',
          className: ANNOUNCEMENT_STATUS_CLASSNAMES.ACTIVE,
        };
      case 'finish':
        return {
          text: 'Finalizado',
          className: ANNOUNCEMENT_STATUS_CLASSNAMES.FINISH,
        };
      case 'waiting':
        return {
          text: 'En espera',
          className: ANNOUNCEMENT_STATUS_CLASSNAMES.WAITING,
        };
      case 'user-register':
        return {
          text: 'Registro usuario',
          className: ANNOUNCEMENT_STATUS_CLASSNAMES.USER_REGISTER,
        };
      case 'deleted':
        return {
          text: 'Eliminado',
          className: '',
        };
      default:
        return { text: '', className: '' };
    }
  };

  const columns = useMemo(
    () => [
      { Header: 'Nombre', accessor: 'name' },
      {
        Header: 'Fecha de activación',
        accessor: function fnAccesor({ startDate }) {
          return formatDate(startDate, 'DD/MM/YYYY');
        },
      },
      {
        Header: 'Fecha de termino',
        accessor: function fnAccesor({ endDate }) {
          return formatDate(endDate, 'DD/MM/YYYY');
        },
      },
      {
        Header: 'Estado',
        accessor: function fnAccesor({ status }) {
          return statusTextTransform(status).text;
        },
        Cell: ({
          row: {
            original: { status },
          },
        }) => (
          <StatusIndicator
            text={statusTextTransform(status).text}
            statusClassName={statusTextTransform(status).className}
          />
        ),
      },
      {
        Header: 'Acción',
        accessor: 'id',
        Cell: ({ value, row: { original } }) => {
          return (
            <div className="flex" style={{ alignItems: 'flex-end' }}>
              {[
                renderCopyAction({
                  urlMentee: original.urlMentee,
                  urlMentor: original.urlMentor,
                  slug: original.slug,
                  rol: original.rol,
                  organization: original.organizationId,
                }),
                renderMenuAction(value, original),
              ]}
            </div>
          );
        },
      },
    ],
    [announcementSelected]
  );

  return (
    <>
      <EnhancedTable
        data={announcements}
        columns={columns}
        isLoadingData={isLoading}
        tableName="Convocatorias"
      />
      <SurveyModal
        modalOpen={isSurveyModalOpen}
        setIsSurveyModalOpen={setIsSurveyModalOpen}
        announcementId={selectedAnnouncementId}
      />

      {isCourseModalOpen && (
        <CoursesModal
          open={isCourseModalOpen}
          onClose={() => setIsCourseModalOpen(false)}
          announcement={selectedAnnouncementId}
        />
      )}
    </>
  );
};

export default AnnouncementTable;
