import { Modal } from "@material-ui/core";
import React from "react";
import Lottie from 'react-lottie';
import download from '../../../assets/images/animation/download-animate.json'
import Button from "../../../components/common/Button";
import ModalContent from "../../../components/common/Modal/ModalContent";
import failed from '../../../assets/images/failed.svg'

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: download,
  renderer: 'svg',
}
const LoadingModal = ({ open }) => {
  return <Modal open={open} className="modal">
    <div className='modal__container'>
      <div className="modal__content">
        <Lottie options={lottieOptions} height={170} width={170} />
        <span>Estamos procesando tu solicitud de descarga</span>
      </div>
    </div>
  </Modal>

}

export const ErrorModal = ({ open, onClose }) => {
  return <Modal open={open} className="modal">
    <ModalContent onClick={onClose}>
      <>
        <div className="flex center">
          <img src={failed} />
        </div>
        <span style={{ maxWidth: 273, textAlign: 'center', alignSelf: 'center' }}>No hemos podido realizar la descarga, por favor reintenta más tarde</span>
        <Button type="button" onClick={onClose}>Entendido</Button>
      </>
    </ModalContent>
  </Modal>


}

export default LoadingModal;