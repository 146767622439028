import React from 'react';
import PropTypes from 'prop-types';
import { useField, ErrorMessage, useFormikContext } from 'formik';
import classnames from 'classnames';
import TextField from '@material-ui/core/TextField';

const Input = ({
  label,
  extra,
  max,
  min,
  icon,
  startIcon,
  type,
  onClickIcon,
  customValidation,
  params,
  isJson,
  defaultValue,
  ...props
}) => {
  const [field, meta] = useField(props);

  const { setFieldValue } = useFormikContext();

  return (
    <div className="input__container">
      {type === 'textarea' ? (
        <TextField
          className={classnames('text-input', {
            error: meta.touched && meta.error,
            endIcon: icon,
            startIcon: startIcon,
          })}
          maxLength={max}
          {...props}
          {...field}
          style={startIcon ? { paddingLeft: '40px' } : {}}
          label={label}
          multiline
          minRows={4}
          type={type}
          defaultValue={defaultValue}
          value={defaultValue ? undefined : field.value}
          disabled={props.disabled}
        />
      ) : type === 'datepicker' ? (
        <TextField
          className={classnames('text-input', {
            error: meta.touched && meta.error,
            endIcon: icon,
            startIcon: startIcon,
          })}
          {...field}
          style={startIcon ? { paddingLeft: '40px' } : {}}
          label={label}
          id="datetime-local"
          type="datetime-local"
          defaultValue={field.value}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ min: min }}
          {...props}
          disabled={props.disabled}
        />
      ) : (
        <TextField
          className={classnames('text-input', {
            error: meta.touched && meta.error,
            endIcon: icon,
            startIcon: startIcon,
          })}
          maxLength={max}
          {...field}
          {...props}
          style={startIcon ? { paddingLeft: '40px' } : {}}
          label={label}
          type={type}
          name={props.name}
          value={field.value}
          onChange={(e) => {
            setFieldValue(field.name, e.target.value);
          }}
          inputProps={{ maxLength: max, min: min, max: max }}
          disabled={props.disabled}
        />
      )}
      {icon ? (
        <span
          className={`input-icon end ${
            !onClickIcon ? !(meta.error && meta.touched) && 'success' : ''
          } `}
          onClick={onClickIcon}
        >
          {icon}
        </span>
      ) : null}
      {startIcon ? <span className="input-icon start">{startIcon}</span> : null}

      {meta.touched && meta.error && (
        <div className="error-message">
          {isJson ? (
            <p className="error-message" name={props.id || props.name}>
              {JSON.stringify(meta.error)}
            </p>
          ) : (
            <ErrorMessage
              className="error-message"
              name={props.id || props.name}
            />
          )}
        </div>
      )}
      {customValidation ? (
        <div className="error-message">
          <p>{customValidation}</p>
        </div>
      ) : null}
      {!meta.error && <p className="extra-message">{extra}</p>}
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string.isRequired,
  extra: PropTypes.string,
  type: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.string,
  icon: PropTypes.node,
  startIcon: PropTypes.node,
  onClickIcon: PropTypes.func,
  customValidation: PropTypes.string,
};

export default Input;
