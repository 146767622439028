import request from '../../utils/request';

function getApplications(pageNumber, limit) {
  const page = pageNumber ? `?pageNumber=${pageNumber}` : '';
  const limitPerPage = limit ? `&pageSize=${limit}` : '';
  return request({
    url: `applications${page}${limitPerPage}`,
    method: 'GET',
    private: true,
  });
}

function updateApplication(payload) {
  return request({
    url: `application/`,
    method: 'PUT',
    private: true,
    data: payload,
  });
}

const ApplicationAPI = {
  getApplications,
  updateApplication,
};

export default ApplicationAPI;
