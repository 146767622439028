import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Close, CheckCircle } from '@material-ui/icons';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { useHistory, useLocation } from 'react-router-dom';
import UsersAPI from '../../user-api';
import Button from '../../../../components/common/Button';
import Skeleton from 'react-placeholder';
import { UserContext } from '../userContext';
import ConfirmationModal from '../../../ClientContainer/statusModal';

const Schedule = ({ setShowAlert, setMessageAlert }) => {
  const location = useLocation();
  const history = useHistory();
  const [selectedSchedule, setSelectedSchedule] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [selectedDay, setSelectedDay] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [endDate, setEndDate] = useState(
    moment().add(1, 'day').format('YYYY-MM-DD')
  );

  const [defaultDate, setDefaultDate] = useState(
    moment().add(1, 'day').format('YYYY-MM-DD')
  );

  const [state, dispatch] = useContext(UserContext);
  const { user } = state;

  const schedule = {
    days: [
      { name: 'Lunes', selected: false, id: 1 },
      { name: 'Martes', selected: false, id: 2 },
      { name: 'Miércoles', selected: false, id: 3 },
      { name: 'Jueves', selected: false, id: 4 },
      { name: 'Viernes', selected: false, id: 5 },
    ],
    hours: [
      '09:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00',
      '18:00',
      '19:00',
      '20:00',
      '21:00',
    ],
  };

  useEffect(() => {
    if (user.role.type === 'mentor') {
      UsersAPI.getMentorCalendar(user.id)
        .then(({ data }) => {
          const dates = data.map((scd) => {
            return moment(scd.date);
          });

          const maxDate = moment.max(dates);
          setDefaultDate(maxDate.format('YYYY-MM-DD'));
          setEndDate(maxDate.format('YYYY-MM-DD'));

          data.map((sch) => {
            var scheduleAux = selectedSchedule;
            const dayName =
              moment(sch.date).format('dddd').charAt(0).toUpperCase() +
              moment(sch.date).format('dddd').slice(1);
            if (!scheduleAux.find((d) => d.name === dayName)) {
              var hoursAux = [];
              sch.hours.map((h) => {
                hoursAux.push(moment(h, 'HH:mm:ss').format('HH:mm'));
                return true;
              });
              scheduleAux.push({
                name: dayName,
                hours: hoursAux,
                day: schedule.days.find((d) => d.name === dayName).id,
              });
              setSelectedSchedule([...scheduleAux]);
              return true;
            } else {
              return false;
            }
          });
          const selectedDay =
            data.length > 0
              ? moment(data[0].date).format('dddd').charAt(0).toUpperCase() +
                moment(data[0].date).format('dddd').slice(1)
              : '';

          setIsLoading(false);
          setSelectedDay(selectedDay);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error('get shedules', error);
        });
    }

    // eslint-disable-next-line
  }, []);

  const selectDay = (day) => {
    var scheduleAux = selectedSchedule;
    if (!scheduleAux.find((d) => d.name === day.name)) {
      scheduleAux.map((schedule, index) => {
        if (schedule.hours.length === 0) {
          scheduleAux.splice(index, 1);
          return true;
        } else {
          return false;
        }
      });
      scheduleAux.push({ name: day.name, hours: [], day: day.id });
      setSelectedSchedule([...scheduleAux]);
    }
    setSelectedDay(day.name);
  };
  const selectHour = (hour) => {
    var scheduleAux = selectedSchedule;
    if (
      selectedSchedule.find((d) => d.name === selectedDay) &&
      !selectedSchedule
        .find((d) => d.name === selectedDay)
        .hours.find((h) => h === hour)
    ) {
      scheduleAux.map((schedule, index) => {
        if (schedule.name === selectedDay) {
          scheduleAux[index].hours.push(hour);
          return true;
        } else {
          return false;
        }
      });
      setSelectedSchedule([...scheduleAux]);

      const allHoursSelected = schedule.hours.every((h) =>
        selectedSchedule.find((d) => d.name === selectedDay).hours.includes(h)
      );
      setIsAllSelected(allHoursSelected);
    }
  };

  const removeHour = (hour, dayRem) => {
    var scheduleAux = selectedSchedule;
    if (
      selectedSchedule.find((d) => d.name === dayRem) &&
      selectedSchedule
        .find((d) => d.name === dayRem)
        .hours.find((h) => h === hour)
    ) {
      scheduleAux.map((schedule, index) => {
        if (schedule.name === dayRem) {
          scheduleAux[index].hours.splice(
            scheduleAux[index].hours.indexOf(hour),
            1
          );
          return true;
        } else {
          return false;
        }
      });
      setSelectedSchedule([...scheduleAux]);
    }
  };
  const removeAllSchedules = () => {
    setSelectedSchedule([]);
    setIsAllSelected(false);
  };
  const handleSubmit = () => {
    UsersAPI.updateMentorCalendar(
      selectedSchedule,
      endDate,
      location.state.idUser
    )
      .then((response) => {
        history.push({
          pathname: `/mentores`,
          state: {
            message: response.data.message,
            type: 'success',
          },
        });
      })
      .catch((e) => {
        setMessageAlert(e.data.message);
        setShowAlert(true);
      });
  };

  const removeAndSubmit = async () => {
    try {
      selectedSchedule.forEach((schedule) => {
        schedule.hours.forEach((hour) => {
          removeHour(hour, schedule.name);
        });
      });

      setShowModal(false);

      await handleSubmit();
    } catch (error) {
      console.error('Error en removeAndSubmit:', error);
    }
  };

  return (
    <div className="edit-schedules-mentor">
      <Grid item style={{ backgroundColor: 'white' }}>
        <Grid container>
          {/** Horarios */}
          <Grid item xs={12} className="role-info horarios">
            {!isLoading ? (
              <>
                <div className="horarios__container">
                  <ConfirmationModal
                    className="modal"
                    open={showModal}
                    handleAccept={removeAndSubmit}
                    handleDenied={() => setShowModal(false)}
                    header="Eliminar horarios"
                    title={
                      '¿Estás seguro de que deseas eliminar estos horarios?'
                    }
                  />

                  <h3>Horarios disponibles</h3>
                  <span>
                    Bloques de horarios disponibles para realizar mentorias
                  </span>
                  <div className="container__item">
                    <p className="horarios__item-title">Días</p>
                    {schedule.days.map((day, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            day.name === selectedDay ||
                            (selectedSchedule.find(
                              (d) => d.name === day.name
                            ) &&
                              selectedSchedule.find((d) => d.name === day.name)
                                .hours.length)
                              ? 'horarios__item active'
                              : 'horarios__item'
                          }
                          onClick={() => {
                            selectDay(day);
                          }}
                        >
                          {day.name == selectedDay &&
                          selectedSchedule.find((d) => d.name === day.name) &&
                          selectedSchedule.find((d) => d.name === day.name)
                            .hours.length ? (
                            <CheckCircle className="item-title__icon" />
                          ) : (
                            <></>
                          )}{' '}
                          {day.name}
                        </div>
                      );
                    })}
                  </div>

                  <div className="container__item">
                    <div className="item-title-container">
                      <p className="horarios__item-title">
                        Horarios disponibles
                      </p>
                      {selectedSchedule.length ? (
                        <span
                          className="schedules"
                          onClick={() => setShowModal(true)}
                          widthFull
                        >
                          <DeleteOutlineOutlinedIcon />
                          Eliminar horarios
                        </span>
                      ) : null}
                    </div>
                    {schedule.hours.map((hour, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            selectedSchedule.find((d) => d.name === selectedDay)
                              ? selectedSchedule
                                  .find((d) => d.name === selectedDay)
                                  .hours.find((h) => h === hour)
                                ? 'horarios__item active'
                                : 'horarios__item'
                              : 'horarios__item'
                          }
                          onClick={() => {
                            selectHour(hour);
                          }}
                        >
                          {hour}
                          {' - '}
                          {moment(hour, 'HH:mm')
                            .add(1, 'hours')
                            .format('HH:mm')}
                        </div>
                      );
                    })}
                  </div>
                </div>

                {selectedSchedule.length ? (
                  <div className="horarios__selected-container">
                    <div>
                      <p className="selected__item-title">
                        Días y horarios para reuniones
                      </p>
                      <Grid container>
                        {selectedSchedule.map((item, index) => {
                          return item.hours.length ? (
                            <Grid item xs={5} key={index}>
                              <div className="col col-span-1 selected__date">
                                {item.name}
                              </div>
                              <div className="col col-span-2">
                                {item.hours.map((hour, indexH) => {
                                  return (
                                    <div
                                      key={index + indexH}
                                      className="selected__hour"
                                    >
                                      {hour}
                                      {' - '}
                                      {moment(hour, 'HH:mm')
                                        .add(1, 'hours')
                                        .format('HH:mm')}
                                      <div
                                        className="selected__remove"
                                        onClick={() => {
                                          removeHour(hour, item.name);
                                        }}
                                      >
                                        <Close className="remove__icon" />
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </Grid>
                          ) : null;
                        })}
                      </Grid>
                      <p className="selected__item-title">
                        Mantener horarios de reuniones hasta:
                      </p>
                      <TextField
                        id="date"
                        type="date"
                        defaultValue={defaultDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(event) => {
                          setEndDate(event.target.value);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </>
            ) : (
              <Skeleton showLoadingAnimation type="text" rows={15} />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: 'white' }}
            className="submit"
          >
            <Grid container justify="center">
              <Grid item xs={4}>
                <Button onClick={handleSubmit} widthFull>
                  Aceptar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Schedule;
