import { FormControlLabel, Stepper, Switch } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useContext, useEffect, useState } from 'react';
import BreadCrumb from '../../../components/Breadcrumbs';
import ColumnSkeleton from '../../../components/common/Skeletons';
import { ACTION } from '../../../reducer/projectReducer';
import Wizard from '../../ClientContainer/ClientCreationContainer/wizard';
import ProjectApi from '../project-api';
import { ProjectContext } from '../projectContext';
import LandingEditForm from './landingEditForm';

const EditProjectClientContainer = (props) => {
  const {
    location: { state: routeState },
  } = props;

  const [loading, setLoading] = useState(true);
  const [state, dispatch] = useContext(ProjectContext);
  const [disabled, setDisabled] = useState(false);
  const [toggleState, setToggleState] = useState(false);

  const links = [
    { url: '/clientes', text: 'Clientes' },
    {
      url: '/clientes/detalles',
      text: 'Detalle del cliente',
      state: { client: routeState.clientId },
    },
    { text: 'Editar Convocatoria', url: '.' },
  ];

  useEffect(() => {
    //get info about announcement
    (async () => {
      try {
        const {
          data: { announcement },
        } = await ProjectApi.fetchProjectAnnouncement(
          routeState.announcementId
        );

        const { status, ...rest } = announcement;

        const state = isToggleChecked(status);

        setToggleState(state);

        //allows to be disabled
        setDisabled(status === 'finish' || status === 'active');

        dispatch({
          type: ACTION.UPDATE_ANNOUNCEMENT_STATUS,
          payload: {
            status: status,
          },
        });

        dispatch({
          type: ACTION.UPDATE_LANDING,
          payload: {
            landing: rest,
          },
        });

        setLoading(false);
      } catch (error) {}
    })();
  }, []);

  const onToggle = (status) => {
    if (status != 'finished' || status != 'active') {
      setToggleState((prev) => !prev);
      dispatch({
        type: ACTION.UPDATE_ANNOUNCEMENT_STATUS,
        payload: {
          status: status === 'waiting' ? 'user-register' : 'waiting',
        },
      });
    }
  };
  const isToggleChecked = (status) => {
    return status === 'user-register' || status === 'active';
  };

  return (
    <>
      <div className="client-project-container client-container">
        <div className="client__back-container">
          <BreadCrumb links={links} />
        </div>
        <Wizard title="Editar convocatoria para cliente">
          {loading ? (
            <div className="wizard__content">
              <div className="wizard_steps-container wizard-padding">
                <Skeleton width={250} height={100} />
              </div>
              <div className="wizard__form-container">
                <Skeleton width={500} height={150} />
                <Skeleton width={500} height={120} />
                <Skeleton width={500} height={120} />
                <Skeleton width={500} height={120} />
                <Skeleton width={500} height={150} />
              </div>
            </div>
          ) : (
            <div className="wizard__content">
              <div className="wizard__steps-container wizard-padding">
                <FormControlLabel
                  className={`wizard__toggle-container ${
                    disabled && 'wizard__toggle-container--disabled'
                  }`}
                  control={
                    <Switch
                      disabled={disabled}
                      checked={toggleState}
                      onChange={() => onToggle(state.status)}
                      name="active"
                    />
                  }
                  label="Activar/Desactivar convocatoria"
                />

                <Stepper text="Landing de registro" isActive={true} />
              </div>
              <div className="wizard__form-container">
                <LandingEditForm />
              </div>
            </div>
          )}
        </Wizard>
      </div>
    </>
  );
};

export default EditProjectClientContainer;
