import request from '../../utils/request';
/**
 * Creates a client
 * @param { name:string, phoneNumber, email, contactName, image } client
 * @returns {any}
 */

function createClient(client) {
  return request({
    url: '/client',
    method: 'POST',
    private: true,
    data: client,
  });
}

function fetchClients() {
  return request({
    url: '/client',
    method: 'GET',
    private: true,
  });
}

function fetchClient(id) {
  return request({
    url: `/client/${id}`,
    method: 'GET',
    private: true,
  });
}

function updateClient(id, client) {
  return request({
    url: `/client/${id}`,
    method: 'PATCH',
    private: true,
    data: client,
  });
}

function fetchAnnouncementPillars(id) {
  return request({
    url: `/annoucement/${id}/pillars`,
    method: 'GET',
    private: true,
  });
}

function createAnnouncementPillars(id, pillars) {
  return request({
    url: `/annoucement/${id}/pillars`,
    method: 'POST',
    private: true,
    data: pillars,
  });
}

function sendInviteMail(id, guestEmails) {
  return request({
    url: `/session/${id}/send-invite`,
    method: 'POST',
    private: true,
    data: {
      guestEmails: guestEmails,
    },
  });
}

const ClientsApi = {
  createClient,
  fetchClients,
  fetchClient,
  updateClient,
  sendInviteMail,
  fetchAnnouncementPillars,
  createAnnouncementPillars,
};

export default ClientsApi;
