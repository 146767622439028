import { FormControlLabel, Switch } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Wizard from '../../ClientContainer/ClientCreationContainer/wizard';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import InputWrapper from '../../../components/forms/Input/inputWrapper';
import InputImage from '../../../components/forms/Input/inputImage';
import { Input } from '../../../components/forms';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import {
  YupEditorMaxLengthValidator,
  YupEditorMinLengthValidator,
} from '../../../utils/validators';
import draftToHtml from 'draftjs-to-html';
import Button from '../../../components/common/Button';
import NewsApi from '../news-api';
import Alert from '../../../components/common/Alert';
import { useHistory } from 'react-router-dom';
import { TIME_TO_REDIRECT } from '../../../utils/constants';
import LinkComponent from '../../../components/common/Link';
import NewsModal from '../newsModal';

YupEditorMaxLengthValidator();
YupEditorMinLengthValidator();

const MAX_LENGTH_TITLE = 70;
const MAX_LENGTH_NEW = 300;
const MIN_LENGTH_NEWS = 10;

const NewsForm = (props) => {
  const history = useHistory();
  const newsId = props.location.state.id;
  const [isLoading, setIsLoading] = useState(true);
  const [newContent, setNewContent] = useState();
  const [textEditor, setTextEditor] = useState();
  const [newStatus, setNewStatus] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDraftSubmitting, setIsDraftSubmitting] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: '',
    content: '',
    note: '',
  });

  const getEditorContent = (content) => {
    const blockFromHTML = convertFromHTML(content);
    const editorContent = ContentState.createFromBlockArray(blockFromHTML);
    const editorText = EditorState.createWithContent(editorContent);
    return editorText;
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const {
          data: { new: news },
        } = await NewsApi.fetchNewById(newsId);
        setNewContent(news);

        const editorText = getEditorContent(news.description);
        const toggleStatus = news.status === 'active';
        setNewStatus(toggleStatus);
        setTextEditor(editorText);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const schema = Yup.object().shape({
    title: Yup.string()
      .max(
        MAX_LENGTH_TITLE,
        `El título no puede superar los ${MAX_LENGTH_TITLE} caracteres`
      )
      .required('Debes ingresar un título válido'),
    description: Yup.string()
      .editorMinLengthValidator(
        MIN_LENGTH_NEWS,
        `La noticia debe contener al menos ${MIN_LENGTH_NEWS} caracteres`
      )
      .editorMaxLengthValidator(
        MAX_LENGTH_NEW,
        `La noticia no puede superar los ${MAX_LENGTH_NEW} caracteres`
      )
      .required('Debes ingresar una descripción válida'),
    image: Yup.string().required('Debes ingresar una imágen válida'),
    status: Yup.string(),
    largeImage: Yup.string().nullable(true),
  });

  const onSubmit = async (values) => {
    setOpen(true);
    setNewContent(values);
    if (isDraft) {
      setModalContent({
        title: 'Guardar borrador',
        content:
          'Guardaremos el avance como un borrador para que puedas retormarlo más tarde',
      });
    } else {
      setModalContent({
        title: 'Edición publicación',
        content: '¿Estás seguro que deseas realizar la publicación',
        note: 'Esta publicación reemplazará a la que se hizo anteriormente',
      });
    }
  };

  const onHandleAccept = async () => {
    try {
      const s = isDraft ? 'draft' : 'active';

      const { createdAt, client, id, updatedAt, user, status, ...rest } =
        newContent;
      //takes content state to sending as description
      const content = draftToHtml(convertToRaw(textEditor.getCurrentContent()));

      if (isDraft) {
        setIsDraftSubmitting(true);
        await NewsApi.updateNews(newsId, {
          ...rest,
          status: s,
          description: content,
        });
      } else {
        setIsSubmitting(true);
        await NewsApi.updateNews(newsId, {
          ...rest,
          status: s,
          description: content,
        });
      }
    } catch (error) {
      setErrorMessage(error.data.message);
    } finally {
      setIsSubmitting(false);
      setTimeout(() => history.push('/novedades'), TIME_TO_REDIRECT);
    }
  };

  return (
    <div className="client-container">
      <div className="client__back-container">
        <LinkComponent
          title="Volver"
          url="/novedades"
          icon="arrow_back"
        ></LinkComponent>
      </div>
      <Wizard title="Editar publicación">
        {errorMessage && <Alert type="error">{errorMessage}</Alert>}
        {!isLoading && (
          <Formik
            initialValues={newContent}
            validationSchema={schema}
            validateOnChange
            onSubmit={onSubmit}
          >
            {({ submitForm, ...props }) => {
              return (
                <Form>
                  <div className="wizard__content wizard__content--col">
                    <div className="wizard__steps-container wizard-padding">
                      <InputWrapper
                        variant="big"
                        label="Imagen inicio (banner)"
                        description="Archivos 440x260px JPG, PNG  - Tamaño máximo 5MB."
                        id="largeImage"
                      >
                        <InputImage
                          name="largeImage"
                          id="largeImage"
                          allowedExts={['image/png', 'image/jpg', 'image/jpeg']}
                        />
                      </InputWrapper>
                      <InputWrapper
                        variant="big"
                        label="Imagen interior (detalle de la publicación)"
                        description="Archivos 240x200px JPG, PNG  - Tamaño máximo 5MB."
                        id="image"
                      >
                        <InputImage
                          name="image"
                          id="image"
                          allowedExts={['image/png', 'image/jpg', 'image/jpeg']}
                        />
                      </InputWrapper>
                    </div>
                    <div className="wizard__form--center grid wizard-padding">
                      <InputWrapper
                        variant="big"
                        label="Nombre de la publicación"
                        id="title"
                      >
                        <Input name="title" label={''} id="title" />
                        <span className="char-counter">
                          {props.values?.title.length}/ {MAX_LENGTH_TITLE}
                        </span>
                      </InputWrapper>
                      <InputWrapper
                        label="Descripción"
                        id="description"
                        name="description"
                        variant="big"
                      >
                        <>
                          <Editor
                            id="description"
                            name="description"
                            wrapperClassName="editor__wrapper"
                            toolbarClassName="editor__toolbar"
                            editorState={textEditor}
                            onEditorStateChange={setTextEditor}
                            onBlur={() => {
                              props.setFieldTouched('description', true);
                              props.setFieldValue(
                                'description',
                                draftToHtml(
                                  convertToRaw(textEditor.getCurrentContent())
                                )
                              );
                            }}
                          />
                          <div className="editor__error">
                            {props.touched.description &&
                              props.errors.description && (
                                <span className=" error-message">
                                  {props.errors.description}
                                </span>
                              )}
                            <span className="char-counter">
                              {
                                textEditor.getCurrentContent().getPlainText('')
                                  .length
                              }
                              /{MAX_LENGTH_NEW}
                            </span>
                          </div>
                        </>
                      </InputWrapper>
                    </div>
                  </div>
                  <div className="wizard__lower-action">
                    <Button
                      secondary
                      loading={isDraftSubmitting}
                      onClick={() => {
                        setIsDraft(true);
                        submitForm();
                      }}
                    >
                      Guardar
                    </Button>
                    <Button
                      loading={isSubmitting}
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      Publicar
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </Wizard>
      <NewsModal
        open={open}
        onClose={() => setOpen(false)}
        handleDenied={() => setOpen(false)}
        handleAccept={() => onHandleAccept()}
        selectedNews={newContent}
        content={modalContent}
      />
    </div>
  );
};

export default NewsForm;
