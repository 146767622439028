import request from '../../utils/request';

/**
 * Description
 * @param {[]} {users
 * @param {number} announcementId
 * @param {string} role}
 * @returns {any}
 */
async function addUserToAnnouncement({ users, announcementId, role }) {
  return request({
    url: `/announcements/${announcementId}/users`,
    method: 'POST',
    private: true,
    data: { users, role },
  });
}
/**
 * Fetch organizations list
 * @param {
 * {}
 * } announcement
 * @returns {any}
 */
async function fetchOrganizations() {
  return request({
    url: `/organizations/list`,
    method: 'GET',
    private: true,
  });
}

/**
 * Fetch announcement data of a organizations
 * @param {
 * {announcementId, organizationId}
 * } announcement
 * @returns {any}
 */
async function fetchOrganizationAnnouncement(announcementId, orgId) {
  return request({
    url: `announcements/${announcementId}/organizations/${orgId}`,
    method: 'GET',
    private: true,
  });
}

async function fetchOpenAnnouncement(announcementId, orgId) {
  return request({
    url: `announcements/${orgId}/${announcementId}`,
    method: 'GET',
    private: true,
  });
}
async function createAnnouncement(announcement) {
  return request({
    url: `/announcements/create`,
    method: 'POST',
    private: true,
    data: announcement,
  });
}

async function fetchAnnouncements() {
  return request({
    url: `/announcements/no-client`,
    method: 'GET',
    private: true,
  });
}

async function updateAnnouncementStatus(id, data) {
  return request({
    url: `/announcements/no-client/${id}`,
    method: 'PATCH',
    private: true,
    data,
  });
}
async function updateAOpenAnnouncement(id, orgId, data) {
  return request({
    url: `/announcements/${orgId}/${id}`,
    method: 'PATCH',
    private: true,
    data,
  });
}

async function rescheduleMeeting(sessionId, session) {
  return request({
    url: `/schedule/reschedule/${sessionId}`,
    method: 'POST',
    private: true,
    data: session,
  });
}
async function rescheduleEmail(sessionId, session) {
  return request({
    url: `/schedule/send-reschedule-mail/${sessionId}`,
    method: 'POST',
    private: true,
    data: session,
  });
}
const AnnouncementApi = {
  addUserToAnnouncement,
  fetchOrganizations,
  fetchOrganizationAnnouncement,
  fetchOpenAnnouncement,
  createAnnouncement,
  fetchAnnouncements,
  updateAnnouncementStatus,
  updateAOpenAnnouncement,
  rescheduleMeeting,
  rescheduleEmail,
};

export default AnnouncementApi;
