//initialState
//user -> user Info, contains info used for forms
//pillars -> list of all pillars
//mentors -> list of all mentors
//schedule -> mentor's schedule
//futureSessions -> used for forms about future meetings.
const initialState = {
  user: {},
  pillars: [],
  mentors: [],
  schedule: [],
  //futureSessions need: schedule: string[], userId:string,
  // mentorId:string, subject:string, description:string, notify:boolean
  futureSessions: {},
  autosaved: [
    { userInfo: false },
    { extraData: false },
    { schedules: false },
    { futureSessions: false },
  ],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_INITIAL_STATE': {
      return {
        ...state,
        user: action.payload.user,
        pillars: action.payload.pillars,
        mentors: action.payload.mentors || state.mentors,
        schedule: action.payload.schedule,
      };
    }

    case 'UPDATE_USER': {
      return {
        ...state,
        user: action.payload.user,
      };
    }

    case 'UPDATE_MENTOR_PILLAR': {
      return {
        ...state,
        user: { ...state.user, pillar: action.payload.pillar },
      };
    }

    case 'UPDATE_MENTEE_PILLAR': {
      return {
        ...state,
        user: { ...state.user, pillars: action.payload.pillars },
      };
    }

    case 'UPDATE_FUTURE_SESSIONS': {
      return {
        ...state,
        futureSessions: action.payload.futureSessions,
      };
    }

    case 'UPDATE_AUTOSAVE_STATUS': {
      return {
        ...state,
        autosaved: action.payload.autosaved,
      };
    }
    default:
      return state;
  }
};
export { initialState };
export default userReducer;
