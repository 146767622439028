import React, { useContext } from 'react';
import Button from '../../../../components/common/Button';
import { Input } from '../../../../components/forms';
import converge from '../../../../assets/images/logo-converge.svg';
import { getImagefromBase64 } from '../../../../utils/images';
import { AnnouncementContext } from '../AnnouncementContext';

const BannerPreview = () => {
  const [state, dispatch] = useContext(AnnouncementContext);
  const { announcement, organization } = state;

  const renderImage = (image) => {
    if (typeof image === 'string') {
      return image;
    } else {
      return getImagefromBase64(image);
    }
  };

  return (
    <>
      <div
        className="landing__banner--mobile"
        style={{
          backgroundImage: `url(${renderImage(announcement.mobileBanner)})`,
          backgroundPosition: 'center',
          backgroundRepeat: ' no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className="landing__announcement-info">
          <div className="announcement__client">
            <div className="announcement__image-container">
              <img src={converge} alt="chile converge logo" />
            </div>
            {organization.logo && (
              <div className="announcement__image-container">
                <img
                  src={renderImage(organization.logo)}
                  alt="chile converge logo"
                />
              </div>
            )}
          </div>
          <h1 className="announcement__title">{announcement.title}</h1>
        </div>
      </div>
      <div className="landing__form--mobile">
        <h1>Ingresa tus datos personales</h1>
        <div>
          <Input name="name" label="nombre completo" />
          <Input name="dni" label="Rut" />
          <Input name="phone" label="teléfono" />
          <Input name="email" label="Email" />
          <Input name="contraseña" label="confirmar contraseñ" />
          <Button widthFull>Continuar</Button>
        </div>
      </div>
      {/* Desktop landing */}
      <div
        className="landing__banner"
        style={{
          backgroundImage: `url(${renderImage(announcement.desktopBanner)})`,
          backgroundPosition: 'center',
          backgroundRepeat: ' no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className="landing__announcement-info">
          <div className="announcement__client">
            <div className="announcement__image-container">
              <img src={converge} alt="chile converge logo" />
            </div>
            {organization.logo && (
              <div className="announcement__image-container">
                <img
                  src={renderImage(organization.logo)}
                  alt="chile converge logo"
                />
              </div>
            )}
          </div>
          <h1 className="announcement__title">{announcement.title}</h1>
        </div>
        <div className="landing__form-container">
          <div className="landing__form">
            <span className="landing__form-title">
              Ingresa tus datos personales
            </span>
            <div>
              <Input name="name" label="Nombre completo" />
              <Input name="dni" label="Rut" />
              <Input name="phone" label="Teléfono" />
              <Input name="email" label="Email" />
              <Input name="contraseña" label="Contraseña" />
              <Input name="contraseña" label="Confirmar contraseña" />
              <button className="landing__button">Continuar</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerPreview;
