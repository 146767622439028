import React, { useContext, useState } from 'react';

import * as Yup from 'yup';
import { FieldArray, Form, Formik } from 'formik';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useHistory } from 'react-router-dom';
import { Modal } from '@material-ui/core';

import { YupEditorMaxLengthValidator, YupEditorMinLengthValidator } from '../../../../utils/validators';
import { AnnouncementContext } from '../../AnnouncementCreation/AnnouncementContext';
import ItemTextBox from '../../AnnouncementCreation/steps/itemBox';
import AnnouncementApi from '../../announcement-api';
import { ACTION } from '../../../../reducer/announcementReducer';
import Alert from '../../../../components/common/Alert';
import ModalContent from '../../../../components/common/Modal/ModalContent';
import Button from '../../../../components/common/Button';
import { announcemtentSchema } from '../../schemas';
import AnnouncementPreview from '../../AnnouncementCreation/Preview/AnnouncementPreview';
import InputWrapper from '../../../../components/forms/Input/inputWrapper';
import InputImage from '../../../../components/forms/Input/inputImage';
import { Input } from '../../../../components/forms';
import { ALLOWED_CHAR, ALLOWED_DESCRIPTION_CHAR, ALLOWED_ITEMS_CHAR } from '../../../ProjectsContainer/schemas/landing';
import { TIME_TO_REDIRECT } from '../../../../utils/constants';

const GraphicsForm = ({ onPrev }) => {
  const history = useHistory();
  YupEditorMaxLengthValidator();
  YupEditorMinLengthValidator();
  const [state, dispatch] = useContext(AnnouncementContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { announcement, organization } = state;
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState({ type: '', message: '' });

  const items = [
    { name: 'Item 1', Component: ItemTextBox, placeholder: 'placeholder' },
    {
      name: 'Item 2',
      Component: ItemTextBox,
      placeholder: 'placeholder 1',
    },
    {
      name: 'Item 3',
      Component: ItemTextBox,
      placeholder: 'placeholder 2',
    },
  ];

  const [activeItem, setActiveItem] = useState(0);

  const defaultContent = state.announcement.about;
  const blockFromHTML = convertFromHTML(defaultContent);
  const content = ContentState.createFromBlockArray(blockFromHTML);
  const [textEditor, setTextEditor] = useState(
    EditorState.createWithContent(content)
  );


  const onSubmit = async (values) => {
    try {
      let announcementPayload = {
        organization, ...announcement, ...values
      }


      announcementPayload.status = announcement.status;

      await AnnouncementApi.updateAOpenAnnouncement(announcement.id, organization.id, announcementPayload)

      setTimeout(() => {
        history.push('/convocatorias',);
        setIsLoading(false);
      }, TIME_TO_REDIRECT);

    } catch (error) {
      setMessageAlert({ type: 'error', message: error.data.message });
      setIsLoading(false);
    }
  };


  const onClickTab = (values, index) => {
    dispatch({
      type: ACTION.UPDATE_ANNOUNCEMENT,
      payload: {
        ...values,
        about: draftToHtml(convertToRaw(textEditor.getCurrentContent())),
      },
    });
    setActiveItem(index);
  };

  const handlePreview = (values) => {
    dispatch({
      type: ACTION.UPDATE_ANNOUNCEMENT,
      payload: {
        ...values,
        about: draftToHtml(convertToRaw(textEditor.getCurrentContent())),
      },
    });
    setOpen(true);
  };

  const handlePrev = () => {
    history.goBack()
  };
  return (
    <>
      {messageAlert && (
        <Alert type={messageAlert.type}>{messageAlert.message}</Alert>
      )}
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalContent
          onClick={() => setIsModalOpen(false)}
          title="Estás saliendo sin guardar los cambios"
        >
          <span className="title">¿Estás seguro que deseas volver?</span>
          <div className="modal__action-container">
            <Button
              type="button"
              secondary
              onClick={() => setIsModalOpen(false)}
            >
              No
            </Button>
            <Button type="button" onClick={handlePrev}>
              Si
            </Button>
          </div>
        </ModalContent>
      </Modal>
      <Formik
        initialValues={announcement}
        validationSchema={announcemtentSchema}
        onSubmit={onSubmit}
      >
        {(props) => {

          return (
            <>
              <AnnouncementPreview open={open} onClose={() => setOpen(false)} />
              <Form>
                <div className="wizard__preview-container">
                  <Button
                    type="button"
                    secondary
                    className="button button--flat"
                    onClick={() => handlePreview(props.values)}
                  >
                    Previsualizar
                  </Button>
                </div>
                <div className="wizard__form">

                  <div className="landing-design__section wizard__grid-section">
                    <div className="title-container grid">
                      <span className="subtitle">
                        Acá podrás modificar los principales elementos del
                        landing de registro siguiendo la línea gráfica del
                        cliente y Chileconverge.
                      </span>
                    </div>
                    <div>

                      <InputWrapper
                        variant="big"
                        label="Banner desktop"
                        description="Archivos 1440x800px PNG, JPG  - Tamaño máximo 5MB."
                        id="desktopBanner"
                      >
                        <InputImage
                          name="desktopBanner"
                          id="desktopBanner"
                          allowedExts={['image/png', 'image/jpg', 'image/jpeg']}
                        />
                      </InputWrapper>
                      <InputWrapper
                        variant="big"
                        label="Banner mobile"
                        description="Archivos 375x522px PNG, JPG  - Tamaño máximo 5MB."
                        id="mobileBanner"
                      >
                        <InputImage
                          name="mobileBanner"
                          id="mobileBanner"
                          allowedExts={['image/png', 'image/jpg', 'image/jpeg']}
                        />
                      </InputWrapper>
                    </div>
                    <div className="wizard__grid-section">
                      <InputWrapper
                        label="Título de la convocatoria"
                        id="title"
                        description="Corresponde al llamado principal que aparece en el banner."
                      >
                        <Input name="title" label="Título" id="title" />
                        <span className="char-counter">
                          {props.values.title.length}/{ALLOWED_CHAR}
                        </span>
                      </InputWrapper>
                      {/* Quienes somos */}
                      <InputWrapper
                        label="Quiénes somos"
                        id="about"
                        description="Descripción resumida"
                        name="about"
                        variant="big"
                      >
                        <>
                          <Editor
                            id="about"
                            name="about"
                            wrapperClassName="editor__wrapper"
                            toolbarClassName="editor__toolbar"
                            editorState={textEditor}
                            onEditorStateChange={setTextEditor}
                            onBlur={() => {
                              props.setFieldTouched('about', true);
                              props.setFieldValue(
                                'about',
                                draftToHtml(
                                  convertToRaw(textEditor.getCurrentContent())
                                )
                              );
                            }}
                          />
                          <div className="editor__error">
                            {props.touched.about && props.errors.about && (
                              <span className=" error-message">
                                {props.errors.about}
                              </span>
                            )}
                            <span className="char-counter">
                              {
                                textEditor.getCurrentContent().getPlainText('')
                                  .length
                              }
                              /{ALLOWED_DESCRIPTION_CHAR}
                            </span>
                          </div>
                        </>
                      </InputWrapper>

                      {/* ITEMS  */}
                      <FieldArray name="items">
                        {(arrayProps) => (
                          <InputWrapper
                            label="Iconografía"
                            id="iconografía"
                            description="Completa la información correspondiente a cada item"
                            name="items"
                            variant="big"
                          >
                            <div className="tabs-panel__container">
                              <div className="tabs__container">
                                {items.map((item, index) => {
                                  return (
                                    <div
                                      className={`${activeItem === index
                                        ? 'tabs__item-tab tabs__item-tab--active'
                                        : 'tabs__item-tab'
                                        }`}
                                      key={index}
                                      onClick={() =>
                                        onClickTab(props.values, index)
                                      }
                                    >
                                      {item.name}
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="tab-panel">
                                <>
                                  {items.map((item, index) => {
                                    const Component = item.Component;
                                    return (
                                      activeItem === index && (
                                        <Component
                                          {...props}
                                          {...arrayProps}
                                          key={index}
                                          name={`item.${index}.text`}
                                          maxChar={ALLOWED_ITEMS_CHAR}
                                        />
                                      )
                                    );
                                  })}
                                </>
                              </div>
                            </div>
                          </InputWrapper>
                        )}
                      </FieldArray>
                    </div>
                  </div>
                  <div className="wizard__action">
                    <Button secondary onClick={() => {
                      onPrev();
                    }} disabled={isLoading}>
                      Volver
                    </Button>
                    <Button type={"submit"} loading={isLoading} disabled={isLoading}>
                      Finalizar
                    </Button>
                  </div>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default GraphicsForm;
