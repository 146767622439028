import React from 'react';

const StatusIndicator = ({ statusClassName, text, error }) => {
  return (
    <div className={`status-container ${error && 'status-container--error'}`}>
      <div className={`status__indicator ${statusClassName}`}></div>
      <span>{text}</span>
    </div>
  );
};

export default StatusIndicator;
