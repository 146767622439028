import React, { useEffect, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CalendarToday from '@material-ui/icons/CalendarToday';
import { useHistory } from 'react-router-dom';
import { Close, Visibility } from '@material-ui/icons';
import { Grid, makeStyles, Modal, Snackbar } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '../../../../../components/common/Button/index';
import SessionAPI from '../../../../SessionContainer/session-api';
import { Input } from '../../../../../components/forms';

import { formatDate, formatSlug } from '../../../../../utils/format';
import CopyElement from '../../../../../components/common/Copy';
import { SITES, statusSession } from '../../../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '0 none',
  },
}));

function ListItemLink(props) {
  const { session } = props;
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [sessionActual, setSessionActual] = useState(session);
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {}, [sessionActual]);

  const handleCancelSession = (values) => {
    setIsSubmitting(true);
    SessionAPI.cancelSession(session.id, values)
      .then((response) => {
        let sess = sessionActual;
        sess.status = 'canceled';
        setSessionActual(sess);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setOpenModal(false);
        setIsSubmitting(false);
      });
  };

  const url =
    window.location.origin === SITES.PROD.admin
      ? SITES.PROD.platform
      : SITES.DEV.platform;

  return (
    <>
      {openModal && (
        <Modal
          className={classes.modal}
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="cancel-session">
            <h3>¿Estás seguro que deseas cancelar esta reunión?</h3>
            <Formik
              initialValues={{
                reason:
                  'Lo siento, no podré asistir a nuestra reunión. Gracias de todas formas',
              }}
              validationSchema={Yup.object({
                reason: Yup.string().required('Ingrese un motivo'),
              })}
              onSubmit={(values) => {
                handleCancelSession(values);
              }}
            >
              <Form>
                <div className="cancel__content">
                  <Input
                    label="Motivo"
                    placeholder="Motivo"
                    name="reason"
                    type="textarea"
                    max={500}
                    showCounter
                    isRequired={true}
                  />
                </div>
                <div className="info__button">
                  <div className="save-area">
                    <Grid container justify="space-around">
                      <Grid item xs={5}>
                        <Button
                          widthFull
                          secondary
                          onClick={() => {
                            setOpenModal(false);
                          }}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item xs={5}>
                        <Button widthFull type="submit" loading={isSubmitting}>
                          Aceptar
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </Modal>
      )}
      {!isSubmitting && (
        <ListItem className="listItem">
          <ListItemIcon>
            <CalendarToday />
          </ListItemIcon>
          <ListItemText
            primary={sessionActual.title}
            secondary={
              formatDate(sessionActual.start, 'DD/MM/YYYY HH:mm:ss') +
              ' Estado: ' +
              statusSession[sessionActual?.status]
            }
          />
          <ListItemIcon>
            <Visibility
              className="sessionDetails"
              onClick={() => {
                history.push('/reuniones/detalle', {
                  idSession: session.id,
                });
              }}
            />
          </ListItemIcon>
          <ListItemIcon>
            <CopyElement
              tooltipText="Url de mentoria"
              className="sessionDetails"
              copyText={`${url}/mentorias/informacion?id=${session.id}`}
            ></CopyElement>
          </ListItemIcon>

          {(sessionActual.status === 'created' ||
            sessionActual.status === 'not-assigned') && (
            <ListItemIcon>
              <Close
                className="sessionDetails"
                onClick={(e) => {
                  setOpenModal(true);
                }}
              />
            </ListItemIcon>
          )}
        </ListItem>
      )}
    </>
  );
}

export default ListItemLink;
