export const TODAY = new Date().toISOString().split('T')[0];
export const ROLES = {
  Mentee: 'mentee',
  Mentor: 'mentor',
};

export const SITES = {
  PROD: {
    admin: 'https://converge-admin.chileconverge.cl',
    platform: 'https://plataforma.chileconverge.cl',
  },
  DEV: {
    admin: 'https://admindev.chileconverge.cl/',
    platform: 'https://chileconvergedev.chileconverge.cl',
  },
};

export const TIME_TO_REDIRECT = 2000;

export const statusSession = {
  created: 'Creada',
  'no-email': 'Sin Mail',
  'not-assigned': 'No Asignada',
  active: 'Activa',
  finished: 'Finalizada',
  canceled: 'Cancelada',
  'not-used': 'No usada',
  abandoned: 'Abandonada',
  rescheduled: 'Fue reagendada',
};
