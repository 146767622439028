import { read, utils, writeFile, writeFileXLSX } from 'xlsx';
import { TODAY } from './constants';

export const exportExcell = async (objects, fileName) => {
  try {
    var wb = utils.book_new();
    objects.map((data) => {
      const ws = utils.json_to_sheet(data.info);
      utils.book_append_sheet(wb, ws, data.name);
    });
    return writeFileXLSX(wb, `${fileName}-${TODAY}.xlsx`, {
      compression: true,
      cellDates: true,
    });
  } catch (e) {
    console.log('ERROR', e);
  }
};
