import React, { useEffect, useMemo, useState } from 'react';
import EnhancedTable from '../../../components/common/Table';
import { useHistory } from 'react-router-dom';
import { formatDate, formatSlug } from '../../../utils/format';
import StatusIndicator from '../../../components/common/StatusIndicator';
import { ANNOUNCEMENT_STATUS_CLASSNAMES } from '../constants';
import Menu, { MenuContainer } from '../../../components/common/Menu';
import ProjectApi from '../../ProjectsContainer/project-api';
import CopyElement from '../../../components/common/Copy';
import { SITES } from '../../../utils/constants';
import PillarsModal from './PillarModal';
import Alert from '../../../components/common/Alert';
import PillarCreationModal from './PillarCreationModal';
import ConfirmationModal from '../statusModal';
import SurveyModal from '../../../components/common/Modal/SurveyModal';
import CoursesModal from '../../AnnouncementContainer/AnnouncementListContainer/courseModal';

const ACTIONS = { waiting: 'waiting', finish: 'finish' };
const ProjectTableContainer = ({
  isLoading,
  projects,
  client,
  setIsLoading,
}) => {
  const history = useHistory();

  const [isSurveyModalOpen, setIsSurveyModalOpen] = useState(false);
  const [selectedAnnouncementId, setSelectedAnnouncementId] = useState(null);
  const [isCourseModalOpen, setIsCourseModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState({ type: '', message: '' });
  const OpenCoursesModal = (announcementId) => {
    setSelectedAnnouncementId(announcementId);
    setIsCourseModalOpen(true);
  };

  const url =
    window.location.origin === SITES.PROD.admin
      ? SITES.PROD.platform
      : SITES.DEV.platform;
  const [isMenuOpen, setIsMenuOpen] = useState({ open: false, id: '' });
  const [announcementSelected, setAnnouncementSelected] = useState({
    announcement: null,
    action: null,
  });

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    message: '',
    titulo: '',
  });
  const [isPillarModalOpen, setIsPillarModalOpen] = useState(false);
  const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);

  const onClick = (id) => {
    setIsMenuOpen((prev) => {
      return { id, open: !prev.open };
    });
  };

  const handleChangeOfStatus = (action, announcement) => {
    switch (action) {
      case ACTIONS.finish:
        setIsModalOpen({
          open: true,
          message:
            '¿Estás seguro de querer finalizar el proyecto? Los mentores y los mentees ya no tendrán las restricciones del cliente',
          titulo: 'Finalización del proyecto',
        });
        setAnnouncementSelected({ ...announcement, action });
        break;

      case ACTIONS.waiting:
        setIsModalOpen({
          open: true,
          message:
            'El proyecto quedará en espera para volver a activar la convocatoria debes editar sus fechas, ¿Estas seguro de pasar el proyecto a espera?',
          titulo: 'Inactivación del proyecto',
        });
        setAnnouncementSelected({ ...announcement, action });
        break;
      default:
        break;
    }
  };

  const handleEnd = async (id) => {
    try {
      const { data } = await ProjectApi.updateProjectStatus(id, 'finish');
    } catch (error) {
    } finally {
      setIsLoading(true);
    }
  };

  const handleDeactivation = async (id) => {
    try {
      const { data } = await ProjectApi.updateProjectStatus(id, 'waiting');
    } catch (error) {
    } finally {
      setIsLoading(true);
    }
  };

  const renderIndicatorByStatus = (text, status) => {
    const className =
      ANNOUNCEMENT_STATUS_CLASSNAMES[status.toUpperCase().replace('-', '_')];

    return <StatusIndicator statusClassName={className} text={text} />;
  };

  const itemMenuRender = (status) =>
    ['active', 'finish', 'waiting'].includes(status) ? 'disabled' : 'warning';

  const menuActions = (announcement) => [
    {
      text: 'Ver detalle',
      action: () =>
        history.push('/clientes/detalles/proyecto', {
          announcementId: announcement.id,
          title: announcement.title,
          client: announcement.clientName,
          clientId: announcement.clientId,
        }),
    },
    {
      text: 'Pilares',
      action: () => {
        setIsPillarModalOpen(true);
        setAnnouncementSelected({ ...announcement, action: null });
      },
    },
    {
      text: 'Editar',
      action: () =>
        history.push('/clientes/detalles/proyecto/editar', {
          announcementId: announcement.id,
          title: announcement.title,
          client: announcement.clientName,
          clientId: announcement.clientId,
        }),
    },
    {
      text: 'Desactivar',
      action: () => handleChangeOfStatus(ACTIONS.waiting, announcement),
      status: itemMenuRender(announcement.status),
    },
    {
      text: 'Finalizar',
      action: () => handleChangeOfStatus(ACTIONS.finish, announcement),
      status: announcement.status === 'finish' ? 'disabled' : 'warning',
    },

    {
      text: 'Agregar encuesta',
      action: () => AddSurvey(announcement.id),
      status: announcement.status === 'finish' ? 'disabled' : '',
    },
    {
      text: 'Agregar Curso',
      action: () => OpenCoursesModal(announcement.id),
      status: announcement.status === 'finish' ? 'disabled' : '',
    },
  ];

  const AddSurvey = (announcementId) => {
    setSelectedAnnouncementId(announcementId);
    setIsSurveyModalOpen(true);
  };

  const columns = useMemo(
    () => [
      { Header: 'Nombre', accessor: 'title' },
      {
        Header: 'Fecha de activación',
        accessor: 'startDate',
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: 'Fecha de termino',
        accessor: 'endDate',
        Cell: ({ value }) => (value ? formatDate(value) : '-'),
      },
      {
        Header: 'Estado',
        accessor: 'translatedStatus',
        Cell: ({
          value,
          row: {
            original: { status },
          },
        }) => renderIndicatorByStatus(value, status),
      },

      {
        Header: 'Acciones',
        accessor: 'id',
        Cell: ({ value, row: { original } }) => {
          return (
            <div className="action-menu-container">
              <CopyElement
                tooltipText="Convocatoria mentee"
                copyText={`${url}/convocatorias/${client.slug}/${formatSlug(
                  original.slug
                )}/${original.urlMentee}`}
              />
              <CopyElement
                tooltipText="Convocatoria mentor"
                copyText={`${url}/convocatorias/${client.slug}/${formatSlug(
                  original.slug
                )}/${original.urlMentor}`}
              />
              <CopyElement
                tooltipText="Inicio de sesión"
                copyText={`${url}/iniciar-sesion/${client.slug}`}
              />
              <div className="action-container">
                <span
                  className="material-symbols-outlined icon"
                  onClick={() => onClick(value)}
                >
                  more_horiz
                </span>
                {isMenuOpen.id == value && (
                  <Menu
                    items={menuActions({
                      ...original,
                      clientName: client.name,
                      clientId: client.id,
                    })}
                    open={isMenuOpen.open}
                  />
                )}
              </div>
            </div>
          );
        },
      },
    ],
    [isMenuOpen.open]
  );
  useEffect(() => {
    if (message.message) {
      const timeout = setTimeout(() => {
        setMessage({ type: '', message: '' });
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [message]);

  return (
    <>
      {message.type && <Alert type={message.type}>{message.msge}</Alert>}
      <EnhancedTable
        data={projects}
        columns={columns}
        tableName="Proyectos"
        isLoadingData={isLoading}
        onClickButton={() =>
          history.push(`/clientes/${formatSlug(client.name)}/convocatoria`, {
            client: {
              id: client.id,
              status: client.status,
              name: client.name,
              isCustomized: client.isCustomized,
            },
          })
        }
        buttonLabel="Nueva convocatoria"
      />

      <SurveyModal
        modalOpen={isSurveyModalOpen}
        setIsSurveyModalOpen={setIsSurveyModalOpen}
        announcementId={selectedAnnouncementId}
      />
      <ConfirmationModal
        title={isModalOpen?.message}
        open={isModalOpen.open}
        header={isModalOpen?.titulo}
        handleDenied={() =>
          setIsModalOpen({ open: false, message: '', title: '' })
        }
        handleAccept={() => {
          announcementSelected.action == ACTIONS.finish
            ? handleEnd(announcementSelected.id)
            : handleDeactivation(announcementSelected.id);
        }}
        onClose={() => setIsModalOpen({ open: false, message: '', title: '' })}
      />
      <PillarsModal
        open={isPillarModalOpen}
        onClose={() => setIsPillarModalOpen(false)}
        announcement={announcementSelected.id}
        setMessage={setMessage}
        handleOpen={() => {
          setIsPillarModalOpen(false);
          setIsCreationModalOpen(true);
        }}
      />
      <PillarCreationModal
        open={isCreationModalOpen}
        setMessage={setMessage}
        onClose={() => setIsCreationModalOpen(false)}
      />
      <CoursesModal
        open={isCourseModalOpen}
        onClose={() => setIsCourseModalOpen(false)}
        announcement={selectedAnnouncementId}
      />
      {error && (
        <div className="error-message">
          <Alert type="error">{error}</Alert>
        </div>
      )}
    </>
  );
};

export default ProjectTableContainer;
