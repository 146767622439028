import { Breadcrumbs } from '@material-ui/core';
import React from 'react';
import LinkComponent from '../common/Link';

const BreadCrumb = ({ links }) => {
  return (
    <div>
      <Breadcrumbs>
        {links.map((link, index) =>
          index === 0 ? (
            <LinkComponent
              key={`item-${index}`}
              url={link.url}
              title={link.text}
              icon="arrow_back"
              className="link--breadcrumb-prev"
              state={link.state}
            />
          ) : (
            <LinkComponent
              url={index == links.length - 1 ? undefined : link.url}
              key={`item-${index}`}
              title={link.text}
              state={link.state}
              className={`${
                index === links.length - 1 ? 'link' : 'link--breadcrumb-prev'
              }`}
            />
          )
        )}
      </Breadcrumbs>
    </div>
  );
};
export default BreadCrumb;
