import React from 'react';
import Grid from '@material-ui/core/Grid';
import Menu from './components/Menu';
import Header from './components/Header';

const PrivateLayout = ({ children }) => {
  return (
    <Grid container className="layout-private__container">
      <Grid item xs={2} className="component-menu">
        <nav>
          <Menu />
        </nav>
      </Grid>
      <Grid item xs={10}>
        <header className="component-header">
          <Header />
        </header>
        <article className="layout-private__content">{children}</article>
      </Grid>
    </Grid>
  );
};

export default PrivateLayout;
