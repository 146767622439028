import request from '../../utils/request';

function getMassiveMails() {
  return request({
    url: '/masive-mail',
    method: 'GET',
    private: true,
  });
}

function addMasiveMail(payload) {
  return request({
    url: `/masive-mail`,
    method: 'POST',
    private: true,
    data: payload,
  });
}

const MassiveMailsAPI = {
  getMassiveMails,
  addMasiveMail,
};

export default MassiveMailsAPI;
