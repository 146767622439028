import React from 'react';
import AnnouncementProvider from '../AnnouncementCreation/AnnouncementContext';
import AnnouncementEditWizard from './AnnouncementEditWizard';

const AnnouncementCreation = () => {
  return (
    <AnnouncementProvider>
      <AnnouncementEditWizard />
    </AnnouncementProvider>
  );
};
export default AnnouncementCreation;
