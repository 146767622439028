import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const ColumnSkeleton = ({ rows }) => {
  return (
    <Grid item xs={12} sm={12} md={6} lg={6}>
      {Array.from({ length: rows }).map((row) => (
        <Skeleton
          key={row}
          showLoadingAnimation
          type="text"
          rows={15}
          className="mt-4"
        />
      ))}
    </Grid>
  );
};

export default ColumnSkeleton;
