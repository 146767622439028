import React, { useEffect, useState } from 'react';
import Indicator from './indicator';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import CancelPresentationOutlinedIcon from '@material-ui/icons/CancelPresentationOutlined';
import UsersAPI from '../../UserContainer/user-api';

const IndicatorContainer = ({ nextToDueDateClicked, toggleIndicator }) => {
  const [indicators, setIndicators] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await UsersAPI.fetchIndicators();
      setIndicators(data);
    })();
  }, []);

  return (
    <>
      <div className="indicator-container">
        <Indicator
          label="Mentores activos con horarios"
          value={indicators.totalActives}
          icon={GroupOutlinedIcon}
        />
        <Indicator
          id="totalNextToDueDate"
          label="Mentores activos con horarios por vencer. (Proximas 2 semanas)"
          value={indicators.totalNextToDueDate}
          icon={QueryBuilderOutlinedIcon}
          isActive={nextToDueDateClicked ? true : false}
          onToggle={() => toggleIndicator('totalNextToDueDate')}
        />
        <Indicator
          label="Total registros inicial e incompletos"
          value={indicators.totalIncompleteRegister}
          icon={EventNoteOutlinedIcon}
        />
        <Indicator
          label="Total desactivados"
          value={indicators.totalDeactivated}
          icon={CancelPresentationOutlinedIcon}
        />
        <Indicator
          label="Total Mentores inactivos"
          value={indicators.totalInactive}
          icon={QueryBuilderOutlinedIcon}
        />
      </div>
    </>
  );
};

export default IndicatorContainer;
