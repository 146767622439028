import React, { useState } from 'react';
import { Modal, Grid, TextField } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { Input } from '../../../components/forms';
import Button from '../../../components/common/Button';
import * as Yup from 'yup';
import InputWrapper from '../../../components/forms/Input/inputWrapper';
import CloseIcon from '@material-ui/icons/Close';
import AnnouncementApi from '../announcement-api';
import RescheduleErrorModal from './rescechule-error';
import { Autocomplete } from '@material-ui/lab';
import RescheduleSummary from './reschedule-summary';

import Select from '../../../components/forms/Select';
import { AVAILABLE_HOURS } from '../../../utils/dates';

const AnnouncementModal = ({ isOpen, onClose, sessionData }) => {
  const [showSummary, setShowSummary] = useState(false);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorData, setErrorData] = useState({});
  const [formValues, setFormValues] = useState({
    idMentor: sessionData.mentor.id,
    idMentee: sessionData.mentee.id,
    title: '',
    description: '',
    available_date: '',
    hour: '',
    mentee: sessionData.mentee.name,
    mentor: sessionData.mentor.name,
    clientId: sessionData.clientId,
  });

  async function onSubmit(values) {
    try {
      const { available_date, hour, ...restSession } = values;
      const time = hour > 9 ? `${hour}:00` : `0${hour}:00`;

      const calendar = { available_date, hour: time };
      const response = await AnnouncementApi.rescheduleMeeting(sessionData.id, {
        ...restSession,
        calendar,
      });

      setFormValues(response);
      setShowSummary(true);
    } catch (error) {
      setShowErrorModal(true);
      setErrorData(error);
    }
  }

  return (
    <div>
      <Modal
        title="Reagendar reunión"
        className="custom_modal"
        open={isOpen}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="reschedule-session">
          <div className="modal__title-container">
            <h3 className="title">
              {showErrorModal ? 'Horario no disponible' : 'Reagendar'}
            </h3>
            <span className="close-modal" onClick={onClose}>
              <CloseIcon className="close__icon" />
            </span>
          </div>
          {showSummary ? (
            <RescheduleSummary
              formValues={formValues}
              onClose={onClose}
              sessionId={sessionData.id}
            />
          ) : showErrorModal ? (
            <RescheduleErrorModal
              errorData={errorData}
              formValues={formValues}
              onBack={() => setShowErrorModal(false)}
            />
          ) : (
            <>
              <p className="description">
                Ingresa los datos mencionados para agregar un <br /> nuevo
                participante a la convocatoria
              </p>
              <Formik
                initialValues={formValues}
                validationSchema={Yup.object({
                  idMentor: Yup.string().required('Se requiere un Mentor'),
                  mentee: Yup.string().required('Se requiere un Mentee'),
                  title: Yup.string().required('Se requiere un asunto'),
                  description: Yup.string().required(
                    'Se requiere una descripción'
                  ),
                  available_date: Yup.string().required(
                    'Se requiere una Fecha para reagendar'
                  ),
                  hour: Yup.string().required(
                    'Se requiere una hora para reagendar'
                  ),
                })}
                onSubmit={(values) => {
                  onSubmit(values);
                }}
              >
                {(props) => (
                  <Form>
                    <div className="cancel__content">
                      <InputWrapper
                        name="idMentor"
                        label="Mentor"
                        id="idMentor"
                      >
                        <input type="hidden" name="mentorId" />
                        <Autocomplete
                          disabled
                          id="mentorName"
                          options={[sessionData.mentor]}
                          getOptionLabel={(option) => {
                            return option.name.toLowerCase();
                          }}
                          onChange={(event, option) => {
                            props.setFieldValue('mentorId', option.id);
                            props.setFieldTouched('mentorId', true);
                          }}
                          onBlur={(e, option) => {
                            props.setFieldTouched('mentorId', true);
                          }}
                          style={props.style}
                          renderInput={(params) => (
                            <TextField
                              label={sessionData.mentor.name}
                              {...params}
                              {...props}
                            />
                          )}
                        />
                      </InputWrapper>

                      <InputWrapper name="mentee" label="Mentee" id="mentee">
                        <input type="hidden" name="mentee" />
                        <Input
                          disabled
                          placeholder="Mentee"
                          name="mentee"
                          type="select"
                          value={sessionData.mentee.name}
                        />
                      </InputWrapper>
                      <InputWrapper name="title" label="Asunto" id="title">
                        <Input
                          placeholder="Asunto"
                          name="title"
                          type="select"
                          max={500}
                        />
                      </InputWrapper>
                      <InputWrapper
                        name="description"
                        label="Descripcion"
                        id="description"
                      >
                        <Input
                          placeholder="Descripcion"
                          name="description"
                          type="textarea"
                          max={500}
                          showCounter
                        />
                      </InputWrapper>
                    </div>
                    <div className="reschedule__date">
                      <InputWrapper
                        name="available_date"
                        label="Fecha de inicio"
                        id="available_date"
                      >
                        <Input label="" type="date" name="available_date" />
                      </InputWrapper>

                      <InputWrapper id="hour" name="hour">
                        <Select
                          name="hour"
                          placeholder="Hora"
                          label="Hora"
                          type="select"
                          options={[]}
                          advancedOptions={AVAILABLE_HOURS}
                        />
                      </InputWrapper>
                    </div>
                    <div className="info__button">
                      <div className="save-area">
                        <Grid container justify="space-around">
                          <Grid item xs={5}>
                            <Button widthFull secondary onClick={onClose}>
                              Cancelar
                            </Button>
                          </Grid>
                          <Grid item xs={5}>
                            <Button widthFull type="submit">
                              Reagendar
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AnnouncementModal;
