import request from '../../utils/request';

function listSurveys(pageNumber, limit) {
  const page = pageNumber ? `&pageNumber=${pageNumber}` : '';
  const limitPerPage = limit ? `?&pageSize=${limit}` : '';
  return request({
    url: `survey/list${limitPerPage}${page}`,
    method: 'GET',
    private: true,
  });
}
function getSurvey(id) {
  return request({
    url: `survey/${id}`,
    method: 'GET',
    private: true,
  });
}
function addSurvey(payload) {
  return request({
    url: 'survey',
    method: 'POST',
    data: payload,
    private: true,
  });
}
function updateSurvey(payload) {
  return request({
    url: `survey`,
    method: 'PATCH',
    private: true,
    data: payload,
  });
}
const SurveysAPI = {
  getSurvey,
  listSurveys,
  addSurvey,
  updateSurvey,
};

export default SurveysAPI;
