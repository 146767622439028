import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../../components/Breadcrumbs';
import OrganizationCard from './organizationCard';
import ProjectApi from '../../ProjectsContainer/project-api';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import MenteesTable from '../../ProjectsContainer/ProjectInfoContainer/menteesTable';
import MentorsTable from '../../ProjectsContainer/ProjectInfoContainer/mentorsTable';
import SessionsTable from '../../ProjectsContainer/ProjectInfoContainer/SessionsTable';
import TabContainer from '../../ProjectsContainer/ProjectInfoContainer/tabContainer';
import AnnouncementApi from '../announcement-api';
import SurveyTable from '../../ProjectsContainer/ProjectInfoContainer/surveyTable';

const AnnouncementInfo = () => {
  const location = useLocation();
  const {
    state: { announcementId, orgId },
  } = location;
  const [announcement, setAnnouncement] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { announcement },
        } = await AnnouncementApi.fetchOrganizationAnnouncement(
          announcementId,
          orgId
        );

        setAnnouncement(announcement);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const links = [
    { url: '/convocatorias', text: 'Convocatorias' },
    { url: '', text: 'Detalle convocatoria' },
  ];

  const tabs = [
    { title: 'Mentees', Component: MenteesTable, buttonText: 'Nuevo usuario' },
    { title: 'Mentores', Component: MentorsTable, buttonText: 'Nuevo usuario' },
    {
      title: 'Reuniones',
      Component: SessionsTable,
      buttonText: 'Nueva Reunión',
    },
    {
      title: 'Encuestas',
      Component: SurveyTable,
      buttonText: '',
    },
  ];

  return (
    <div className="content-panel user-container">
      <BreadCrumb links={links} />
      <h2>Detalle de convocatoria</h2>
      <OrganizationCard isLoading={isLoading} announcement={announcement} />
      <TabContainer
        tabs={tabs}
        variant="bordered"
        buttonText="Nuevo usuario"
        project={{ announcementId }}
      />
    </div>
  );
};

export default AnnouncementInfo;
