import SeeEmail from '../../../components/common/SeeEmail'
import React, { useEffect, useState } from 'react';
import TableSite from '../../../components/common/Table';
import Alert from '../../../components/common/Alert';
import { useHistory, useLocation } from 'react-router-dom';
import MassiveMailsAPI from '../massive-mails-api';

const MassiveMailsTableContainer = () => {
  const location = useLocation();
  const history = useHistory();
  const [massiveMails, setMassiveMails] = useState([]);
  const [messageResponse, setMessageResponse] = useState({
    message: '',
    type: '',
    show: false,
  });
  let timer;
  const [APILoading, setAPILoading] = useState(false);

  const fetchMassiveMails = () => {
    setAPILoading(true);
    MassiveMailsAPI.getMassiveMails().then(response => {
      renderMassiveMails(response);
    }).then(() => {
      setAPILoading(false);
    }).catch((e) => {
      setMessageResponse({
        message: e.data.message,
        type: 'error',
        show: true,
      });
    })
  }


  function renderMassiveMails(response) {
    setMassiveMails(() =>
      response.data.map((massiveMail) => {
        return {
          id: massiveMail.id,
          subject: massiveMail.subject,
          sender: massiveMail.user.name,
          destinationGroup: massiveMail.userType === 'mentee' ? 'Mentees' : massiveMail.userType === 'mentor' ? 'Mentores' : massiveMail.userType === 'both' ? 'Mentees y Mentores' : 'Desconocido',
          mailType: massiveMail.type === 'image' ? 'Imagen' : massiveMail.type === 'html' ? 'HTML' : massiveMail.type === 'string' ? 'Texto' : 'Desconocido',
          text: massiveMail.text,
        };
      }))
  }
  useEffect(() => {
    // eslint-disable-next-line
    timer = setTimeout(function () {
      setMessageResponse({
        message: '',
        type: 'error',
        show: false,
      });
    }, 5000);
    fetchMassiveMails();

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (location.state) {
      setMessageResponse({
        message: location.state.message,
        type: location.state.type,
        show: true,
      });
    }
  }, [location.state]);
  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Asunto',
        accessor: 'subject',
      },
      {
        Header: 'Remitente',
        accessor: 'sender',
      },
      {
        Header: 'Destinatarios',
        accessor: 'destinationGroup',
      },
      {
        Header: "Tipo",
        accessor: "mailType"
      },
      {
        Header: '',
        id: 'actions',
        accessor: function (id) {
          return (
            <SeeEmail mailData={id} />
          );
        },
        disableSortBy: true,
      },
    ], []);
  return (<>
    <div className="user-container">
      {messageResponse.show && <Alert type={messageResponse.type}>{messageResponse.message}</Alert>}
      <TableSite
        data={massiveMails}
        columns={columns}
        tableName='Correos Enviados'
        buttonLabel="Nuevo Correo"
        onClickButton={() => history.push('/correos/crear')}
        isLoadingData={APILoading}
      />
    </div>
  </>
  );
};

export default MassiveMailsTableContainer;
