import React from 'react';

const Indicator = ({
  label,
  value,
  icon: IconComponent,
  isActive,
  onToggle,
  id,
}) => {
  const handleClick = () => {
    if (onToggle) {
      onToggle(!isActive, id);
    }
  };

  return (
    <div
      className={`indicator ${isActive ? 'active-indicator' : ''}`}
      onClick={handleClick}
    >
      <div className="indicator-container">
        {IconComponent && <IconComponent className="icon-indicator" />}
        <p className="indicator-value">{value}</p>
      </div>
      <p className="description">{label}</p>
    </div>
  );
};

export default Indicator;
