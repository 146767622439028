import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, IconButton } from '@material-ui/core';
import { Videocam, Close, AttachFile } from '@material-ui/icons';
import Skeleton from 'react-placeholder';
import SessionApi from '../session-api';
import Tabs from '../../../components/common/Tabs';
import Modal from '../../../components/common/Modal';
import Alert from '../../../components/common/Alert';
import InfoParticipants from './InfoParticipants';

import AssignDateByMentor from './AssignDateByMentor';
import { AppContext } from '../../../app/AppContext';
import { formatDate } from '../../../utils/format';
import { statusSession } from '../../../utils/constants';

const SessionDetailsContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    settings: { settings },
  } = useContext(AppContext);
  const isMountedRef = useRef(null);
  const [session, setSession] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [mentor, setMentor] = useState({});
  const [mentee, setMentee] = useState({});
  const [files, setFiles] = useState([]);
  const [notes, setNotes] = useState([]);
  const [polls, setPoll] = useState([]);
  const [typeAlert, setTypeAlert] = useState('info');
  const [messageAlert, setMessageAlert] = useState('');
  const [showModal, setShowModal] = useState(false);

  setTimeout(function () {
    setMessageAlert('');
    setTypeAlert('info');
  }, 5000);

  useEffect(() => {
    isMountedRef.current = true;
    if ((location.state && location.state.idSession) || typeAlert !== 'info') {
      SessionApi.getDetailsSession(location.state.idSession).then(
        (response) => {
          if (isMountedRef.current) {
            const data = {
              id: response.data.id,
              title: response.data.title,
              description: response.data.description,
              dateStart: response.data.start,
              dateEnd: response.data.end,
              status: response.data.status,
              pillar: response.data.pillar.name,
              created: response.data.createdAt,
              cancelReason: response.data.cancelReason,
              cancelUser: response.data.cancelUser?.name || '',
            };

            const users = response.data.users;
            users.map((user) => {
              return user.role.type === 'mentor'
                ? setMentor(user)
                : setMentee(user);
            });

            setFiles(response.data.files);
            setNotes(response.data.notes);
            setPoll(response.data.polls);
            setSession(data);
            setIsLoading(false);
          }
        }
      );
    } else {
      history.push('/reuniones');
    }

    return () => (isMountedRef.current = false);
  }, [location, history, typeAlert]);

  const handleChangeSession = (type, message) => {
    setTypeAlert(type);
    setMessageAlert(message);
    handleChangeShowModal(false);
  };

  const handleChangeShowModal = (bool) => {
    setShowModal(bool);
  };

  const tabs = [{ title: 'Datos del Mentor' }, { title: 'Datos del Mentee' }];

  const contentTabs = [
    <InfoParticipants
      personalData={mentor}
      type="mentor"
      notes={notes}
      polls={polls}
      idSession={session.id}
      statusSession={session.status}
      onChange={handleChangeSession}
    />,
    <InfoParticipants
      personalData={mentee}
      type="mentee"
      notes={notes}
      polls={polls}
      idSession={session.id}
    />,
  ];

  const downloadFile = (id, name) => {
    SessionApi.getFileDownload(id)
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data.url]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error('ERROR download', error);
      });
  };

  return (
    <Grid container className="user-container">
      <Grid item xs={12}>
        {messageAlert && <Alert type={typeAlert}>{messageAlert}</Alert>}
        <Grid container className="user-container__edit" direction="column">
          <Grid item className={`title `} style={{ backgroundColor: 'white' }}>
            <IconButton
              aria-label="delete"
              className={`delete-button`}
              onClick={() => history.push('/reuniones')}
            >
              <Close />
            </IconButton>
            <Videocam />
            <span>Detalle de la reunión</span>
          </Grid>
          <Grid item style={{ backgroundColor: 'white' }}>
            <Grid container>
              <Grid item xs={4}>
                <div className="info-session">
                  <h3>Datos de la reunión</h3>
                  {!isLoading ? (
                    <>
                      <div className="info-session__item">
                        <p>
                          <strong>Asunto:</strong>
                        </p>
                        <p>{session?.title}</p>
                      </div>
                      <div className="info-session__item">
                        <p>
                          <strong>Descripción:</strong>
                        </p>
                        <p>{session?.description}</p>
                      </div>
                      <div className="info-session__item">
                        <p>
                          <strong>Fecha Creación:</strong>
                        </p>
                        <p>
                          {formatDate(session?.created, 'DD/MM/YYYY HH:mm')}
                        </p>
                      </div>
                      <div className="info-session__item">
                        <p>
                          <strong>Fecha Inicio:</strong>
                        </p>
                        <p>
                          {formatDate(session?.dateStart, 'DD/MM/YYYY HH:mm')}
                        </p>
                        {mentor.id && !settings.autoAssignSession && (
                          <Modal
                            buttonName="Editar"
                            type="link"
                            title="Seleccione nueva fecha de reunión"
                            onOpen={showModal}
                            onChange={handleChangeShowModal}
                            content={
                              <AssignDateByMentor
                                idMentor={mentor.id}
                                idSession={session.id}
                                onChange={handleChangeSession}
                              />
                            }
                          />
                        )}
                      </div>
                      <div className="info-session__item">
                        <p>
                          <strong>Fecha Término:</strong>{' '}
                        </p>
                        <p>
                          {formatDate(session?.dateEnd, 'DD/MM/YYYY HH:mm')}
                        </p>
                      </div>
                      <div className="info-session__item">
                        <p>
                          <strong>Pilar:</strong>
                        </p>
                        <p>{session?.pillar}</p>
                      </div>
                      <div className="info-session__item">
                        <p>
                          <strong>Estado:</strong>
                        </p>
                        <p>{statusSession[session?.status]}</p>
                      </div>
                      {session?.status === 'canceled' && (
                        <>
                          <div className="info-session__item">
                            <p>
                              <strong>Motivo cancelación:</strong>
                            </p>
                            <p>{session?.cancelReason}</p>
                          </div>
                          <div className="info-session__item">
                            <p>
                              <strong>Usuario que canceló:</strong>
                            </p>
                            <p>{session?.cancelUser}</p>
                          </div>
                        </>
                      )}
                      <br />
                      {files.length > 0 && (
                        <div className="notes">
                          <h3>Archivos</h3>
                          {files.map((file, index) => {
                            return (
                              <div
                                key={`id_note_${index}`}
                                className="notes__item"
                                onClick={() => {
                                  downloadFile(file.id, file.name);
                                }}
                              >
                                <div className="notes__text file">
                                  <AttachFile className="files__icon" />
                                  <span>{file.name}</span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </>
                  ) : (
                    <Skeleton variant="text" rows={10} />
                  )}
                </div>
              </Grid>
              <Grid item xs={8}>
                <div className="info-participants">
                  <Tabs variant="fullWidth" tabs={tabs} content={contentTabs} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SessionDetailsContainer;
