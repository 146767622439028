import request from '../../utils/request';

//creates announcements, login customization and platform customization
function createClientPlatform(clientId, payload) {
  return request({
    url: `announcements/${clientId}`,
    method: 'POST',
    private: true,
    data: payload,
  });
}

function fetchProjectsByClient(clientId) {
  return request({
    url: `client/announcements/${clientId}`,
    method: 'GET',
    private: true,
  });
}
//fetch the announcement of a project
function fetchProjectAnnouncement(announcementId) {
  return request({
    url: `announcements/${announcementId}`,
    method: 'GET',
    private: true,
  });
}

function updateProjectAnnouncement(announcementId, payload) {
  return request({
    url: `announcements/${announcementId}`,
    method: 'PUT',
    private: true,
    data: payload,
  });
}

function fetchUserByProject(announcementId, role) {
  return request({
    url: `announcements/${announcementId}/users`,
    method: 'GET',
    private: true,
    params: {
      role,
    },
  });
}

function updateProjectStatus(announcementId, status) {
  return request({
    url: `announcements/${announcementId}`,
    method: 'PATCH',
    private: true,
    data: {
      status,
    },
  });
}
function fetchSessionsByProject(announcementId) {
  return request({
    url: `announcements/${announcementId}/sessions`,
    method: 'GET',
    private: true
  })
}

function updateUserApplication(announcementId, data){
   return request({
    url: `announcements/${announcementId}/apply`,
    method: 'PUT',
    private: true,
    data:data
  })
}
const ProjectApi = {
  createClientPlatform,
  fetchProjectsByClient,
  fetchProjectAnnouncement,
  fetchUserByProject,
  fetchSessionsByProject,
  updateProjectAnnouncement,
  updateProjectStatus,
  updateUserApplication,
};

export default ProjectApi;
