import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import Button from '../../../../components/common/Button';
import InputImage from '../../../../components/forms/Input/inputImage';
import InputWrapper from '../../../../components/forms/Input/inputWrapper';
import { ProjectContext } from '../../projectContext';
import InputColor from './../../../../components/forms/Input/InputColor';
import * as Yup from 'yup';
import draftToHtml from 'draftjs-to-html';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';
import { ACTION } from '../../../../reducer/projectReducer';
import PlatformPreview from './platformModalPreview';
import { InfoOutlined } from '@material-ui/icons';
import {
  YupEditorMaxLengthValidator,
  YupEditorMinLengthValidator,
} from '../../../../utils/validators';
import ProjectApi from '../../project-api';
import Alert from '../../../../components/common/Alert';
import { useHistory } from 'react-router-dom';
import { TIME_TO_REDIRECT } from '../../../../utils/constants';

const ALLOWED_CHAR = 300;
const PlatformForm = ({ onPrev }) => {
  YupEditorMaxLengthValidator();
  YupEditorMinLengthValidator();
  const history = useHistory();
  const [state, dispatch] = useContext(ProjectContext);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { platform, status } = state;
  const defaultWelcomeText = state.platform.welcomeMessage;

  const blockFromHTML = convertFromHTML(defaultWelcomeText);
  const content = ContentState.createFromBlockArray(blockFromHTML);

  const [textEditor, setTextEditor] = useState(
    EditorState.createWithContent(content)
  );

  const [messageAlert, setMessageAlert] = useState({ type: '', message: '' });
  const [isLoading, setIsLoading] = useState(false);

  const schema = Yup.object().shape({
    platformPrimaryColor: Yup.string().required(
      'Debes ingresar un color primario'
    ),
    platformSecondaryColor: Yup.string().required(
      'Debes ingresar un color secundario'
    ),
    platformBanner: Yup.string().required(
      'Debes ingresar un banner para la plataforma '
    ),
    welcomeMessage: Yup.string()
      .editorMinLengthValidator(1, 'Debes ingresar un texto de bienvenida')
      .editorMaxLengthValidator(
        ALLOWED_CHAR,
        `El largo máximo para la descripción son de ${ALLOWED_CHAR} caracteres`
      )
      .required('Debes ingresar un texto de bienvenida'),
  });

  const handleOnPrev = (values) => {
    dispatch({
      type: ACTION.UPDATE_PLATFORM,
      payload: {
        platform: {
          ...values,
          welcomeMessage: draftToHtml(
            convertToRaw(textEditor.getCurrentContent())
          ),
        },
      },
    });

    onPrev();
  };

  const handlePreview = (values) => {
    dispatch({
      type: ACTION.UPDATE_PLATFORM,
      payload: {
        platform: {
          ...values,
          welcomeMessage: draftToHtml(
            convertToRaw(textEditor.getCurrentContent())
          ),
        },
      },
    });

    setIsOpenModal(true);
  };

  const onSubmit = async (values) => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      const { landing, login } = state;
      const payload = {
        landing,
        login,
        platform: values,
        status: status ? 'user-register' : 'waiting',
      };

      const resp = await ProjectApi.createClientPlatform(
        state.client.id,
        payload
      );
      setMessageAlert({
        type: 'success',
        message: 'Convocatoria creada exitosamente',
      });
      setTimeout(() => {
        history.push('/clientes/detalles', {
          client: state.client.id,
        });
        setIsLoading(false);
      }, TIME_TO_REDIRECT);
    } catch (error) {
      setMessageAlert({ type: 'error', message: error.data.message });
      setIsLoading(false);
    }
  };
  return (
    <div>
      <Formik
        initialValues={platform}
        validationSchema={schema}
        validateOnBlur
        onSubmit={onSubmit}
      >
        {(props) => {
          return (
            <>
              {messageAlert && (
                <Alert type={messageAlert.type}>{messageAlert.message}</Alert>
              )}
              <PlatformPreview
                open={isOpenModal}
                onClose={() => setIsOpenModal(false)}
              />
              <Form>
                <div className="wizard__preview-container">
                  <Button
                    type="button"
                    secondary
                    className="button button--flat"
                    onClick={() => handlePreview(props.values)}
                  >
                    Previsualizar
                  </Button>
                </div>
                <div className="wizard__form">
                  <div className="wizard__grid-section">
                    <div className="title-container grid">
                      <span className="subtitle">
                        Acá podrás modificar los principales elementos de la
                        plataforma siguiendo la línea gráfica del cliente y
                        Chileconverge.
                      </span>
                      <span className="wizard__title-section">Colores</span>
                    </div>
                    <div className="wizard__grid-section">
                      <InputWrapper
                        variant="full"
                        label="Color Primario"
                        id="primaryColor"
                      >
                        <div className="preview__color-container">
                          <InputColor name="platformPrimaryColor" />
                          <div className="description-container">
                            <InfoOutlined className="info_svg" />
                            <span className="description">
                              El color primario corresponde a todos los botones
                              de acción dentro de la plataforma.
                            </span>
                          </div>
                        </div>
                      </InputWrapper>
                      <InputWrapper
                        variant="full"
                        label="Color Secundario"
                        id="secondaryColor"
                      >
                        <div className="preview__color-container">
                          <InputColor name="platformSecondaryColor" />
                          <div className="description-container">
                            <InfoOutlined className="info_svg" />
                            <span className="description">
                              El color secundario corresponde a los iconos que
                              muestra la plataforma.
                            </span>
                          </div>
                        </div>
                      </InputWrapper>
                    </div>
                  </div>
                  <div className="landing-design__section wizard__grid-section">
                    <InputWrapper
                      variant="big"
                      label="Banner desktop"
                      description="Archivos 1440x800px JPG, PNG  - Tamaño máximo 5MB. "
                      id="platformBanner"
                    >
                      <InputImage
                        name="platformBanner"
                        id="platformBanner"
                        allowedExts={['image/png', 'image/jpg', 'image/jpeg']}
                      />
                    </InputWrapper>

                    <div className="wizard__grid-section">
                      <InputWrapper
                        label="Texto de bienvenida"
                        id="welcomeMessage"
                        name="welcomeMessage"
                        variant="big"
                      >
                        <>
                          <Editor
                            id="welcomeMessage"
                            name="welcomeMessage"
                            wrapperClassName="editor__wrapper"
                            toolbarClassName="editor__toolbar"
                            onEditorStateChange={setTextEditor}
                            editorState={textEditor}
                            onBlur={() => {
                              props.setFieldTouched('welcomeMessage', true);
                              props.setFieldValue(
                                'welcomeMessage',
                                draftToHtml(
                                  convertToRaw(textEditor.getCurrentContent())
                                )
                              );
                            }}
                          />

                          <div className="editor__error">
                            {props.touched.welcomeMessage &&
                              props.errors.welcomeMessage && (
                                <span className=" error-message">
                                  {props.errors.welcomeMessage}
                                </span>
                              )}
                            <span className="char-counter">
                              {
                                textEditor.getCurrentContent().getPlainText('')
                                  .length
                              }
                              /{ALLOWED_CHAR}
                            </span>
                          </div>
                        </>
                      </InputWrapper>
                    </div>
                  </div>
                  <div className="wizard__action">
                    <Button
                      secondary
                      type="button"
                      onClick={() => handleOnPrev(props.values)}
                    >
                      Volver
                    </Button>
                    <Button loading={isLoading} type="submit" disabled={isLoading}>
                      Finalizar
                    </Button>
                  </div>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default PlatformForm;
