import { Paper } from '@material-ui/core';
import React, { useContext } from 'react';
import { ProjectContext } from '../../projectContext';
import { getImagefromBase64 } from '../../../../utils/images';
import { CourseIcon, ManagementIcon, MeetingIcon, SellingIcon } from './icons';

const userCards = [
  {
    title: 'Mentorias',
    content:
      'Estamos aquí para ayudarte. ¿Tienes dudas sobre tus negocios? respóndelas directamente con especialistas en distintas áreas.',
    Icon: MeetingIcon,
  },
  {
    title: 'Cursos',
    content:
      'Estás a un click de encontrar los conocimientos que necesitas. Avanza a tu tiempo.',
    Icon: CourseIcon,
  },
  {
    title: 'Crea tu tienda virtual',
    content:
      'Lleva tu negocio al mundo digital, abre tu tienda virtual en pocos pasos.',
    Icon: SellingIcon,
  },
  {
    title: 'Gestiona tu personal contratado',
    content:
      'Centraliza todas tus tareas en una sola plataforma: Remuneraciones, Gestión de Personas y más',
    Icon: ManagementIcon,
  },
];

const ContentPreview = () => {
  const [state, dispatch] = useContext(ProjectContext);
  const { platform } = state;

  return (
    <div>
      <div
        className="platform__image-container"
        style={{
          background: `url(${getImagefromBase64(platform.platformBanner)})`,
          backgroundColor: 'black',
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      ></div>
      <div className="platform__welcome-container">
        <div className="platform__welcome-text-container">
          <span className="welcome-username">Hola [Nombre]!</span>
          <span
            className="welcome-message"
            dangerouslySetInnerHTML={{
              __html: platform.welcomeMessage,
            }}
          ></span>
        </div>
      </div>
      <div className="platform__user-actions">
        {userCards.map((card) => {
          const Icon = card.Icon;
          return (
            <Paper className="platform__card">
              <div className="title-container">
                <Icon color={platform.platformSecondaryColor} />

                <span className="title">{card.title}</span>
              </div>
              <span className="description-card">{card.content}</span>

              <button
                style={{
                  backgroundColor: platform.platformPrimaryColor,
                  color: '#FFFFFF',
                  padding: '8px',
                  border: 'none',
                  fontWeight: 700,
                  borderRadius: '4px',
                  fontSize: '16px',
                  alignSelf: 'end',
                }}
              >
                Comenzar
              </button>
            </Paper>
          );
        })}
      </div>
    </div>
  );
};

export default ContentPreview;
