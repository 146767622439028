
const initialState = {
  organization: {
    id: null,
    name: "",
    logo: ""
  },
  announcement: {
    role: 'mentor',
    name: '',
    title: '',
    startDate: '',
    endDate: '',
    urlMentee: '',
    urlMentor: 'registro-mentor',
    desktopBanner: '',
    mobileBanner: '',
    about: '',
    items: [
      { icon: '', text: '' },
      { icon: '', text: '' },
      { icon: '', text: '' },
    ],
  }
};


export const ACTION = {
  UPDATE_ORGANIZATION: 'UPDATE_ORGANIZATION',
  UPDATE_ANNOUNCEMENT: 'UPDATE_ANNOUNCEMENT',
  UPDATE_ANNOUNCEMENT_STATUS: 'UPDATE_ANNOUNCEMENT_STATUS'
}
const announcementReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.UPDATE_ORGANIZATION:
      return { ...state, organization: { ...state.organization, ...action.payload } }


    case ACTION.UPDATE_ANNOUNCEMENT:
      return { ...state, announcement: { ...state.announcement, ...action.payload } }

    case ACTION.UPDATE_ANNOUNCEMENT_STATUS:
      return { ...state, announcement: { ...state.announcement, status: action.payload } }
    default:
      return state
  }
}

export { initialState }
export default announcementReducer;