import React, { useEffect, useState, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Input } from '../../../components/forms';
import { Modal, Grid, Box, MenuItem, Popover } from '@material-ui/core';
import Button from '../../../components/common/Button';
import TableSite from '../../../components/common/Table';
import Alert from '../../../components/common/Alert';
import SessionAPI from '../session-api';
import { Form, Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { AppContext } from '../../../app/AppContext';
import { formatDate } from '../../../utils/format';
import { SITES, statusSession } from '../../../utils/constants';
import Menu, { MenuContainer } from '../../../components/common/Menu';
import InvitationModal from '../../../components/common/Modal/InvitationModal';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '0 none',
  },
  paper: {
    backgroundColor: 'white',
    border: '0 none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const SessionTableContainer = () => {
  const allStatus = [
    'created',
    'not-assigned',
    'active',
    'finished',
    'canceled',
    'not-used',
    'abandoned',
  ];
  const history = useHistory();
  const statusEl = useRef();
  const {
    user: { role },
    roles: { roles },
  } = useContext(AppContext);
  const isMountedRef = useRef(null);
  const [openModal, setSOpenModal] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('info');
  const [idSession, setIdSession] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [status, setStatus] = useState(allStatus);
  const [isMenuOpen, setIsMenuOpen] = useState({ id: null, open: false });
  const [isInvitationModalOpen, setIsInvitationModalOpen] = useState(false);
  const [currentPage, SetCurrentPage ] = useState(0)
  const [totalPages, setTotalPages] = useState()

  setTimeout(function () {
    setMessage('');
  }, 5000);

  useEffect(() => {
    isMountedRef.current = true;
    setIsLoading(true)
    SessionAPI.getSessions({ limit: 25, skip: currentPage }).then((response) => {
      if (isMountedRef.current) {
        let tempSessions = [];
        if (status.length === 1) {
          tempSessions = response.data.sessions.filter((session) => {
            return session.status === status[0] && session;
          });
        } else {
          tempSessions = response.data.sessions;
        }
        setTotalPages(response.data.totalPages)
        setSessions(() => tempSessions);
        setIsLoading(false);
      }
    });
    return () => (isMountedRef.current = false);
  }, [status, currentPage]);

  const handleCancelSession = (values) => {
    setIsSubmitting(true);
    SessionAPI.cancelSession(idSession, values)
      .then((response) => {
        let cancelledSession;
        let filteredSessions = sessions.filter((session) => {
          if (session.id === idSession) {
            cancelledSession = session;
            return false;
          } else {
            return true;
          }
        });

        cancelledSession.status = 'canceled';
        setIsSubmitting(false);
        setSessions(() => [cancelledSession, ...filteredSessions]);
        setMessage(response.data.message);
        setTypeMessage('success');
        setSOpenModal(false);
      })
      .catch((e) => {
        setSOpenModal(false);
        setIsSubmitting(false);
        setMessage(e.data.message);
        setTypeMessage('error');
      });
  };

  const showUser = (users, roleType) => {
    const role = roles.find((tempRole) => {
      return tempRole.type === roleType;
    });
    const user = users.find((tempUser) => {
      return tempUser.role === role.id;
    });

    return user && user.name;
  };

  const getRefererUser = (users, roleType) => {
    const role = roles.find((roleTemp) => {
      return roleTemp.type === roleType;
    });

    const referer =
      users.find((userTemp) => userTemp?.role === role.id)?.referer || '-';
    return referer;
  };
  const url =
    window.location.origin === SITES.PROD.admin
      ? SITES.PROD.platform
      : SITES.DEV.platform;
  const classes = useStyles();

  const onClick = (id) => {
    setIsMenuOpen((prevState) => ({
      id,
      open: prevState.id !== id || !prevState.open,
    }));
  };

  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setMessage('Link copiado al portapapeles');
    setTypeMessage('success');
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Pilar',
        accessor: 'pillar.name',
      },
      {
        Header: function fnHeader() {
          return (
            <span
              id="change-status"
              ref={statusEl}
              onClick={() => {
                if (!showStatus) {
                  setShowStatus(true);
                }
              }}
            >
              Estado
              <Popover
                id="fade-menu"
                anchorEl={() => statusEl.current}
                open={showStatus}
                onClose={() => {
                  setShowStatus(false);
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Box p={2} width="120px">
                  <MenuItem
                    style={{ placeContent: 'center' }}
                    onClick={() => {
                      setStatus(allStatus);
                    }}
                  >
                    Todos
                  </MenuItem>
                  {allStatus.map((value, index) => {
                    return (
                      <MenuItem
                        key={index}
                        style={{ placeContent: 'center' }}
                        onClick={() => {
                          setShowStatus(false);
                          setStatus([value]);
                        }}
                      >
                        {statusSession[value]}
                      </MenuItem>
                    );
                  })}
                </Box>
              </Popover>
            </span>
          );
        },
        id: 'status',
        accessor: function fnAccesor({ status }) {
          return statusSession[status];
        },
        disableSortBy: true,
      },
      {
        Header: 'Fecha y hora',
        accessor: function fnAccesor({ start }) {
          return <p>{formatDate(start, 'DD/MM/YYYY HH:mm')}</p>;
        },
      },
      {
        Header: 'Referer Mentee',
        accessor: function fnAccesor({ users }) {
          return getRefererUser(users, 'mentee');
        },
      },
      {
        Header: 'Mentee',
        accessor: function fnAccesor({ users }) {
          return showUser(users, 'mentee');
        },
      },
      {
        Header: 'Referer Mentor',
        accessor: function fnAccesor({ users }) {
          return getRefererUser(users, 'mentor');
        },
      },
      {
        Header: 'Mentor',
        accessor: function fnAccesor({ users }) {
          return showUser(users, 'mentor');
        },
      },
      {
        Header: '',
        id: 'actions',
        accessor: function fnAccesor({ id, status }) {
          return (
            <MenuContainer onClick={() => onClick(id)}>
              {isMenuOpen.id === id && (
                <Menu
                  open={isMenuOpen.open}
                  items={[
                    {
                      text: 'Ver detalle',
                      action: () =>
                        history.push('/reuniones/detalle', {
                          idSession: id,
                        }),
                    },
                    {
                      text: 'Url de mentoria',
                      action: () =>
                        copyTextToClipboard(
                          `${url}/mentorias/informacion?id=${id}`
                        ),
                    },
                    {
                      text: 'Agregar invitado',
                      action: () => {
                        setIsInvitationModalOpen(true);
                        setIdSession(id);
                      },
                    },
                    role &&
                      (role.type === 'admin' || role.type === 'manager') &&
                      ['created', 'not-assigned', 'no-email'].includes(
                        status
                      ) && {
                        text: 'Cancelar',
                        action: () => {
                          setSOpenModal(true);
                          setIdSession(id);
                        },
                      },
                  ]}
                />
              )}
            </MenuContainer>
          );
        },
        disableSortBy: true,
      },
    ],
    [history, showStatus, allStatus, role, showUser, isMenuOpen]
  );

  return (
    <div className="user-container">
      {message && <Alert type={typeMessage}>{message}</Alert>}
      {openModal && (
        <Modal
          className={classes.modal}
          open={openModal}
          onClose={() => {
            setSOpenModal(false);
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="cancel-session">
            <h3>¿Estás seguro que deseas cancelar esta reunión?</h3>
            <Formik
              initialValues={{
                reason:
                  'Lo siento, no podré asistir a nuestra reunión. Gracias de todas formas',
              }}
              validationSchema={Yup.object({
                reason: Yup.string().required('Ingrese un motivo'),
              })}
              onSubmit={(values) => {
                handleCancelSession(values);
              }}
            >
              <Form>
                <div className="cancel__content">
                  <Input
                    label="Motivo"
                    placeholder="Motivo"
                    name="reason"
                    type="textarea"
                    max={500}
                    showCounter
                    isRequired={true}
                  />
                </div>
                <div className="info__button">
                  <div className="save-area">
                    <Grid container justify="space-around">
                      <Grid item xs={5}>
                        <Button
                          widthFull
                          secondary
                          onClick={() => {
                            setSOpenModal(false);
                          }}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item xs={5}>
                        <Button widthFull type="submit" loading={isSubmitting}>
                          Aceptar
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </Modal>
      )}
      <InvitationModal
        open={isInvitationModalOpen}
        onClose={() => setIsInvitationModalOpen(false)}
        sessionId={idSession}
      />
      <TableSite
        data={sessions}
        columns={columns}
        totalPages={Math.ceil(totalPages/25)}
        isDynamic
        currentPage={currentPage}
        onChangePage={(e, page) => SetCurrentPage(page)}
        isLoadingData={isLoading}
        tableName="Reuniones"
      />
    </div>
  );
};

export default SessionTableContainer;
