import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Input } from '../../../components/forms';
import { Grid, IconButton } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../../components/common/Button';
import Alert from '../../../components/common/Alert';
import { formatDate } from '../../../utils/format';
import { Skeleton } from '@material-ui/lab';
import SurveysAPI from '../survey-api';
import { Close } from '@material-ui/icons';

const SurveyEditContainer = () => {
  let timer;
  const history = useHistory();
  const location = useLocation();
  const [survey, setSurvey] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const [initialValues, setInitialValues] = useState({
    name: '',
    jsonData: '',
  });

  const messageRequired = 'Es un campo requerido';

  const schema = Yup.object({
    name: Yup.string()
      .max(30, 'El nombre de la encuesta no puede superar los 30 caracteres')
      .required('Nombre de la encuesta requerido'),
    jsonData: Yup.object().required(messageRequired),
  });

  useEffect(() => {
    // eslint-disable-next-line
    timer = setTimeout(function () {
      setShowAlert(false);
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  });

  useEffect(() => {
    SurveysAPI.getSurvey(location.state.surveyId).then((response) => {
      setSurvey(response.data);
      setInitialValues({
        name: response.data.name,
        jsonData: JSON.stringify(response.data.jsonData),
      });
      setIsLoading(false);
    });
  }, []);

  const handleSubmit = (values) => {
    SurveysAPI.updatePillar({ ...values, id: survey.id })
      .then((response) => {
        history.push({
          pathname: '/encuestas',
          state: {
            message: response.data.message,
            type: 'success',
          },
        });
      })
      .catch((e) => {
        setShowAlert(true);
        setMessageAlert(e.data.message);
      });
  };

  if (isLoading) return <></>;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      validateOnBlur
    >
      {({ setFieldValue }) => (
        <Form>
          <Grid container className="user-container__creation">
            <Grid item xs={12}>
              {showAlert && <Alert type="error">{messageAlert}</Alert>}
            </Grid>
            <Grid
              item
              xs={4}
              className="title"
              style={{ backgroundColor: 'white' }}
            >
              <IconButton
                aria-label="delete"
                className="delete-button none-mentor"
                onClick={() => history.push('/encuestas')}
              >
                <Close />
              </IconButton>

              <span>Editar encuesta</span>
            </Grid>
            <Grid className="content" container>
              <Grid item xs={4} style={{ backgroundColor: 'white' }}>
                <Grid
                  container
                  direction="column"
                  className="personal-info"
                  style={{ backgroundColor: 'white' }}
                >
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}>
                    <span>
                      <strong>Nombre</strong>
                    </span>
                    <Input name="name" placeholder="Nombre" label="" />
                  </Grid>
                  <Grid item xs={12}>
                    <span>
                      <strong>Json Data</strong>
                    </span>
                    <Input
                      name="jsonData"
                      placeholder="JSON"
                      label=""
                      type="textarea"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} style={{ backgroundColor: 'white' }}>
              <Grid item xs={12} className="personal-info">
                <p>
                  <strong>Fecha de creación:</strong> <br />
                  {isLoading ? (
                    <Skeleton />
                  ) : (
                    <span>{formatDate(survey.createdAt)}</span>
                  )}
                </p>
              </Grid>
              <Grid item xs={12} className="personal-info">
                <p>
                  <strong>Fecha de modificación:</strong> <br />
                  {isLoading ? (
                    <Skeleton />
                  ) : (
                    <span>{formatDate(survey.updatedAt)}</span>
                  )}
                </p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={4}
                style={{ backgroundColor: 'white' }}
                className="submit"
              >
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item xs={8}>
                    <Button type="submit" widthFull>
                      Aceptar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default SurveyEditContainer;
