import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const Category = ({ title, description }) => {
  const HtmlTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
      width: 220,
    },
    arrow: {
      color: '#FFFFFF',
    },
  }))(Tooltip);

  return (
    description ? 
    <HtmlTooltip
      title={
        <div className="bg__tooltip">
          <p className="title">{title}</p>
          <p className="description">{description}</p>
        </div>
      }
      arrow
      placement="top"
    >
      <span>
        <div className="category">
          <p>{title}</p>
        </div>
      </span>
    </HtmlTooltip>: <span>
        <div className="category">
          <p>{title}</p>
        </div>
      </span>
  );
};

Category.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default Category;
