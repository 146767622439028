import React, { useEffect, useState } from 'react';
import Button from '../../../components/common/Button';
import Search from '../../../components/common/Table/searchBox';
import { StrongSubtitle } from '../../../components/common/Title';
import HeadingSection from '../../../components/common/Title/headingSection';
import NewsCard from './newsCard';
import { useHistory } from 'react-router-dom';
import NewsApi from '../news-api';
import moment from 'moment';
import Alert from '../../../components/common/Alert';

import NewsModal from '../newsModal';
import Pagination from '@material-ui/lab/Pagination';

const NewsList = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [news, setNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredNews, setFilteredNews] = useState([]);
  const [error, setError] = useState('');
  const [modalContent, setModalContent] = useState({
    title: '',
    header: '',
    action: '',
    note: '',
  });
  const [open, setOpen] = useState(false);

  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const itemsToDisplay = news.slice(startIndex, endIndex);
  const totalPages = Math.ceil(news.length / itemsPerPage);

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          const {
            data: { news },
          } = await NewsApi.fetchNews();
          const modifiedNews = news.map((n) => {
            const timeAgo = moment().from(n.createdAt, true);
            return { ...n, timeAgo };
          });

          setNews(modifiedNews);
          setFilteredNews(modifiedNews);
        } catch (error) {
          const e = error.data.message || error.statusText;
          setError(e);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [isLoading]);

  const onChange = ({ target }) => {
    const filteredList = news.filter(
      (n) =>
        n.title.includes(target.value) ||
        new Date(n.createdAt).toLocaleString().includes(target.value)
    );

    setFilteredNews(filteredList);
  };

  const actionStrategy = (action, news) => {
    switch (action) {
      case 'active':
        setModalContent({
          header: 'Activar Publicación',
          content: '¿Estás seguro que desear activar la publicación?',
          action,
          news,
        });
        break;

      case 'draft':
        setModalContent({
          header: 'Desactivar Publicación',
          content:
            '¿Estás seguro que desear desactivar la publicación? Quedará en modo Borrador',
          action,
          news,
        });
        break;
      case 'deleted':
        setModalContent({
          header: 'Eliminar Publicación',
          content: '¿Estás seguro que desear eliminar la publicación?',
          action,
          news,
        });
        break;

      default:
        break;
    }
  };

  //it is a callback on newsCard
  const onClickAction = async (action, id) => {
    setOpen(true);
    actionStrategy(action, id);
  };

  const handleAccept = async () => {
    try {
      await NewsApi.updateNewsStatus(modalContent.news.id, modalContent.action);
      setOpen(false);
    } catch (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      const e = error.data.message ? error.data.message : error.statusText;
      setError(e);
    } finally {
      setIsLoading(true);
    }
  };

  return (
    <>
      <div className="user-container content-panel news__container">
        {error && <Alert type="error">{error}</Alert>}
        <HeadingSection
          title="Novedades"
          subtitle="Acá podras administrar, crear, editar y eliminar publicaciones de la plataforma Chileconverge."
        />
        {/* actions */}
        <div
          className="news__action"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ display: 'flex', gap: 24 }}>
            <StrongSubtitle text="Publicaciones" variant="subtitle--big" />
            <Search onChange={(e) => onChange(e)} />
          </div>
          <Button
            type="button"
            primary
            onClick={() => history.push('/novedades/crear')}
          >
            Crear nueva publicación
          </Button>
        </div>
        {/* news */}
        {!isLoading &&
          itemsToDisplay.map((news) => (
            <NewsCard
              news={news}
              onClickAction={(action, id) => onClickAction(action, id)}
              setError={setError}
            />
          ))}
      </div>
      <NewsModal
        selectedNews={modalContent.news}
        content={modalContent}
        onClose={() => setOpen(!open)}
        open={open}
        handleAccept={() => handleAccept(modalContent.action)}
        handleDenied={() => setOpen(false)}
      />
      <div className="search-paginator">
        <span>Páginas </span>
        <Pagination
          className="inline-block"
          count={totalPages}
          page={currentPage}
          onChange={(event, page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};

export default NewsList;
