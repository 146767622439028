import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ControlPointSharpIcon from '@material-ui/icons/ControlPointSharp';
import ModalContent from './ModalContent';
import { Input } from '../../forms';
import Button from '../Button';
import ClientsApi from '../../../content/ClientContainer/client-api';

const InvitationModal = ({ open, onClose, sessionId }) => {
  const [guestEmails, setGuestEmails] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');

  const addGuest = (values) => {
    try {
      if (
        guestEmails.length < 4 &&
        values.guestEmails &&
        !guestEmails.includes(values.guestEmails)
      ) {
        setGuestEmails([...guestEmails, values.guestEmails]);
      } else if (guestEmails.length >= 4) {
        setAlertMessage(
          'Se ha alcanzado el límite máximo de correos electrónicos.'
        );
        setAlertType('error');
        setTimeout(() => {
          setAlertMessage('');
        }, 3000);
      }
    } catch (error) {}
  };

  const removeGuestEmail = (index) => {
    const updatedGuestEmails = [...guestEmails];
    updatedGuestEmails.splice(index, 1);
    setGuestEmails(updatedGuestEmails);
  };

  const sendInvitations = async () => {
    try {
      await ClientsApi.sendInviteMail(sessionId, guestEmails.join(','));
      setAlertMessage('Invitaciones enviadas correctamente.');
      setAlertType('success');
      setTimeout(() => {
        setAlertMessage('');
        onClose();
      }, 4000);
    } catch (error) {
      setAlertMessage('Error al enviar las invitaciones.');
      setAlertType('error');
    }
  };
  useEffect(() => {
    if (open) {
      setGuestEmails([]);
      setAlertMessage('');
      setAlertType('success');
    }
  }, [open]);
  return (
    <Modal className="modal" open={open} onClose={onClose}>
      <ModalContent title="Agregar invitado mentoría" onClick={onClose}>
        {alertMessage && (
          <span style={{ color: alertType === 'error' ? 'red' : 'green' }}>
            {alertMessage}
          </span>
        )}

        <div className="reschedule-session">
          <Formik
            initialValues={{ guestEmails: '' }}
            validationSchema={Yup.object({
              guestEmails: Yup.string()
                .email('Correo electrónico inválido')
                .required('Ingrese un correo electrónico'),
            })}
            onSubmit={sendInvitations}
          >
            {({ values }) => (
              <Form>
                <p style={{ color: '#11111180', padding: '10px' }}>
                  Ingresa el correo del invitado para notificar <br /> su
                  invitación a la mentoría
                </p>
                <Input
                  label="usuario@mail.com"
                  name="guestEmails"
                  type="email"
                />
                <ErrorMessage
                  name="guestEmails"
                  component="div"
                  className="error"
                />
                <div
                  style={{
                    margin: '10px 0',
                    color: '#0BB5E7',
                    display: 'flex',
                    cursor: 'pointer',
                  }}
                  onClick={() => addGuest(values, guestEmails)}
                >
                  <ControlPointSharpIcon />
                  Agregar invitado
                </div>
                <ul>
                  {guestEmails.map((email, index) => (
                    <div className="modal__header" key={index}>
                      {email}
                      <span
                        className="material-icons icon"
                        onClick={() => removeGuestEmail(index)}
                      >
                        close
                      </span>
                    </div>
                  ))}
                </ul>
                <div className="modal__action-container">
                  <Button secondary onClick={onClose}>
                    Cancelar
                  </Button>
                  <Button type="submit">Enviar Invitaciones</Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ModalContent>
    </Modal>
  );
};

InvitationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sessionId: PropTypes.number.isRequired,
};

export default InvitationModal;
