import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FieldArray, Form, Formik } from 'formik';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from 'draft-js';

import draftToHtml from 'draftjs-to-html';
import { useHistory } from 'react-router-dom';
import { Modal } from '@material-ui/core';
import { ACTION } from '../../../reducer/projectReducer';
import Alert from '../../../components/common/Alert';
import Button from '../../../components/common/Button';
import ModalContent from '../../../components/common/Modal/ModalContent';

import ProjectApi from '../project-api';
import ItemTextBox from '../ProjectCreationContainer/landing/itemTextBox';
import { ProjectContext } from '../projectContext';
import LandingModalPreview from '../ProjectCreationContainer/landing/landingModalPreview';
import InputWrapper from '../../../components/forms/Input/inputWrapper';
import { Input } from '../../../components/forms';
import InputImage from '../../../components/forms/Input/inputImage';
import {
  ALLOWED_CHAR,
  ALLOWED_DESCRIPTION_CHAR,
  ALLOWED_ITEMS_CHAR,
  landingEditSchema,
} from '../schemas/landing';
import moment from 'moment';
import { TIME_TO_REDIRECT } from '../../../utils/constants';

const TODAY = new Date().toISOString().split('T')[0];

const LandingEditForm = () => {
  const history = useHistory();
  const [state, dispatch] = useContext(ProjectContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { client, landing, status } = state;
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState({ type: '', message: '' });

  const [disabled, setDisabled] = useState({
    startDate: false,
    endDate: false,
    title: false,
    urlMentee: true,
    urlMentor: true,
    logo: false,
    desktopBanner: false,
    mobileBanner: false,
    about: false,
    items: false,
  });

  useEffect(() => {
    if (status == 'active' || status == 'finish') {
      setMessageAlert({
        type: 'warning',
        message: `Esta convocatoria ${
          status == 'active' ? 'esta activa' : 'ha finalizado'
        } no puede ser editada`,
      });
    }
  }, []);

  useEffect(() => {
    editableStrategy(status);
  }, [status]);

  const editableStrategy = (state) => {
    switch (state) {
      case 'waiting':
        setDisabled({
          startDate: false,
          endDate: false,
          title: false,
          urlMentee: true,
          urlMentor: true,
          logo: false,
          desktopBanner: false,
          mobileBanner: false,
          about: false,
          items: false,
        });
        break;

      case 'user-register':
        setDisabled({
          startDate: true,
          endDate: false,
          title: true,
          urlMentee: true,
          urlMentor: true,
          logo: false,
          desktopBanner: false,
          mobileBanner: false,
          about: false,
          items: false,
        });

        break;

      case 'active':
        setDisabled({
          startDate: true,
          endDate: true,
          title: true,
          urlMentee: true,
          urlMentor: true,
          logo: true,
          desktopBanner: true,
          mobileBanner: true,
          about: true,
          items: true,
        });
        break;
      case 'finish':
        setDisabled({
          startDate: true,
          endDate: true,
          title: true,
          urlMentee: true,
          urlMentor: true,
          logo: true,
          desktopBanner: true,
          mobileBanner: true,
          about: true,
          items: true,
        });
        break;
      default:
        break;
    }
  };

  //can announcement be delayed
  const canBeDelayed = (date) => {
    const today = moment();
    if (status === 'waiting') {
      return true;
    } else if (status === 'finish' || status == 'active') {
      return false;
    } else {
      return moment(date).isAfter(today);
    }
  };

  const items = [
    { name: 'Item 1', Component: ItemTextBox, placeholder: 'placeholder' },
    {
      name: 'Item 2',
      Component: ItemTextBox,
      placeholder: 'placeholder 1',
    },
    {
      name: 'Item 3',
      Component: ItemTextBox,
      placeholder: 'placeholder 2',
    },
  ];

  const [activeItem, setActiveItem] = useState(0);

  const defaultContent = state.landing.about;
  const blockFromHTML = convertFromHTML(defaultContent);
  const content = ContentState.createFromBlockArray(blockFromHTML);
  const [textEditor, setTextEditor] = useState(
    EditorState.createWithContent(content)
  );

  const slugifyName = (name) => name.toLowerCase().replace(' ', '-');

  const onSubmit = (values) => {
    if(isLoading) return;
    (async () => {
      setIsLoading(true);
      const { id } = landing;
      try {
        await ProjectApi.updateProjectAnnouncement(id, {
          status,
          ...values,
        });
        setMessageAlert({
          type: 'success',
          message: 'La convocatoría ha sido editada exitosamente',
        });

        setTimeout(() => {
          history.goBack();
          setIsLoading(false);
        }, TIME_TO_REDIRECT);
      } catch (error) {
        setMessageAlert({ type: 'error', message: error.data.message });
        setIsLoading(false);
      }
    })();
  };

  const onClickTab = (values, index) => {
    dispatch({
      type: ACTION.UPDATE_LANDING,
      payload: {
        landing: {
          ...values,
          about: draftToHtml(convertToRaw(textEditor.getCurrentContent())),
        },
      },
    });
    setActiveItem(index);
  };

  const handlePreview = (values) => {
    dispatch({
      type: ACTION.UPDATE_LANDING,
      payload: {
        landing: {
          ...values,
          about: draftToHtml(convertToRaw(textEditor.getCurrentContent())),
        },
      },
    });
    setOpen(true);
  };

  return (
    <>
      {messageAlert && (
        <Alert type={messageAlert.type}>{messageAlert.message}</Alert>
      )}
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalContent
          onClick={() => setIsModalOpen(false)}
          title="Estás saliendo sin guardar los cambios"
        >
          <span className="title">¿Estás seguro que deseas volver?</span>
          <div className="modal__action-container">
            <Button
              type="button"
              secondary
              onClick={() => setIsModalOpen(false)}
            >
              No
            </Button>
            <Button type="button" onClick={() => history.goBack()}>
              Si
            </Button>
          </div>
        </ModalContent>
      </Modal>
      <Formik
        initialValues={landing}
        validationSchema={landingEditSchema}
        validateOnBlur
        onSubmit={onSubmit}
      >
        {(props) => {
          return (
            <>
              <LandingModalPreview open={open} onClose={() => setOpen(false)} />
              <Form>
                <div className="wizard__preview-container">
                  <Button
                    type="button"
                    secondary
                    className="button button--flat"
                    onClick={() => handlePreview(props.values)}
                  >
                    Previsualizar
                  </Button>
                </div>
                <div className="wizard__form">
                  <div className="wizard__grid-section">
                    <div className="title-container grid">
                      <span className="wizard__title-section">
                        Fecha de activación
                      </span>
                      <span className="subtitle">
                        Selecciona la fecha de activación del landing de
                        registro y a quien va dirigido.
                      </span>
                    </div>
                    <div className="wizard__grid-section">
                      <InputWrapper label="Fecha de inicio" id="startDate">
                        <Input
                          InputProps={{
                            inputProps: { min: TODAY },
                          }}
                          label=""
                          id="startDate"
                          type="date"
                          name="startDate"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          //since it can be delayed it should be the opposite one
                          disabled={!canBeDelayed(props.values.startDate)}
                        />
                      </InputWrapper>
                      <InputWrapper label="Fecha de termino" id="startDate">
                        <Input
                          InputProps={{
                            inputProps: {
                              min: props.values?.startDate || TODAY,
                            },
                          }}
                          label=""
                          id="endDate"
                          type="date"
                          name="endDate"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={disabled.endDate}
                        />
                      </InputWrapper>
                      <InputWrapper label="URL mentee" id="urlMentee">
                        <Input
                          name="urlMentee"
                          label={'registroMentee'}
                          id="urlMentee"
                          disabled
                        />
                      </InputWrapper>
                      <InputWrapper label="URL mentor" id="urlMentor">
                        <Input
                          name="urlMentor"
                          label={'registroMentor'}
                          id="urlMentor"
                          defaultValue={`/${slugifyName(
                            client?.name
                          )}/registroMentee`}
                          disabled
                        />
                      </InputWrapper>
                    </div>
                  </div>
                  <div className="landing-design__section wizard__grid-section">
                    <div className="title-container grid">
                      <span className="wizard__title-section">
                        Diseño de landing de registro
                      </span>
                      <span className="subtitle">
                        Acá podrás modificar los principales elementos del
                        landing de registro siguiendo la línea gráfica del
                        cliente y Chileconverge.
                      </span>
                    </div>
                    <div>
                      <InputWrapper
                        variant="big"
                        label="Logo cliente"
                        description="Archivos 176x70px  PNG, SVG  - Tamaño máximo 5MB."
                        id="logo"
                      >
                        <InputImage
                          name="logo"
                          id="logo"
                          allowedExts={['image/png', 'image/svg+xml']}
                          disabled={disabled.logo}
                        />
                      </InputWrapper>
                      <InputWrapper
                        variant="big"
                        label="Banner desktop"
                        description="Archivos 1440x800px PNG, JPG  - Tamaño máximo 5MB."
                        id="desktopBanner"
                      >
                        <InputImage
                          name="desktopBanner"
                          id="desktopBanner"
                          allowedExts={['image/png', 'image/jpg', 'image/jpeg']}
                          disabled={disabled.desktopBanner}
                        />
                      </InputWrapper>
                      <InputWrapper
                        variant="big"
                        label="Banner mobile"
                        description="Archivos 375x522px PNG, JPG  - Tamaño máximo 5MB."
                        id="mobileBanner"
                      >
                        <InputImage
                          name="mobileBanner"
                          id="mobileBanner"
                          allowedExts={['image/png', 'image/jpg', 'image/jpeg']}
                          disabled={disabled.mobileBanner}
                        />
                      </InputWrapper>
                      <InputWrapper
                        label="Título de la convocatoria"
                        id="title"
                        description="Corresponde al llamado principal que aparece en el banner."
                      >
                        <Input
                          name="title"
                          label="Título"
                          id="title"
                          disabled={disabled.title}
                        />
                        <span className="char-counter">
                          {props.values.title.length}/{ALLOWED_CHAR}
                        </span>
                      </InputWrapper>
                    </div>
                    <div className="wizard__grid-section">
                      {/* Quienes somos */}
                      <InputWrapper
                        label="Quiénes somos"
                        id="about"
                        description="Descripción resumida"
                        name="about"
                        variant="big"
                      >
                        <>
                          <Editor
                            id="about"
                            name="about"
                            wrapperClassName="editor__wrapper"
                            toolbarClassName="editor__toolbar"
                            editorState={textEditor}
                            onEditorStateChange={setTextEditor}
                            onBlur={() => {
                              props.setFieldTouched('about', true);
                              props.setFieldValue(
                                'about',
                                draftToHtml(
                                  convertToRaw(textEditor.getCurrentContent())
                                )
                              );
                            }}
                            readOnly={disabled.about}
                          />
                          <div className="editor__error">
                            {props.touched.about && props.errors.about && (
                              <span className=" error-message">
                                {props.errors.about}
                              </span>
                            )}
                            <span className="char-counter">
                              {
                                textEditor.getCurrentContent().getPlainText('')
                                  .length
                              }
                              /{ALLOWED_DESCRIPTION_CHAR}
                            </span>
                          </div>
                        </>
                      </InputWrapper>

                      {/* ITEMS  */}
                      <FieldArray name="items">
                        {(arrayProps) => (
                          <InputWrapper
                            label="Iconografía"
                            id="iconografía"
                            description="Completa la información correspondiente a cada item"
                            name="items"
                            variant="big"
                          >
                            <div className="tabs-panel__container">
                              <div className="tabs__container">
                                {items.map((item, index) => {
                                  return (
                                    <div
                                      className={`${
                                        activeItem === index
                                          ? 'tabs__item-tab tabs__item-tab--active'
                                          : 'tabs__item-tab'
                                      }`}
                                      key={index}
                                      onClick={() =>
                                        onClickTab(props.values, index)
                                      }
                                    >
                                      {item.name}
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="tab-panel">
                                <>
                                  {items.map((item, index) => {
                                    const Component = item.Component;
                                    return (
                                      activeItem === index && (
                                        <Component
                                          {...props}
                                          {...arrayProps}
                                          key={index}
                                          name={`item.${index}.text`}
                                          maxChar={ALLOWED_ITEMS_CHAR}
                                          disabled={disabled.items}
                                        />
                                      )
                                    );
                                  })}
                                </>
                              </div>
                            </div>
                          </InputWrapper>
                        )}
                      </FieldArray>
                    </div>
                  </div>
                  <div className="wizard__action">
                    <Button secondary onClick={() => setIsModalOpen(true)}>
                      Volver
                    </Button>
                    <Button type="submit" loading={isLoading} disabled={isLoading}>
                      Finalizar
                    </Button>
                  </div>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default LandingEditForm;
