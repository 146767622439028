import React, { useState } from 'react';

import Wizard from '../../ClientContainer/ClientCreationContainer/wizard';
import Stepper from '../../../components/common/stepper';

import { Form, Formik } from 'formik';
import DateWorkShop from './CreationInputs/dateInput';
import TextForm from './CreationInputs/textForm';

import InputSummary from './CreationInputs/inputSummary';
import LinkForm from './CreationInputs/linkInput';
import LinkComponent from '../../../components/common/Link';

const WorkShopCreation = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [workShopData, setWorkShopData] = useState({
    title: '',
    description: '',
    assistants: 0,
    startDate: '',
    image: '',
    //Enlace a la grabación del taller
    recordingLink: '',
    //Enlace a la acceso del taller
    workshopLink: '',
    status: 'inactive',
    hour: '',
    estimatedTime: '',
    receptor: '',
    pillar: '',
    file: '',
  });
  const [alertMessage, setAlertMessage] = useState('');

  const showAlert = (message) => {
    setAlertMessage(message);
  };
  const onNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };
  const onPrev = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  const handleSumit = () => {
    setWorkShopData(workShopData);
  };
  const workShopSteps = [
    {
      title: 'Título y descripción',
      Component: TextForm,
    },
    {
      title: 'Horario',
      Component: DateWorkShop,
    },
    {
      title: 'Links',
      Component: LinkForm,
    },
    {
      title: 'Resumen',
      Component: () => (
        <InputSummary
          workshopData={workShopData}
          setWorkShopData={setWorkShopData}
          onPrev={onPrev}
        />
      ),
    },
  ];

  return (
    <div className="client-container">
      <div className="client__back-container">
        <LinkComponent
          title="Volver"
          url="/talleres"
          icon="arrow_back"
        ></LinkComponent>
      </div>

      <Formik initialValues={workShopData} onSubmit={handleSumit}>
        <Form>
          <Wizard title="Crear nuevo taller">
            <div className="wizard__content">
              <div className="wizard__steps-container wizard-padding">
                {workShopSteps.map((currentStep, index) => (
                  <Stepper
                    text={currentStep.title}
                    key={index}
                    isActive={index === activeStep}
                    isDone={index < activeStep}
                  />
                ))}
              </div>

              {workShopSteps.map((currentStep, index) => {
                const C = currentStep.Component;
                return (
                  index === activeStep && (
                    <C
                      onPrev={onPrev}
                      onNext={onNext}
                      workShopData={workShopData}
                      setWorkShopData={setWorkShopData}
                      key={`showing-step-${index}`}
                    />
                  )
                );
              })}
            </div>
          </Wizard>
        </Form>
      </Formik>
    </div>
  );
};

export default WorkShopCreation;
