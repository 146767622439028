import React, { useState } from 'react';
import Button from '../../../components/common/Button';

const TabContainer = ({ tabs, variant, project }) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const tabStyle = variant
    ? `tabs__item-tab tabs__item-tab--${variant}`
    : 'tabs__item-tab';

  return (
    <>
      {/* TABS  AND BUTTON*/}
      <div className="flex-row">
        <div className="project-tabs__container tabs__container ">
          {tabs.map((tab) => (
            <div
              onClick={() => {
                setSelectedTab(tab);
              }}
              className={
                tab.title == selectedTab.title
                  ? 'tabs__item-tab tabs__item-tab--active'
                  : tabStyle
              }
            >
              {tab.title}
            </div>
          ))}
        </div>
        {/* <Button type="button" onClick={() => {}}>
          {selectedTab.buttonText}
        </Button> */}
      </div>
      {/* TABS'S VIEW */}
      <div className="project-view__container">
        {<selectedTab.Component project={project} />}
      </div>
    </>
  );
};

export default TabContainer;
