import { Modal } from '@material-ui/core';
import React, { useContext } from 'react';
import Button from '../../../../components/common/Button';
import AllysPreview from './allysPreview';
import BannerPreview from './bannerPreview';
import DescriptionPreview from './descriptionPreview';
import moveappsLogo from './../../../../assets/images/moveapps-logo.svg';

const AnnouncementPreview = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} className="modal">
      <div className="modal__container modal--full preview__landing">
        {/* HEADER */}
        <div className="modal__title modal__title--secondary">
          <span className="title">Previsualización del cliente</span>
          <Button onClick={onClose}>
            <span className="material-symbols-outlined">arrow_back</span>
            <span>Volver</span>
          </Button>
        </div>
        {/* BANNER*/}
        <BannerPreview />
        {/* DESCRIPTION */}
        <DescriptionPreview />
        {/* ALLYS */}
        <AllysPreview />
        {/* FOOTER */}
        <div className="preview__footer">
          <div>
            <span className="footer__text">Volver a</span>&nbsp;
            <span className="footer__text--secondary">Chileconvege.cl</span>
          </div>
          <div>
            <img src={moveappsLogo}></img>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AnnouncementPreview;
