import request from '../../utils/request';

function getSessions({ limit = 25, skip = 0}) {
  return request({
    url: 'session/list',
    method: 'GET',
    private: true,
    params: {
      limit,
      skip
    }
    
  });
}
function cancelSession(idSession, reason) {
  return request({
    url: `sessions/${idSession}/cancel`,
    method: 'PATCH',
    data: reason,
    private: true,
  });
}
function getDetailsSession(id) {
  return request({
    url: `/sessions/${id}/admin`,
    method: 'GET',
    private: true,
  });
}
function getFileDownload(id) {
  return request({
    url: `/file/download/${id}/admin`,
    method: 'GET',
    private: true,
  });
}
function getMentors() {
  return request({
    url: '/users/schedules',
    method: 'GET',
    private: true,
  });
}
function assignMentor(id, payload) {
  return request({
    url: `/session/${id}/assign`,
    method: 'POST',
    data: payload,
    private: true,
  });
}
function getDatesByMentor(idMentor) {
  return request({
    url: `/schedules/users/${idMentor}`,
    method: 'GET',
    private: true,
  });
}
function assignDate(id, idSchedule) {
  return request({
    url: `/session/${id}/schedule/${idSchedule}`,
    method: 'PATCH',
    data: {},
    private: true,
  });
}

const SessionAPI = {
  getSessions,
  cancelSession,
  getDetailsSession,
  getFileDownload,
  getMentors,
  assignMentor,
  getDatesByMentor,
  assignDate,
};

export default SessionAPI;
