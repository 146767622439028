import React, { useContext } from 'react';
import { AnnouncementContext } from '../AnnouncementContext';

const DescriptionPreview = () => {
  const [state, dispatch] = useContext(AnnouncementContext);
  const { announcement } = state;

  return (
    <div className="landing__description">
      <div className="container">
        <h1>Quiénes somos</h1>
        <hr className="divider-title" />
        {announcement.about && (
          <div
            className="us-text"
            dangerouslySetInnerHTML={{ __html: announcement.about }}
          />
        )}
        {/* ICONS */}
        <div className="landing__items">
          {announcement.items &&
            announcement?.items.map((item) => (
              <div className="item-container">
                <div className="landing__description-item">
                  <img src={item.icon} />
                </div>
                <div dangerouslySetInnerHTML={{ __html: item.text }} />
              </div>
            ))}
        </div>
        <div className="landing__platform-description">
          <img
            className="landing__image-description"
            src="https://plataforma.chileconverge.cl/_next/static/images/gif_cc-bc1e21eeb8982024874c08b3b26a0139.gif"
            alt="plataforma-gif"
          />

          <div className="landing__platform-text">
            <h1>La plataforma</h1>
            <span>
              En nuestra plataforma podrás aprender y acceder a mentorías, donde
              una persona especialista te acompañará a resolver las dudas que
              tengas sobre tu negocio.
            </span>
            <b>
              Además podrás interactuar con otros microempresarios y llevar tus
              ventas al mundo digital!
            </b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DescriptionPreview;
