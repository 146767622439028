import * as Yup from 'yup';
import { getTomorrowDate } from '../../utils/dates';
import {
  YupEditorMaxLengthValidator,
  YupEditorMinLengthValidator,
} from '../../utils/validators';
import { ALLOWED_CHAR, ALLOWED_DESCRIPTION_CHAR, ALLOWED_ITEMS_CHAR } from '../ProjectsContainer/schemas/landing';
YupEditorMaxLengthValidator();
YupEditorMinLengthValidator();

export const announcemtentSchema = Yup.object().shape({
  title: Yup.string()
    .max(
      ALLOWED_CHAR,
      `El largo máximo para el título son de ${ALLOWED_CHAR} caracteres`
    )
    .trim()
    .required('Debes ingresar un titulo para la convocatoria'),
  desktopBanner: Yup.string().required(
    'Debes ingresar una imágen válida como Banner'
  ),
  mobileBanner: Yup.string().required(
    'Debes ingresar una imágen válida para el Banner en mobile'
  ),
  about: Yup.string()
    .editorMinLengthValidator(1, 'Debes ingresar la descripción del programa')
    .editorMaxLengthValidator(
      ALLOWED_DESCRIPTION_CHAR,
      `El largo máximo para la descripción son de ${ALLOWED_DESCRIPTION_CHAR} caracteres`
    )
    .required('Debes ingresar la descripción del programa'),
  items: Yup.array()
    .of(
      Yup.object({
        icon: Yup.string()
          .typeError('Debes seleccionar un icono de la lista')
          .required('Debes seleccionar un icono'),
        text: Yup.string()
          .editorMinLengthValidator(0, 'Debes ingresar un texto para los items')
          .editorMaxLengthValidator(
            ALLOWED_ITEMS_CHAR,
            `El largo máximo para la descripción son de ${ALLOWED_ITEMS_CHAR} caracteres`
          )
          .required('Debes ingresar los items'),
      })
    )
    .min(3, 'Debes ingresar minimo 3 items'),
});

export const receptorSchema = Yup.object().shape({
  name: Yup.string()
    .max(
      ALLOWED_CHAR,
      `El nombre no debe superar los ${ALLOWED_CHAR} caracteres`
    )
    .required('Debes agregar un nombre para la convocatoria'),
  logo: Yup.string().required('Debes agregar un logo para la organización'),
  organization: Yup.object({
    id: Yup.string().nullable(),
    name: Yup.string()
      .max(50, `El nombre de la organización no debe superar los 50 caracteres`)
      .required('Debes Agregar una Organización para la convocatoria'),
  }),
  role: Yup.string(),
  urlMentee: Yup.string().matches(
    /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
    'Debes ingresar una URL para registro mentee, no se aceptan caracteres especiales'
  ).when('role', {
    is: 'mentee' || 'both',
    then: Yup.string()
      .required('Debes ingresar una URL válida para el mentee'),
  }).when('role', {
    is: 'both',
    then: Yup.string()
      .required('Debes ingresar una URL válida para el mentee'),
  }),
  urlMentor: Yup.string().matches(
    /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
    'Debes ingresar una URL para registro mentor, no se aceptan caracteres especiales'
  ).when('role', {
    is: 'mentor',
    then: Yup.string()
      .required('Debes ingresar una URL válida para el mentor'),
  }).when('role', {
    is: 'both',
    then: Yup.string()
      .required('Debes ingresar una URL válida para el mentor'),
  }),
  startDate: Yup.date().min(getTomorrowDate(),'La fecha de registro debe ser desde mañana').required(
    'Debes ingresar una fecha de inicio de registro.'
  ),
  endDate: Yup.date().required('Debes ingresar una fecha de término.'),
  role: Yup.string()
});


export const receptorEditSchema = Yup.object().shape({
  name: Yup.string()
    .max(
      ALLOWED_CHAR,
      `El nombre no debe superar los ${ALLOWED_CHAR} caracteres`
    )
    .required('Debes agregar un nombre para la convocatoria'),
  logo: Yup.string().required('Debes agregar un logo para la organización'),
  organization: Yup.object({
    id: Yup.string().nullable(),
    name: Yup.string()
      .max(50, `El nombre de la organización no debe superar los 50 caracteres`)
      .required('Debes Agregar una Organización para la convocatoria'),
  }),
  role: Yup.string(),
  urlMentee: Yup.string().matches(
    /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
    'Debes ingresar una URL para registro mentee, no se aceptan caracteres especiales'
  ).when('role', {
    is: 'mentee' || 'both',
    then: Yup.string()
      .required('Debes ingresar una URL válida para el mentee'),
  }).when('role', {
    is: 'both',
    then: Yup.string()
      .required('Debes ingresar una URL válida para el mentee'),
  }),
  urlMentor: Yup.string().matches(
    /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
    'Debes ingresar una URL para registro mentor, no se aceptan caracteres especiales'
  ).when('role', {
    is: 'mentor',
    then: Yup.string()
      .required('Debes ingresar una URL válida para el mentor'),
  }).when('role', {
    is: 'both',
    then: Yup.string()
      .required('Debes ingresar una URL válida para el mentor'),
  }),
  startDate: Yup.date().required(
    'Debes ingresar una fecha de inicio de registro.'
  ),
  endDate: Yup.date().required('Debes ingresar una fecha de término.'),
  role: Yup.string()
});
