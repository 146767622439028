import { IconButton, Modal } from '@material-ui/core';
import { ThumbUp, ThumbDown } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import TableSite from '../../../components/common/Table';
import Alert from '../../../components/common/Alert';
import { useLocation } from 'react-router-dom';
import ApplicationAPI from '../application-api';

import ModalContent from '../../../components/common/Modal/ModalContent';
import Button from '../../../components/common/Button';

const ApplicationTableContainer = () => {
  const location = useLocation();
  const [solicitudes, setSolicitudes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedReason, setSelectedReason] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedApplicationID, setSelectedApplicationID] = useState(null);
  const [copiedSuccess, setCopiedSuccess] = useState(false);

  const [totalPages, setTotalPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(0);

  const [messageResponse, setMessageResponse] = useState({
    message: '',
    type: '',
    show: false,
  });

  let timer;
  const [APILoading, setAPILoading] = useState(false);
  const fetchAndRenderApplications = async () => {
    try {
      const response = await ApplicationAPI.getApplications(pageNumber);
      setTotalPages(response.data.totalPages);

      renderApplications(response);
      setIsLoading(false);
    } catch (error) {
      setMessageResponse({
        message: error.data.message,
        type: 'error',
        show: true,
      });
      setIsLoading(false);
    }
  };

  async function modifyApplication(values) {
    try {
      setAPILoading(true);
      const response = await ApplicationAPI.updateApplication({
        id: values.id,
        status: values.status,
      });

      setSolicitudes((prevSolicitudes) =>
        prevSolicitudes.map((solicitud) => {
          if (solicitud.applicationID === values.id) {
            return {
              ...solicitud,
              status: values.status === 'accepted' ? 'Aceptada' : 'Denegada',
            };
          }
          return solicitud;
        })
      );
      setMessageResponse({
        message: response?.data?.message,
        type: 'success',
        show: true,
      });
    } catch (e) {
      setMessageResponse({
        message: e.data.message,
        type: 'error',
        show: true,
      });
    } finally {
      setAPILoading(false);
    }
  }
  const visualPageNumber = pageNumber + 1;

  const onChangePage = (event, newPage) => {
    const newPageNumber = newPage - 1;
    if (newPageNumber >= 0 && newPageNumber < totalPages) {
      setPageNumber(newPageNumber);
    }
  };
  async function renderApplications(response) {
    const applications = response.data.applications;
    const renderedApplications = await Promise.all(
      applications.map(async (application) => {
        const user = application.user;
        const userName = user ? user.name : 'Usuario no disponible';
        const userEmail = user ? user.email : 'Email no disponible';
        const userPhone = user ? user?.celphone : 'Celular no disponible';
        const reason = application.reason
          ? application.reason
          : 'Motivo no especificado';
        const referer = user ? user.referer : 'Sin referer';

        return {
          applicationID: application.id,
          userID: userName,
          oldPillar: application.oldPillar.name,
          newPillar: application.newPillar.name,
          status:
            application.status === 'denied'
              ? 'Denegada'
              : application.status === 'accepted'
              ? 'Aceptada'
              : 'Pendiente',
          applicationDate: application.applicationDate,
          referer: referer,
          celphone: userPhone,
          email: userEmail,
          reason: reason,
        };
      })
    );

    setSolicitudes(renderedApplications);
  }

  useEffect(() => {
    fetchAndRenderApplications(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    // eslint-disable-next-line
    timer = setTimeout(function () {
      setMessageResponse({
        message: '',
        type: 'error',
        show: false,
      });
    }, 5000);

    setIsLoading(false);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (location.state) {
      setMessageResponse({
        message: location.state.message,
        type: location.state.type,
        show: true,
      });
    }
  }, [location.state]);

  const openReasonClick = (reason, applicationID) => {
    setSelectedReason(reason);
    setSelectedApplicationID(applicationID);

    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const copyInfo = (value) => {
    navigator.clipboard.writeText(value);

    setMessageResponse({
      message: 'Copiado',
      type: 'success',
      show: true,
    });

    setTimeout(() => {
      setCopiedSuccess(false);
      setMessageResponse({
        message: 'Copiado',
        type: 'success',
        show: false,
      });
    }, 2000);
  };

  const statusColor = (value) => {
    const color =
      {
        Denegada: '#CF1322',
        Aceptada: '#27AE60',
      }[value] || 'Pendiente';

    return `<span style="color: ${color};">${value}</span>`;
  };
  const columns = [
    {
      Header: 'Nº',
      accessor: 'applicationID',
    },
    {
      Header: 'Nombre Mentor',
      accessor: 'userID',
    },
    {
      Header: 'E-Mail',
      accessor: 'email',
      Cell: ({ value }) => (
        <span style={{ cursor: 'pointer' }} onClick={() => copyInfo(value)}>
          {value}
          {copiedSuccess && (
            <Alert type={messageResponse.type}>{messageResponse.message}</Alert>
          )}
        </span>
      ),
    },

    {
      Header: 'Fecha',
      accessor: 'applicationDate',
    },
    {
      Header: 'Pilar Solicitado',
      accessor: 'newPillar',
    },

    {
      Header: 'Referer',
      accessor: 'referer',
    },

    {
      Header: 'Celular',
      accessor: 'celphone',
      Cell: ({ value }) => (
        <span style={{ cursor: 'pointer' }} onClick={() => copyInfo(value)}>
          {value}
          {copiedSuccess && (
            <Alert type={messageResponse.type}>{messageResponse.message}</Alert>
          )}
        </span>
      ),
    },
    {
      Header: 'Motivo',
      accessor: 'reason',
      Cell: ({ value, row }) => (
        <span
          className="reason"
          style={{ cursor: 'pointer' }}
          onClick={() => openReasonClick(value, row.original.applicationID)}
        >
          {value}
        </span>
      ),
    },
    {
      Header: 'Estado',
      accessor: 'status',
      Cell: ({ value }) => (
        <span dangerouslySetInnerHTML={{ __html: statusColor(value) }} />
      ),
    },
    {
      Header: '',
      id: 'actions',
      accessor: function ({ applicationID, status }) {
        return status !== 'Pendiente' ? (
          <> </>
        ) : (
          <>
            <IconButton
              onClick={() =>
                modifyApplication({ status: 'accepted', id: applicationID })
              }
            >
              <ThumbUp color="primary" />
            </IconButton>
            <IconButton
              onClick={() =>
                modifyApplication({ status: 'denied', id: applicationID })
              }
            >
              <ThumbDown color="secondary" />
            </IconButton>
          </>
        );
      },

      disableSortBy: true,
    },
  ];

  return (
    <>
      <div className="user-container">
        {messageResponse.show && (
          <Alert type={messageResponse.type}>{messageResponse.message}</Alert>
        )}
        <TableSite
          data={solicitudes}
          columns={columns}
          isLoadingData={isLoading}
          tableName="Solicitudes"
          loader={APILoading}
          totalPages={totalPages}
          currentPage={visualPageNumber}
          onChangePage={onChangePage}
          isDynamic
        />
        {selectedReason !== 'Motivo no especificado' && (
          <Modal className="modal" open={modalOpen} onClose={closeModal}>
            <ModalContent
              title={`Motivo de solicitud Nº ${selectedApplicationID}`}
              onClick={closeModal}
            >
              <p style={{ padding: '24px' }}>{selectedReason}</p>
              <div className="modal__action-container modal__action-container--1-column">
                <Button onClick={closeModal}>Entendido</Button>
              </div>
            </ModalContent>
          </Modal>
        )}
      </div>
    </>
  );
};

export default ApplicationTableContainer;
