import { FormControlLabel, Modal, Switch } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import LinkComponent from '../../../components/common/Link';
import Stepper from '../../../components/common/stepper';
import ClientForm from './clientForm';

import Wizard from './wizard';
import ConfirmationModal from '../statusModal';

const ClientCreationContainer = () => {
  const [isClientActive, setIsClientActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [shouldShowMessage, setShouldShowMessage] = useState(true);
  const onToggle = () => {
    if (shouldShowMessage) {
      setIsOpen(true);
    } else {
      setIsClientActive(false);
      setShouldShowMessage(true);
    }
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAccept = () => {
    setIsClientActive(true);
    setIsOpen(false);
    setShouldShowMessage(false);
  };

  const handleDenied = () => {
    setIsClientActive(false);
    setIsOpen(false);
    setShouldShowMessage(true);
  };

  return (
    <div className="client-container">
      <ConfirmationModal
        title="¿Estás seguro/a que deseas activar al cliente?"
        onClose={handleClose}
        open={isOpen}
        handleAccept={handleAccept}
        handleDenied={handleDenied}
      />
      <div className="client__back-container">
        <LinkComponent
          title="Volver"
          url="/clientes"
          icon="arrow_back"
        ></LinkComponent>
      </div>
      <Wizard title="Crear nuevo cliente">
        <div className="wizard__content">
          <div className="wizard__steps-container wizard-padding">
            <FormControlLabel
              className="wizard__toggle-container"
              control={
                <Switch
                  name="active"
                  checked={isClientActive}
                  onChange={onToggle}
                />
              }
              label="Aprobar y activar cliente"
            />
            <Stepper text="Datos del cliente" isActive={true} />
          </div>
          <div className="wizard__form-container">
            <ClientForm isClientActive={isClientActive} />
          </div>
        </div>
      </Wizard>
    </div>
  );
};

export default ClientCreationContainer;
