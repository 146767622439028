import React, { useContext, useState } from 'react';
import Search from '../../../../components/common/Table/searchBox';
import { getImagefromBase64 } from '../../../../utils/images';
import { ProjectContext } from '../../projectContext';

const NavbarPreview = () => {
  const [state, dispatch] = useContext(ProjectContext);
  const {
    platform,
    landing: { logo },
    client,
  } = state;

  return (
    <div className="platform__navbar-container">
      <div className="platform__logo-container">
        <img
          className="platform__logo"
          src={getImagefromBase64(logo)}
          alt={`logo ${client.name}`}
        />
      </div>
      <div className="platform__navbar-actions">
        <Search className="platform__search-input" />

        <div>
          <span className="platform__icon material-symbols-outlined">
            error
          </span>
          <span className="material-symbols-outlined">notifications</span>
        </div>
        <span className="client name">Cristobal Moya</span>
        <span className="avatar avatar--small"></span>
      </div>
    </div>
  );
};
export default NavbarPreview;
