import React from 'react';
import UserProvider from './userContext';
import UserEditForm from './userEditForm';

const UserEditContainer = () => {
  return (
    <UserProvider>
      <UserEditForm />
    </UserProvider>
  );
};

export default UserEditContainer;
