import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '../../../components/common/Button';
import PersonIcon from '@material-ui/icons/Person';
const RescheduleErrorModal = ({ onBack, errorData }) => {
  if (!errorData || !errorData.config || !errorData.config.data) {
    return (
      <div className="error__text">
        <p>Error de datos</p>
      </div>
    );
  }
  const {
    mentor = '',
    mentee = '',
    calendar: { available_date = '', hour = '' } = {},
  } = errorData ? JSON.parse(errorData.config.data) : {};

  return (
    <div className="error__container">
      <div className="error__text">
        <p>
          Actualmente uno de los participantes{' '}
          <strong>
            no se encuentra <br /> disponible
          </strong>
          &nbsp; para el día
          <strong>
            &nbsp; {available_date}, a las {hour}:00 hrs.
          </strong>
        </p>

        <br />
        <p> Te invitamos a buscar un nuevo horario o fecha para el usuario:</p>
        <div className="error__user">
          <PersonIcon /> <p>Mentor: {mentor}</p>
        </div>
      </div>

      <hr />
      <Grid container justify="space-around">
        <Grid item xs={5}>
          <Button widthFull secondary onClick={onBack}>
            Volver atrás
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default RescheduleErrorModal;
