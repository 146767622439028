import { Paper } from '@material-ui/core';
import React from 'react';
import Title from '../../components/common/Title';
import clientIcon from '../../assets/images/talleres.png';
import { SectionCard } from '../../components/common/Cards/sectionCard';

const WorkShopContainer = () => {
  return (
    <div className="user-container">
      <div className="content-panel">
        <Title title="Formación" />
        <span className="subtitle">
          Encuentra aquí toda la información asociada al material de
          aprendizaje. Podrás crear y administrar cursos, reuniones y material
          complementario.
        </span>
        <div className="client__flex-container">
          <SectionCard
            title="Talleres"
            caracteristics={['Conferencias / reuniones masivas']}
            img={clientIcon}
            link="/talleres"
          />
          {/*  <SectionCard
            title="Cursos y Material complementario"
            caracteristics={['Administrar cursos y material complementario.']}
            img={announcementIcon}
            link="/convocatorias"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default WorkShopContainer;
