import React, { useState, useEffect, useContext } from 'react';
import List from '@material-ui/core/List';
import ListItemLink from './ListItemLink';
import { Grid } from '@material-ui/core';
import UsersAPI from '../../user-api';
import { UserContext } from '../userContext';
import SessionAPI from '../../../SessionContainer/session-api';

const ListSessions = () => {
  const [sessions, setSessions] = useState([]);
  const [state, dispatch] = useContext(UserContext);
  const [scheduleLink, setScheduleLink] = useState();
  const { user } = state;
  useEffect(() => {
    UsersAPI.getSessions(user.id).then((response) => {
      setSessions(response?.data || []);
    });
  }, []);
  useEffect(() => {
    (async () => {
      try {
        const { response } = await SessionAPI.getDetailsSession(user.id);
        setScheduleLink(response);
      } catch (error) {
        console.error('Error al obtener detalles de sesión:', error);
      }
    })();
  }, []);

  return (
    <div className="edit-list-sessions">
      <Grid container className="user-container__edit" direction="column">
        <Grid item style={{ backgroundColor: 'white' }}>
          <h3>Lista de reuniones</h3>
          {sessions.length > 0 ? (
            <List>
              {sessions.map((session) => {
                return <ListItemLink key={session.id} session={session} />;
              })}
            </List>
          ) : (
            <p className="empty">Aun no tiene reuniones agendadas</p>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ListSessions;
