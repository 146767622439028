import { FormControlLabel, Switch } from '@material-ui/core';
import React, { useState } from 'react';
import Wizard from '../../ClientContainer/ClientCreationContainer/wizard';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import InputWrapper from '../../../components/forms/Input/inputWrapper';
import InputImage from '../../../components/forms/Input/inputImage';
import { Input } from '../../../components/forms';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import {
  YupEditorMaxLengthValidator,
  YupEditorMinLengthValidator,
} from '../../../utils/validators';
import draftToHtml from 'draftjs-to-html';
import Button from '../../../components/common/Button';
import NewsApi from '../news-api';
import Alert from '../../../components/common/Alert';
import { useHistory } from 'react-router-dom';
import { TIME_TO_REDIRECT } from '../../../utils/constants';

YupEditorMaxLengthValidator();
YupEditorMinLengthValidator();

const MAX_LENGTH_TITLE = 70;
const MAX_LENGTH_NEW = 300;
const MIN_LENGTH_NEWS = 10;

const NewsForm = () => {
  const history = useHistory();
  const [textEditor, setTextEditor] = useState(EditorState.createEmpty());
  const [newStatus, setNewStatus] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDraftSubmitting, setIsDraftSubmitting] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const schema = Yup.object().shape({
    title: Yup.string()
      .max(
        MAX_LENGTH_TITLE,
        `El título no puede superar los ${MAX_LENGTH_TITLE} caracteres`
      )
      .required('Debes ingresar un título válido'),
    description: Yup.string()
      .editorMinLengthValidator(
        MIN_LENGTH_NEWS,
        `La noticia debe contener al menos ${MIN_LENGTH_NEWS} caracteres`
      )
      .editorMaxLengthValidator(
        MAX_LENGTH_NEW,
        `La noticia no puede superar los ${MAX_LENGTH_NEW} caracteres`
      )
      .required('Debes ingresar una descripción válida'),
    image: Yup.string().required('Debes ingresar una imágen válida'),
    largeImage: Yup.string().nullable(true),
    status: Yup.string(),
  });

  const onSubmit = async (values) => {
    try {
      const status = isDraft ? 'draft' : 'active';

      if (isDraft) {
        setIsDraftSubmitting(true);
        await NewsApi.createNews({ ...values, status });
      } else {
        setIsSubmitting(true);
        await NewsApi.createNews({ ...values, status });
      }
    } catch (error) {
      setErrorMessage(error.data.message);
    } finally {
      setIsSubmitting(false);
      setTimeout(() => history.push('/novedades'), TIME_TO_REDIRECT);
    }
  };

  return (
    <Wizard title="Crear nueva publicación">
      {errorMessage && <Alert type="error">{errorMessage}</Alert>}
      <Formik
        initialValues={{
          title: '',
          description: '',
          image: '',
          largeImage: '',
        }}
        validationSchema={schema}
        validateOnChange
        onSubmit={onSubmit}
      >
        {({ submitForm, ...props }) => {
          return (
            <Form>
              <div className="wizard__content wizard__content--col">
                <div className="wizard__steps-container wizard-padding">
                  <InputWrapper
                    variant="big"
                    label="Imagen inicio (banner)"
                    description="Archivos 440x260px JPG, PNG  - Tamaño máximo 5MB."
                    id="largeImage"
                  >
                    <InputImage
                      name="largeImage"
                      id="largeImage"
                      allowedExts={['image/png', 'image/jpg', 'image/jpeg']}
                    />
                  </InputWrapper>
                  <InputWrapper
                    variant="big"
                    label="Imagen"
                    description="Archivos 240x200px JPG, PNG  - Tamaño máximo 5MB."
                    id="image"
                  >
                    <InputImage
                      name="image"
                      id="image"
                      allowedExts={['image/png', 'image/jpg', 'image/jpeg']}
                    />
                  </InputWrapper>
                </div>
                <div className="wizard__form--center grid wizard-padding">
                  <InputWrapper
                    variant="big"
                    label="Nombre de la publicación"
                    id="title"
                  >
                    <Input name="title" label={''} id="title" />
                    <span className="char-counter">
                      {props.values['title'].length}/ {MAX_LENGTH_TITLE}
                    </span>
                  </InputWrapper>

                  <InputWrapper
                    label="Descripción"
                    id="description"
                    name="description"
                    variant="big"
                  >
                    <>
                      <Editor
                        id="description"
                        name="description"
                        wrapperClassName="editor__wrapper"
                        toolbarClassName="editor__toolbar"
                        editorState={textEditor}
                        onEditorStateChange={setTextEditor}
                        onBlur={() => {
                          props.setFieldTouched('description', true);
                          props.setFieldValue(
                            'description',
                            draftToHtml(
                              convertToRaw(textEditor.getCurrentContent())
                            )
                          );
                        }}
                      />
                      <div className="editor__error">
                        {props.touched.description &&
                          props.errors.description && (
                            <span className=" error-message">
                              {props.errors.description}
                            </span>
                          )}
                        <span className="char-counter">
                          {
                            textEditor.getCurrentContent().getPlainText('')
                              .length
                          }
                          /{MAX_LENGTH_NEW}
                        </span>
                      </div>
                    </>
                  </InputWrapper>
                </div>
              </div>
              <div className="wizard__lower-action">
                <Button
                  secondary
                  loading={isDraftSubmitting}
                  onClick={() => {
                    setIsDraft(true);
                    submitForm();
                  }}
                >
                  Guardar
                </Button>
                <Button
                  loading={isSubmitting}
                  onClick={() => {
                    setIsDraft(false);
                    submitForm();
                  }}
                >
                  Publicar
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Wizard>
  );
};

export default NewsForm;
