import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Input } from '../../../../components/forms';
import Select from '../../../../components/forms/Select';
import Button from '../../../../components/common/Button';
import Alert from '../../../../components/common/Alert';
import { Grid, FormControlLabel } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import InputWrapper from '../../../../components/forms/Input/inputWrapper';
import Checkbox from '@material-ui/core/Checkbox';
import ControlPointSharpIcon from '@material-ui/icons/ControlPointSharp';
import InstitutionInput from '../../../../components/forms/Input/institutionInput';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import Dropdown from '../../../WorkShopContainer/WorkShopCreate/CreationInputs/dropDownInput';
import CoursesAPI from '../../course-api';

const CourseCreationContainer = ({
  onNext,
  course,
  setCourse,
  pillars,
  institutions,
  setInstitutions,
  institutionLogo,
  setRefresh,
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const [selectedPillar, setSelectedPillar] = useState();
  const [showInstitutionInput, setShowInstitutionInput] = useState(false);

  const toggleInstitutionInput = () => setShowInstitutionInput((prev) => !prev);

  const onClickPillar = (option, setFieldValue) => {
    setFieldValue('pillar', option.id);
    setSelectedPillar(option);
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    setCourse(values);
    onNext();
  };

  const Cancel = () => {
    history.push('/cursos');
  };

  const onInstitutionSubmit = (institution, setFieldValue) => {
    const institutionData = {
      value: institution.name,
      id: institution.id,
      logo: institution.logo,
    };
    setCourse((prevCourse) => ({
      ...prevCourse,
      institution: institutionData,
    }));
    setShowInstitutionInput(false);
    setFieldValue('institution', institutionData);
  };
  const handleNewInstitution = (newInstitution) => {
    setInstitutions((prevInstitutions) => [
      ...prevInstitutions,
      newInstitution,
    ]);
  };

  useEffect(() => {
    const foundPillar = pillars.find((p) => p.id === course.pillar);
    if (foundPillar) {
      setSelectedPillar(foundPillar);
    }
  }, [course.pillar, pillars]);
  return (
    <div className="wizard__form wizard__form--full">
      <Formik
        initialValues={course}
        validationSchema={Yup.object({
          name: Yup.string().required('Nombre requerido'),
          institution: Yup.string().required('Institución requerida'),
          estimatedTime: Yup.string().required('Tiempo requerido'),
          hash: Yup.string().required('Hash requerido'),
          pillar: Yup.number().required('Pilar Requerido').min(1),
          active: Yup.boolean(),
          hasVideo: Yup.boolean(),
          hasReading: Yup.boolean(),
          hasDownload: Yup.boolean(),
          description: Yup.string().required('Descripción requerida'),
        })}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue, setFieldTouched }) => {
          return (
            <Form>
              <div className="wizard__grid-section">
                {showAlert && <Alert type="error">{messageAlert}</Alert>}

                <strong>Información del curso</strong>

                <div style={{ backgroundColor: 'white' }}>
                  <div container direction="column" className="personal-info">
                    <InputWrapper style={{ marginTop: '12px' }}>
                      <span>
                        <strong>Nombre</strong>
                      </span>
                      <Input name="name" placeholder="Nombre" label="" />
                    </InputWrapper>

                    <InputWrapper>
                      <span>
                        <strong>Institución</strong>
                      </span>
                      {showInstitutionInput ? (
                        <>
                          <InstitutionInput
                            onClose={toggleInstitutionInput}
                            onInstitutionSubmit={onInstitutionSubmit}
                            institutionLogo={institutionLogo}
                            onClickAccept={handleNewInstitution}
                            {...values}
                          />
                          <div
                            onClick={toggleInstitutionInput}
                            style={{
                              margin: '10px 0',
                              color: '#0BB5E7',
                              display: 'flex',
                              cursor: 'pointer',
                            }}
                          >
                            <ControlPointSharpIcon /> Seleccionar institución
                            existente
                          </div>
                        </>
                      ) : (
                        <>
                          <Select
                            label="Selecciona Institución"
                            name="institution"
                            options={[]}
                            advancedOptions={institutions}
                            isRequired={true}
                          />

                          <div
                            onClick={toggleInstitutionInput}
                            style={{
                              margin: '10px 0',
                              color: '#0BB5E7',
                              display: 'flex',
                              cursor: 'pointer',
                            }}
                          >
                            <ControlPointSharpIcon /> Agregar nueva institución
                          </div>
                        </>
                      )}
                    </InputWrapper>

                    <InputWrapper
                      name="description"
                      label="Descripcion"
                      id="description"
                    >
                      <Input
                        placeholder="Descripcion"
                        name="description"
                        type="textarea"
                        max={500}
                        showCounter
                      />
                    </InputWrapper>

                    <div>
                      <span>
                        <strong>Tipo de contenido</strong>
                      </span>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="hasVideo"
                              name="hasVideo"
                              style={{ color: '#0BB5E7' }}
                              onChange={(e) =>
                                setFieldValue('hasVideo', e.target.checked)
                              }
                              color="primary"
                              checked={values.video}
                            />
                          }
                          label="Videos"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="hasReading"
                              id="hasReading"
                              style={{ color: '#0BB5E7' }}
                              onChange={(e) =>
                                setFieldValue('hasReading', e.target.checked)
                              }
                              color="primary"
                              checked={values.readingMaterial}
                            />
                          }
                          label="Material de lectura"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="hasDownload"
                              id="hasDownload"
                              style={{ color: '#0BB5E7' }}
                              onChange={(e) =>
                                setFieldValue('hasDownload', e.target.checked)
                              }
                              color="primary"
                              checked={values.downloadableMaterial}
                            />
                          }
                          label="Material descargable"
                        />
                      </div>
                    </div>

                    <InputWrapper>
                      <span>
                        <strong>Hash de Curso</strong>
                      </span>
                      <Input name="hash" placeholder="Hash" label="" />
                    </InputWrapper>
                    <InputWrapper>
                      <span>
                        <strong>Tiempo Estimado</strong>
                      </span>
                      <Input
                        name="estimatedTime"
                        placeholder="Tiempo estimado"
                        label=""
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <span>
                        <strong>Pilar</strong>
                      </span>

                      <Dropdown
                        id="pillar"
                        label="Selecciona Pilar"
                        name="pillar"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        options={pillars.map((pillar) => ({
                          name: pillar.name,
                          id: pillar.id,
                        }))}
                        selectedOption={selectedPillar}
                        onClick={(option) =>
                          onClickPillar(option, setFieldValue)
                        }
                      />
                      {touched.pillar && errors.pillar ? (
                        <span className="error-message">{errors.pillar}</span>
                      ) : null}
                    </InputWrapper>
                  </div>
                </div>
                <div className="wizard__action">
                  <Button
                    variant="outlined"
                    color="default"
                    widthFull
                    secondary
                    onClick={Cancel}
                  >
                    Cancelar
                  </Button>

                  <Button type="submit" widthFull>
                    Aceptar
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CourseCreationContainer;
