import { Menu, MenuItem } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../app/AppContext';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const Header = () => {
  const { user } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState();
  const history = useHistory();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="component-header__container">
      <div
        onClick={(e) => handleClick(e)}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <div className="titles">
          <span style={{ display: 'block' }}>{user.name}</span>
        </div>
        <div className="perfil__inicial">
          {user.name && user.name.substr(0, 1)}
        </div>
        <KeyboardArrowDownIcon className="perfil__icon" />
      </div>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            window.sessionStorage.removeItem('converge.token.private');
            window.sessionStorage.removeItem('converge.roles');
            window.sessionStorage.removeItem('converge.settings');
            user.actions.cleanUser();
            history.push('/');
          }}
        >
          Cerrar Sesión
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Header;
