import React from 'react';
import PropTypes from 'prop-types';
import ActionMenu from '../../ActionMenu';

const Menu = ({ items, open }) => {
  return (
    <ActionMenu isOpen={open}>
      {items.map(({ status, action, text }) => (
       <ItemMenu status={status} action={action} text={text} />
      ))}
    </ActionMenu>
  );
};


export const ItemMenu = ({status, action,text})=>{
  return (
    <div
    className={`action-menu__item action-menu__item--${status}`}
    onClick={status === 'disabled' ? null : action}
  >
    <span>{text}</span>
  </div>
  )

}

export const MenuContainer = ({ children, onClick, isActive, icon = "more_horiz", }) => {

  return (
    <>
      <div className="action-container">
        <span className={`material-symbols-outlined icon ${isActive && 'icon--active'}`} onClick={onClick}>
          {icon}
        </span>
        {children}
      </div>
    </>
  );

};



Menu.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
};

export default Menu;
