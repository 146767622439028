import React, { useEffect, useState } from 'react';
import moment from 'moment';
import classnames from 'classnames';

const SchedulesAvailables = ({ data, onClickSchedule }) => {
  const [dates, setDates] = useState([]);
  const [idSchedule, setIdSchedule] = useState(0);

  useEffect(() => {
    if (data) {
      var availableDates = [];
      var tempAvailableDates = [];
      for (let i = 0; i < data.length; i++) {
        tempAvailableDates = availableDates.filter(
          (resp) => resp['availableDate'] === data[i]['availableDate']
        );
        if (tempAvailableDates.length > 0) {
          availableDates[availableDates.indexOf(tempAvailableDates[0])][
            'hours'
          ].push({
            hour: data[i]['hour'],
            id: data[i]['id'],
          });
        } else {
          availableDates.push({
            availableDate: data[i]['availableDate'],
            hours: [{ hour: data[i]['hour'], id: data[i]['id'] }],
          });
        }
      }

      availableDates.map((availableDate) => {
        return availableDate.hours.sort(function (a, b) {
          const date1 = moment(
            `${availableDate.availableDate} ${a.hour}`
          ).format('YYYY-MM-DD HH:mm:ss');
          const date2 = moment(
            `${availableDate.availableDate} ${b.hour}`
          ).format('YYYY-MM-DD HH:mm:ss');
          if (date1 < date2) return -1;
          if (date1 > date2) return 1;
          return 0;
        });
      });

      availableDates.sort(function (a, b) {
        const date1 = moment(a.availableDate).format('YYYY-MM-DD');
        const date2 = moment(b.availableDate).format('YYYY-MM-DD');
        if (date1 < date2) return -1;
        if (date1 > date2) return 1;
        return 0;
      });

      return setDates(availableDates);
    }
  }, [data]);

  const handleClickSchedule = (id) => {
    setIdSchedule(id);
    onClickSchedule && onClickSchedule(id);
    return true;
  };

  return (
    <div className="assign-date-select">
      {dates.map((date) => {
        return (
          <div className="assign-date-select__day" key={date.availableDate}>
            <p>
              <strong>
                {moment(date.availableDate).format('DD [de] MMMM')}
              </strong>
            </p>
            <div className="assign-date__hours">
              {date.hours.map((hour) => {
                return (
                  <div
                    key={hour.id}
                    className={classnames('assign-date__hours--hour', {
                      active: idSchedule > 0 && idSchedule === hour.id,
                    })}
                    onClick={() => handleClickSchedule(hour.id)}
                  >
                    <span>{hour.hour} hrs</span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SchedulesAvailables;
