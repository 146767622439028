import React from 'react';
const Search = ({
  className = 'actions-table__search search-input',
  onChange,
}) => {
  return (
    <div className={className}>
      <input placeholder="Buscar" onChange={(e) => onChange(e)} />
      <span className="search-input__icon material-symbols-outlined">
        search
      </span>
    </div>
  );
};
export default Search;
