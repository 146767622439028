import React, { useState } from 'react';
import { Typography, Modal } from '@material-ui/core';

import CoursesAPI from '../../course-api';
import Button from '../../../../components/common/Button';
import ModalContent from '../../../../components/common/Modal/ModalContent';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Alert from '../../../../components/common/Alert';
import CourseSummarySection from './summary';
import ConfirmationModal from '../../../ClientContainer/statusModal';

const CourseSummary = ({
  course,
  institutions,
  pillars,
  onPrev,
  mode,
  setInstitutions,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [messageAlert, setMessageAlert] = useState(null);
  const history = useHistory();

  const modalOpen = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };
  const renderTitle = mode === 'create' ? 'Crear nuevo curso' : 'Editar curso';

  const addCourse = async (course) => {
    return await CoursesAPI.addCourse(course)
      .then((response) => {
        setMessageAlert({
          type: 'success',
          message: 'Curso creado exitosamente',
        });
        setTimeout(() => history.push('/cursos'), 3000);
        setSubmitting(true);
      })

      .catch((error) => {
        setMessageAlert({
          type: 'error',
          message: error.data.message,
        });
      })
      .finally(() => setSubmitting(false));
  };

  const updateCourse = async (course) => {
    return await CoursesAPI.updateCourse(course)
      .then((response) => {
        setMessageAlert({
          type: 'success',
          message: 'Curso actualizado exitosamente',
        });
        setTimeout(() => history.push('/cursos'), 3000);
        setSubmitting(true);
      })
      .catch((error) => {
        setMessageAlert({
          type: 'error',
          message: 'Error al actualizar el curso',
        });
        console.error(error);
      })
      .finally(() => setSubmitting(false));
  };
  const publishCourse = () => {
    setOpenModal(false);
    mode === 'create' ? addCourse(course) : updateCourse(course);
  };

  const institutionName = institutions.find(
    (inst) => inst.id === course.institution
  )?.value;
  const pillar = pillars.find((p) => p.id === Number(course.pillar));

  const pillarName = pillar?.name;

  return (
    <>
      <div className="wizard__form wizard__form--full">
        {messageAlert && (
          <Alert type={messageAlert.type}>{messageAlert.message}</Alert>
        )}
        <CourseSummarySection
          course={course}
          institutions={institutions}
          institutionName={institutionName}
          pillarName={pillarName}
          setInstitutions={setInstitutions}
        />

        {openModal && (
          <ConfirmationModal
            header={renderTitle}
            title={
              mode === 'create'
                ? '¿Estás seguro que quieres crear el curso ?'
                : '¿Estás seguro que quieres editar el curso ?'
            }
            onClose={closeModal}
            open={openModal}
            handleAccept={publishCourse}
            handleDenied={closeModal}
          />
        )}

        <div className="wizard__action">
          <Button secondary onClick={onPrev}>
            Volver
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={modalOpen}
            variant="contained"
            style={{ marginTop: '20px' }}
          >
            {mode === 'create' ? 'Publicar Curso' : 'Actualizar Curso'}
          </Button>
        </div>
      </div>
    </>
  );
};
export default CourseSummary;
