import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import routes from '../routes/routes';
import RouteWrapper from '../routes/RouteWrapper';
import AppContextProvider from './AppContext';
import Loader from '../components/common/Loader';

const App = () => {
  return (
    <Router>
      <AppContextProvider>
        <Loader />
        <Switch>
          {routes.map((route, index) => {
            return <RouteWrapper key={`route_${index}`} {...route} />;
          })}
        </Switch>
      </AppContextProvider>
    </Router>
  );
};

export default App;
