import React from 'react';

import ProjectProvider from '../projectContext';

import ProjectWizard from './ProjectWizard';

const ProjectCreationContainer = () => {
  return (
    <ProjectProvider>
      <ProjectWizard />
    </ProjectProvider>
  );
};

export default ProjectCreationContainer;
