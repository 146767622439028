import request from '../../utils/request';

function login({ email, password, gRecaptchaResponse }) {
  return request({
    url: 'user/auth',
    method: 'POST',
    data: {
      email,
      password,
      gRecaptchaResponse,
      channel: 'WEB_ADMIN',
    },
  });
}

function getRoles() {
  return request({
    url: 'role/list',
    method: 'GET',
    private: true,
  });
}

function getSettings() {
  return request({
    url: 'client/info',
    method: 'GET',
    private: true,
  });
}

const AuthAPI = {
  login,
  getRoles,
  getSettings,
};

export default AuthAPI;
