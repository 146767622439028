import moment from 'moment';
require('moment/locale/es');

const DEFAULT_FORMAT = 'DD/MM/YYYY';

/**
 * trims values in a JSON,
 * recommended for forms
 * @param {} valuetoTrim
 * @returns {any}
 */
export const trimFunction = (valuetoTrim) => {
  Object.entries(valuetoTrim).forEach(([key, value]) => {
    return (valuetoTrim[key] = value.trim());
  });

  return valuetoTrim;
};

function formatAmount(value) {
  return new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
  }).format(value);
}

const formatDate = (
  date = new Date(),
  formatOut = DEFAULT_FORMAT,
  formatIn
) => {
  if (formatIn) {
    return moment(date, formatIn).format(formatOut);
  } else {
    return moment(date).format(formatOut);
  }
};

const formatPhone = (number) => {
  const identifier = number.substring(0, 1);
  const numberphone = number.substring(1, 9);
  return `+56 ${identifier} ${numberphone}`;
};

const formatSlug = (text) => {
  return text.replace(' ', '-');
};

const capitalizingPhrase = (text) => {
  const [firstCharacter, ...rest] = text;
  return `${firstCharacter.toUpperCase()}${rest.join('')}`;
};

export {
  formatDate,
  formatAmount,
  formatPhone,
  formatSlug,
  capitalizingPhrase,
};
