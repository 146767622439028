import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import InputWrapper from '../../../components/forms/Input/inputWrapper';
import * as Yup from 'yup';
import { Input } from '../../../components/forms';
import Button from '../../../components/common/Button';

const SurveyCreationForm = ({ onCancel, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [encuesta, setEncuesta] = useState({
    name: '',
    jsonData: '',
  });

  const messageRequired = 'Es un campo requerido';

  const schema = Yup.object({
    name: Yup.string()
      .max(30, 'El nombre de la encuesta no puede superar los 30 caracteres')
      .required('Nombre de la encuesta requerido'),
    jsonData: Yup.object().required(messageRequired),
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    onSubmit(values);
    setLoading(false);
  };

  return (
    <Formik
      initialValues={encuesta}
      validationSchema={schema}
      onSubmit={handleSubmit}
      validateOnChange
    >
      {(props) => {
        return (
          <Form style={{ display: 'grid', gap: 16 }}>
            <p>
              Para crear una encuesta haz click{' '}
              <a href="https://surveyjs.io/create-free-survey" target="_blank">
                aquí
              </a>
              , diseña tu encuesta. Copia y pega el código que te entrega en el
              apartado de JsonEditor
            </p>
            <InputWrapper variant="big" label="Nombre" id="Name">
              <Input name="name" placeholder="Nombre" label="" />
            </InputWrapper>
            <InputWrapper variant="big" label="Data" id="jsonData">
              <Input
                name="jsonData"
                placeholder="JSON"
                label=""
                type="textarea"
                isJson
              />
            </InputWrapper>

            <div className="modal__action-container modal__action-container--2-columns"></div>
            <div className="modal__action-container">
              <Button secondary onClick={onCancel} loading={loading}>
                Cancelar
              </Button>
              <Button type="submit" loading={loading}>
                Guardar
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SurveyCreationForm;
