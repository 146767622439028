import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import BreadCrumb from '../../../components/Breadcrumbs';
import ProjectApi from '../../ProjectsContainer/project-api';

import ClientsApi from '../client-api';
import ClientInfoCard from './clientInfoCard';
import ProjectContainer from './projectContainer';

const ClientInfoContainer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const {
    state: { client: id },
  } = location;

  const links = [
    { url: '/clientes', text: 'Clientes' },
    { url: '/clientes', text: 'Detalle del cliente' },
  ];

  const [client, setClient] = useState();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if (isLoading) {
      (async () => {
        Promise.allSettled([
          ClientsApi.fetchClient(id),
          ProjectApi.fetchProjectsByClient(id),
        ])
          .then((resp) => {
            const [
              {
                value: {
                  data: { client },
                },
              },
              {
                value: {
                  data: { announcements },
                },
              },
            ] = resp;

            setClient(client);
            const projects = announcements.map((announcement) => {
              switch (announcement.status) {
                case 'finish':
                  return { ...announcement, translatedStatus: 'Finalizada' };

                case 'waiting':
                  return { ...announcement, translatedStatus: 'En espera' };

                case 'user-register':
                  return {
                    ...announcement,
                    translatedStatus: 'Registro usuario',
                  };

                case 'active':
                  return { ...announcement, translatedStatus: 'Activo' };
                default:
                  return announcement;
              }
            });

            setProjects(projects);
          })
          .catch((error) => console.log(error))
          .finally(() => setIsLoading(false));
      })();
    }
  }, [isLoading]);

  return (
    <div className="content-panel user-container">
      <BreadCrumb links={links} />
      <h2>Detalle del cliente</h2>
      <ClientInfoCard isLoading={isLoading} client={client} />
      <ProjectContainer
        client={client}
        projects={projects}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </div>
  );
};

export default ClientInfoContainer;
