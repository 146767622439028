import React from 'react';
import Title from '../../components/common/Title';
import clientIcon from '../../assets/images/clients-icon.svg';
import announcementIcon from '../../assets/images/announcement-icon.png';//'./assets/images/announcement-icon.png';
import { SectionCard } from '../../components/common/Cards/sectionCard';


const Announcements = () => {
  return (
    <div className="user-container">
      <div className="content-panel">
        <Title title="Clientes y Convocatorias" />
        <span className="subtitle">
          Acá encontrarás toda la información de los clientes y convocatorias,
          podrás crear nuevos clientes y convocatorias.
        </span>
        <div className="client__flex-container">
          <SectionCard
            title="Clientes"
            caracteristics={[
              'Crear nuevos clientes',
              'Activar y desactivar clientes',
              'Customizar plataforma',
              'Crear convocatoria para clientes',
            ]}
            img={clientIcon}
            link="/clientes"
          />
          <SectionCard
            title="Convocatorias abiertas"
            caracteristics={[
              'Crear convocatorias',
              'Activar y desactivar proyecto',
              'Customizar plataforma',
              'Detalle de usuarios',
            ]}
            img={announcementIcon}
            link="/convocatorias"
          />
        </div>
      </div>
    </div>
  );
};


export default Announcements;
