import React from 'react';
import { useField, ErrorMessage } from 'formik';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const SelectIdName = ({
  label,
  extra,
  max,
  icon,
  startIcon,
  onClickIcon,
  type,
  options,
  onChangeProps,
  ...props
}) => {
  const [muiSelectValue, setMuiSelectValue] = React.useState(360);
  const [field, meta] = useField(props);

  const handleMuiSelectOnChange = (event) => {
    event.persist();
    event.target.value && onChangeProps(event.target.value);
    return setMuiSelectValue(event.target.value);
  };

  return (
    <div className="input__container">
      <Select
        id="standard-select"
        className="select-id-name"
        value={muiSelectValue}
        {...field}
        placeholder={label}
        onClick={handleMuiSelectOnChange}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id} name={option.name}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {meta.touched && meta.error && (
        <div className="error-message">
          <ErrorMessage
            className="error-message"
            name={props.id || props.name}
          />
        </div>
      )}
    </div>
  );
};
SelectIdName.propTypes = {
  label: PropTypes.string.isRequired,
  extra: PropTypes.string,
  max: PropTypes.number,
  type: PropTypes.string,
  icon: PropTypes.node,
  startIcon: PropTypes.node,
  onClickIcon: PropTypes.func,
  options: PropTypes.array,
};
export default SelectIdName;
