import { useField } from 'formik';
import React from 'react';
import {
  handleFileRead,
  isValidImageSize,
  isValidImagetype,
} from '../../../utils/images';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const InputFile = (props) => {
  const [field, meta, helpers] = useField(props.name);

  const showFileName = () => {
    if (field.value.name) {
      return field.value.name;
    } else if (field.value.includes('http')) {
      const urlParts = field.value.split('/');
      const filename = urlParts[urlParts.length - 1];
      return filename;
    } else {
      return null;
    }
  };

  const isThereFile = field.value?.name
    ? true
    : field.value?.includes('http')
    ? true
    : false;

  const onChange = async (e) => {
    if (isValidImageSize(e.target.files[0])) {
      if (isValidImagetype(props.allowedExts, e.target.files[0])) {
        const base64 = await handleFileRead(e.target.files);
        helpers.setValue(base64);
      } else {
        helpers.setError(
          `Asegurate que el archivo sea de formato ${props.allowedExts.join(
            ', '
          )}`
        );
      }
    } else {
      helpers.setError('Asegurate que el archivo sea menor a 10MB');
    }
  };

  const onClick = () => {
    helpers.setValue('');
  };
  return (
    <div
      className="input-image__container"
      onClick={() => helpers.setTouched(true)}
    >
      <label
        className={`${
          props.disabled ? 'input-image input-image--disabled' : 'input-image'
        }`}
        htmlFor={props.id}
      >
        <span className="material-symbols-outlined input-image__icon">
          file_upload
        </span>
        <span className="input-image__text">Cargar archivo</span>
      </label>
      <input
        type="file"
        name={props.name}
        multiple
        className="input-image__field"
        id={props.id}
        onChange={(e) => onChange(e)}
        accept={props.accept}
        disabled={props.disabled}
      ></input>

      {isThereFile && (
        <div className="input-file">
          <div>
            <AttachFileIcon className="file-icon file-icon--rotated" />
            <span className="file-name">{showFileName()}</span>
          </div>
          <DeleteOutlineOutlinedIcon
            className="file-icon file-icon--big"
            onClick={onClick}
          />
        </div>
      )}

      {meta.error && meta.touched && (
        <span className="error-message">{meta.error}</span>
      )}
    </div>
  );
};

export default InputFile;
