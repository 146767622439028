import React, { useEffect, useState } from 'react';
import SessionAPI from '../../session-api';
import Button from '../../../../components/common/Button';
import { Grid } from '@material-ui/core';
import Skeleton from 'react-placeholder';
import SchedulesAvailables from '../SchedulesAvailables';

const AssignDateByMentor = ({ idMentor, idSession, onChange }) => {
  const [schedules, setSchedules] = useState([]);
  const [empty, setEmpty] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [idSchedule, setIdSchedule] = useState(0);

  useEffect(() => {
    SessionAPI.getDatesByMentor(idMentor)
      .then((response) => {
        setIsLoading(false);
        return setSchedules(response.data);
      })
      .catch((e) => {
        setEmpty(e.data?.message);
      });
  }, [idMentor]);

  const handleClickSchedule = (id) => {
    return setIdSchedule(id);
  };

  const handleClick = () => {
    SessionAPI.assignDate(idSession, idSchedule)
      .then((response) => {
        onChange('success', response.data.message);
      })
      .catch((e) => {
        onChange('error', e.data.message);
      });
  };

  return empty === '' ? (
    <div className="assign-date">
      {!isLoading ? (
        <>
          <SchedulesAvailables
            data={schedules}
            onClickSchedule={handleClickSchedule}
          />
          <div className="assign-date-button">
            <Grid container justify="center">
              <Grid item xs={12} md={4}>
                <Button
                  disabled={idSchedule === 0}
                  widthFull
                  onClick={handleClick}
                >
                  Aceptar
                </Button>
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <Skeleton variant="text" rows={10} />
      )}
    </div>
  ) : (
    <div className="assign-date">
      <p>No existen horas disponibles para este Mentor</p>
    </div>
  );
};

export default AssignDateByMentor;
