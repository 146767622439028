import React, { useEffect, useState } from 'react';
import Avatar from '../../../components/common/avatars';
import { StrongSubtitle } from '../../../components/common/Title';
import logo from '../../../assets/images/logo-converge.svg';
import Menu, { MenuContainer } from '../../../components/common/Menu';
import NewsApi from '../news-api';
import { useHistory } from 'react-router-dom';
import StarIcon from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import Alert from '../../../components/common/Alert';

const NewsCard = ({ news, onClickAction, setError }) => {
  const history = useHistory();
  const [active, setActive] = useState(false);
  const [isFeatured, setIsFeatured] = useState(news.isFeatured);
  const [messageAlert, setMessageAlert] = useState({ type: '', message: '' });

  const onClick = (news) => {
    setActive(!active);
  };

  const actionText = (status) => {
    return status === 'active'
      ? 'Desactivar'
      : status === 'inactive'
      ? 'Activar'
      : 'Desactivar';
  };

  const statusText = (status) => {
    switch (status) {
      case 'active':
        return 'Activo';
        break;
      case 'inactive':
        return 'Inactivo';
        break;

      case 'draft':
        return 'Borrador';
        break;

      case 'deleted':
        return 'Eliminado';
        break;
    }
  };

  const handleClickByAction = (news) => {
    return news.status === 'active'
      ? onClickAction('draft', news)
      : news.status === 'inactive'
      ? onClickAction('active', news)
      : null;
  };

  const creationDate = new Date(news.createdAt).toLocaleDateString();

  const toggleFeatured = async () => {
    try {
      const updatedIsFeatured = !isFeatured;
      setIsFeatured((prev) => !isFeatured);
      await NewsApi.updateNews(news.id, news, updatedIsFeatured);
    } catch (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setError('Ha ocurrido un error al destacar la novedad.');
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };

  return (
    <div className="news__card">
      {/* header */}
      <div className="news-heading">
        <div className="title-section">
          <Avatar image={logo} variant="extra-small" />
          <StrongSubtitle text={`${news.title} - ${statusText(news.status)}`} />
          <div onClick={toggleFeatured}>
            {isFeatured ? (
              <StarIcon
                style={{
                  fill: 'rgba(255, 197, 61, 1)',
                }}
              />
            ) : (
              <StarBorder
                style={{
                  fill: 'rgba(219, 221, 224, 1)',
                }}
              />
            )}
          </div>
        </div>
        <div className="details-section">
          <span className="news-info">Hace {news.timeAgo}</span>
          <div
            className={`menu-container ${active && 'menu-container--active'}`}
          >
            <MenuContainer
              type="edit"
              onClick={() => onClick(news)}
              icon="edit"
              isActive={active}
            >
              {active && (
                <Menu
                  open={active}
                  items={[
                    {
                      text: 'Editar',
                      action: () => {
                        history.push('/novedades/editar', { id: news.id });
                      },
                    },
                    {
                      text: actionText(news.status),
                      action: () => {
                        handleClickByAction(news);
                      },
                      status: news.status === 'draft' ? 'disabled' : null,
                    },
                    {
                      text: 'Eliminar',
                      status: 'warning',
                      action: () => {
                        onClickAction('deleted', news);
                      },
                    },
                  ]}
                ></Menu>
              )}
            </MenuContainer>
          </div>
        </div>
      </div>

      {/* content */}
      <div className="news-content">
        <div
          className="news-content-info"
          dangerouslySetInnerHTML={{ __html: news.description }}
        ></div>
        <div className="image-container">
          <img src={news.image} />
        </div>
      </div>
      <span className="news-info">{creationDate}</span>
    </div>
  );
};

export default NewsCard;
