import React from 'react';
import converge from '../../../../../src/assets/images/logo-converge.svg';

const MenuPreview = () => {
  return (
    <div className="platform__menu">
      <div className="menu__items">
        <ul>
          <li className="items">
            <span className="material-icons">home</span>Inicio
          </li>
          <li className="items">
            <span className="material-icons">notifications</span>Notificaciones
          </li>
          <li className="items">
            <span className="material-icons">person</span>Perfil
          </li>
          <li className="items">
            <span className="material-icons">groups</span>Red de contactos
          </li>
          <li className="items">
            <span className="material-icons">forum</span>Foro
          </li>
          <li className="items">
            <span className="material-icons">location_city</span>Empresas
          </li>
          <li className="items">
            <span className="material-icons">local_activity</span>Beneficios
          </li>
        </ul>
        <hr />
        <ul>
          <li>
            <span className="material-icons">logout</span>Cerrar sesión
          </li>
        </ul>
      </div>
      <div className="menu__footer">
        <div className="menu__img-container">
          <img src={converge} />
        </div>
        <div className="menu__footer-text">
          <span className="link">Políticas de Privacidad</span>
          <span className="link">Preguntas Frecuentes</span>
          <span className="brand">CONVERGE 2021</span>
          <br />
          <span className="footer-text">Todos los derechos reservados.</span>
        </div>
      </div>
    </div>
  );
};

export default MenuPreview;
