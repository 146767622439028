import React, { useContext } from 'react';
import { Modal } from '@material-ui/core';
import { Check } from '@material-ui/icons';

import Button from '../../../../components/common/Button';
import { ProjectContext } from '../../projectContext';
import workChat from './../../../../assets/images/work-chat.png';
import converge from './../../../../assets/images/logo-converge.svg';
import Input from './../../../../components/forms/Input';
import imageBanner from './../../../../assets/images/bg-login-izquierdo.png';

const LoginModalPreview = ({ open, onClose }) => {
  const [state] = useContext(ProjectContext);
  const { description, loginPrimaryColor, loginSecondaryColor } = state.login;
  const { name } = state.client;
  const { logo } = state.landing;

  return (
    <Modal open={open} onClose={onClose} className="modal">
      <div className="modal__container modal--full preview__landing">
        {/* HEADER */}
        <div className="modal__title modal__title--secondary">
          <span className="title">Previsualización del cliente</span>
          <Button onClick={onClose}>
            <span className="material-symbols-outlined">arrow_back</span>
            <span>Volver</span>
          </Button>
        </div>
        <main className="preview_login_container">
          <div
            className="preview_login_banner"
            style={{
              backgroundImage: `linear-gradient(${loginPrimaryColor}90,${loginPrimaryColor}90),url(${imageBanner})`,
              backgroundSize: 'cover',
            }}
          />
          <section className="preview_login_content">
            <div className="preview_login_form">
              {/* column with form */}
              <div className="preview-login-container">
                <div className="preview_login_form_header">
                  <img
                    src={!logo ? converge : logo.data}
                    alt={!logo ? 'logo converge' : logo.name}
                  />
                  <strong className="">Ingreso de usuarios</strong>
                </div>
                <div className="preview_login_form_body">
                  <Input type="text" name="email" label="E-Mail" />
                  <Input name="password" label="contrasena" type="password" />
                  <button
                    style={{
                      backgroundColor: loginPrimaryColor,
                    }}
                  >
                    Iniciar sesión
                  </button>
                  <a style={{ color: loginPrimaryColor }}>
                    ¿Olvidaste tu contraseña?
                  </a>
                  <div className="acceso__terminos terms__text">
                    Al ingresar, estás aceptando los{' '}
                    <a style={{ color: loginPrimaryColor }}>
                      Términos de Servicio y Privacidad{' '}
                    </a>
                    de uso de Chileconverge.
                  </div>
                </div>
              </div>
              <img src={converge} alt="logo-converge" />
            </div>
            {/* column with description */}
            <div className="preview_login_description">
              <img src={workChat} />
              {description && (
                <div dangerouslySetInnerHTML={{ __html: description }} />
              )}
              <ul>
                <li>
                  <Check
                    style={{ color: loginSecondaryColor, marginRight: '10px' }}
                  />
                  Acompañamiento de mentores especialistas que te ayudarán en la
                  toma de decisiones de tu negocio.
                </li>
                <li>
                  <Check
                    style={{ color: loginSecondaryColor, marginRight: '10px' }}
                  />
                  Cursos de formación para apoyar tu crecimiento.
                </li>
                <li>
                  <Check
                    style={{ color: loginSecondaryColor, marginRight: '10px' }}
                  />
                  Acceder a lista tienda donde podrás crear tu tienda digital de
                  forma simple y segura.
                </li>
              </ul>
              <p>¡Súmate a nuestra red!</p>
            </div>
          </section>
        </main>
      </div>
    </Modal>
  );
};

export default LoginModalPreview;
