import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useField, ErrorMessage, useFormikContext } from 'formik';

const Card = ({
  label,
  extra,
  type,
  customValidation,
  isRequired,
  options,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const [active, setActive] = useState('');
  useEffect(() => {
    if (field.value) {
      setActive(field.value);
    }
  }, [field.value]);
  return (
    <div className="input__container">
      <label>
        {label}
        {isRequired ? null : ' (Opcional)'}
      </label>
      <div className="input__container--cards">
        {options.map((option, index) => {
          return (
            <div
              key={index}
              className={
                active === option.split(',')[0]
                  ? `card__item active card${index}`
                  : `card__item card${index}`
              }
              onClick={() => {
                setFieldValue(field.name, option.split(',')[0]);
                setActive(option.split(',')[0]);
              }}
            >
              <h2>{option.split(',')[0]}</h2>
              <p>{option.split(',')[1]}</p>
            </div>
          );
        })}
      </div>
      <input
        type={type}
        name={props.name}
        value={field.value}
        hidden={true}
        readOnly={true}
      />
      {meta.touched && meta.error && (
        <div className="error-message">
          <ErrorMessage
            className="error-message"
            name={props.id || props.name}
          />
        </div>
      )}
      {customValidation ? (
        <div className="error-message">
          <p>{customValidation}</p>
        </div>
      ) : null}
      {!meta.error && <p className="extra-message">{extra}</p>}
    </div>
  );
};

Card.propTypes = {
  label: PropTypes.string.isRequired,
  extra: PropTypes.string,
  type: PropTypes.string,
  customValidation: PropTypes.string,
  options: PropTypes.array,
  isRequired: PropTypes.bool,
};

export default Card;
