import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CopyElement = ({ tooltipText, copyText }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCopyClick = () => {
    try {
      navigator.clipboard.writeText(copyText);

      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error al copiar: ', error);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="tooltip">
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          ¡El enlace se ha copiado exitosamente!
        </Alert>
      </Snackbar>
      <span
        className="material-symbols-outlined copy-item"
        onClick={handleCopyClick}
      >
        content_copy
      </span>

      <span className="tooltiptext">{tooltipText}</span>
    </div>
  );
};

export default CopyElement;
