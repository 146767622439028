import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ModalMaterial from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';

function getModalStyle() {
  return {
    top: `50%`,
    left: `51%`,
    transform: `translate(-50%, -51%)`,
  };
}

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 700,
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      outline: 0,
    },
  })
);

//This component renders a button that throws the modal
const Modal = ({
  buttonName,
  type = 'button',
  title,
  content,
  onOpen,
  onChange,
}) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    onOpen !== undefined && setOpen(onOpen);
  }, [onOpen]);

  const handleOpen = () => {
    setOpen(true);
    onChange(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <button className={type} type="button" onClick={handleOpen}>
        <span>{buttonName}</span>
      </button>
      <ModalMaterial
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <div className="modal">
            <div className="modal__title">
              <h3 className="title">{title}</h3>
              <button className="title__close" onClick={handleClose}>
                <CloseIcon className="close__icon" />
              </button>
            </div>
            {content}
          </div>
        </div>
      </ModalMaterial>
    </>
  );
};

Modal.propTypes = {
  buttonName: PropTypes.string.isRequired,
  type: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.node,
};

export default Modal;
