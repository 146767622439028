import { Modal } from '@material-ui/core';
import React from 'react';
import Button from '../../components/common/Button';
import ModalContent from '../../components/common/Modal/ModalContent';

const ConfirmationModal = ({
  title,
  onClose,
  handleAccept,
  handleDenied,
  open,
  header = 'Activar cliente',
}) => {
  return (
    <Modal open={open} onClose={onClose} className="modal">
      <ModalContent title={header} onClick={onClose}>
        <span className="title">{title}</span>
        <div className="modal__action-container">
          <Button type="button" secondary onClick={handleDenied}>
            No
          </Button>
          <Button type="button" onClick={handleAccept}>
            Si
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};
export default ConfirmationModal;
