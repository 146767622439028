import React, { useState } from 'react';
import { Form, Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from '../../../components/forms/Select';
import Button from '../../../components/common/Button';
import Alert from '../../../components/common/Alert';
import { Grid, Paper } from '@material-ui/core';
import { ImageSearch, MailOutlineOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import MassiveMailsAPI from '../massive-mails-api';
import Divider from '@material-ui/core/Divider';
import Input from '../../../components/forms/Input';

const MassiveMailCreationContainer = () => {
  const history = useHistory();
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const [preview, setPreview] = useState({ value: '', type: '' });
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (values) => {
    setIsLoading(true);
    MassiveMailsAPI.addMasiveMail(values)
      .then((response) => {
        history.push({
          pathname: '/correos',
          state: {
            message: response.data.message,
            type: 'success',
          },
        });
      })
      .then(() => setIsLoading(false))
      .catch((e) => {
        setIsLoading(false);
        setShowAlert(true);
        setMessageAlert(e.data.message);
      });
  };

  const handleFileRead = (files) => {
    return new Promise((resolve) => {
      Array.from(files).forEach((archivo) => {
        var reader = new FileReader();
        reader.readAsDataURL(archivo);
        reader.onload = () => {
          setPreview({ value: reader.result, type: 'image' });
          resolve({ name: files[0].name, data: reader.result });
        };
      });
    });
  };

  return (
    <Formik
      initialValues={{
        subject: '',
        type: '',
        userType: '',
        text: '',
        image: {},
      }}
      validationSchema={Yup.object({
        subject: Yup.string().required('Asunto requerido.'),
        type: Yup.string().required('Tipo requerido.'),
        userType: Yup.string().required('Seleccione destinatario.'),
        text: Yup.string().required('No puede enviar un correo vacío.'),
        image: Yup.string().required('No puede enviar un correo vacío.'),
      })}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values }) => (
        <Form>
          <Grid className="mail-creation-container" container spacing={3}>
            <Grid item xs={12}>
              {showAlert && <Alert type="error">{messageAlert}</Alert>}
            </Grid>
            <Grid item xs={5}>
              <Paper style={{ padding: '20px' }}>
                <Grid
                  item
                  style={{
                    fontWeight: 'bold',
                    padding: '5px 0px 16px 0px',
                    display: 'flex',
                  }}
                >
                  <MailOutlineOutlined
                    style={{ marginRight: '10px' }}
                    fontSize="small"
                  />
                  <span>Crear Correo</span>
                </Grid>
                <Divider />
                <Grid item>
                  <Grid container direction="column" className="personal-info">
                    <Grid item style={{ marginTop: '12px' }}>
                      <span>
                        <strong>Asunto</strong>
                      </span>
                      <Input name="subject" placeholder="Asunto" label="" />
                    </Grid>
                    <Grid item xs={12}>
                      <span>
                        <strong>Destinatarios</strong>
                      </span>
                      <Select
                        label="Selecciona Destinatarios"
                        name="userType"
                        options={[]}
                        advancedOptions={[
                          { value: 'Mentores', id: 'mentor' },
                          { value: 'Mentees', id: 'mentee' },
                          { value: 'Administradores', id: 'admin' },
                          { value: 'Ambos', id: 'both' },
                        ]}
                        isRequired={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <span>
                        <strong>Tipo de Correo</strong>
                      </span>
                      <Select
                        label="Selecciona tipo de correo"
                        name="type"
                        options={[]}
                        advancedOptions={[
                          { value: 'Texto', id: 'string' },
                          { value: 'HTML', id: 'html' },
                          { value: 'Imagen', id: 'image' },
                        ]}
                        isRequired={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {values.type === 'string' || values.type === 'html' ? (
                        <>
                          <span>
                            <strong>Ingrese Mensaje </strong>
                          </span>
                          <Input
                            name="text"
                            placeholder="Ingrese Mensaje"
                            type="textarea"
                            showCounter
                            isRequired={true}
                          />

                          <Grid item xs={4} style={{ marginBottom: '20px' }}>
                            <div
                              onClick={() =>
                                setPreview({
                                  value: values.text,
                                  type: values.type,
                                })
                              }
                            >
                              <h4 className="Filter-control">
                                <ImageSearch
                                  style={{ marginRight: '8px' }}
                                  fontSize="small"
                                />
                                Vista Previa
                              </h4>
                            </div>
                          </Grid>
                        </>
                      ) : (
                        values.type === 'image' && (
                          <>
                            <span>
                              <strong>Ingrese Mensaje </strong>
                            </span>
                            <input
                              type="file"
                              name="image"
                              onChange={async (e) => {
                                const base64 = await handleFileRead(
                                  e.target.files
                                );
                                values.image = base64;
                                values.text = 'none';
                              }}
                              accept="image/*"
                            />
                            <ErrorMessage
                              name="image"
                              render={(msg) => (
                                <div style={{ color: '#e53e3e' }}>{msg}</div>
                              )}
                            />
                          </>
                        )
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />

                <Grid container style={{ marginTop: '20px' }}>
                  <Grid item xs={12}>
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      spacing={3}
                    >
                      <Grid item xs={4}>
                        <Button
                          onClick={() => history.push('/correos')}
                          secondary
                          widthFull
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button type="submit" widthFull loading={isLoading}>
                          Aceptar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={7}>
              <Paper>
                <Grid
                  item
                  className={`preview-title${preview.value && '-on'}`}
                  style={{
                    fontWeight: 'bold',
                    padding: '20px',
                    display: 'flex',
                  }}
                >
                  <ImageSearch
                    style={{ marginRight: '4px' }}
                    fontSize="small"
                  />
                  <span>Vista Previa</span>
                </Grid>
                <Divider />
                <Grid
                  container
                  className="preview-grid"
                  justify="center"
                  alignItems="center"
                >
                  {values.type === 'image' && !preview.value ? (
                    <ImageSearch className="preview-logo" fontSize="large" />
                  ) : (
                    ''
                  )}
                  {preview.type === 'html' ? (
                    <div dangerouslySetInnerHTML={{ __html: preview.value }} />
                  ) : preview.type === 'string' ? (
                    `${preview.value}`
                  ) : preview.type === 'image' ? (
                    <img
                      src={preview.value}
                      alt="Email"
                      className="preview-image"
                    />
                  ) : (
                    ''
                  )}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default MassiveMailCreationContainer;
