import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import Select from '../../../../components/forms/Select';
import CalendarTodayOutlined from '@material-ui/icons/CalendarTodayOutlined';
import Button from '../../../../components/common/Button';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import UsersAPI from '../../user-api';
import Alert from '../../../../components/common/Alert';
import Skeleton from 'react-placeholder';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from '../userContext';
import { Input } from '../../../../components/forms';
import ControlPointSharpIcon from '@material-ui/icons/ControlPointSharp';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '0 none',
    padding: '40 0',
  },
}));

const SetSessions = () => {
  const history = useHistory();
  const location = useLocation();
  const { clientId } = location.state;
  const time = moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm');

  const [state, dispatch] = useContext(UserContext);
  const { user, mentors, futureSessions } = state;

  //set mentor options
  const mentorList = mentors.map((mentor) => ({
    id: mentor.id,
    value: mentor.name,
  }));

  const veces = Array.from({ length: 16 }, (x, i) => {
    if (i === 0) {
      return { value: `${i + 1} vez`, id: i + 1 };
    } else {
      return { value: `${i + 1} veces`, id: i + 1 };
    }
  });

  const dias = Array.from({ length: 15 }, (x, i) => {
    if (i === 0) {
      return { value: `${i + 1} dia`, id: i + 1 };
    } else {
      return { value: `${i + 1} dias`, id: i + 1 };
    }
  });
  const [message, setMessage] = useState({});
  const [messageDates, setMessageDates] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [initialHour, setInitialHour] = useState(null);
  const [initialDate, setInitialDate] = useState(null);
  const [initialTime, setInitialTime] = useState(time);
  const [inputVeces, setInputVeces] = useState(0);
  const [inputDias, setInputDias] = useState(0);
  const [mentor, setMentor] = useState(0);
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [calculated, setCalculated] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [submit1, setSubmit1] = useState(false);
  const [guestEmails, setGuestEmails] = useState([]);
  const [alertType, setAlertType] = useState('success');
  const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });
  const [openModal, setOpenModal] = useState(false);
  const classes = useStyles();

  //schedule resume
  const initialSchedules = () => {
    setMessageDates([]);
    setCalculated(true);
    if (validation()) {
      const schedulesArray = Array.from({ length: inputVeces }, (x, i) => {
        if (!i) {
          return moment(initialTime).format('YYYY-MM-DD HH:mm');
        } else {
          return moment(initialTime)
            .add(parseInt(inputDias * i), 'days')
            .format('YYYY-MM-DD HH:mm');
        }
      });
      dispatch({
        type: 'UPDATE_FUTURE_SESSIONS',
        payload: {
          futureSessions: {
            schedules: schedulesArray,
            userId: user.id,
            mentorId: mentor,
            subject,
            description,
            notify: false,
            guestEmails,
          },
        },
      });

      UsersAPI.getBusySchedule(mentor, user.id).then((response) => {
        schedulesArray.forEach((schedule, index) => {
          response.data.forEach((date) => {
            if (
              moment(schedule).format('YYYY-MM-DD HH:mm') ===
              moment(date.date).format('YYYY-MM-DD HH:mm')
            ) {
              let messageD = messageDates;
              messageD[index] = date.message;
              setMessageDates(messageD);
            }
          });
        });
        setCalculated(false);
      });
    }
  };

  const validation = () => {
    setMessage({});
    const actualDate = moment().subtract(1, 'hour');
    let validate = true;
    let validateMessage = {};
    if (initialTime) {
      if (!moment(initialTime).isAfter(actualDate.format('YYYY-MM-DDTHH:mm'))) {
        validate = false;
        validateMessage.initialTime =
          'La fecha seleccionada no puede ser anterior a la fecha actual';
        setMessage(validateMessage);
      }
    }
    if (!initialHour) {
      validate = false;
      validateMessage.initialHour = 'Ingrese Hora';
      setMessage(validateMessage);
    }
    if (!initialDate) {
      validate = false;
      validateMessage.initialDate = 'Ingrese Fecha';
      setMessage(validateMessage);
    }
    if (mentor === 0) {
      validate = false;
      validateMessage.mentor = 'Debe seleccionar un mentor';
      setMessage(validateMessage);
    }
    if (inputVeces === 0) {
      validate = false;
      validateMessage.inputVeces = 'Valor Requerido';
      setMessage(validateMessage);
    }
    if (inputDias === 0) {
      validate = false;
      validateMessage.inputDias = 'Valor Requerido';
      setMessage(validateMessage);
    }
    if (subject.localeCompare('') === 0) {
      validate = false;
      validateMessage.subject = 'Debe ingresar asunto';
      setMessage(validateMessage);
    }
    if (description.localeCompare('') === 0) {
      validate = false;
      validateMessage.description = 'Ingrese una pequeña descripción';
      setMessage(validateMessage);
    }
    if (!validate) {
      setCalculated(false);
    }
    return validate;
  };

  const handleSubmit = (notify) => {
    if (validation() && !messageDates.length) {
      if (notify) {
        setSubmit(true);
      } else {
        setSubmit1(true);
      }

      UsersAPI.createMassiveSessions({
        calendar: futureSessions.schedules,
        idMentee: user.id,
        idMentor: mentor,
        title: subject,
        description,
        notify,
        clientId,
        guestEmails,
      })
        .then((response) => {
          dispatch({
            type: 'UPDATE_FUTURE_SESSIONS',
            payload: {
              futureSessions: {},
            },
          });
          setAlertMessage({ type: 'success', message: response.data.message });
          setOpenModal(false);
        })
        .catch((e) => {
          setAlertMessage({ type: 'error', message: e.data.message });
        })
        .finally(() => {
          setSubmit(false);
          setSubmit1(false);
        });
    }
  };

  const changeInitialTime = (date, time) => {
    let oldTime = initialTime.split(' ')[1];
    if (!time) {
      if (oldTime) {
        setInitialTime(date + ' ' + oldTime);
      } else {
        setInitialTime(date + ' 00:00');
      }
      setInitialDate(date);
    } else {
      setInitialHour(time);
      setInitialTime(moment(initialTime).format('YYYY-MM-DD') + ' ' + time);
    }
  };
  const addGuest = (values) => {
    try {
      if (
        guestEmails.length < 4 &&
        values.guestEmail &&
        !guestEmails.includes(values.guestEmail)
      ) {
        setGuestEmails([...guestEmails, values.guestEmail]);
      } else if (guestEmails.length >= 4) {
        setAlertMessage(
          'Se ha alcanzado el límite máximo de correos electrónicos.'
        );
        setAlertType('error');
        setTimeout(() => {
          setAlertMessage('');
        }, 3000);
      }
    } catch (error) {}
  };
  const removeGuestEmail = (index) => {
    const updatedGuestEmails = [...guestEmails];
    updatedGuestEmails.splice(index, 1);
    setGuestEmails(updatedGuestEmails);
  };
  const changeIndividualTime = (key, date, time) => {
    let schedulesArray = futureSessions.schedules;
    const actualDate = moment().subtract(1, 'hour');
    let newDate = '';
    let oldDate = schedulesArray[key].split(' ')[0];
    let oldTime = schedulesArray[key].split(' ')[1];
    if (date) {
      schedulesArray[key] = moment(date).format('YYYY-MM-DD') + ' ' + oldTime;
      newDate = moment(date).format('YYYY-MM-DD') + ' ' + oldTime;
    }
    if (time) {
      schedulesArray[key] = moment(oldDate).format('YYYY-MM-DD') + ' ' + time;
      newDate = moment(oldDate).format('YYYY-MM-DD') + ' ' + time;
    }
    let messageD = [];
    if (!moment(newDate).isAfter(actualDate.format('YYYY-MM-DDTHH:mm'))) {
      messageD[key] = 'La fecha ingresada no puede ser menor a la actual.';
    }
    UsersAPI.getBusySchedule(mentor, user.id).then((response) => {
      schedulesArray.forEach((schedule, index) => {
        response.data.forEach((date) => {
          if (
            moment(schedule).format('YYYY-MM-DD HH:mm') ===
            moment(date.date).format('YYYY-MM-DD HH:mm')
          ) {
            messageD[index] = date.message;
          }
        });
      });
    });
    setMessageDates(messageD);
    setSchedules(schedulesArray);
    dispatch({
      type: 'UPDATE_FUTURE_SESSIONS',
      payload: {
        futureSessions: {
          schedules: schedulesArray,
          userId: user.id,
          mentorId: mentor,
          subject,
          description,
          notify: false,
        },
      },
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        mentorId: mentor,
        dias: inputDias,
        veces: inputVeces,
      }}
      validationSchema={Yup.object({
        mentor: Yup.string().required(
          'Seleccione mentor para asignar reuniones'
        ),
        guestEmail: Yup.string().required('Ingrese un correo electrónico'),
      })}
    >
      {({ values }) => (
        <Form>
          {openModal && (
            <Modal
              className={classes.modal}
              open={openModal}
              onClose={() => {
                setOpenModal(false);
              }}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div className="cancel-session notificacion">
                <h3>¿Desea notificar a los participantes?</h3>
                <div className="info__button">
                  <div className="save-area">
                    <Grid container justify="space-around">
                      <Grid item xs={5}>
                        <Button
                          widthFull
                          secondary
                          loading={submit1}
                          onClick={() => {
                            handleSubmit(false);
                          }}
                        >
                          No, gracias
                        </Button>
                      </Grid>
                      <Grid item xs={5}>
                        <Button
                          widthFull
                          type="submit"
                          onClick={() => handleSubmit(true)}
                          loading={submit}
                        >
                          Si
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </Modal>
          )}
          {mentorList.length === 0 ? (
            <Grid container>
              <Grid item xs={12}>
                <Skeleton showLoadingAnimation type="text" rows={15} />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid container spacing={4} className="future-sessions">
                <Grid item xs={12}>
                  {alertMessage.message && (
                    <Alert type={alertMessage.type}>
                      {alertMessage.message}
                    </Alert>
                  )}
                  <h3>Agendar reuniones futuras</h3>
                </Grid>
                <Grid item className="future-sessions-column" xs={12} sm={5}>
                  <h4>Mentor</h4>
                  <Select
                    name="mentorId"
                    label="Nombre Mentor"
                    type="select"
                    options={[]}
                    advancedOptions={mentorList}
                    placeholder="Nombre Mentor"
                    onChange={(event) => {
                      setMentor(event.target.value);
                    }}
                  />
                  {message.mentor && (
                    <div className="error-message">{message.mentor}</div>
                  )}
                  <Grid item xs={12}>
                    <h4>Seleccionar día y hora inicial</h4>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6} className="date-grid">
                      <TextField
                        className="text-input"
                        id="date"
                        type="date"
                        label="  "
                        defaultValue={initialTime}
                        onChange={(event) => {
                          changeInitialTime(
                            moment(event.target.value).format('YYYY-MM-DD'),
                            ''
                          );
                        }}
                      />
                      {message.initialDate && (
                        <div
                          className="error-message"
                          style={{ marginTop: '20px' }}
                        >
                          {message.initialDate}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={6} className="hour1-grid">
                      <Select
                        name="hora"
                        placeholder="Hora"
                        label="Hora"
                        type="select"
                        options={[]}
                        advancedOptions={[
                          { id: 8, value: '08:00' },
                          { id: 9, value: '09:00' },
                          { id: 10, value: '10:00' },
                          { id: 11, value: '11:00' },
                          { id: 12, value: '12:00' },
                          { id: 13, value: '13:00' },
                          { id: 14, value: '14:00' },
                          { id: 15, value: '15:00' },
                          { id: 16, value: '16:00' },
                          { id: 17, value: '17:00' },
                          { id: 18, value: '18:00' },
                          { id: 19, value: '19:00' },
                          { id: 20, value: '20:00' },
                          { id: 21, value: '21:00' },
                        ]}
                        onChange={(event) => {
                          changeInitialTime('', event.target.value + ':00');
                        }}
                      />
                      {message.initialHour && (
                        <div className="error-message">
                          {message.initialHour}
                        </div>
                      )}
                    </Grid>
                    {message.initialTime && (
                      <Grid item xs={12}>
                        <div className="error-message">
                          {message.initialTime}
                        </div>
                      </Grid>
                    )}
                  </Grid>
                  <h4>Repetir</h4>
                  <Grid container>
                    <Grid item xs={5}>
                      <Select
                        name="veces"
                        label=""
                        type="select"
                        options={[]}
                        advancedOptions={veces}
                        placeholder="Nombre Mentor"
                        onChange={(event) => setInputVeces(event.target.value)}
                      />
                      {message.inputVeces && (
                        <div className="error-message">
                          {message.inputVeces}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'center' }}>
                      <h4 style={{ marginTop: '12px' }}>Cada</h4>
                    </Grid>
                    <Grid item xs={5}>
                      <Select
                        name="dias"
                        label=""
                        type="select"
                        options={[]}
                        advancedOptions={dias}
                        placeholder="Nombre Mentor"
                        onChange={(event) => {
                          setInputDias(event.target.value);
                        }}
                      />
                      {message.inputDias && (
                        <div className="error-message">{message.inputDias}</div>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <h4>Asunto</h4>
                      <TextField
                        className="text-input"
                        name="subject"
                        type="text"
                        value={subject}
                        onChange={(event) => {
                          setSubject(event.target.value);
                        }}
                      />
                      {message.subject && (
                        <div className="error-message">{message.subject}</div>
                      )}
                    </Grid>
                    <Grid item xs={12} className="description-box">
                      <h4>Descripción</h4>
                      <TextField
                        rows={4}
                        multiline
                        className="text-input"
                        name="description"
                        label="Ingrese descripción"
                        type="textarea"
                        value={description}
                        onChange={(event) => {
                          setDescription(event.target.value);
                        }}
                      />
                      {message.description && (
                        <div className="error-message">
                          {message.description}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} className="description-box">
                      <h4>Agregar invitado a mentoría</h4>
                      <p style={{ color: '#11111180', padding: '10px' }}>
                        Ingresa el correo del invitado para notificar <br /> su
                        invitación a la mentoría
                      </p>
                      <Input
                        label="usuario@mail.com"
                        name="guestEmail"
                        type="email"
                      />

                      <div
                        style={{
                          margin: '10px 0',
                          color: '#0BB5E7',
                          display: 'flex',
                          cursor: 'pointer',
                        }}
                        onClick={() => addGuest(values, guestEmails)}
                      >
                        <ControlPointSharpIcon />
                        Agregar invitado
                      </div>
                      <ul>
                        {guestEmails.map((email, index) => (
                          <div className="modal__header" key={index}>
                            {email}
                            <span
                              className="material-icons icon"
                              onClick={() => removeGuestEmail(index)}
                            >
                              close
                            </span>
                          </div>
                        ))}
                      </ul>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        onClick={() => initialSchedules()}
                        primary
                        widthFull
                        loading={calculated}
                      >
                        Ver Horarios
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className="future-sessions-column" xs={12} sm={7}>
                  <div className="future-sessions-subtitle">
                    <h4 className="subtitle">Fecha y hora programada</h4>
                  </div>
                  {/* future sessions summary */}
                  {!calculated && futureSessions.schedules?.length ? (
                    <Grid className="shedules-box">
                      {futureSessions &&
                        futureSessions?.schedules?.map((schedule, key) => {
                          return (
                            <Grid container key={key}>
                              <Grid item xs={2} className="name-grid">
                                <h4>Sesión {key + 1}:</h4>
                              </Grid>
                              <Grid item xs={6} className="date-grid">
                                <TextField
                                  className="text-input"
                                  id="date"
                                  type="date"
                                  label=""
                                  defaultValue={schedule.split(' ')[0]}
                                  onChange={(event) => {
                                    changeIndividualTime(
                                      key,
                                      event.target.value,
                                      null
                                    );
                                  }}
                                />
                                {messageDates[key] && (
                                  <div className="error-message error-grid">
                                    {messageDates[key]}
                                  </div>
                                )}
                              </Grid>
                              <Grid item xs={3} className="hour-grid">
                                <Select
                                  name="hora"
                                  placeholder="Hora"
                                  label="Hora"
                                  type="select"
                                  defaultValue={schedule.split(' ')[1]}
                                  options={[]}
                                  onChange={(event) => {
                                    changeIndividualTime(
                                      key,
                                      null,
                                      event.target.value
                                    );
                                  }}
                                  advancedOptions={[
                                    { id: '08:00', value: '08:00' },
                                    { id: '09:00', value: '09:00' },
                                    { id: '10:00', value: '10:00' },
                                    { id: '11:00', value: '11:00' },
                                    { id: '12:00', value: '12:00' },
                                    { id: '13:00', value: '13:00' },
                                    { id: '14:00', value: '14:00' },
                                    { id: '15:00', value: '15:00' },
                                    { id: '16:00', value: '16:00' },
                                    { id: '17:00', value: '17:00' },
                                    { id: '18:00', value: '18:00' },
                                    { id: '19:00', value: '19:00' },
                                    { id: '20:00', value: '20:00' },
                                    { id: '21:00', value: '21:00' },
                                  ]}
                                />
                              </Grid>
                              <Grid item xs={1}></Grid>
                            </Grid>
                          );
                        })}
                    </Grid>
                  ) : (
                    <div className="empty-box">
                      <CalendarTodayOutlined
                        style={{ fontSize: 120 }}
                        color="disabled"
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: '20px' }}>
                <Grid item xs={12}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid item xs={4}>
                      <Button
                        onClick={() => history.push('/usuarios')}
                        secondary
                        widthFull
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        onClick={() => {
                          futureSessions.schedules.length && setOpenModal(true);
                        }}
                        primary
                        widthFull
                      >
                        Confirmar Reuniones
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default SetSessions;
