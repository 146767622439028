import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import TableSite from '../../../components/common/Table';
import Alert from '../../../components/common/Alert';
import BenefitsApi from '../benefit-api';
import { useHistory, useLocation } from 'react-router-dom';

const BenefitTableContainer = () => {
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [benefits, setBenefits] = useState([]);
  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('info');
  let timer;

  useEffect(() => {
    // eslint-disable-next-line
    timer = setTimeout(function () {
      setMessage('');
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (location.state) {
      setMessage(location.state?.message);
      setTypeMessage(location.state?.type);
    }
  }, [location.state]);

  useEffect(() => {
    BenefitsApi.getBenefits().then((response) => {
      setBenefits(() => {
        const benefitList = [];
        response.data.benefit.forEach((benefit) => {
          if (benefit) {
            benefitList.push({
              id: benefit.id,
              title: benefit.title,
              type:
                benefit.benefitType === 'featured'
                  ? 'Destacada'
                  : benefit.benefitType === 'normal'
                  ? 'Normal'
                  : 'Error',
              startDate: moment(parseInt(benefit.startDate)).format('L'),
              endDate: moment(parseInt(benefit.endDate)).format('L'),
              active: benefit.active === true ? 'Activo' : 'Inactivo',
            });
          }
        });
        return benefitList;
      });
      setIsLoading(false);
    });
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Título',
        accessor: 'title',
      },
      {
        Header: 'Tipo',
        accessor: 'type',
      },
      {
        Header: 'Fecha inicio',
        accessor: 'startDate',
      },
      {
        Header: 'Fecha Término',
        accessor: 'endDate',
      },
      {
        Header: 'Estado',
        accessor: 'active',
      },
      {
        Header: '',
        id: 'actions',
        accessor: function fnAccesor({ id }) {
          return (
            <IconButton
              onClick={() => {
                history.push('/beneficios/editar', {
                  benefitId: id,
                });
              }}
            >
              <Edit />
            </IconButton>
          );
        },
        disableSortBy: true,
      },
    ],
    [history]
  );

  return (
    <div className="user-container">
      {message && <Alert type={typeMessage}>{message}</Alert>}
      <TableSite
        data={benefits}
        columns={columns}
        isLoadingData={isLoading}
        tableName="Beneficios"
        buttonLabel="Agregar nuevo Beneficio"
        onClickButton={() => history.push('/beneficios/crear')}
      />
    </div>
  );
};

export default BenefitTableContainer;
