import React, { useEffect, useState } from 'react';

import Stepper from '../../../components/common/stepper';

import Wizard from '../../ClientContainer/ClientCreationContainer/wizard';

import LinkComponent from '../../../components/common/Link';

import CoursesAPI from '../course-api';
import PillarAPI from '../../PillarContainer/pillar-api';
import CourseEditContainer from './course-edit';
import CourseSummary from '../CoursesCreationContainer/steps/course-summary';
import {
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';

import Skeleton from '@material-ui/lab/Skeleton';

const CourseEdit = () => {
  const { courseId } = useParams();

  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [institutions, setInstitutions] = useState([]);
  const [pillars, setPillars] = useState([]);
  const [course, setCourse] = useState({
    name: '',
    estimatedTime: '',
    hash: '',
    pillar: 0,
    active: false,
    hasVideo: false,
    hasReading: false,
    hasDownload: false,
    description: '',
    institution: '',
  });

  const courseSteps = [
    {
      title: 'Informacion del curso',
      Component: CourseEditContainer,
    },
    {
      title: 'Resumen',
      Component: CourseSummary,
    },
  ];
  useEffect(() => {
    PillarAPI.fetchActivePillars()
      .then((response) => {
        setPillars(response.data);
      })
      .catch((e) => {
        console.log(e, 'error');
      });
    CoursesAPI.getInstitution()
      .then((response) => {
        const institutions = response.data.map((institution) => ({
          value: institution.name,
          id: institution.id,
        }));
        setInstitutions(institutions);
      })
      .catch((e) => {
        console.log(e, 'error');
      });
  }, []);

  useEffect(() => {
    if (pillars.length && institutions.length) {
      (async () => {
        try {
          const { data } = await CoursesAPI.getCourse(courseId);

          const foundPillar = pillars.find((p) => +p.id === +data.pillar);

          setCourse((prev) => ({ ...prev, ...data, pillar: foundPillar }));
          setLoading(false);
        } catch (error) {
          console.error('Error al obtener el curso:', error);
        }
      })();
    }
  }, [pillars, institutions]);

  const onNext = () => setActiveStep((prev) => prev + 1);
  const onPrev = () => setActiveStep((prev) => prev - 1);
  const handleFormSubmit = (values) => {
    setCourse(values);

    onNext();
  };

  return (
    <>
      {' '}
      {loading ? (
        <Skeleton />
      ) : (
        <div className="client-container">
          <div className="client__back-container">
            <LinkComponent title="Volver" url="/cursos" icon="arrow_back" />
          </div>
          <Wizard title={'Editar curso'}>
            <div className="wizard__content">
              <div className="wizard__steps-container wizard-padding">
                {courseSteps.map((currentStep, index) => (
                  <Stepper
                    key={index}
                    text={currentStep.title}
                    isActive={index === activeStep}
                    isDone={index < activeStep}
                  />
                ))}
              </div>
              {courseSteps.map((currentStep, index) => {
                const C = currentStep.Component;
                return (
                  index === activeStep && (
                    <C
                      onPrev={onPrev}
                      onNext={onNext}
                      handleFormSubmit={handleFormSubmit}
                      setCourse={setCourse}
                      pillars={pillars}
                      institutions={institutions}
                      course={course}
                      isLoading={loading}
                      key={`step-component-${index}`}
                    />
                  )
                );
              })}
            </div>
          </Wizard>
        </div>
      )}{' '}
    </>
  );
};

export default CourseEdit;
