import { IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import TableSite from '../../../components/common/Table';
import Alert from '../../../components/common/Alert';
import { useHistory, useLocation } from 'react-router-dom';
import PillarAPI from '../pillar-api';

const PillarTableContainer = () => {
  const location = useLocation();
  const history = useHistory();
  const [pillars, setPillars] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('info');
  let timer;

  useEffect(() => {
    // eslint-disable-next-line
    timer = setTimeout(function () {
      setMessage('');
    }, 5000);

    PillarAPI.getPillars().then((response) => {
      setPillars(() =>
        response.data.map((pillar) => {
          return {
            id: pillar.id,
            name: pillar.name,
            description: pillar.description,
            bgColor: !pillar.active ? 'error' : 'default',
          };
        })
      );
      setIsLoading(false);
    });

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    setMessage(location.state && location.state.message);
    setTypeMessage(location.state && location.state.type);
  }, [location.state]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'name',
      },
      {
        Header: 'Descripcion',
        accessor: 'description',
      },
      {
        Header: '',
        id: 'actions',
        accessor: function ({ id }) {
          return (
            <IconButton
              onClick={() => {
                history.push('/pilares/editar', {
                  pillarId: id,
                });
              }}
            >
              <Edit />
            </IconButton>
          );
        },
        disableSortBy: true,
      },
    ],
    [history]
  );
  return (
    <div className="user-container">
      {message && <Alert type={typeMessage}>{message}</Alert>}
      <TableSite
        data={pillars}
        columns={columns}
        isLoadingData={isLoading}
        tableName="Pilares"
        buttonLabel="Agregar nuevo pilar"
        onClickButton={() => history.push('/pilares/crear')}
      />
    </div>
  );
};

export default PillarTableContainer;
