import React, { useEffect, useState, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import BoxParticipants from '../../../../components/common/BoxParticipants';
import Modal from '../../../../components/common/Modal';
import { AppContext } from '../../../../app/AppContext';
import Alert from '../../../../components/common/Alert';
import AssignMentor from '../AssignMentor';
import SessionAPI from '../../session-api';
import request from '../../../../utils/request';

const InfoParticipants = ({
  type,
  personalData,
  notes,
  polls,
  idSession,
  statusSession,
  onChange,
}) => {
  const history = useHistory();
  const {
    loader,
    settings: { settings },
  } = useContext(AppContext);
  const [myNotes, setMyNotes] = useState([]);
  const [myPoll, setMyPoll] = useState([]);
  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('info');
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState({ mentor: [], mentee: [] });

  let survey = [];
  let mentor = [];
  let mentee = [];
  mentor['question1'] = '¿Cómo calificarías la videollamada?';
  mentor['question2'] = '¿Cómo calificarías la reunión?';
  mentor['question3'] = '¿Qué mejorarias de esta experiencia de mentoría?';
  mentor['question5'] = '¿Qué nueva categoría nos sugieres integrar?';
  survey['mentor'] = mentor;
  mentee['question1'] = '¿Cómo calificarías la videollamada?';
  mentee['question2'] = '¿Cómo calificarías la ayuda brindada por el Mentor?';
  mentee['question6'] =
    '¿Qué tan probable es que recomiendes este servicio a otros emprendedores?';
  mentee['question3'] = '¿Qué mejorarías de esta experiencia de mentoría?';
  mentee['question4'] =
    '¿Se respondieron todas tus preguntas en la reunión? ¿cuáles no?';
  mentee['question5'] = '¿Qué nueva categoría nos sugieres integrar?';
  survey['mentee'] = mentee;

  useEffect(() => {
    request({
      url: '/poll/all',
      method: 'GET',
      private: true,
    }).then(({ data }) => {
      for (let i = 0; data.length > i; i++) {
        if (data[i].role === 'mentee' || data[i].role === 'both') {
          setQuestions((prevQuestions) => ({
            ...prevQuestions,
            mentee: [...prevQuestions.mentee, data[i]],
          }));
        } else {
          setQuestions((prevQuestions) => ({
            ...prevQuestions,
            mentor: [...prevQuestions.mentor, data[i]],
          }));
        }
      }
    });
  }, []);

  useEffect(() => {
    let arrayNoteAux = [];
    let arrayPollAux = [];
    notes.map((note) => {
      if (note.user === personalData.id) {
        arrayNoteAux.push(note);
      }
      return arrayNoteAux;
    });
    setMyNotes(arrayNoteAux);
    polls.map((poll) => {
      if (poll.user === personalData.id) {
        arrayPollAux.push(poll);
      }
      return arrayPollAux;
    });
    setMyPoll(arrayPollAux);
  }, [notes, personalData.id, polls]);

  const handleClickUser = () => {
    return history.push('/usuarios/editar', {
      idUser: personalData.id,
      roleType: personalData.role.type,
    });
  };

  const handleAssignMentor = (payload) => {
    loader.actions.show('Cargando...');
    setLoading(true);
    SessionAPI.assignMentor(idSession, payload)
      .then((response) => {
        loader.actions.hide();
        setLoading(false);
        setTypeMessage('success');
        setMessage(response.data.message);
        onChange('success', '');
      })
      .catch((e) => {
        loader.actions.hide();
        setLoading(false);
        setTypeMessage('error');
        setMessage(e.data.message);
      });
  };

  const handleChangeMentor = (payload) => {
    setLoading(true);
    SessionAPI.assignDate(idSession, payload.idSchedule)
      .then((response) => {
        setLoading(false);
        setTypeMessage('success');
        setMessage(response.data.message);
        onChange('success', '');
        handleChangeShowModal(false);
      })
      .catch((e) => {
        setLoading(false);
        setTypeMessage('error');
        setMessage(e.data.message);
      });
  };

  const handleChangeShowModal = (bool) => {
    setShowModal(bool);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {message && <Alert type={typeMessage}>{message}</Alert>}
        {personalData && personalData.name ? (
          <>
            <div className="info-participants__personal">
              <div className="info-participants__personal--title">
                <h3>Datos del {type}</h3>{' '}
                {!settings.autoAssignSession && type === 'mentor' && (
                  <Modal
                    buttonName="Editar mentor"
                    type="link"
                    title="Seleccionar nuevo mentor"
                    onOpen={showModal}
                    onChange={handleChangeShowModal}
                    content={
                      <div className="assign-mentor__modal">
                        <AssignMentor
                          personalData={personalData}
                          type={type}
                          statusSession={statusSession}
                          onSubmit={handleChangeMentor}
                          currentMentor={personalData.id}
                          loading={loading}
                        />
                      </div>
                    }
                  />
                )}
              </div>
              {personalData.name ? (
                <BoxParticipants
                  handleClick={() => {
                    handleClickUser();
                  }}
                  name={personalData.name}
                />
              ) : (
                <p>Sin Mentor Asignado</p>
              )}
              {personalData.bio && (
                <p>
                  <strong>Biografía: </strong> {personalData.bio}
                </p>
              )}
              {personalData.linkedin && (
                <p>
                  <strong>LinkedIn: </strong>{' '}
                  <Link to={personalData.linkedin}>
                    {personalData.linkedin}
                  </Link>
                </p>
              )}
            </div>
            <div className="info-participants__notes">
              <h3>Apuntes de la reunión</h3>
              {myNotes.length > 0 ? (
                myNotes.map((note) => {
                  return <p key={note.id}>{'- ' + note.text}</p>;
                })
              ) : (
                <p>Sin Apuntes</p>
              )}
            </div>
            <div className="info-participants__poll">
              <h3>Datos de encuesta</h3>
              {myPoll.length > 0 ? (
                myPoll.map((poll) => {
                  const question = questions[type].find(
                    (q) => q.id === poll.pollQuestion
                  );
                  return (
                    <p key={poll.id}>
                      {question && (
                        <>
                          <strong>{question.title}</strong>
                          <br />{' '}
                          {question.type === 'boolean'
                            ? poll.answer
                              ? 'Si'
                              : 'No'
                            : poll.answer}
                        </>
                      )}
                    </p>
                  );
                })
              ) : (
                <p>Sin información</p>
              )}
            </div>
          </>
        ) : (
          <>
            {type === 'mentee' ? (
              <div className="info-participants__personal">
                <h3>Datos del {type} </h3>
                <p>Sin mentee asociado.</p>
              </div>
            ) : (
              <div className="info-participants__personal">
                {(statusSession === 'not-assigned' ||
                  statusSession === 'created') &&
                !settings.autoAssignSession ? (
                  <>
                    <h3>Designar Mentor</h3>
                    <AssignMentor
                      personalData={personalData}
                      type={type}
                      statusSession={statusSession}
                      onSubmit={handleAssignMentor}
                      loading={loading}
                    />
                  </>
                ) : (
                  <>
                    <h3>Datos del {type} </h3>
                    <p>Sin mentor asociado.</p>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default InfoParticipants;
