import React, { useContext } from 'react';
import { AppContext } from '../../../app/AppContext';

const Loader = () => {
  const { loader } = useContext(AppContext);

  return (
    loader.show && (
      <div className="loader__container">
        <div className="loader__content">
          <div className="loader__spin"></div>
          <p>{loader.message}</p>
        </div>
      </div>
    )
  );
};

export default Loader;
