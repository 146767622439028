import React, { useContext, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { InfoOutlined } from '@material-ui/icons';
import InputWrapper from '../../../../components/forms/Input/inputWrapper';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertFromHTML,
  ContentState,
  convertToRaw,
} from 'draft-js';
import Button from '../../../../components/common/Button';
import { ACTION } from '../../../../reducer/projectReducer';
import LoginModalPreview from './LoginModalPreview';
import { ProjectContext } from '../../projectContext';
import InputColor from './../../../../components/forms/Input/InputColor';
import draftToHtml from 'draftjs-to-html';
import {
  YupEditorMaxLengthValidator,
  YupEditorMinLengthValidator,
} from '../../../../utils/validators';

const ALLOWED_DESCRIPTION_CHAR = 350;

const LoginForm = ({ onPrev, onNext }) => {
  YupEditorMaxLengthValidator();
  YupEditorMinLengthValidator();
  const [state, dispatch] = useContext(ProjectContext);

  const { login } = state;
  const [open, setOpen] = useState(false);

  const defaultContentLogin = login.description;
  const blockFromHTML = convertFromHTML(defaultContentLogin);
  const content = ContentState.createFromBlockArray(blockFromHTML);

  const [textEditor, setTextEditor] = useState(
    EditorState.createWithContent(content)
  );

  const schema = Yup.object().shape({
    loginPrimaryColor: Yup.string().required(
      'Debes ingresar un color primario'
    ),
    loginSecondaryColor: Yup.string().required(
      'Debes ingresar un color secundario'
    ),
    description: Yup.string()
      .editorMinLengthValidator(
        1,
        'Debes ingresar un mensaje para el login diferenciado'
      )
      .editorMaxLengthValidator(
        ALLOWED_DESCRIPTION_CHAR,
        `El largo máximo para la descripción son de ${ALLOWED_DESCRIPTION_CHAR} caracteres`
      )
      .required('Debes ingresar un mensaje para el login diferenciado'),
  });

  const onSubmit = (values) => {
    dispatch({
      type: ACTION.UPDATE_LOGIN,
      payload: {
        login: {
          ...values,
          description: draftToHtml(
            convertToRaw(textEditor.getCurrentContent())
          ),
        },
      },
    });
    onNext();
  };

  const handlePreview = (values) => {
    dispatch({
      type: ACTION.UPDATE_LOGIN,
      payload: {
        login: {
          ...values,
          description: draftToHtml(
            convertToRaw(textEditor.getCurrentContent())
          ),
        },
      },
    });
    setOpen(true);
  };

  const handlePrev = (values) => {
    dispatch({
      type: ACTION.UPDATE_LOGIN,
      payload: {
        login: {
          ...values,
          description: draftToHtml(
            convertToRaw(textEditor.getCurrentContent())
          ),
        },
      },
    });
    onPrev();
  };

  return (
    <Formik
      initialValues={login}
      validationSchema={schema}
      validateOnBlur
      onSubmit={onSubmit}
    >
      {(props) => {
        return (
          <>
            <LoginModalPreview open={open} onClose={() => setOpen(false)} />
            <Form>
              <div className="wizard__preview-container">
                <Button
                  secondary
                  type="button"
                  onClick={() => handlePreview(props.values)}
                >
                  Previsualizar
                </Button>
              </div>
              <div className="wizard__form">
                <div className="wizard__grid-section">
                  <div className="title-container grid">
                    <span className="subtitle">
                      Acá podrás modificar los principales colores y elementos
                      del login del cliente.
                    </span>
                  </div>
                  <div className="wizard__grid-section">
                    <span className="wizard__title-section">Colores</span>
                    <InputWrapper
                      variant="full"
                      label="Color Primario"
                      id="loginPrimaryColor"
                    >
                      <div className="preview__color-container">
                        <InputColor name="loginPrimaryColor" />
                        <div className="description-container">
                          <InfoOutlined className="info_svg" />
                          <span className="description">
                            El color primario corresponde al botón de acción y
                            el color de filtro aplicado en la imagen del login.
                          </span>
                        </div>
                      </div>
                    </InputWrapper>
                    <InputWrapper
                      variant="full"
                      label="Color Secundario"
                      id="loginSecondaryColor"
                    >
                      <div className="preview__color-container">
                        <InputColor name="loginSecondaryColor" />
                        <div className="description-container">
                          <InfoOutlined className="info_svg" />
                          <span className="description">
                            El color secundario corresponde a los iconos que
                            muestra el login.
                          </span>
                        </div>
                      </div>
                    </InputWrapper>
                  </div>
                </div>
                <div className="landing-design__section wizard__grid-section">
                  <div className="wizard__grid-section">
                    <InputWrapper
                      label="Mensaje para login diferenciado"
                      id="description"
                      name="description"
                      variant="big"
                    >
                      <>
                        <Editor
                          name="description"
                          wrapperClassName="editor__wrapper"
                          toolbarClassName="editor__toolbar"
                          editorState={textEditor}
                          onEditorStateChange={setTextEditor}
                          onBlur={() => {
                            props.setFieldTouched('description', true);
                            props.setFieldValue(
                              'description',
                              draftToHtml(
                                convertToRaw(textEditor.getCurrentContent())
                              )
                            );
                          }}
                        />

                        <div className="editor__error">
                          {props.touched.description &&
                            props.errors.description && (
                              <div className="error-message">
                                {props.errors.description}
                              </div>
                            )}
                          <span className="char-counter">
                            {
                              textEditor.getCurrentContent().getPlainText('')
                                .length
                            }
                            /{ALLOWED_DESCRIPTION_CHAR}
                          </span>
                        </div>
                      </>
                    </InputWrapper>
                  </div>
                </div>
                <div className="wizard__action">
                  <Button secondary onClick={() => handlePrev(props.values)}>
                    Volver
                  </Button>
                  <Button type="submit">Siguiente</Button>
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
