import React, { createContext, useReducer } from "react";
import announcementReducer, { initialState } from "../../../reducer/announcementReducer";


const AnnouncementContext = createContext();
const AnnouncementProvider = ({ children }) => {
  return (
    <AnnouncementContext.Provider value={useReducer(announcementReducer, initialState)}>
      {children}
    </AnnouncementContext.Provider>
  )
}

export { AnnouncementContext }
export default AnnouncementProvider;