import { FormControlLabel, Stepper, Switch } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LinkComponent from '../../../components/common/Link';
import ClientsApi from '../client-api';
import Wizard from '../ClientCreationContainer/wizard';
import ClientForm from './clientForm';
import ConfirmationModal from '../statusModal';

const ClientEditContainer = () => {
  const {
    state: { client: id },
  } = useLocation();

  const [isloading, setIsLoding] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [clientStatus, setClientStatus] = useState(false);

  const [client, setClient] = useState({
    status: '',
    name: '',
    contactName: '',
    phoneNumber: '',
    email: '',
    emailCoord: '',
    logo: '',
  });

  const onToggle = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { client },
        } = await ClientsApi.fetchClient(id);
        setClient({
          status: client.status,
          name: client.name,
          contactName: client.contactName,
          phoneNumber: client.phoneNumber,
          email: client.email,
          emailCoord: client.emailCoord || '',
          status: client.status,
          logo: client.logo,
        });

        setClientStatus(client.status);

        setIsLoding(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  const onClose = () => setIsOpen(false);
  const onAccept = () => {
    setClientStatus((prev) => !prev);
    onClose();
  };

  return (
    <div className="client-container">
      <ConfirmationModal
        header={!clientStatus ? 'Activar cliente' : 'Desactivar cliente'}
        title={
          !clientStatus
            ? '¿Estás seguro/a que deseas activar el cliente'
            : '¿Estás seguro/a que deseas desactivar al cliente?'
        }
        open={isOpen}
        onClose={onClose}
        handleAccept={onAccept}
        handleDenied={onClose}
      />
      <div className="client__back-container">
        <LinkComponent
          title="Volver"
          url="/clientes"
          icon="arrow_back"
        ></LinkComponent>
      </div>
      <Wizard title="Editar cliente">
        <div className="wizard__content">
          <div className="wizard__steps-container wizard-padding">
            <FormControlLabel
              className="wizard__toggle-container"
              control={
                <Switch
                  checked={clientStatus}
                  onChange={onToggle}
                  name="active"
                />
              }
              label="Aprobar y activar cliente"
            />

            <Stepper text="Datos del cliente" isActive={true} />
          </div>
          <div className="wizard__form-container">
            <ClientForm
              isClientActive={clientStatus}
              client={client}
              loading={isloading}
            />
          </div>
        </div>
      </Wizard>
    </div>
  );
};
export default ClientEditContainer;
