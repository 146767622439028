import React from 'react';
import { Typography } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const CourseSummarySection = ({ course, institutions, pillarName }) => {
  const institution = institutions?.find(
    (inst) => inst.id === course?.institution
  );

  return (
    <div className="wizard__grid-section">
      <h2>Resumen</h2>
      <div className="container-summary">
        <div className="title-section">
          <h3 color="textSecondary">Título</h3>
          <Typography variant="body1">{course.name}</Typography>
        </div>
        <div className="description-section">
          <h3 color="textSecondary">Descripción</h3>
          <Typography variant="body1">{course.description}</Typography>
        </div>
        <div className="institution-section">
          <h3 color="textSecondary">Institución</h3>
          <Typography variant="body1">
            {institution ? institution.value : 'Institución no encontrada'}
          </Typography>
          {institution && institution.logo && (
            <img
              src={institution.logo}
              alt={`Logo de ${institution.value}`}
              style={{
                maxWidth: '150px',
                maxHeight: '50px',
                marginTop: '10px',
              }}
            />
          )}
        </div>
        <div className="category-section">
          <h3 color="textSecondary">Categoría del curso</h3>
          <div className="category-detail">
            <FiberManualRecordIcon className="FiberManualRecordIcon" />
            {pillarName}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseSummarySection;
