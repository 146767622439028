import request from '../../utils/request';

function createNews(news) {
  return request({
    url: '/news',
    method: 'POST',
    private: true,
    data: news,
  });
}

function fetchNews() {
  return request({
    url: '/news',
    method: 'GET',
    private: true,
  });
}

function fetchNewById(id) {
  return request({
    url: `/news/${id}`,
    method: 'GET',
    private: true,
  });
}

/**
 *  Updates news status it may be active - inactive - deleted
 * @param { string } status
 * @param { string } id
 */
function updateNewsStatus(id, status, isFeatured) {
  return request({
    url: `/news/${id}`,
    method: 'PATCH',
    private: true,
    data: { id, status, isFeatured },
  });
}

function updateNews(id, news, isFeatured) {
  return request({
    url: `/news/${id}`,
    method: 'PUT',
    private: true,
    data: { ...news, isFeatured },
  });
}

const NewsApi = {
  createNews,
  fetchNews,
  fetchNewById,
  updateNewsStatus,
  updateNews,
};

export default NewsApi;
