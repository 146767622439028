import React, { useEffect, useState } from 'react';
import Button from '../../../components/common/Button';
import ModalContent from '../../../components/common/Modal/ModalContent';
import PillarAPI from '../../PillarContainer/pillar-api';
import * as Yup from 'yup';
import PillarCreationForm from '../../../components/common/Pillars/creationForm';
import { Modal } from '@material-ui/core';

const PillarCreationModal = ({ open, onClose, setMessage , handleOpen}) => {
  const [loading, setLoading] = useState(true);
  

  const handleSubmit = async (values) => {
    try {
      await PillarAPI.addPillar(values)
      setMessage({type:'success',msge: 'Pilar creado exitosamente'})
    } catch (error) {
      setMessage({type:'error',msge: error.data.message})
    }
    finally{
      onClose()
    }
  };

  return (
    <Modal open={open} onClose={onClose} className="modal">
      <ModalContent title="Crear pilar" onClick={onClose}>
     <PillarCreationForm  onCancel={onClose} onSubmit={handleSubmit}/>
      </ModalContent>
    </Modal>
  );
};

export default PillarCreationModal;
