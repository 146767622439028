import React from 'react';
import ProjectProvider from '../projectContext';
import EditProjectClientContainer from './EditProject';

//it's only used for client's announcements
const ProjectEditContainer = (props) => {
  return (
    <ProjectProvider>
      <EditProjectClientContainer {...props} />
    </ProjectProvider>
  );
};
export default ProjectEditContainer;
