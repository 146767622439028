//Auto-saving user info.
//it is used when the admin user is surfing between tabs, if it  has changed some info
//it should be saved

// taken from https://itnext.io/formik-introduction-autosave-react-19d4c15cfb90

import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { UserContext } from './userContext';
import Alert from '../../../components/common/Alert';

const AutoSave = ({ debounceMs = 1000, context }) => {
  const formik = useFormikContext();
  const [isSaved, setIsSaved] = useState(null);
  const [state, dispatch] = useContext(UserContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  //filter autosaved section according its name. returns list of
  //section that are not the actual section
  const filterByContext = state.autosaved.filter((c) => {
    return Object.keys(c) !== context;
  });

  const debouncedSubmit = useCallback(
    _.debounce(() => {
      dispatch({
        type: 'UPDATE_USER',
        payload: { user: formik.values },
      });

      setIsSaved(true);
    }, debounceMs),
    [formik.values, debounceMs]
  );

  //update list of tabs that have been submitted
  useEffect(() => {
    if (formik.isSubmitting) {
      dispatch({
        type: 'UPDATE_AUTOSAVE_STATUS',
        payload: {
          autosaved: [...filterByContext, { [context]: false }],
        },
      });
    }
  }, [formik.isSubmitting]);

  //updates list of tabs that have been autosaved
  useEffect(() => {
    if (isSaved) {
      const filterByState = state.autosaved.filter((c) => {
        return Object.keys(c) !== context;
      });

      dispatch({
        type: 'UPDATE_AUTOSAVE_STATUS',
        payload: { autosaved: [...filterByContext, { [context]: true }] },
      });
    }
  }, [isSaved]);

  useEffect(() => debouncedSubmit, [debouncedSubmit, formik.values]);

  const isActiveSection = state.autosaved.some(
    (a) => Object.keys(a).toString() === context
  );

  const isSectionSaved = state.autosaved.find(
    (a) => Object.keys(a).toString() === context
  )[context];

  return (
    <p className="text-center text-success">
      {!!formik.isSubmitting ? null : isSaved ? (
        <Alert type="warning">
          Recuerda aceptar para guardar los datos modificados
        </Alert>
      ) : isActiveSection && isSectionSaved ? (
        <Alert type="warning">
          Recuerda aceptar para guardar los datos modificados
        </Alert>
      ) : null}
    </p>
  );
};

export default AutoSave;
