import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import LinkComponent from '../../../components/common/Link';
import Wizard from '../../ClientContainer/ClientCreationContainer/wizard';

import Stepper from '../../../components/common/stepper';
import { AnnouncementContext } from '../AnnouncementCreation/AnnouncementContext';
import ReceptorForm from './steps/receptorForm';
import GraphicsForm from './steps/graphicsForm';
import AnnouncementApi from '../announcement-api';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { ACTION } from '../../../reducer/announcementReducer';
import ConfirmationModal from '../../ClientContainer/statusModal';

const AnnouncementEditWizard = () => {
  const {
    state: { orgId, announcementId },
  } = useLocation();

  const [_, dispatch] = useContext(AnnouncementContext);
  const [activeStep, setActiveStep] = useState(0);
  const [announcementStatus, setAnnouncementStatus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { announcement },
        } = await AnnouncementApi.fetchOpenAnnouncement(announcementId, orgId);

        const {
          data: { organizations },
        } = await AnnouncementApi.fetchOrganizations();

        setOrganizations(organizations);

        const { organizationId, ...rest } = announcement;

        dispatch({ type: ACTION.UPDATE_ORGANIZATION, payload: organizationId });
        dispatch({ type: ACTION.UPDATE_ANNOUNCEMENT, payload: rest });
        setAnnouncementStatus(rest.status === 'user-register' ? true : false);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const status = announcementStatus ? 'user-register' : 'waiting';
    dispatch({ type: ACTION.UPDATE_ANNOUNCEMENT_STATUS, payload: status });
  }, [announcementStatus]);

  const steps = [
    { title: 'Fecha de activación y receptor', Component: ReceptorForm },
    { title: 'Elementos gráficos', Component: GraphicsForm },
  ];

  const onToggle = () => {
    setIsOpen(true);
  };

  const onClose = () => setIsOpen(false);
  const onAccept = () => {
    dispatch({
      type: ACTION.UPDATE_ANNOUNCEMENT_STATUS,
      payload: { status: !announcementStatus },
    });
    setAnnouncementStatus((prev) => !prev);
    onClose();
  };

  return (
    <div className="client-container">
      <ConfirmationModal
        header={
          !announcementStatus
            ? 'Activar Convocatoria'
            : 'Desactivar Convocatoria'
        }
        title={
          !announcementStatus
            ? '¿Estás seguro/a que deseas activar la convocatoria?'
            : '¿Estás seguro/a que deseas dejar la convocatoria en espera?'
        }
        open={isOpen}
        onClose={onClose}
        handleAccept={onAccept}
        handleDenied={onClose}
      />
      <div className="client__back-container">
        <LinkComponent title="Volver" url="/convocatorias" icon="arrow_back" />
      </div>
      <Wizard title="Crear nueva convocatoria">
        <div className="wizard__content">
          {/* STEPS CONTAINER */}
          <div className="wizard__steps-container wizard-padding">
            <FormControlLabel
              className="wizard__toggle-container"
              control={
                <Switch
                  checked={announcementStatus}
                  onChange={onToggle}
                  name="active"
                />
              }
              label="Aprobar y activar convocatoria"
            />
            {steps.map((step, index) => (
              <Stepper
                text={step.title}
                isActive={index === activeStep}
                key={`step-${index}`}
                isDone={index < activeStep}
              />
            ))}
          </div>
          <div className="wizard__form-container">
            {steps.map((step, index) => {
              const C = step.Component;
              return (
                index === activeStep && (
                  <C
                    key={`active-step-${index}`}
                    organizations={organizations}
                    isLoading={isLoading}
                    onNext={() => setActiveStep((prev) => prev + 1)}
                    onPrev={() => setActiveStep((prev) => prev - 1)}
                  />
                )
              );
            })}
          </div>
        </div>
      </Wizard>
    </div>
  );
};

export default AnnouncementEditWizard;
