import React, { useState, useEffect } from 'react';
import Alert from '../../../components/common/Alert';
import { useHistory } from 'react-router-dom';

import Title from '../../../components/common/Title';
import LinkComponent from '../../../components/common/Link';
import SurveysAPI from '../survey-api';
import SurveyCreationForm from './SurveyCreationForm';

const SurveyCreate = () => {
  let timer;
  const history = useHistory();
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');

  useEffect(() => {
    // eslint-disable-next-line
    timer = setTimeout(function () {
      setShowAlert(false);
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  });

  const handleSubmit = async (values) => {
    try {
      await SurveysAPI.addSurvey(values);
      history.push({
        pathname: '/encuestas',
        state: {
          message: 'encuesta creada exitosamente',
          type: 'success',
        },
      });
    } catch (error) {
      setShowAlert(true);
      setMessageAlert(error.data.message);
    }
  };

  return (
    <div
      className="user-container content-panel"
      style={{ background: '#ffff', minHeight: '100vh' }}
    >
      <LinkComponent title="Volver" url="/encuestas" icon="arrow_back" />
      <Title title="Crear encuesta" />

      <div style={{ justifyContent: 'start', display: 'flex' }}>
        {showAlert && <Alert type="error">{messageAlert}</Alert>}
        <SurveyCreationForm
          onCancel={() => history.push('/encuestas')}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default SurveyCreate;
