import React from 'react';
import PropTypes from 'prop-types';
import { useField, ErrorMessage, useFormikContext } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CheckBox = ({ label, extra, max, type, customValidation, ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  return (
    <div className="input__container">
      <FormControlLabel
        control={<Checkbox color="primary" />}
        label={label}
        labelPlacement="end"
        name={field.name}
        onChange={(e) => {
          setFieldValue(field.name, e.target.checked);
        }}
      />
      {meta.touched && meta.error && (
        <div className="error-message">
          <ErrorMessage
            className="error-message"
            name={props.id || props.name}
          />
        </div>
      )}
      {customValidation ? (
        <div className="error-message">
          <p>{customValidation}</p>
        </div>
      ) : null}
      {!meta.error && <p className="extra-message">{extra}</p>}
    </div>
  );
};

CheckBox.propTypes = {
  label: PropTypes.string.isRequired,
  extra: PropTypes.string,
  type: PropTypes.string,
  max: PropTypes.number,
  customValidation: PropTypes.string,
};

export default CheckBox;
