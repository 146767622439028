import { Modal } from '@material-ui/core';
import React from 'react';
import Button from '../../components/common/Button';
import ModalContent from '../../components/common/Modal/ModalContent';

const NewsModal = ({
  open,
  onClose,
  handleDenied,
  handleAccept,
  selectedNews,
  content,
}) => {
  return (
    <Modal open={open} onClose={onClose} className="modal">
      <ModalContent title={content.header} onClick={onClose}>
        <div className="grid grid--24 center">
          <span className="title">{content?.content}</span>
          <div className="grid">
            <span className="title">{selectedNews?.title}</span>
            <span className="title title--light">
              {content.note ||
                new Date(selectedNews?.createdAt).toLocaleDateString()}
            </span>
          </div>
        </div>
        <div className="modal__action-container">
          <Button type="button" secondary onClick={handleDenied}>
            No
          </Button>
          <Button type="button" onClick={handleAccept}>
            Si
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default NewsModal;
