import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';

const EditorMarkDown = ({ label, name, handleChange, ...props }) => {
  const { errors, touched } = useFormikContext();
  const mdParser = new MarkdownIt();
  return (
    <div className="input__container">
      <label htmlFor="text">Tips de sesión</label>
      <MdEditor
        value={name}
        style={{ height: '250px' }}
        plugins={['header', 'fonts', 'link', 'mode-toggle']}
        renderHTML={(text): string => mdParser.render(text)}
        onChange={({ text }): void => handleChange(text)}
        name={name}
      />
      {errors['name'] && touched['name'] ? <div>{errors['name']}</div> : null}
    </div>
  );
};

EditorMarkDown.propTypes = {
  label: PropTypes.string.isRequired,
};

export default EditorMarkDown;
