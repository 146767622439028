import React, { useEffect, useState } from 'react';
import TableSite from '../../../components/common/Table';
import {
  IconButton,
  MenuItem,
  Popover,
  Typography,
  Box,
  TableRow,
  TablePagination,
} from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import request from '../../../utils/request';
import { USER_STATUS_CLASSNAMES } from '../../ClientContainer/constants';
import StatusIndicator from '../../../components/common/StatusIndicator';
import UsersAPI from '../user-api';

const IndicatorTable = ({ isLoadingData, tableName }) => {
  const history = useHistory();
  const [showDropdown, setShowDropDown] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mentorsdata, setMentorsData] = useState([]);
  const [page, setPage] = useState(0);

  const [typeMessage, setTypeMessage] = useState('info');
  const [message, setMessage] = useState('');

  useEffect(() => {
    (async () => {
      const response = await UsersAPI.fetchNextToDueDate();
      const firstUser = response.data.userList;
      const pages = response.data.totalPages;

      setMentorsData(firstUser);

      setPage(pages);
    })();
  }, []);

  const renderStatus = (status, registro) => {
    switch (status) {
      case 'active':
        return (
          <StatusIndicator
            statusClassName={USER_STATUS_CLASSNAMES[status]}
            text="Activo"
          />
        );
      case 'inactive':
        if (registro !== 0) {
          return (
            <StatusIndicator
              statusClassName={USER_STATUS_CLASSNAMES[status]}
              text="Postulación incompleta"
            />
          );
        } else {
          return (
            <StatusIndicator
              statusClassName={USER_STATUS_CLASSNAMES[status]}
              text="Revisar Postulación"
            />
          );
        }
      case 'rejected':
        return (
          <StatusIndicator
            statusClassName={USER_STATUS_CLASSNAMES[status]}
            text="Desactivado"
          />
        );
      case 'deactivated':
        return (
          <StatusIndicator
            statusClassName={USER_STATUS_CLASSNAMES[status]}
            text="Desactivado"
          />
        );

      default:
        return (
          <StatusIndicator
            statusClassName={USER_STATUS_CLASSNAMES[status]}
            text="Sin especificar"
          />
        );
    }
  };

  const handleDeleteUser = (id) => {
    request({
      url: `/user/delete/${id}`,
      method: 'DELETE',
      private: true,
    })
      .then(({ data }) => {
        setAnchorEl(null);
        setShowDropDown(null);
        setMessage(data.message);
        setTypeMessage('success');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setShowDropDown(id);
  };

  const IndicatorColumns = React.useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Estado',
        accessor: 'status',
        Cell: ({
          row: {
            original: { status, registro },
          },
        }) => renderStatus(status, registro),
      },
      {
        Header: 'Pilar',
        accessor: 'pillar_name',
      },
      {
        Header: 'Referer',
        accessor: 'referer',
      },
      {
        Header: '',
        id: 'actions',
        accessor: function fnAccessor({ id }) {
          return (
            <>
              <IconButton
                onClick={() => {
                  history.push('/usuarios/editar', {
                    idUser: id,
                    roleType: 'mentor',
                  });
                }}
              >
                <Edit />
              </IconButton>
              <>
                <IconButton
                  onClick={(e) => {
                    if (showDropdown === id) {
                      setShowDropDown(null);
                    } else {
                      handleClick(e, id);
                    }
                  }}
                >
                  <Delete />
                </IconButton>
                {showDropdown === id && (
                  <Popover
                    id="fade-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={!!showDropdown}
                    onClose={() => {
                      setAnchorEl(null);
                      setShowDropDown(null);
                    }}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Box p={2} width="200px">
                      <Typography>
                        ¿Estás seguro que deseas eliminar al usuario?
                      </Typography>
                      <MenuItem
                        style={{ color: 'red', placeContent: 'center' }}
                        onClick={() => {
                          handleDeleteUser(id);
                        }}
                      >
                        Eliminar
                      </MenuItem>
                      <MenuItem
                        style={{ placeContent: 'center' }}
                        onClick={() => {
                          setAnchorEl(null);
                          setShowDropDown(null);
                        }}
                      >
                        cancelar
                      </MenuItem>
                    </Box>
                  </Popover>
                )}
              </>
            </>
          );
        },
        disableSortBy: true,
      },
    ],
    [history, showDropdown]
  );

  return (
    <>
      <TableSite
        data={mentorsdata}
        columns={IndicatorColumns}
        isLoadingData={isLoadingData}
        tableName={tableName}
        totalPages={page}
      />
    </>
  );
};

export default IndicatorTable;
