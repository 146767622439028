import React from 'react';
const ModalContent = ({ onClick, title, children }) => {
  return (
    <div className="modal__container">
      <div className="modal__header">
        <span className="title">{title || ''}</span>
        <span className="material-icons icon" onClick={onClick}>
          close
        </span>
      </div>
      <div className="modal__content">{children}</div>
    </div>
  );
};

export default ModalContent;
