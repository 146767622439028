import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from 'react-placeholder';
import ReportsApi from '../ReportsContainer/reports-api';

const DashboardContainer = () => {
  const [totalHoursMentor, setTotalHoursMentor] = useState(0);
  const [totalHoursCategory, setTotalHoursCategory] = useState(0);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ReportsApi.getHoursCategory()
      .then((response) => {
        const totalUsers = response.data
          .map((category) => {
            return category.totalUsers;
          })
          .reduce(function (a, b) {
            return a + b;
          });

        const totalHours = response.data
          .map((category) => {
            return category.totalHours;
          })
          .reduce(function (a, b) {
            return a + b;
          });

        setTotalHoursMentor(totalUsers);
        setTotalHoursCategory(totalHours);
        setRows(response.data);
      })
      .catch((error) => console.error('ERROR', error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Grid container className="user-container__creation" direction="column">
      <Grid
        item
        xs={12}
        className={`title `}
        style={{ backgroundColor: 'white' }}
      >
        <h1>Dashboard</h1>
      </Grid>

      <Grid item xs={12} style={{ backgroundColor: 'white' }}>
        <div className="dashboard-container">
          <Grid container>
            <Grid item xs={8}>
              <h3>Información de categorías</h3>
              {!isLoading ? (
                <TableContainer component={Paper}>
                  <Table aria-label="pillars-info">
                    <TableHead className="table-header">
                      <TableRow>
                        <TableCell>Categoría</TableCell>
                        <TableCell align="right">Número de mentores</TableCell>
                        <TableCell align="right">Horas asociadas</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.totalUsers}</TableCell>
                          <TableCell align="right">{row.totalHours}</TableCell>
                        </TableRow>
                      ))}
                      <TableRow className="table-footer">
                        <TableCell component="th" scope="row">
                          Horas mín. disponibilizadas
                        </TableCell>
                        <TableCell align="right">{totalHoursMentor}</TableCell>
                        <TableCell align="right">
                          {totalHoursCategory}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Skeleton showLoadingAnimation type="text" rows={10} />
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default DashboardContainer;
