import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import Button from '../../../components/common/Button';
import AnnouncementApi from '../announcement-api';
import AnnouncementTable from './AnnouncementTable';

const Announcements = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [allAnnouncements, setAllAnnouncements] = useState([]);

  useEffect(() => {
    (async () => {
      const {
        data: { announcements },
      } = await AnnouncementApi.fetchAnnouncements();
      // const clientsWithStatus = clients.map((client) => {
      //   return { ...client, status: client.status ? 'Activo' : 'Inactivo' };
      // });
      setAllAnnouncements(announcements);
      setIsLoading(false);
    })();
  }, []);

  return (
    <div className="user-container ">
      <div className="content-panel">
        <div className="client__action-container">
          <span className="subtitle">
            Detalle de convocatorias registradas en la plataforma
          </span>
          <Button
            type="button"
            primary
            onClick={() => history.push('/convocatorias/crear')}
          >
            <span className="material-icons">add</span>
            Nueva convocatoria
          </Button>
        </div>
        <AnnouncementTable setIsLoading={setIsLoading} isLoading={isLoading} setAllAnnouncements={setAllAnnouncements} announcements={allAnnouncements} />
      </div>
    </div>
  );
};

export default Announcements;
