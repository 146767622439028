import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Button from '../../../components/common/Button';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import AnnouncementApi from '../announcement-api';
import Alert from '../../../components/common/Alert';

const RescheduleSummary = ({ formValues, onClose, sessionId }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const requestData = JSON.parse(formValues.config.data);
  const {
    idMentor,
    idMentee,
    mentor,
    mentee,
    title,
    description,
    calendar: { available_date, hour },
  } = requestData;

  async function sendEmail() {
    try {
      const sendEmailResponse = await AnnouncementApi.rescheduleEmail(
        sessionId
      );
      if (sendEmailResponse.status === 200) {
        setSuccessMessage('Correo enviado con éxito');
        setTimeout(() => onClose(), 5000);
      }
    } catch (error) {
      setErrorMessage(`Error al enviar el correo`);
      setTimeout(() => onClose(), 6000);
    }
  }

  return (
    <div>
      {errorMessage && <Alert type="error">{errorMessage}</Alert>}
      {successMessage && <Alert type="success">{successMessage}</Alert>}
      <div className="title__success">
        <h4>¡Su reunión se reagendó con éxito!</h4>
        <CheckOutlinedIcon style={{ color: '#389E0D' }} />
      </div>

      <div className="content__success">
        <p>Mentor: {mentor}</p>
        <p>Mentee: {mentee}</p>
        <p>Asunto: {title}</p>
        <p>Descripción: {description}</p>
        <p>
          ¿Cuándo?: {available_date}, a las {hour}:00 hrs.
        </p>
      </div>
      <h4>¿Desea notificar a los participantes?</h4>
      <Grid container justify="space-around">
        <Grid item xs={5}>
          <Button widthFull secondary onClick={onClose}>
            No, gracias
          </Button>
        </Grid>
        <Grid item xs={5}>
          <Button widthFull onClick={sendEmail}>
            Si
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default RescheduleSummary;
