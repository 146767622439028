import React, { useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import InputWrapper from './inputWrapper';
import InputImage from './inputImage';
import Button from '../../common/Button';
import CoursesAPI from '../../../content/CoursesContainer/course-api';
import { Input } from '../../forms';
const InstitutionInput = ({ onClose, onClickAccept, ...props }) => {
  return (
    <Paper style={{ padding: '20px', margin: 'auto', maxWidth: 600 }}>
      <div>
        <Grid container spacing={2} direction="column">
          <strong>Agregar nueva institución</strong>
          <Grid item>
            <InputWrapper id="institution.name" name="institution.name">
              <Input
                id="institution.name"
                name="institution.name"
                fullWidth
                label="Ingresa Nombre de la institución"
                variant="outlined"
              />
            </InputWrapper>
          </Grid>
          <Grid item>
            <InputWrapper
              variant="big"
              name="institution.logo"
              id="institution.logo"
              label="Cargar logo de institución"
              description="El archivo debe estar en escala de grises y medir 150x50px como máximo. Formatos permitidos JPG PNG SVG."
            >
              <InputImage
                iconName="cloud_upload"
                name="institution.logo"
                id="institution.logo"
                allowedExts={[
                  'image/png',
                  'image/jpg',
                  'image/jpeg',
                  'image/svg+xml',
                ]}
              />
            </InputWrapper>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default InstitutionInput;
