import React, { useEffect, useState } from 'react';
import empty from '../../../assets/images/empty.png';
import { useHistory } from 'react-router-dom';
import ProjectApi from '../../ProjectsContainer/project-api';
import ProjectTableContainer from './clientProjectTable';
import { formatSlug } from '../../../utils/format';
import EmptyCard from '../../../components/common/Cards/emptyCard';
import Button from '../../../components/common/Button';

const ProjectContainer = ({ client, projects, isLoading, setIsLoading }) => {
  const history = useHistory();

  return (
    <>
      {isLoading ? null : projects.length ? (
        <ProjectTableContainer
          isLoading={isLoading}
          projects={projects}
          client={client}
          setIsLoading={setIsLoading}
        />
      ) : (
        <EmptyCard
          message="Actualmente no hay convocatorias registradas"
          img={empty}
        >
          <Button
            onClick={() =>
              history.push(
                `/clientes/${formatSlug(client.name)}/convocatoria`,
                {
                  client: {
                    id: client.id,
                    status: client.status,
                    name: client.name,
                  },
                }
              )
            }
          >
            <span className="material-symbols-outlined">add</span>Crear
            convocatoria
          </Button>
        </EmptyCard>
      )}
    </>
  );
};

export default ProjectContainer;
