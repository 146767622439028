//yup custom validators
import * as Yup from 'yup';

export const editorMinLengthValidator = (min, value) => {
  try {
    //replace all html tags from text and trimmed it
    const plainText = value.replace(/(<([^>]+)>)/gi, '').trim();

    //if false it throws error
    return plainText.length >= min ? true : false;
  } catch (error) {
    return true;
  }
};

export const YupEditorMinLengthValidator = () => {
  Yup.addMethod(
    Yup.string,
    'editorMinLengthValidator',
    function (maxValue, errorMessage) {
      return this.test(`test-editor-validator`, errorMessage, function (value) {
        try {
          const result = editorMinLengthValidator(maxValue, value);
          return result;
        } catch (error) {
          new Yup.ValidationError(errorMessage);
        }
      });
    }
  );
};

export const editorMaxLengthValidator = (max, value) => {
  try {
    //replace all html tags from text and trimmed it
    const plainText = value.replace(/(<([^>]+)>)/gi, '').trim();
    //if false it throws error
    return plainText.trim().length <= max ? true : false;
  } catch (error) {
    return true;
  }
};

export const YupEditorMaxLengthValidator = () => {
  Yup.addMethod(
    Yup.string,
    'editorMaxLengthValidator',
    function (maxValue, errorMessage) {
      return this.test(`test-editor-validator`, errorMessage, function (value) {
        try {
          const result = editorMaxLengthValidator(maxValue, value);
          return result;
        } catch (error) {
          new Yup.ValidationError(errorMessage);
        }
      });
    }
  );
};
