import React, { useContext, useState } from 'react';

import * as Yup from 'yup';
import { FieldArray, Form, Formik } from 'formik';
import InputWrapper from '../../../../components/forms/Input/inputWrapper';
import { Input } from '../../../../components/forms';
import InputImage from '../../../../components/forms/Input/inputImage';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from 'draft-js';
import Button from '../../../../components/common/Button';
import draftToHtml from 'draftjs-to-html';
import { ACTION } from '../../../../reducer/projectReducer';
import { useHistory } from 'react-router-dom';
import LandingModalPreview from './landingModalPreview';
import { ProjectContext } from '../../projectContext';
import ItemTextBox from './itemTextBox';
import { Modal } from '@material-ui/core';
import ModalContent from '../../../../components/common/Modal/ModalContent';
import {
  YupEditorMaxLengthValidator,
  YupEditorMinLengthValidator,
} from '../../../../utils/validators';
import ProjectApi from '../../project-api';
import Alert from '../../../../components/common/Alert';

import {
  ALLOWED_CHAR,
  ALLOWED_DESCRIPTION_CHAR,
  ALLOWED_ITEMS_CHAR,
  landingSchema,
} from '../../schemas/landing';
import { TIME_TO_REDIRECT, TODAY } from '../../../../utils/constants';
import { getTomorrowDate } from '../../../../utils/dates';

const LandingForm = ({ onNext, onPrev }) => {
  const history = useHistory();
  YupEditorMaxLengthValidator();
  YupEditorMinLengthValidator();
  const [state, dispatch] = useContext(ProjectContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { client, landing, status } = state;
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState({ type: '', message: '' });

  const items = [
    { name: 'Item 1', Component: ItemTextBox, placeholder: 'placeholder' },
    {
      name: 'Item 2',
      Component: ItemTextBox,
      placeholder: 'placeholder 1',
    },
    {
      name: 'Item 3',
      Component: ItemTextBox,
      placeholder: 'placeholder 2',
    },
  ];

  const [activeItem, setActiveItem] = useState(0);

  const defaultContent = state.landing.about;
  const blockFromHTML = convertFromHTML(defaultContent);
  const content = ContentState.createFromBlockArray(blockFromHTML);
  const [textEditor, setTextEditor] = useState(
    EditorState.createWithContent(content)
  );

  const slugifyName = (name) => name.toLowerCase().replace(' ', '-');

  const onSubmit = (values) => {
    if (isLoading) return;
    if (client.isCustomized) {
      submitAnnouncement(values);
    } else {
      dispatch({
        type: ACTION.UPDATE_LANDING,
        payload: {
          landing: {
            ...values,
            about: draftToHtml(convertToRaw(textEditor.getCurrentContent())),
          },
          status: status ? 'user-register' : 'waiting',
        },
      });
      onNext();
    }
  };

  const submitAnnouncement = async (values) => {
    try {
      setIsLoading(true);

      const payload = {
        landing: values,
        status: status ? 'user-register' : 'waiting',
      };

      const resp = await ProjectApi.createClientPlatform(client.id, payload);

      setMessageAlert({
        type: 'success',
        message: 'Convocatoria creada exitosamente',
      });

      setTimeout(() => {
        history.push('/clientes/detalles', {
          client: state.client.id,
        });
        setIsLoading(false);
      }, TIME_TO_REDIRECT);
    } catch (error) {
      setMessageAlert({ type: 'error', message: error.data.message });
      setIsLoading(false);
    }
  };

  const onClickTab = (values, index) => {
    dispatch({
      type: ACTION.UPDATE_LANDING,
      payload: {
        landing: {
          ...values,
          about: draftToHtml(convertToRaw(textEditor.getCurrentContent())),
        },
      },
    });
    setActiveItem(index);
  };

  const handlePreview = (values) => {
    dispatch({
      type: ACTION.UPDATE_LANDING,
      payload: {
        landing: {
          ...values,
          about: draftToHtml(convertToRaw(textEditor.getCurrentContent())),
        },
      },
    });
    setOpen(true);
  };

  const handlePrev = () => {
    setIsModalOpen(false);
    history.push(`/clientes/detalles`, {
      client: client.id,
    });
  };
  return (
    <>
      {messageAlert && (
        <Alert type={messageAlert.type}>{messageAlert.message}</Alert>
      )}
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalContent
          onClick={() => setIsModalOpen(false)}
          title="Estás saliendo sin guardar los cambios"
        >
          <span className="title">¿Estás seguro que deseas volver?</span>
          <div className="modal__action-container">
            <Button
              type="button"
              secondary
              onClick={() => setIsModalOpen(false)}
            >
              No
            </Button>
            <Button type="button" onClick={handlePrev}>
              Si
            </Button>
          </div>
        </ModalContent>
      </Modal>
      <Formik
        initialValues={landing}
        validationSchema={landingSchema}
        validateOnBlur
        onSubmit={onSubmit}
      >
        {(props) => {
          return (
            <>
              <LandingModalPreview open={open} onClose={() => setOpen(false)} />
              <Form>
                <div className="wizard__preview-container">
                  <Button
                    type="button"
                    secondary
                    className="button button--flat"
                    onClick={() => handlePreview(props.values)}
                  >
                    Previsualizar
                  </Button>
                </div>
                <div className="wizard__form">
                  <div className="wizard__grid-section">
                    <div className="title-container grid">
                      <span className="wizard__title-section">
                        Fecha de activación
                      </span>
                      <span className="subtitle">
                        Selecciona la fecha de activación del landing de
                        registro y a quien va dirigido.
                      </span>
                    </div>
                    <div className="wizard__grid-section">
                      <InputWrapper label="Fecha de inicio" id="startDate">
                        <Input
                          InputProps={{
                            inputProps: { min: getTomorrowDate() },
                          }}
                          label=""
                          id="startDate"
                          type="date"
                          name="startDate"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </InputWrapper>

                      <InputWrapper label="Fecha de termino" id="startDate">
                        <Input
                          InputProps={{
                            inputProps: {
                              min: props.values?.startDate || getTomorrowDate(),
                            },
                          }}
                          label=""
                          id="endDate"
                          type="date"
                          name="endDate"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </InputWrapper>
                      <InputWrapper label="URL mentee" id="urlMentee">
                        <Input
                          name="urlMentee"
                          label={'registroMentee'}
                          id="urlMentee"
                        />
                      </InputWrapper>
                      <InputWrapper label="URL mentor" id="urlMentor">
                        <Input
                          name="urlMentor"
                          label={'registroMentor'}
                          id="urlMentor"
                          defaultValue={`/${slugifyName(
                            client?.name
                          )}/registroMentee`}
                        />
                      </InputWrapper>
                    </div>
                  </div>
                  <div className="landing-design__section wizard__grid-section">
                    <div className="title-container grid">
                      <span className="wizard__title-section">
                        Diseño de landing de registro
                      </span>
                      <span className="subtitle">
                        Acá podrás modificar los principales elementos del
                        landing de registro siguiendo la línea gráfica del
                        cliente y Chileconverge.
                      </span>
                    </div>
                    <div>
                      <InputWrapper
                        variant="big"
                        label="Logo cliente"
                        description="Archivos 176x70px  PNG, SVG  - Tamaño máximo 5MB."
                        id="logo"
                      >
                        <InputImage
                          name="logo"
                          id="logo"
                          allowedExts={['image/png', 'image/svg+xml']}
                        />
                      </InputWrapper>
                      <InputWrapper
                        variant="big"
                        label="Banner desktop"
                        description="Archivos 1440x800px PNG, JPG  - Tamaño máximo 5MB."
                        id="desktopBanner"
                      >
                        <InputImage
                          name="desktopBanner"
                          id="desktopBanner"
                          allowedExts={['image/png', 'image/jpg', 'image/jpeg']}
                        />
                      </InputWrapper>
                      <InputWrapper
                        variant="big"
                        label="Banner mobile"
                        description="Archivos 375x522px PNG, JPG  - Tamaño máximo 5MB."
                        id="mobileBanner"
                      >
                        <InputImage
                          name="mobileBanner"
                          id="mobileBanner"
                          allowedExts={['image/png', 'image/jpg', 'image/jpeg']}
                        />
                      </InputWrapper>
                      <InputWrapper
                        label="Título de la convocatoria"
                        id="title"
                        description="Corresponde al llamado principal que aparece en el banner."
                      >
                        <Input name="title" label="Título" id="title" />
                        <span className="char-counter">
                          {props.values.title.length}/{ALLOWED_CHAR}
                        </span>
                      </InputWrapper>
                    </div>
                    <div className="wizard__grid-section">
                      {/* Quienes somos */}
                      <InputWrapper
                        label="Quiénes somos"
                        id="about"
                        description="Descripción resumida"
                        name="about"
                        variant="big"
                      >
                        <>
                          <Editor
                            id="about"
                            name="about"
                            wrapperClassName="editor__wrapper"
                            toolbarClassName="editor__toolbar"
                            editorState={textEditor}
                            onEditorStateChange={setTextEditor}
                            onBlur={() => {
                              props.setFieldTouched('about', true);
                              props.setFieldValue(
                                'about',
                                draftToHtml(
                                  convertToRaw(textEditor.getCurrentContent())
                                )
                              );
                            }}
                          />
                          <div className="editor__error">
                            {props.touched.about && props.errors.about && (
                              <span className=" error-message">
                                {props.errors.about}
                              </span>
                            )}
                            <span className="char-counter">
                              {
                                textEditor.getCurrentContent().getPlainText('')
                                  .length
                              }
                              /{ALLOWED_DESCRIPTION_CHAR}
                            </span>
                          </div>
                        </>
                      </InputWrapper>

                      {/* ITEMS  */}
                      <FieldArray name="items">
                        {(arrayProps) => (
                          <InputWrapper
                            label="Iconografía"
                            id="iconografía"
                            description="Completa la información correspondiente a cada item"
                            name="items"
                            variant="big"
                          >
                            <div className="tabs-panel__container">
                              <div className="tabs__container">
                                {items.map((item, index) => {
                                  return (
                                    <div
                                      className={`${
                                        activeItem === index
                                          ? 'tabs__item-tab tabs__item-tab--active'
                                          : 'tabs__item-tab'
                                      }`}
                                      key={index}
                                      onClick={() =>
                                        onClickTab(props.values, index)
                                      }
                                    >
                                      {item.name}
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="tab-panel">
                                <>
                                  {items.map((item, index) => {
                                    const Component = item.Component;
                                    return (
                                      activeItem === index && (
                                        <Component
                                          {...props}
                                          {...arrayProps}
                                          key={index}
                                          name={`item.${index}.text`}
                                          maxChar={ALLOWED_ITEMS_CHAR}
                                        />
                                      )
                                    );
                                  })}
                                </>
                              </div>
                            </div>
                          </InputWrapper>
                        )}
                      </FieldArray>
                    </div>
                  </div>
                  <div className="wizard__action">
                    <Button secondary onClick={() => setIsModalOpen(true)}>
                      Volver
                    </Button>
                    <Button
                      type="submit"
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      {client.isCustomized ? 'Finalizar' : 'Siguiente'}
                    </Button>
                  </div>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default LandingForm;
