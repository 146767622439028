import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { useContext, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';

import { AnnouncementContext } from '../AnnouncementContext';

const ICON_LIST = [
  'https://aws-files-converge-prod-sa.s3.sa-east-1.amazonaws.com/icon-1.svg',
  'https://aws-files-converge-prod-sa.s3.sa-east-1.amazonaws.com/icon-2.svg',
  'https://aws-files-converge-prod-sa.s3.sa-east-1.amazonaws.com/icon-3.svg',
  'https://aws-files-converge-prod-sa.s3.sa-east-1.amazonaws.com/icon-4.svg',
  'https://aws-files-converge-prod-sa.s3.sa-east-1.amazonaws.com/icon-5.svg',
  'https://aws-files-converge-prod-sa.s3.sa-east-1.amazonaws.com/icon-6.svg',
  'https://aws-files-converge-prod-sa.s3.sa-east-1.amazonaws.com/icon-7.svg',
  'https://aws-files-converge-prod-sa.s3.sa-east-1.amazonaws.com/icon-8.svg',
];

const ItemTextBox = ({ placeholder, editorName, disabled, ...props }) => {
  //item.[index].name
  const index = props.name.split('.')[1];
  const [state, dispatch] = useContext(AnnouncementContext);

  const [iconSelected, setIconSelected] = useState(
    state.announcement.items[index].icon
  );
  const defaultContent = state.announcement.items[index].text;
  const blockFromHTML = convertFromHTML(defaultContent);
  const content = ContentState.createFromBlockArray(blockFromHTML);
  const [textEditor, setTextEditor] = useState(
    EditorState.createWithContent(content)
  );

  const isIconActive = (icon) => icon === iconSelected;

  const onClick = (icon) => {
    setIconSelected(icon);
    const item = {
      text: draftToHtml(convertToRaw(textEditor.getCurrentContent())) || '',
      icon,
    };
    props.replace(index, item);
  };

  const onBlur = () => {
    props.setFieldTouched('items', true);
    const item = {
      text: draftToHtml(convertToRaw(textEditor.getCurrentContent())) || '',
      icon: iconSelected,
    };
    props.replace(index, item);
  };

  return (
    <>
      <div className="landing__icon-container">
        {ICON_LIST.map((icon, index) => (
          <div
            key={`icon-${index}`}
            className={
              isIconActive(icon)
                ? 'landing__icon-item-button landing__icon-item-button--active'
                : 'landing__icon-item-button'
            }
            onClick={() => !disabled && onClick(icon)}
          >
            <img
              src={icon}
              className={
                isIconActive(icon)
                  ? 'landing__icon-image landing__icon-image--active'
                  : 'landing__icon-image'
              }
            />
          </div>
        ))}
      </div>
      <Editor
        name="editor_description"
        wrapperClassName="editor__wrapper"
        toolbarClassName="editor__toolbar"
        editorState={textEditor}
        onEditorStateChange={setTextEditor}
        onBlur={onBlur}
        readOnly={disabled}
      />
      <div className="editor__error">
        {props.errors?.items?.length && props.touched.items && (
          <span className="error-message">
            Debes seleccionar un icono por cada item y escribir un texto
          </span>
        )}
        <span className="char-counter">
          {textEditor.getCurrentContent().getPlainText('').length}/
          {props.maxChar}
        </span>
      </div>
    </>
  );
};

export default ItemTextBox;
