import React, { useMemo, useState } from 'react';
import StatusIndicator from '../../../components/common/StatusIndicator';
import EnhancedTable from '../../../components/common/Table';
import { formatPhone } from '../../../utils/format';
import ActionMenu from '../../../components/ActionMenu';
import { useHistory } from 'react-router-dom';
import { STATUS_CLASSNAMES } from '../constants';
import SurveyModal from '../../../components/common/Modal/SurveyModal';

const ClientTableContainer = ({ isLoading, clients }) => {
  const history = useHistory();
  const [clientSelected, setClientSelected] = useState({
    isOpen: false,
    id: '',
  });

  const renderMenuAction = (id, name) => (
    <>
      <div className="action-container">
        <span
          className="material-symbols-outlined icon"
          onClick={() =>
            setClientSelected((prev) => {
              return { isOpen: !prev.isOpen, id: id };
            })
          }
        >
          more_horiz
        </span>
        {/* this allows to open just one menu in the corresponding client */}
        {clientSelected.id === id && (
          <ActionMenu isOpen={clientSelected.isOpen}>
            <div
              className="action-menu__item"
              onClick={() =>
                history.push(`/clientes/detalles`, {
                  client: id,
                })
              }
            >
              <span>Ver detalle</span>
            </div>
            <div
              className="action-menu__item"
              onClick={() => history.push(`clientes/editar`, { client: id })}
            >
              <span>Editar</span>
            </div>
          </ActionMenu>
        )}
      </div>
    </>
  );

  const columns = useMemo(
    () => [
      { Header: 'Cliente', accessor: 'name' },
      { Header: 'Nombre', accessor: 'contactName' },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Teléfono',
        accessor: 'phoneNumber',
        Cell: ({ value }) => formatPhone(value),
      },
      {
        Header: 'Estado',
        accessor: 'status',
        Cell: ({
          row: {
            original: { status },
          },
        }) => (
          <StatusIndicator
            text={status}
            statusClassName={
              status === 'Activo'
                ? STATUS_CLASSNAMES.ACTIVE
                : STATUS_CLASSNAMES.INACTIVE
            }
          />
        ),
      },
      {
        Header: 'Acción',
        accessor: 'id',
        Cell: ({
          value,
          row: {
            original: { name },
          },
        }) => renderMenuAction(value, name),
      },
    ],
    [clientSelected]
  );

  return (
    <>
      <EnhancedTable
        data={clients}
        columns={columns}
        isLoadingData={isLoading}
        tableName="Clientes"
      />
    </>
  );
};

export default ClientTableContainer;
