import { IconButton, Modal } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import TableSite from '../../../components/common/Table';
import Alert from '../../../components/common/Alert';
import { useHistory, useLocation } from 'react-router-dom';
import Menu, { MenuContainer } from '../../../components/common/Menu';
import Button from '../../../components/common/Button';
import ModalContent from '../../../components/common/Modal/ModalContent';
import SurveysAPI from '../survey-api';

const LIMIT_PER_PAGE = 10;
const SurveyTableContainer = () => {
  const history = useHistory();
  const [survey, setSurvey] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState({ type: '', text: '' });

  const [isMenuOpen, setIsMenuOpen] = useState({ open: false, id: '' });
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const fetchSurveys = () => {
    setIsLoading(true);
    SurveysAPI.listSurveys(currentPage, LIMIT_PER_PAGE)
      .then((response) => {
        setSurvey(
          response.data.surveys.map((survey) => ({
            id: survey.id,
            name: survey.name,
            uid: survey.uid,
            jsonData: survey.jsonData,
            bgColor: 'default',
          }))
        );
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        setMessage({ type: 'error', text: 'Error al cargar los cursos.' });
      })
      .finally(() => {
        setTimeout(() => {
          setMessage({ type: '', text: '' });
        }, 1500);
        setIsLoading(false);
        setRefresh(false);
      });
  };

  useEffect(() => {
    fetchSurveys();
  }, [currentPage, refresh]);

  const visualPageNumber = currentPage + 1;

  const onChangePage = (event, newPage) => {
    const newPageNumber = newPage - 1;
    if (newPageNumber >= 0 && newPageNumber < totalPages) {
      setCurrentPage(newPageNumber);
    }
  };

  const onClick = (survey) => {
    setIsMenuOpen((prev) => ({
      open: prev.id === survey.id ? !prev.open : true,
      id: survey.id,
      survey,
    }));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'name',
      },
      {
        Header: 'UID',
        accessor: 'uid',
      },
      {
        Header: '',
        id: 'actions',
        accessor: 'id',
        Cell: ({ row }) => {
          return (
            <MenuContainer onClick={() => onClick(row.original)}>
              {isMenuOpen.id === row.original.id && isMenuOpen.open && (
                <Menu
                  open={true}
                  items={[
                    {
                      text: 'Editar',
                      action: () => {
                        history.push(`/encuestas/editar`, {
                          surveyId: row.original.id,
                        });
                      },
                    },
                  ]}
                />
              )}
            </MenuContainer>
          );
        },
        disableSortBy: false,
      },
    ],
    [history, isMenuOpen]
  );
  return (
    <div className="user-container">
      {message.text != '' && <Alert type={message.type}>{message.text}</Alert>}

      <TableSite
        data={survey}
        columns={columns}
        currentPage={visualPageNumber}
        totalPages={totalPages}
        onChangePage={onChangePage}
        isLoadingData={isLoading}
        tableName="Encuestas"
        buttonLabel="Agregar nueva encuesta"
        onClickButton={() => history.push('/encuestas/crear')}
        isDynamic
      />
    </div>
  );
};

export default SurveyTableContainer;
