import React from "react";
import AnnouncementProvider from "./AnnouncementContext";
import AnnouncementWizard from "./AnnouncementWizard";

const AnnouncementCreation = () => {
  return (<AnnouncementProvider>
    <AnnouncementWizard/>
  </AnnouncementProvider>)

}
export default AnnouncementCreation;