import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Select from '../../../../components/forms/Select';
import { Input } from '../../../../components/forms';
import InputImage from '../../../../components/forms/Input/inputImage';
import InputWrapper from '../../../../components/forms/Input/inputWrapper';
import Button from '../../../../components/common/Button';
import InputFile from '../../../../components/forms/Input/inputFile';

const ALLOWED_CHAR = 160;
const ALLOWED_DESCRIPTION_CHAR = 300;

const TextForm = ({ workShopData, onNext, setWorkShopData }) => {
  const [fontSizeVisible, setFontSizeVisible] = useState(true);
  const defaultContent = workShopData.description;
  const blockFromHTML = convertFromHTML(defaultContent);
  const content = ContentState.createFromBlockArray(blockFromHTML);
  const [textEditor, setTextEditor] = useState(
    EditorState.createWithContent(content)
  );

  const toggleFontSizeVisibility = () => {
    setFontSizeVisible(!fontSizeVisible);
  };

  const onSubmit = (values) => {
    setWorkShopData(values);
    onNext();
  };

  const textFormSchema = Yup.object().shape({
    title: Yup.string()
      .max(
        ALLOWED_CHAR,
        `El largo máximo para el título son de ${ALLOWED_CHAR} caracteres`
      )
      .trim()
      .required('Debes ingresar un titulo para el taller'),
    description: Yup.string()
      .max(
        ALLOWED_DESCRIPTION_CHAR,
        `El largo máximo para la descripción son de ${ALLOWED_CHAR} caracteres`
      )
      .trim()
      .required('Debes ingresar una descripción para el taller'),
    receptor: Yup.string()
      .test(
        'valid-receptor',
        'Debes seleccionar un receptor válido',
        (value) => {
          return value === 'mentor' || value === 'mentee' || value === 'both';
        }
      )
      .required('Debes seleccionar un receptor para el taller'),
    image: Yup.string().required('Debes ingresar una imágen válida'),
    file: Yup.string().nullable(),
  });

  const toolbarOptions = {
    options: ['inline', 'fontSize', 'textAlign'],
    inline: {
      options: ['bold', 'italic', 'underline'],
    },
    fontSize: {
      className: 'toolbar__fontSize',
      options: [12, 14, 16, 18, 24, 30, 36],
      visible: fontSizeVisible,
    },
    textAlign: {
      className: 'toolbar__textAlign',
      options: ['left', 'center', 'right'],
    },
  };

  const history = useHistory();

  const returnWorshopTable = () => {
    history.push('/talleres');
  };

  return (
    <div className="wizard__form wizard__form--full">
      <Formik
        onSubmit={onSubmit}
        initialValues={workShopData}
        validationSchema={textFormSchema}
      >
        {(props) => {
          return (
            <Form>
              <div className="wizard__grid-section">
                <InputWrapper
                  variant="full"
                  id="title"
                  label="Título y descripción"
                  description="Nombre del taller"
                >
                  <Input
                    id="title"
                    label="Ingresa un texto breve"
                    name="title"
                    type="text"
                    max={160}
                  />
                </InputWrapper>
                <InputWrapper
                  variant="full"
                  id="description"
                  label="Descripción resumida"
                >
                  <Editor
                    id="description"
                    name="description"
                    wrapperClassName="editor__wrapper"
                    toolbarClassName="editor__toolbar"
                    editorState={textEditor}
                    onEditorStateChange={setTextEditor}
                    onBlur={() => {
                      props.setFieldTouched('description', true);
                      props.setFieldValue(
                        'description',
                        draftToHtml(
                          convertToRaw(textEditor.getCurrentContent())
                        )
                      );
                    }}
                    toolbar={toolbarOptions}
                  />
                  <div className="editor__error">
                    {props.touched.description && props.errors.description && (
                      <span className="error-message">
                        {props.errors.description}
                      </span>
                    )}
                    <span className="char-counter">
                      {textEditor.getCurrentContent().getPlainText('').length}/
                      {ALLOWED_DESCRIPTION_CHAR}
                    </span>
                  </div>
                </InputWrapper>

                <div>
                  <p className="title">Usuario receptor del taller</p>
                  <Select
                    name="receptor"
                    options={[]}
                    advancedOptions={[
                      { id: 'mentee', value: 'Mentee' },
                      { id: 'mentor', value: 'Mentor' },
                      { id: 'both', value: 'Ambos' },
                    ]}
                  />
                </div>
                <InputWrapper
                  variant="full"
                  label="Imagen del taller"
                  description="Archivos 443x263px JPG, PNG  - Tamaño máximo 5MB. "
                  id="logo"
                >
                  <InputImage
                    name="image"
                    id="image"
                    allowedExts={['image/png', 'image/jpg', 'image/jpeg']}
                  />
                </InputWrapper>
                <InputWrapper
                  variant="full"
                  label="Archivos complementarios"
                  description="Archivos PDF o PPT  -  Tamaño máximo 10MB. "
                  id="logo"
                >
                  <InputFile
                    name="file"
                    id="file"
                    accept=".pdf,.ppt,.pptx,.xls,.xlsx,.doc,.docx"
                    allowedExts={[
                      'application/pdf',
                      'application/vnd.ms-powerpoint',
                      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                      'application/vnd.ms-excel',
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                      'application/msword',
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    ]}
                  />
                </InputWrapper>
                <div className="wizard__action">
                  <Button secondary onClick={returnWorshopTable}>
                    Volver
                  </Button>
                  <Button type="submit">Siguiente</Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default TextForm;
