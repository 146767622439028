import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import Button from '../../../components/common/Button';
import { Input } from '../../../components/forms';
import InputWrapper from '../../../components/forms/Input/inputWrapper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import Select from '../../../components/forms/Select';
import UsersAPI from '../../UserContainer/user-api';
import { TODAY } from '../../../utils/constants';
import { AVAILABLE_HOURS } from '../../../utils/dates';

const SESSION_QTY = Array.from({ length: 15 }, (_, index) => {
  const qty = index + 1;
  const word = qty > 1 ? 'veces' : 'vez';
  return { id: qty, value: `${qty} ${word}` };
});

const DAYS_BETWEEN = Array.from({ length: 15 }, (_, index) => {
  const qty = index + 1;
  const word = qty > 1 ? 'días' : 'día';
  return { id: qty, value: `${qty} ${word}` };
});
const SessionForm = ({ session, setSession }) => {
  const {
    state: { clientId, ref },
  } = useLocation();

  const [mentors, setMentors] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await UsersAPI.getMentors(clientId, ref);

      setMentors(data);
    })();
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const schema = Yup.object().shape({
    mentorId: Yup.string().required('Debes seleccionar un mentor'),
    menteeId: Yup.string().required('Debes seleccionar un mentee'),
    title: Yup.string().required('Debes ingresar un asunto'),
    description: Yup.string().required('Debes ingresar una descripción'),
    startDay: Yup.date().required('Debes ingresar una fecha de inicio'),
    startTime: Yup.number()
      .min(8, 'Debes ingresar una hora válida')
      .required('Debes ingresar una hora'),
    timesToRepeat: Yup.number()
      .min(1, 'Debes seleccionar una catidad de días a repetir')
      .required('Debes seleccionar cantidad de días a repetir'),
    daysToRepeat: Yup.number()
      .min(1, 'Debes seleccionar una catidad de días a repetir')
      .required('Debes seleccionar cantidad de días entre reunión'),
  });
  return (
    <>
      <Formik
        initialValues={session}
        validationSchema={schema}
        validateOnChange
        onSubmit={(values) => setSession(values)}
      >
        {(props) => {
          return (
            <Form className="wizard__form wizard__form--center">
              <InputWrapper label="Mentor" id="mentorName">
                <input type="hidden" name="mentorId" />
                <Autocomplete
                  id="mentorName"
                  options={mentors}
                  getOptionLabel={(option) => {
                    return option.name.toLowerCase();
                  }}
                  onChange={(event, option) => {
                    props.setFieldValue('mentorId', option.id);
                    props.setFieldTouched('mentorId', true);
                  }}
                  onBlur={(e, option) => {
                    props.setFieldTouched('mentorId', true);
                  }}
                  style={props.style}
                  renderInput={(params) => <TextField {...params} {...props} />}
                />

                <span
                  className={`${
                    props.touched.mentorId && props.errors.mentorId
                      ? 'error-message'
                      : 'error-message error-message--non-visible'
                  }`}
                >
                  {props.errors.mentorId || ''}
                </span>
              </InputWrapper>
              <InputWrapper label="Mentee" id="menteeName">
                <Input
                  name="menteeName"
                  placeholder="Nombre mentee"
                  id="menteeName"
                  disabled
                />
              </InputWrapper>
              <InputWrapper label="Asunto" id="title">
                <Input name="title" placeholder="Asunto" />
              </InputWrapper>
              <InputWrapper label="Descripción" id="title">
                <Input
                  rows={4}
                  multiline
                  className="text-input"
                  name="description"
                  label="Ingrese descripción"
                  type="textarea"
                />
              </InputWrapper>
              <InputWrapper
                label="Seleccionar día y hora inicial"
                id="schedule"
              >
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: 8,
                  }}
                >
                  <Input
                    className="date"
                    name="startDay"
                    type="date"
                    InputProps={{
                      inputProps: { min: TODAY },
                    }}
                    required
                    pattern="\d{4}-\d{2}-\d{2}"
                  />
                  <Select
                    name="startTime"
                    placeholder="Hora"
                    label="Hora"
                    type="select"
                    options={[]}
                    advancedOptions={AVAILABLE_HOURS}
                  />
                </div>
              </InputWrapper>

              <InputWrapper label="Repetir" id="schedule">
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr auto 1fr',
                    gap: 8,
                  }}
                >
                  <Select
                    name="timesToRepeat"
                    label="15 veces"
                    type="select"
                    options={[]}
                    advancedOptions={SESSION_QTY}
                  />
                  <span>Cada</span>
                  <Select
                    name="daysToRepeat"
                    label="3 días"
                    type="select"
                    options={[]}
                    advancedOptions={DAYS_BETWEEN}
                  />
                </div>
              </InputWrapper>

              <div style={{ justifyContent: 'center', display: 'flex' }}>
                <Button type="submit" loading={isLoading}>
                  Ver horarios
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SessionForm;
