import React from 'react';
import ModalContent from '../../../components/common/Modal/ModalContent';
import { Modal } from '@material-ui/core';
import Button from '../../../components/common/Button';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import AnnouncementApi from '../announcement-api';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const UsersModal = ({
  isOpen,
  setIsOpen,
  users,
  setUsersChecked,
  announcementId,
  role,
  setAlertMessage,
}) => {
  const history = useHistory();

  const onClickRemove = (id) => {
    const list = users.filter((user) => user.id !== id);
    setUsersChecked(list);
  };

  const onClickAdd = async () => {
    try {
      const usersIds = users.map((u) => u.id);
      const data = AnnouncementApi.addUserToAnnouncement({
        users: usersIds,
        role,
        announcementId,
      });
      setAlertMessage({
        type: 'success',
        message: 'Usuarios agregados exitosamente',
      });
    } catch (error) {
      setAlertMessage({
        type: 'error',
        message: error.date.message,
      });
    } finally {
      setIsOpen(false);
    }
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} className="modal">
      <ModalContent
        onClick={() => setIsOpen(false)}
        title={`Se agregarán ${users.length} usuarios a la convocatoria`}
      >
        <div className="user-modal">
          <span style={{ textAlign: 'justify' }}>
            A continuación se muestran los usuarios que se agregarán a la
            convocatoria, si lo necesitas puedes remover usuarios de la lista.
            <br />
            Si un usuario se encuentra ya participando de una convocatoria,
            &nbsp;
            <strong>
              se le asignará el referer y el id del cliente al que se le está
              asignando
            </strong>
          </span>
          <div className="user-modal__container">
            {users.map((user) => (
              <div className="user-modal__item">
                <RemoveCircleOutlineIcon
                  className="icon"
                  onClick={() => onClickRemove(user.id)}
                />
                <span>{user.email}</span>
              </div>
            ))}
          </div>
          <Button secondary widthFull onClick={onClickAdd}>
            Agregar
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default UsersModal;
