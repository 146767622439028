import * as Yup from 'yup';
import { getTomorrowDate } from '../../../utils/dates';
import {
  YupEditorMaxLengthValidator,
  YupEditorMinLengthValidator,
} from '../../../utils/validators';
YupEditorMaxLengthValidator();
YupEditorMinLengthValidator();

export const ALLOWED_CHAR = 60;
export const ALLOWED_DESCRIPTION_CHAR = 200;
export const ALLOWED_ITEMS_CHAR = 100;

export const landingSchema = Yup.object().shape({
  title: Yup.string()
    .max(
      ALLOWED_CHAR,
      `El largo máximo para el título son de ${ALLOWED_CHAR} caracteres`
    )
    .trim()
    .required('Debes ingresar un titulo para la convocatoria'),
  startDate: Yup.date().min(getTomorrowDate(),'La fecha de registro debe ser desde mañana').required(
    'Debes ingresar una fecha de inicio de registro.'
  ),
  endDate: Yup.date().required('Debes ingresar una fecha de termino.'),
  urlMentee: Yup.string()
    .matches(
      /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
      'Debes ingresar una URL para registro mentee, no se aceptan caracteres especiales'
    )
    .required('Debes ingresar una URL para registro mentee'),
  urlMentor: Yup.string()
    .matches(
      /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
      'Debes ingresar una URL para registro mentor, no se aceptan caracteres especiales'
    )
    .required('Debes ingresar una URL para registro mentor'),
  logo: Yup.string().required('Debes ingresar una imágen válida'),
  desktopBanner: Yup.string().required(
    'Debes ingresar una imágen válida como Banner'
  ),
  mobileBanner: Yup.string().required(
    'Debes ingresar una imágen válida para el Banner en mobile'
  ),
  about: Yup.string()
    .editorMinLengthValidator(1, 'Debes ingresar la descripción del programa')
    .editorMaxLengthValidator(
      ALLOWED_DESCRIPTION_CHAR,
      `El largo máximo para la descripción son de ${ALLOWED_DESCRIPTION_CHAR} caracteres`
    )
    .required('Debes ingresar la descripción del programa'),
  items: Yup.array()
    .of(
      Yup.object({
        icon: Yup.string()
          .typeError('Debes seleccionar un icono de la lista')
          .required('Debes seleccionar un icono'),
        text: Yup.string()
          .editorMinLengthValidator(0, 'Debes ingresar un texto para los items')
          .editorMaxLengthValidator(
            ALLOWED_ITEMS_CHAR,
            `El largo máximo para la descripción son de ${ALLOWED_ITEMS_CHAR} caracteres`
          )
          .required('Debes ingresar los items'),
      })
    )
    .min(3, 'Debes ingresar minimo 3 items'),
});

export const landingEditSchema = Yup.object().shape({
  title: Yup.string()
    .max(
      ALLOWED_CHAR,
      `El largo máximo para el título son de ${ALLOWED_CHAR} caracteres`
    )
    .trim()
    .required('Debes ingresar un titulo para la convocatoria'),
  startDate: Yup.date().required(
    'Debes ingresar una fecha de inicio de registro.'
  ),
  endDate: Yup.date().required('Debes ingresar una fecha de termino.'),
  urlMentee: Yup.string()
    .matches(
      /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
      'Debes ingresar una URL para registro mentee, no se aceptan caracteres especiales'
    )
    .required('Debes ingresar una URL para registro mentee'),
  urlMentor: Yup.string()
    .matches(
      /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
      'Debes ingresar una URL para registro mentor, no se aceptan caracteres especiales'
    )
    .required('Debes ingresar una URL para registro mentor'),
  logo: Yup.string().required('Debes ingresar una imágen válida'),
  desktopBanner: Yup.string().required(
    'Debes ingresar una imágen válida como Banner'
  ),
  mobileBanner: Yup.string().required(
    'Debes ingresar una imágen válida para el Banner en mobile'
  ),
  about: Yup.string()
    .editorMinLengthValidator(1, 'Debes ingresar la descripción del programa')
    .editorMaxLengthValidator(
      ALLOWED_DESCRIPTION_CHAR,
      `El largo máximo para la descripción son de ${ALLOWED_DESCRIPTION_CHAR} caracteres`
    )
    .required('Debes ingresar la descripción del programa'),
  items: Yup.array()
    .of(
      Yup.object({
        icon: Yup.string()
          .typeError('Debes seleccionar un icono de la lista')
          .required('Debes seleccionar un icono'),
        text: Yup.string()
          .editorMinLengthValidator(0, 'Debes ingresar un texto para los items')
          .editorMaxLengthValidator(
            ALLOWED_ITEMS_CHAR,
            `El largo máximo para la descripción son de ${ALLOWED_ITEMS_CHAR} caracteres`
          )
          .required('Debes ingresar los items'),
      })
    )
    .min(3, 'Debes ingresar minimo 3 items'),
});

