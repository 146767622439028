import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  MenuItem,
  Popover,
  Typography,
} from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import TableSite from '../../../components/common/Table';
import Alert from '../../../components/common/Alert';
import UsersAPI from '../user-api';
import request from '../../../utils/request';
import { useHistory, useLocation } from 'react-router-dom';
import useDebounce from '../../../hooks/useDebounce';

const LIMIT_PER_PAGE = 25;
const UserContainer = () => {
  const location = useLocation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDropdown, setShowDropDown] = useState();
  const [showRoles, setShowRoles] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('info');
  const [activePage, setActivePage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setfilter] = useState('');

  const debouncedTerm = useDebounce(filter, 1000);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (location.state) {
      setMessage(location.state?.message);
      setTypeMessage(location.state?.type);
    }
  }, [location.state]);

  useEffect(() => {
    if (!debouncedTerm) {
      (async () => {
        try {
          setIsLoading(true);
          const { data } = await UsersAPI.getUsers(
            ['mentee'],
            activePage,
            LIMIT_PER_PAGE
          );
          setUsers(data.users);
          setTotalPages(data.totalPages);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
          setShowRoles(false);
        }
      })();
    }
  }, [activePage, debouncedTerm]);

  const onChangePage = (event, newPage) => {
    setActivePage(newPage);
  };

  useEffect(() => {
    if (debouncedTerm !== '') {
      (async () => {
        try {
          setIsLoading(true);
          const { data } = await UsersAPI.searchUsers('mentee', debouncedTerm);
          setUsers(data.users);
          setTotalPages(1);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [debouncedTerm]);

  const handleDeleteUser = (id) => {
    request({
      url: `/user/delete/${id}`,
      method: 'DELETE',
      private: true,
    })
      .then(({ data }) => {
        setAnchorEl(null);
        setShowDropDown(undefined);
        setMessage(data.message);
        setTypeMessage('success');
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .catch((error) => {
        console.error(error);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
  };
  const columns = React.useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Rol',
        accessor: 'role.name',
      },
      {
        Header: 'Pilar',
        accessor: 'pillar.name',
      },
      {
        Header: '',
        id: 'actions',
        accessor: function fnAccesor({ id, role, status }) {
          return (
            <>
              <IconButton
                onClick={() => {
                  history.push('/usuarios/editar', {
                    idUser: id,
                    roleType: role.type,
                  });
                }}
              >
                <Edit />
              </IconButton>
              <>
                <IconButton
                  onClick={(e) => {
                    if (showDropdown === id) {
                      setShowDropDown(undefined);
                    } else {
                      handleClick(e);
                      setShowDropDown(id);
                    }
                  }}
                >
                  <Delete />
                </IconButton>
                {showDropdown === id && (
                  <Popover
                    id="fade-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={!!showDropdown}
                    onClose={() => {
                      setAnchorEl(null);
                      setShowDropDown(undefined);
                    }}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Box p={2} width="200px">
                      <Typography>
                        ¿Estás seguro que deseas eliminar al usuario?
                      </Typography>
                      <MenuItem
                        style={{ color: 'red', placeContent: 'center' }}
                        onClick={() => {
                          handleDeleteUser(id);
                        }}
                      >
                        Eliminar
                      </MenuItem>
                      <MenuItem
                        style={{ placeContent: 'center' }}
                        onClick={() => {
                          setAnchorEl(null);
                          setShowDropDown(undefined);
                        }}
                      >
                        cancelar
                      </MenuItem>
                    </Box>
                  </Popover>
                )}
              </>
            </>
          );
        },
        disableSortBy: true,
      },
    ],
    [showRoles, showDropdown, anchorEl, history]
  );

  return (
    <div className="user-container">
      {message && <Alert type={typeMessage}>{message}</Alert>}
      <TableSite
        data={users}
        columns={columns}
        isLoadingData={isLoading}
        tableName="Usuarios"
        buttonLabel="Agregar usuario nuevo"
        onClickButton={() => history.push('/usuarios/crear')}
        onChangePage={onChangePage}
        currentPage={activePage}
        totalPages={totalPages}
        onSearch={(e) => setfilter(e.target.value)}
        isDynamic
      />
    </div>
  );
};

export default UserContainer;
