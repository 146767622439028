import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Input } from '../../../components/forms';
import Select from '../../../components/forms/Select';
import {
  FormControlLabel,
  Grid,
  Paper,
  Divider,
  IconButton,
  Switch,
  TextField,
} from '@material-ui/core';
import { Close, Edit } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import BenefitsAPI from '../benefit-api';
import Button from '../../../components/common/Button';
import Alert from '../../../components/common/Alert';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const BenefitEditContainer = () => {
  let timer;
  const history = useHistory();
  const [showAlert, setShowAlert] = useState(false);
  // const [categories, setCategories] = useState([]);
  const [messageAlert, setMessageAlert] = useState('');
  const [loader, setLoader] = useState(false);
  const [textoEditor, setTextoEditor] = useState(EditorState.createEmpty());

  const initialValues = {
    title: '',
    description: '',
    benefitType: '',
    longDescription: '',
    startDate: '',
    endDate: '',
    baseImage: '',
    largeImage: '',
    active: false,
  };

  useEffect(() => {
    // eslint-disable-next-line
    timer = setTimeout(function () {
      setShowAlert(false);
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, [showAlert]);

  const handleFileRead = (files) => {
    return new Promise((resolve) => {
      Array.from(files).forEach((archivo) => {
        var reader = new FileReader();
        reader.readAsDataURL(archivo);
        reader.onload = () => {
          resolve({ name: files[0].name, data: reader.result });
        };
      });
    });
  };
  const handleSubmit = (values) => {
    setLoader(true);
    values.longDescription = draftToHtml(
      convertToRaw(textoEditor.getCurrentContent())
    );
    values.startDate = +moment(values.startDate, 'YYYY-MM-DDTHH:mm');
    values.endDate = +moment(values.endDate, 'YYYY-MM-DDTHH:mm');
    BenefitsAPI.createBenefit(values)
      .then((response) => {
        setLoader(false);
        history.push({
          pathname: '/beneficios',
          state: {
            message: response.data.message,
            type: 'success',
          },
        });
      })
      .catch((e) => {
        setLoader(false);
        setMessageAlert(e.data.message);
        setShowAlert(true);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        title: Yup.string().required('Título requerido'),
        description: Yup.string().required('Descripción requerida'),
        benefitType: Yup.string().required('Ingrese tipo de beneficio'),
        // categories: Yup.string().required('Ingrese categoría de beneficio'),
        startDate: Yup.string().required('Ingrese fecha de inicio'),
        endDate: Yup.string().required('Ingrese fecha de término'),
        baseImage: Yup.string().required('Imágen requerida'),
        largeImage: Yup.string().required('Imágen requerida'),
      })}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, errors, touched }) => (
        <Form>
          <Grid className="user-container" container spacing={3}>
            <Grid item xs={12}>
              {showAlert && <Alert type="error">{messageAlert}</Alert>}
            </Grid>
            <Grid item xs={10}>
              <Paper style={{ padding: '20px' }} className="title">
                <Grid item className="close-container">
                  <IconButton
                    aria-label="delete"
                    className="close-benefit"
                    onClick={() => history.push('/beneficios')}
                  >
                    <Close />
                  </IconButton>
                </Grid>
                <Edit
                  style={{ marginRight: '10px' }}
                  fontSize="small"
                  className="edit-pen"
                />
                <span className="benefit-title">Editar Beneficio</span>

                <Divider />
                <Grid item>
                  <Grid container direction="column" className="personal-info">
                    <Grid item style={{ marginTop: '12px' }}>
                      <span>
                        <strong>Título</strong>
                      </span>
                      <Input name="title" placeholder="Título" label="" />
                    </Grid>
                    <Grid item xs={12}>
                      <span>
                        <strong>Descripción</strong>
                      </span>
                      <Input
                        name="description"
                        placeholder="Descripción"
                        label=""
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        label="Selecciona tipo de beneficio"
                        name="benefitType"
                        options={[]}
                        advancedOptions={[
                          { value: 'Destacado', id: 'featured' },
                          { value: 'Normal', id: 'normal' },
                        ]}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Select
                        label="Selecciona categoría de beneficio"
                        name='category'
                        options={categories}
                        advancedOptions={[]}
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <span>
                        <strong>Ingrese Descripción larga</strong>
                      </span>
                      {errors.longDescription && touched.longDescription ? (
                        <div className="benefit-error">
                          {errors.longDescription}
                        </div>
                      ) : null}
                      <div className="editorSpace">
                        <Editor
                          name="longDescription"
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class"
                          toolbarClassName="toolbar-class"
                          editorState={textoEditor}
                          onEditorStateChange={setTextoEditor}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '18px' }}>
                      <span>
                        <strong>Imagen de Beneficio</strong>
                        <br />
                      </span>
                      <em className="benefit-sub-label">
                        Debe ser de 290px x 150 pxs incluyendo el logo.
                      </em>
                      <input
                        name="baseImage"
                        type="file"
                        onChange={async (e) => {
                          const base64 = await handleFileRead(e.target.files);
                          setFieldValue('baseImage', base64);
                        }}
                        accept="image/*"
                      />
                      {errors.baseImage && touched.baseImage ? (
                        <div className="benefit-error">{errors.baseImage}</div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '18px' }}>
                      <span>
                        <strong>Imagen para slider (Imagen grande)</strong>
                        <br />
                      </span>
                      <em className="benefit-sub-label">
                        Archivos 440x260px JPG, PNG - Tamaño máximo 5MB.
                      </em>
                      <input
                        name="largeImage"
                        type="file"
                        onChange={async (e) => {
                          const base64 = await handleFileRead(e.target.files);
                          setFieldValue('largeImage', base64);
                        }}
                        accept="image/*"
                      />
                      {errors.baseImage && touched.largeImage ? (
                        <div className="benefit-error">{errors.largeImage}</div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="startDate"
                        id="datetime-local"
                        label="Inicio Publicación"
                        type="datetime-local"
                        defaultValue={initialValues.startDate}
                        onChange={(e) => {
                          setFieldValue('startDate', e.target.value);
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {errors.startDate && touched.startDate ? (
                        <div className="benefit-error">{errors.startDate}</div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <TextField
                        name="endDate"
                        id="datetime-local"
                        label="Término Publicación"
                        type="datetime-local"
                        defaultValue={initialValues.endDate}
                        onChange={(e) => {
                          setFieldValue('endDate', e.target.value);
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {errors.endDate && touched.endDate ? (
                        <div className="benefit-error">{errors.endDate}</div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        className="switch-label"
                        control={
                          <Switch
                            className="benefit-switch"
                            defaultChecked={initialValues.active}
                            onChange={(e) => {
                              setFieldValue('active', e.target.checked);
                            }}
                            name="active"
                          />
                        }
                        label="Activar Beneficio"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    xs={4}
                    style={{ backgroundColor: 'white' }}
                    className="submit"
                  >
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      spacing={3}
                    >
                      <Grid item xs={8}>
                        <Button type="submit" widthFull loading={loader}>
                          Aceptar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default BenefitEditContainer;
