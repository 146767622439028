import React, { useEffect, useState } from 'react';
import { FormControlLabel, Grid, Switch } from '@material-ui/core';
import { formatDate } from '../../../../utils/format';

const SessionSection = ({ user }) => {
  return (
    <Grid
      item
      xs={6}
      style={{
        backgroundColor: 'white',
      }}
      className="personal-info"
    >
      {user.role.name === 'mentor' && (
        <Grid item xs={12}>
          <h3>Tipo de registro</h3>

          <span>
            {user.preRegistry ? 'Postulación' : 'Registro Administrador'}
          </span>
        </Grid>
      )}
      <Grid item xs={12}>
        <h3>Rol</h3>
        <span>{user.role.name}</span>
      </Grid>
      <Grid item xs={12}>
        <h3>Reuniones totales</h3>
        <span>{user.totalSessions}</span>
      </Grid>
      <Grid item xs={12}>
        <h3>Fecha de enrolamiento</h3>
        <span>{formatDate(user.createdAt)}</span>
      </Grid>
      <Grid item xs={12}>
        <h3>Última conexión</h3>

        <span>
          {user.sessions.length
            ? formatDate(user.sessions[0].createdAt, 'DD/MM/YYYY HH:mm')
            : 'Nunca'}
        </span>
      </Grid>
    </Grid>
  );
};
export default SessionSection;
