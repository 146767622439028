import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { parseJwt } from '../utils/session';

export const AppContext = React.createContext();

class AppContextProvider extends Component {
  initialState = {
    loader: {
      show: false,
      message: 'Cargando...',
    },
  };

  constructor() {
    super();
    this.state = {
      ...this.initialState,
      user: undefined,
      roles: undefined,
      settings: undefined,
    };
  }

  componentDidMount() {
    const token = window.sessionStorage.getItem('converge.token.private');
    const roles = JSON.parse(window.sessionStorage.getItem('converge.roles'));
    const settings = JSON.parse(
      window.sessionStorage.getItem('converge.settings')
    );

    if (token) {
      const user = parseJwt(token);
      this.userActions.setUser(user);
    }

    roles && this.rolesActions.setRoles(roles);
    settings && this.settingsActions.setSettings(settings);
  }

  clean = () => {
    this.setState({
      ...this.initialState,
    });
  };

  userActions = {
    setUser: (user) => {
      this.setState(() => ({
        user,
      }));
    },
    cleanUser: () => {
      this.setState(() => ({
        user: undefined,
      }));
    },
  };

  loaderActions = {
    show: (message) => {
      this.setState(() => ({
        loader: { message, show: true },
      }));
    },
    hide: () => {
      this.setState(() => ({
        loader: { show: false },
      }));
    },
  };

  rolesActions = {
    setRoles: (roles) => {
      this.setState(() => ({
        roles,
      }));
    },
    cleanUser: () => {
      this.setState(() => ({
        roles: [],
      }));
    },
  };

  settingsActions = {
    setSettings: (settings) => {
      this.setState(() => ({
        settings,
      }));
    },
    cleanSettings: () => {
      this.setState(() => ({
        settings: [],
      }));
    },
  };

  render() {
    const { children } = this.props;
    const { user, loader, roles, settings } = this.state;

    return (
      <AppContext.Provider
        value={{
          user: { ...user, actions: this.userActions },
          loader: { ...loader, actions: this.loaderActions },
          roles: { roles, actions: this.rolesActions },
          settings: { settings, actions: this.settingsActions },
        }}
      >
        {children}
      </AppContext.Provider>
    );
  }
}

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(AppContextProvider);
