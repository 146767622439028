import request from '../../utils/request';

function getUsers(roles, pageNumber = null, limit = null) {
  return request({
    url: 'user/list',
    method: 'POST',
    data: {
      types: roles,
      pageNumber,
      limit,
    },
    private: true,
  });
}
function searchUsers(role, term) {
  return request({
    url: 'user/search',
    method: 'POST',
    data: {
      type: role,
      term,
    },
    private: true,
  });
}

function getUser(id) {
  return request({
    url: `user/get/${id}`,
    method: 'GET',
    private: true,
  });
}
function createUser(data) {
  return request({
    url: 'user/create',
    method: 'POST',
    private: true,
    data,
  });
}
function updateUser(id, data) {
  return request({
    url: `user/update/${id}`,
    method: 'PATCH',
    data,
    private: true,
  });
}

function deleteUser(id) {
  return request({
    url: `/user/delete/${id}`,
    method: 'DELETE',
    private: true,
  });
}
function updateMentorCalendar(schedules, date, user) {
  return request({
    url: `schedule/create`,
    method: 'POST',
    data: {
      schedules,
      date,
      user,
    },
    private: true,
  });
}
function getMentorCalendar(id) {
  return request({
    url: `schedule/get-by-user-external/${id}`,
    method: 'GET',
    private: true,
  });
}
function updateMenteePillars(pillars, user) {
  return request({
    url: '/user/pillars',
    method: 'PATCH',
    private: true,
    data: {
      pillars,
      user,
    },
  });
}
function getSessions(idUser) {
  return request({
    url: `/users/${idUser}/sessions`,
    method: 'GET',
    private: true,
  });
}
function getExtraData(idUser) {
  return request({
    url: `/users/${idUser}/extra`,
    method: 'GET',
    private: true,
  });
}
function updateExtraData(idUser, data) {
  return request({
    url: `users/${idUser}/extra`,
    method: 'PATCH',
    data: { data },
    private: true,
  });
}
function getBusySchedule(idMentor, idMentee) {
  return request({
    url: `schedules/busy/users/${idMentor}/${idMentee}`,
    method: 'GET',
    private: true,
  });
}
function getMentors(clientId = null, ref = null) {
  return request({
    url: `/users/mentors`,
    method: 'GET',
    private: true,
    params: {
      clientId,
      referer: ref,
    },
  });
}
function createMassiveSessions({
  calendar,
  idMentee,
  idMentor,
  title,
  description,
  notify,
  clientId = 1,
  announcementId = null,
  guestEmails,
}) {
  return request({
    url: '/sessions/massive',
    method: 'POST',
    private: true,
    data: {
      calendar,
      idMentee,
      idMentor,
      title,
      description,
      notify,
      clientId,
      announcementId,
      guestEmails,
    },
  });
}
async function fetchPdfUser(id) {
  return await request({
    url: `/report/pdf-user/${id}`,
    method: 'GET',
    private: true,
  });
}
async function fetchIndicators(clientId = 1, ref = null) {
  return await request({
    url: `mentor/indicators`,
    method: 'GET',
    private: true,
    params: {
      clientId,
      referer: ref,
    },
  });
}
async function fetchNextToDueDate(pageNumber, pageSize) {
  return await request({
    url: 'mentor/get-next-to-duedate',
    method: 'GET',
    private: true,
    params: {
      pageNumber,
      pageSize,
    },
  });
}
const UsersAPI = {
  getUsers,
  searchUsers,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  updateMentorCalendar,
  getMentorCalendar,
  updateMenteePillars,
  getSessions,
  getExtraData,
  updateExtraData,
  getBusySchedule,
  getMentors,
  createMassiveSessions,
  fetchPdfUser,
  fetchIndicators,
  fetchNextToDueDate,
};

export default UsersAPI;
