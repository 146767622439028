import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Input } from '../../../components/forms';
import { Grid, FormControlLabel, Switch } from '@material-ui/core';
import SettingsAPI from '../setting-api';
import Button from '../../../components/common/Button';
import Alert from '../../../components/common/Alert';
import { Skeleton } from '@material-ui/lab';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';

const SettingContainer = () => {
  const [initialValues, setInitialValues] = useState({
    name: '',
    sessionDuration: 0,
    sessionTips: '',
    sessionTipsMentor: '',
    termsAndConditions: '',
    totalActiveSessions: 0,
    requestExtraData: false,
    outOfService: false,
  });
  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('info');
  const [isLoading, setIsLoading] = useState(true);
  const [sessionTips, setSessionTips] = useState('');
  const [sessionTipsMentor, setSessionTipsMentor] = useState('');
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [activeExtraData, setActiveExtraData] = useState(false);
  const [activeOutOfService, setActiveOutOfService] = useState(false);
  const mdParser = new MarkdownIt();

  useEffect(() => {
    SettingsAPI.getSettings().then((response) => {
      setInitialValues({
        name: response.data.name,
        sessionDuration: response.data.sessionDuration,
        sessionTips: response.data.sessionTips,
        sessionTipsMentor: response.data.sessionTipsMentor,
        termsAndConditions: response.data.termsAndConditions,
        totalActiveSessions: response.data.totalActiveSessions,
        requestExtraData: response.data.requestExtraData,
        outOfService:
          response.data.outOfService === null
            ? false
            : response.data.outOfService,
      });
      setActiveExtraData(response.data.requestExtraData);
      setActiveOutOfService(
        response.data.outOfService === null ? false : response.data.outOfService
      );
      setSessionTips(response.data.sessionTips);
      setSessionTipsMentor(response.data.sessionTipsMentor);
      setTermsAndConditions(response.data.termsAndConditions);
      setIsLoading(false);
    });
  }, []);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    SettingsAPI.updateSettings({ ...values })
      .then((response) => {
        setMessage(response.data.message);
        setTypeMessage('success');
        setIsLoading(false);
      })
      .catch((e) => {
        setMessage(e.data.message);
        setTypeMessage('error');
        setIsLoading(false);
      });
  };

  return (
    <Grid container className="user-container__creation" direction="column">
      {message && <Alert type={typeMessage}>{message}</Alert>}
      <Grid
        item
        xs={8}
        className={`title `}
        style={{ backgroundColor: 'white' }}
      >
        <h1>Configuraciones</h1>
      </Grid>

      <Grid item>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={Yup.object({
            sessionDuration: Yup.number('Debe agregar solo números').required(
              'Ingrese tiempo de sesión'
            ),
            sessionTips: Yup.string().required(
              'Ingrese tips de sesión para mentee'
            ),
            sessionTipsMentor: Yup.string().required(
              'Ingrese tips de sesión para mentor'
            ),
            termsAndConditions: Yup.string().required(
              'Ingrese términos y condiciones'
            ),
            totalActiveSessions: Yup.number(
              'Debe agregar solo números'
            ).required('Ingrese número de sesiones activas'),
          })}
          onSubmit={handleFormSubmit}
        >
          {({ setFieldValue, errors, touched, values }) => (
            <Form>
              <Grid container>
                <Grid item xs={8} style={{ backgroundColor: 'white' }}>
                  <Grid
                    container
                    direction="column"
                    className="configuraciones-info"
                    style={{ backgroundColor: 'white' }}
                  >
                    <Grid item xs={12}>
                      {!isLoading ? (
                        <Input
                          name="name"
                          disabled
                          placeholder="Nombre"
                          label="Nombre"
                        />
                      ) : (
                        <Skeleton />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {!isLoading ? (
                        <Input
                          name="sessionDuration"
                          placeholder="Duración de sesión"
                          label="Duración de sesión"
                          type="number"
                          extra="Tiempo en horas"
                        />
                      ) : (
                        <Skeleton />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {!isLoading ? (
                        <FormControlLabel
                          className="swith-extradata input-swith"
                          control={
                            <Switch
                              checked={activeExtraData}
                              onChange={(e) => {
                                setFieldValue(
                                  'requestExtraData',
                                  e.target.checked
                                );
                                setActiveExtraData(e.target.checked);
                              }}
                              name="requestExtraData"
                            />
                          }
                          label="Mostrar datos adicionales"
                        />
                      ) : (
                        <Skeleton />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {!isLoading ? (
                        <Input
                          name="totalActiveSessions"
                          placeholder="Sesiones activas"
                          label="Sesiones activas"
                          type="number"
                        />
                      ) : (
                        <Skeleton />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {!isLoading ? (
                        <FormControlLabel
                          className="input-swith"
                          control={
                            <Switch
                              checked={activeOutOfService}
                              onChange={(e) => {
                                setFieldValue('outOfService', e.target.checked);
                                setActiveOutOfService(e.target.checked);
                              }}
                              name="outOfService"
                            />
                          }
                          label="Fuera de Servicio"
                        />
                      ) : (
                        <Skeleton />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {!isLoading ? (
                        <div className="editor-markdown__container">
                          <label htmlFor="text">Tips para mentee</label>
                          <MdEditor
                            value={sessionTips}
                            style={{ height: '250px' }}
                            plugins={['header', 'fonts', 'link', 'mode-toggle']}
                            renderHTML={(text): string => mdParser.render(text)}
                            onChange={({ text }): void => {
                              setSessionTips(text);
                              setFieldValue('sessionTips', text);
                            }}
                            name="sessionTips"
                          />
                          {errors.sessionTips && touched.sessionTips ? (
                            <div className="error-message">
                              {errors.sessionTips}
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <Skeleton />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {!isLoading ? (
                        <div className="editor-markdown__container">
                          <label htmlFor="text">Tips para mentor</label>
                          <MdEditor
                            value={sessionTipsMentor}
                            style={{ height: '250px' }}
                            plugins={['header', 'fonts', 'link', 'mode-toggle']}
                            renderHTML={(text): string => mdParser.render(text)}
                            onChange={({ text }): void => {
                              setSessionTipsMentor(text);
                              setFieldValue('sessionTipsMentor', text);
                            }}
                            name="sessionTipsMentor"
                          />
                          {errors.sessionTipsMentor &&
                          touched.sessionTipsMentor ? (
                            <div className="error-message">
                              {errors.sessionTipsMentor}
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <Skeleton />
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      {!isLoading ? (
                        <div className="editor-markdown__container">
                          <label htmlFor="text">Términos y condiciones</label>
                          <MdEditor
                            value={termsAndConditions}
                            style={{ height: '250px' }}
                            plugins={['header', 'fonts', 'link', 'mode-toggle']}
                            renderHTML={(text): string => mdParser.render(text)}
                            onChange={({ text }): void => {
                              setTermsAndConditions(text);
                              setFieldValue('termsAndConditions', text);
                            }}
                            name="termsAndConditions"
                          />
                          {errors.termsAndConditions &&
                          touched.termsAndConditions ? (
                            <div className="error-message">
                              {errors.termsAndConditions}
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <Skeleton />
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="submit">
                    <Grid container justify="center">
                      <Grid item xs={4}>
                        {!isLoading ? (
                          <Button type="submit" widthFull>
                            Aceptar
                          </Button>
                        ) : (
                          <Skeleton />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default SettingContainer;
